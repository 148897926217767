import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EditInPlaceComponent } from './edit-in-place/edit-in-place.component';
import { EditModeDirective } from './edit-mode.directive';
import { ViewModeDirective } from './view-mode.directive';

@NgModule({
  declarations: [EditInPlaceComponent, ViewModeDirective, EditModeDirective],
  imports: [CommonModule],
  exports: [EditInPlaceComponent, ViewModeDirective, EditModeDirective],
})
export class EditInPlaceModule {}
