/* eslint-disable */
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'jsonParse'
})

export class JsonParserPipe implements PipeTransform {
  transform(value){
    return JSON.parse(value);
  }
}
