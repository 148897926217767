import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { AvasPatient } from 'src/app/models/Dashboard';

export const featureAdapter = createEntityAdapter<AutomationFollowupPatientStoreEntity>({
  selectId: (x) => (x.isLead ? 'Lead_' : 'Patient_') + x.id,
  sortComparer: (a, b) => a.id - b.id,
});

export interface State extends EntityState<AutomationFollowupPatientStoreEntity> {
  avaPatientsCount: AvaPatientInfo;
  isLoading: boolean;
  error: string | unknown;
}

export const initialState: State = featureAdapter.getInitialState({
  avaPatientsCount: null,
  isLoading: false,
  error: null,
});

export type AutomationFollowupPatientArea = 'Pending' | 'Ava';
export type AutomationFollowupPatientCard = 'NewLead' | 'NoShow' | 'Recall' | 'Pending' | 'Misc';
export type AutomationFollowupPatientStoreEntity = AvasPatient & { cardArea: AutomationFollowupPatientArea; card: AutomationFollowupPatientCard };

export interface AvaPatientInfo {
  NewLead: number;
  NoShow: number;
  Recall: number;
  Pending: number;
  Misc: number;
}
