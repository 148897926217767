/* eslint-disable */
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {

	@Input() placeholderTxt: any;
	@Input()  set ddOptions (options: {id: number; option: string}[]) {
    this.intializeDDOptions();
    this._ddOptions = options;
  }
	@Input() theme: any;
	@Output() changeSelection = new EventEmitter();
	@Input() defaultOpen: boolean;
  //@Input() isNote:boolean;
	_ddOptions: {id: number; option: string;}[];

	showOptions: boolean = false;
	optionSelected: boolean = false;

	subscription: Subscription;
	dd_id:any;

  constructor(private dataService:DataService) { }

  ngOnInit() {
	this.intializeDDOptions();
	this.subscription = this.dataService.getMessage<{ dd_id; }>().subscribe(message => {
		if(message.event == 'dropdownstate'){
			if(this.dd_id != message.data.dd_id){
				this.showOptions = false;
			}
		}
	});
  }

  intializeDDOptions() {
	this.dd_id = (+ new Date()) + (Math.floor(Math.random() * 1000) + 1);
	if(this.defaultOpen == true){
		this.showOptions = true;
	} else {
		this.showOptions = false;
	}
  }

  ddOpen() {
	this.showOptions = this.showOptions==true ? false : true;
	this.dataService.sendMessage('dropdownstate', {'dd_id': this.dd_id, 'state': this.showOptions});
  }

  onClickedOutside() {
	this.showOptions = false;



  }

  selectedOption(indx) {
  //  console.info(this.theme);
	this.placeholderTxt = this._ddOptions[indx].option;
	this.showOptions = false;
  this.optionSelected = true;
  // if(this.isNote)
	// this.changeSelection.emit(this.placeholderTxt);
  // else
	this.changeSelection.emit(this._ddOptions[indx].id);
  }

}
