import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { AvaSliderAcceptedSettings, AvaSliderDto, IPerson, PaymentOptionDto, TreatmentTypeDto } from 'src/app/services/api.service';
import { AvaSliderEditorDialogComponent, IAvaSliderEditorDialogData } from './modal/ava-slider-editor-dialog/ava-slider-editor-dialog.component';
import { CasePresentDialogComponent, ICasePresentDialogData } from './modal/case-present-dialog/case-present-dialog.component';
import {
  IPaymentOptionEditorDialogData,
  PaymentOptionEditorDialogComponent,
} from './modal/payment-option-editor-dialog/payment-option-editor-dialog.component';
import {
  ISendSliderDialogData,
  ISendSliderDialogResult,
  ISendSliderRecipientOption,
  SendSliderDialogComponent,
} from './modal/send-slider-dialog/send-slider-dialog.component';
import {
  ITreatmentTypeEditorDialogData,
  TreatmentTypeEditorDialogComponent,
} from './modal/treatment-type-editor-dialog/treatment-type-editor-dialog.component';

@Injectable()
export class CasePresentationService {
  constructor(private _dialog: MatDialog) {}

  public openTreatmentTypeEditorDialog(treatmentType: TreatmentTypeDto, dialogConfig: Partial<MatDialogConfig> = {}): Observable<TreatmentTypeDto> {
    return this._dialog
      .open<TreatmentTypeEditorDialogComponent, ITreatmentTypeEditorDialogData, TreatmentTypeDto>(TreatmentTypeEditorDialogComponent, {
        data: { treatmentType: treatmentType.clone() },
        ...dialogConfig,
      })
      .afterClosed();
  }

  public openPaymentOptionEditorDialog(paymentOption: PaymentOptionDto, dialogConfig: Partial<MatDialogConfig> = {}): Observable<PaymentOptionDto> {
    return this._dialog
      .open<PaymentOptionEditorDialogComponent, IPaymentOptionEditorDialogData, PaymentOptionDto>(PaymentOptionEditorDialogComponent, {
        data: { paymentOption: paymentOption.clone() },
        ...dialogConfig,
      })
      .afterClosed();
  }

  public openCasePresentDialog(casePresentation: AvaSliderDto, dialogConfig: Partial<MatDialogConfig> = {}): Observable<AvaSliderAcceptedSettings> {
    return this._dialog
      .open<CasePresentDialogComponent, ICasePresentDialogData, AvaSliderAcceptedSettings>(CasePresentDialogComponent, {
        data: { casePresentation: casePresentation.clone() },
        width: '720px',
        ...dialogConfig,
      })
      .afterClosed();
  }

  public openAvaSliderEditorDialog(avaSlider: AvaSliderDto, dialogConfig: Partial<MatDialogConfig> = {}): Observable<AvaSliderDto> {
    return this._dialog
      .open<AvaSliderEditorDialogComponent, IAvaSliderEditorDialogData, AvaSliderDto>(AvaSliderEditorDialogComponent, {
        data: { avaSlider: avaSlider.clone() },
        ...dialogConfig,
      })
      .afterClosed();
  }

  public openSendSliderDialog(
    avaSlider: AvaSliderDto,
    recipientOptions: ISendSliderRecipientOption[],
    patient: IPerson,
    dialogConfig: Partial<MatDialogConfig> = {}
  ): Observable<ISendSliderDialogResult> {
    return this._dialog
      .open<SendSliderDialogComponent, ISendSliderDialogData, ISendSliderDialogResult>(SendSliderDialogComponent, {
        data: { avaSlider, recipientOptions, patient },
        width: '720px',
        ...dialogConfig,
      })
      .afterClosed();
  }
}
