import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, combineLatest, noop, of } from 'rxjs';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/ComponentBase';
import { RootStoreState, UserStoreSelectors } from 'src/app/root-store';
import {
  AcceptViewModel,
  AddAvaSliderModel,
  AvaSliderDto,
  CasePresentationClient,
  IPerson,
  PatientContractClient,
  SendPaymentPlanRequest,
  SignPatientContractDto,
} from 'src/app/services/api.service';
import { CasePresentationService } from 'src/app/shared/case-presentation/case-presentation.service';
import { ISendSliderRecipientOption } from 'src/app/shared/case-presentation/modal/send-slider-dialog/send-slider-dialog.component';
import { FinancialService } from 'src/app/shared/financial/financial.service';

@Component({
  selector: 'app-ava-sliders',
  templateUrl: './ava-sliders.component.html',
  styleUrls: ['./ava-sliders.component.scss'],
  host: {
    '[class.disabled]': 'this.disabled',
  },
})
export class AvaSlidersComponent extends ComponentBase {
  @ViewChild('deleteConfirmTemplate', { static: true }) deleteConfirmTemplate: TemplateRef<unknown>;

  private _patientId$ = new ReplaySubject<number>();
  private _patient: IPerson;
  public get patient(): IPerson {
    return this._patient;
  }
  @Input('patient')
  public set patient(value: IPerson) {
    this._patient = value;
    if (value && value.patientId) {
      this._patientId$.next(value.patientId);
    }
  }

  @Input('disabled') disabled: boolean = false;

  private _refreshAvaSliders$ = new BehaviorSubject<void>(null);
  public avaSliders$: Observable<AvaSliderDto[]> = this._refreshAvaSliders$.pipe(
    switchMap(() => this._patientId$),
    switchMap((patientId) => this._casePresentationClient.casePresentation_GetAvaSliderByPatient(patientId)),
    map((results) => results.sort((a, b) => a.createDate.getTime() - b.createDate.getTime()))
  );

  constructor(
    private _casePresentationClient: CasePresentationClient,
    private _casePresentationService: CasePresentationService,
    private _dialog: MatDialog,
    private _store$: Store<RootStoreState.State>,
    private _financialService: FinancialService,
    private _patientContractClient: PatientContractClient
  ) {
    super();
  }

  addAvaSlider(): void {
    const avaSlider = new AvaSliderDto({
      id: 0,
      patientId: this._patient.patientId,
      contractTemplateId: null,
      createDate: new Date(Date.now()),
      settings: {
        insuranceCoverageAmount: 0,
        paymentOptions: [],
        treatmentTypes: [],
      },
    });

    this.__subSink.sink = this._casePresentationService
      .openAvaSliderEditorDialog(avaSlider)
      .pipe(
        tap(() => this.__working()),
        switchMap((result) => (result ? this._casePresentationClient.casePresentation_Add(new AddAvaSliderModel(result)) : EMPTY)),
        take(1)
      )
      .subscribe(
        () => this._refreshAvaSliders$.next(),
        noop,
        () => this.__doneWorking()
      );
  }

  editAvaSlider(avaSlider: AvaSliderDto): void {
    this.__subSink.sink = this._casePresentationService
      .openAvaSliderEditorDialog(avaSlider)
      .pipe(
        tap(() => this.__working()),
        tap((result) => {
          if (result && !!result.acceptDate) {
            // Reset sent status on edit
            result.emailSendDate = null;
          }
        }),
        switchMap((result) => (result ? this._casePresentationClient.casePresentation_Update(result.id, new AddAvaSliderModel(result)) : EMPTY)),
        take(1)
      )
      .subscribe(noop, noop, () => {
        this._refreshAvaSliders$.next();
        this.__doneWorking();
      });
  }

  sendAvaSlider(avaSlider: AvaSliderDto): void {
    const recipientOptions: ISendSliderRecipientOption[] = [
      {
        recipient: {
          firstName: this._patient.firstName.trim(),
          lastName: this._patient.lastName.trim(),
          to: this._patient.email.trim(),
        },
      },
      ...this.patient.patientResponsiblePartyLinks
        .map((x) => x.responsibleParty)
        .filter((x) => !this.__isNullOrUndefined(x.email) && x.canSendEmail)
        .map((x) => ({
          recipient: {
            firstName: x.firstName.trim(),
            lastName: x.lastName.trim(),
            to: x.email.trim(),
          },
          responsiblePartyId: x.responsiblePartyId,
        })),
    ];
    this.__subSink.sink = this._casePresentationService
      .openSendSliderDialog(avaSlider, recipientOptions.length > 0 ? recipientOptions : null, this._patient)
      .pipe(
        switchMap((result) =>
          result ? combineLatest([of(result), this._store$.select(UserStoreSelectors.selectUserById(result.teamMemberId))]) : EMPTY
        ),
        tap(() => this.__working()),
        switchMap(([result, teamMember]) =>
          result
            ? this._casePresentationClient.casePresentation_SendMail(
                new SendPaymentPlanRequest({
                  patientId: this._patient.patientId,
                  paymentPlanId: avaSlider.id,
                  teamMemberId: result.teamMemberId,
                  emailSignature: result.footer,
                  file: null,
                  from: teamMember.firstName + ' ' + teamMember.lastName,
                  message: result.body + result.buttonHtml,
                  responsiblePartyId: result.responsiblePartyId,
                  subject: result.subject,
                  toEmail: result.recipient.to,
                  toName: result.recipient.firstName + ' ' + result.recipient.lastName,
                })
              )
            : EMPTY
        ),
        take(1)
      )
      .subscribe(
        () => this._refreshAvaSliders$.next(),
        noop,
        () => this.__doneWorking()
      );
  }

  launchSliderPresentation(avaSlider: AvaSliderDto): void {
    this.__subSink.sink = this._casePresentationService
      .openCasePresentDialog(avaSlider, { width: '100vw', height: '100vh' })
      .pipe(
        tap(() => this.__working()),
        switchMap((result) =>
          result
            ? this._casePresentationClient
                .casePresentation_Accept(
                  new AcceptViewModel({
                    settings: result,
                  }),
                  avaSlider.id
                )
                .pipe(
                  withLatestFrom(of(result)),
                  tap(() => this._refreshAvaSliders$.next())
                )
            : EMPTY
        ),
        switchMap(([result, acceptedSettings]) =>
          result.patientContractId
            ? combineLatest([
                of(result),
                this._financialService.openContractViewDialog(result.patientContractId, acceptedSettings, avaSlider.patientId),
              ])
            : EMPTY
        ),
        switchMap((x) => combineLatest([of(x), this._financialService.openSignatureDialog('full')])),
        switchMap(([[sliderResult, contractSignatures], finalSignature]) =>
          this._patientContractClient.patientContract_SignPatientContract(
            new SignPatientContractDto({
              patientContractId: sliderResult.patientContractId,
              signatures: contractSignatures.signatures,
              finalSignature: finalSignature.value,
              checkboxes: contractSignatures.checkboxes,
            }),
            sliderResult.patientContractId,
            avaSlider.patientId
          )
        )
      )
      .subscribe(noop, noop, () => this.__doneWorking());
  }

  deleteAvaSlider(avaSlider: AvaSliderDto): void {
    this.__subSink.sink = this._dialog
      .open(this.deleteConfirmTemplate, { data: avaSlider })
      .afterClosed()
      .pipe(
        tap(() => this.__working()),
        switchMap((result) => (result ? this._casePresentationClient.casePresentation_Delete(avaSlider.id) : EMPTY))
      )
      .subscribe(
        () => this._refreshAvaSliders$.next(),
        noop,
        () => this.__doneWorking()
      );
  }
}
