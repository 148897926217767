import { FollowUpCategoryEnum, Patient } from '../models/AvaContext';
import { ActionType } from '../models/Communication';
import { AvasPatient } from '../models/Dashboard';
import { INotificationPersonDto, INotificationResponsiblePartyDto, IPerson, IResponsibleParty } from '../services/api.service';

function isCommunicationOptOut(
  person: INotificationPersonDto | IPerson,
  patients: AvasPatient[],
  selectedResponsibleParty: INotificationResponsiblePartyDto | IResponsibleParty
): boolean {
  return (
    (person && person.isCommunicationOptOut) ||
    (selectedResponsibleParty && selectedResponsibleParty.isCommunicationOptOut) ||
    (patients && patients.some((x) => x.isCommunicationOptOut))
  );
}

function noValidPatientsCount(patients: AvasPatient[], criteria: 'email' | 'phone'): number {
  if (!patients) {
    return 0;
  }
  return (patients || []).filter((x) => (criteria == 'email' && !x.isEmailValid) || (criteria == 'phone' && !x.isPhoneValid)).length;
}

export function GetCommunicationAlertMessage(
  person: INotificationPersonDto | IPerson,
  patients: AvasPatient[],
  selectedResponsibleParty: INotificationResponsiblePartyDto | IResponsibleParty,
  followUpCategory?: FollowUpCategoryEnum,
  actions?: ActionType[]
): string {
  const canEmail = canSendEmail(person, patients, selectedResponsibleParty);
  const canText = canSendSms(person, patients, selectedResponsibleParty);
  const canSendMessage = canEmail || canText;
  let message = '';
  const isMultiMode = (patients || []).length > 0;
  const noValidEmailCount = isMultiMode ? noValidPatientsCount(patients, 'email') : 0;
  const noValidPhoneCount = isMultiMode ? noValidPatientsCount(patients, 'phone') : 0;
  const totalCount = (patients || []).length;

  // CONDITION: Patient or responsible party has opted out from communication
  if (isCommunicationOptOut(person, patients, selectedResponsibleParty)) {
    const name = isMultiMode
      ? 'selected patients'
      : selectedResponsibleParty && !person.isCommunicationOptOut
      ? selectedResponsibleParty.firstName
      : person.firstName;
    message = `${name} has been opted out of communication`;

    // CONDITION: The user is trying to set a Lead into a patient follow-up sequence
  } else if (isLead(person, patients) && followUpCategory && followUpCategory !== FollowUpCategoryEnum.NewLead) {
    message = 'A lead may not be added to a patient follow-up. ';

    // CONDITION: Only one action (i.e., "Set" message or sending a message from communcation modal)
  } else if (actions && actions.length === 1) {
    const action = actions[0];
    if (action === ActionType.email && !canEmail) {
      if (isLead(person, patients)) {
        message = "I can't send emails to leads.";
      } else {
        message = isMultiMode
          ? `I can't send emails to selected patients because ${noValidEmailCount} out of ${totalCount} do not have a valid email address.`
          : `I can't send emails ${selectedResponsibleParty ? 'on behalf of' : 'to'} ${person.firstName} because  ${
              selectedResponsibleParty ? selectedResponsibleParty.firstName + ' ' + selectedResponsibleParty.lastName : person.firstName
            } does not have a ${
              person.isChild && (!person.patientResponsiblePartyLinks || !person.patientResponsiblePartyLinks.length)
                ? 'responsible party with a'
                : ''
            } valid email address.`;
      }
    } else if (action === ActionType.message && !canText) {
      message = isMultiMode
        ? `I can't send texts to selected patients because ${noValidPhoneCount} out of ${totalCount} do not have a valid phone number.`
        : `I can't send texts ${selectedResponsibleParty ? 'on behalf of' : 'to'} ${person.firstName} because  ${
            selectedResponsibleParty ? selectedResponsibleParty.firstName + ' ' + selectedResponsibleParty.lastName : person.firstName
          } does not have a ${
            person.isChild && (!person.patientResponsiblePartyLinks || !person.patientResponsiblePartyLinks.length) ? 'responsible party with a' : ''
          } valid phone number.`;
    }
    // CONDITION: More than one action (i.e., "Auto" sequence)
  } else if (actions && actions.length > 1 && (!canText || !canEmail)) {
    // CONDITION: PMS patient or NPS patient
    if (isLead(person, patients)) {
      message = "I can't send emails to leads";

      if (!canText) {
        message += isMultiMode
          ? `, and I can't send texts to selected patients because ${noValidPhoneCount} out of ${totalCount} do not have a valid cell phone number`
          : `, and I can't send texts ${selectedResponsibleParty ? 'on behalf of' : 'to'} ${person.firstName} because  ${
              selectedResponsibleParty ? selectedResponsibleParty.firstName + ' ' + selectedResponsibleParty.lastName : person.firstName
            } does not have a ${
              person.isChild && (!person.patientResponsiblePartyLinks || !person.patientResponsiblePartyLinks.length)
                ? 'responsible party with a'
                : ''
            } valid cell phone number`;
      }
      message += '.';
    } else {
      if (isMultiMode) {
        message = `I can't send ${canSendMessage ? (!canEmail ? 'emails' : 'texts') : 'texts or emails'} to selected patients because `;
        const msgs: string[] = [];
        if (noValidPhoneCount > 0) {
          msgs.push(`${noValidPhoneCount} out of ${totalCount} do not have a valid cell phone number`);
        }
        if (noValidEmailCount > 0) {
          msgs.push(`${noValidEmailCount} out of ${totalCount} do not have a valid email address`);
        }
        if (msgs.length > 0) {
          message += ` ${msgs.join(' and ')}`;
        }
      } else {
        message = `I can't send ${canSendMessage ? (!canEmail ? 'emails' : 'texts') : 'texts or emails'} ${
          selectedResponsibleParty ? 'on behalf of' : 'to'
        } ${person.firstName} because  ${
          selectedResponsibleParty ? selectedResponsibleParty.firstName + ' ' + selectedResponsibleParty.lastName : person.firstName
        } does not have a ${
          person.isChild && (!person.patientResponsiblePartyLinks || !person.patientResponsiblePartyLinks.length) ? 'responsible party with a' : ''
        } valid ${canSendMessage ? (!canEmail ? 'email address' : 'cell phone number') : 'email address or cell phone number'}.`;
      }
    }

    if (actions.length === 3) {
      message += ` However, I can still send ${canSendMessage ? (canEmail ? 'emails and' : 'texts and') : ''} call notifications.`;
    }
  }

  return message;
}

function isLead(person: INotificationPersonDto | IPerson, patients: AvasPatient[]): boolean {
  if (person != null && person.isLead) {
    return true;
  }
  if (patients != null && patients.length > 0 && patients.some((x) => x.isLead)) {
    return true;
  }
  return false;
}

export function canSendEmail(
  person: INotificationPersonDto | IPerson,
  patients: AvasPatient[],
  selectedResponsibleParty: INotificationResponsiblePartyDto | IResponsibleParty
): boolean {
  if (isLead(person, patients)) {
    return false;
  } else if (selectedResponsibleParty) {
    return selectedResponsibleParty.canSendEmail;
  } else if (person && person.isChild) {
    return !!person.patientResponsiblePartyLinks.find((p) => p.responsibleParty.canSendEmail);
  } else {
    return (person != null && person.canSendEmail) || (patients != null && patients.every((x) => x.isEmailValid));
  }
}

export function canSendSms(
  person: INotificationPersonDto | IPerson,
  patients: AvasPatient[],
  selectedResponsibleParty: INotificationResponsiblePartyDto | IResponsibleParty
): boolean {
  if (selectedResponsibleParty) {
    return selectedResponsibleParty.canSendSms;
  } else if (person && person.isChild) {
    return !!person.patientResponsiblePartyLinks.find((p) => p.responsibleParty.canSendSms);
  } else {
    return (person != null && person.canSendSms) || (patients != null && patients.every((x) => x.isPhoneValid));
  }
}

export function isResponsiblePartyForAdult(
  person: INotificationPersonDto | IPerson,
  selectedResponsibleParty: INotificationResponsiblePartyDto | IResponsibleParty
): boolean {
  return person instanceof Patient && person.age >= 18 && !!selectedResponsibleParty;
}
