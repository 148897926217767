import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { noop } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/ComponentBase';
import { CasePresentationClient, PatientLedgerTransactionDto, PostingCodeTypeEnum } from 'src/app/services/api.service';

@Component({
  selector: 'app-import-slider-dialog',
  templateUrl: './import-slider-dialog.component.html',
  styleUrls: ['./import-slider-dialog.component.scss'],
})
export class ImportSliderDialogComponent extends ComponentBase {
  __avaSliders$ = this._casePresentationClient.casePresentation_GetAvaSliderByPatient(this._data.patientId).pipe(
    map((sliders) => sliders.filter((x) => x.acceptDate != null)),
    tap(() => this.__doneWorking()),
    shareReplay()
  );
  __transactionsPreview: PatientLedgerTransactionDto[];

  constructor(@Inject(MAT_DIALOG_DATA) private _data: IImportSliderDialogData, private _casePresentationClient: CasePresentationClient) {
    super();
    this.__working();
  }

  __getResult(sliderId: number): IImportSliderDialogResult {
    return {
      ledgerId: this._data.ledgerId,
      patientId: this._data.patientId,
      sliderId: sliderId,
    };
  }

  __previewTransactions(sliderId: number): void {
    this.__working();
    this.__subSink.sink = this._casePresentationClient
      .casePresentation_GetTransactionsForSlider(sliderId)
      .pipe(take(1))
      .subscribe(
        (result) => (this.__transactionsPreview = result),
        noop,
        () => this.__doneWorking()
      );
  }

  __getPostingCodeType(value: PostingCodeTypeEnum): string {
    return PostingCodeTypeEnum[value];
  }

  __assertItemType(item: unknown): PatientLedgerTransactionDto {
    return this.__defineElementAs<PatientLedgerTransactionDto>(item);
  }
}

export interface IImportSliderDialogData {
  ledgerId: number;
  patientId: number;
}

export interface IImportSliderDialogResult {
  ledgerId: number;
  patientId: number;
  sliderId: number;
}
