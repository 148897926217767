import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ContractCheckboxes, ContractSignatures, IContractViewData } from '../../contract-view/contract-view.component';

@Component({
  selector: 'app-contract-view-dialog',
  templateUrl: './contract-view-dialog.component.html',
  styleUrls: ['./contract-view-dialog.component.scss'],
})
export class ContractViewDialogComponent {
  get __contractViewData(): IContractViewData {
    return this._data.contractViewData;
  }

  __signatures: ContractSignatures = {};
  __checkboxes: ContractCheckboxes = {};

  constructor(@Inject(MAT_DIALOG_DATA) private _data: IContractViewDialogData) {}

  __getResult(): IContractViewDialogResult {
    return {
      signatures: this.__signatures,
      checkboxes: this.__checkboxes,
    };
  }
}

export interface IContractViewDialogData {
  contractViewData: IContractViewData;
}

export interface IContractViewDialogResult {
  signatures: ContractSignatures;
  checkboxes: ContractCheckboxes;
}
