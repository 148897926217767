import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, share, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthorizeClient, IOpenIddictResponseDto, OpenIddictResponseDto, UserDetailsDto } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { State } from '../root-state';
import * as AuthStoreActions from './actions';
import * as AuthStoreSelectors from './selectors';

@Injectable({ providedIn: 'root' })
export class AuthStoreEffects implements OnInitEffects {
  private get _token(): OpenIddictResponseDto {
    const val = JSON.parse(sessionStorage.getItem('oidToken'));
    if (val != null) return OpenIddictResponseDto.fromJS(val);
    else return null;
  }
  private set _token(value: OpenIddictResponseDto) {
    sessionStorage.setItem('oidToken', JSON.stringify(value.toJSON()));
  }

  private get _userInfo(): UserDetailsDto {
    const val = JSON.parse(sessionStorage.getItem('avaUser'));
    if (val != null) return UserDetailsDto.fromJS(val);
    else return null;
  }
  private set _userInfo(value: UserDetailsDto) {
    sessionStorage.setItem('avaUser', JSON.stringify(value.toJSON()));
  }

  constructor(private _store$: Store<State>, private _actions$: Actions, private _authClient: AuthorizeClient, private _http: HttpClient) {}

  ngrxOnInitEffects(): Action {
    return AuthStoreActions.Init();
  }

  init$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.Init),
      concatMap(() => {
        if (this._token != null && this._userInfo != null) {
          return of(AuthStoreActions.LoginUserInfoSuccess({ userInfo: this._userInfo }), AuthStoreActions.LoginAuthSuccess({ token: this._token }));
        }
        return EMPTY;
      })
    )
  );

  loginAuthResult$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.Login),
      switchMap((action) =>
        // Unfortunately NSwag client generation doesn't seem to properly support x-www-urlencoded type of submissions so it is done manually here
        this._http
          .post<IOpenIddictResponseDto>(
            `${environment.ServerPath}api/Authorize/token`,
            new HttpParams({
              fromObject: { username: action.username, password: action.password, grant_type: 'password' },
            })
          )
          .pipe(map((x) => OpenIddictResponseDto.fromJS(x)))
      ),
      map((result) => AuthStoreActions.LoginAuthSuccess({ token: result })),
      tap((action) => {
        this._token = action.token;
      }),
      catchError((err: HttpErrorResponse) => of(AuthStoreActions.LoginFailure({ error: err.error }))),
      share()
    )
  );

  loginUserInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.LoginAuthSuccess),
      withLatestFrom(this._store$.select(AuthStoreSelectors.selectUserInfo)),
      filter(([_, userInfo]) => userInfo == null),
      switchMap(() => this._authClient.authorize_GetUserDetails()),
      map((result) => AuthStoreActions.LoginUserInfoSuccess({ userInfo: result })),
      tap((action) => {
        this._userInfo = action.userInfo;
      }),
      catchError((res: HttpErrorResponse) => of(AuthStoreActions.LoginFailure({ error: res.error }))),
      share()
    )
  );

  loginResult$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.LoginUserInfoSuccess, AuthStoreActions.LoginAuthSuccess),
      withLatestFrom(this._store$.select(AuthStoreSelectors.selectState)),
      switchMap(([action, state]) => {
        if (state.token && state.userInfo) {
          return of(AuthStoreActions.LoginSuccess({ token: state.token, userInfo: state.userInfo }));
        }
        return EMPTY;
      }),
      share()
    )
  );

  refreshResult$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.RefreshToken),
      withLatestFrom(this._store$.select(AuthStoreSelectors.selectOAuth)),
      exhaustMap(([_, oauth]) =>
        this._http
          .post<IOpenIddictResponseDto>(
            `${environment.ServerPath}api/Authorize/token`,
            new HttpParams({
              fromObject: { grant_type: 'refresh_token', refresh_token: oauth.refresh_token },
            })
          )
          .pipe(map((x) => OpenIddictResponseDto.fromJS(x)))
      ),
      map((result) => AuthStoreActions.RefreshTokenSuccess({ token: result })),
      catchError((res: HttpErrorResponse) => of(AuthStoreActions.RefreshTokenFailure({ error: res.error }))),
      share()
    )
  );

  changeClient$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.ChangeClient),
      tap((action) => {
        const user = this._userInfo;
        user.customerId = action.customerId;
        user.clientName = action.clientName;
        if (action.teamMemberId && action.teamMemberId > 0) user.teamMemberId = action.teamMemberId;
        this._userInfo = user;
      }),
      map(() => AuthStoreActions.ChangeClientSucess())
    )
  );
}
