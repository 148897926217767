import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ITemplateUpdate } from '../ITemplateUpdate';
import { ITemplateTableData } from '../templates-table/ITemplateTableData';

@Component({
  selector: 'app-templates-library',
  templateUrl: './templates-library.component.html',
  styleUrls: ['./templates-library.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TemplatesLibraryComponent implements OnChanges {
  @Input() public dataSource: ITemplateTableData[] = [];
  @Input() public updateFunction: (data: ITemplateUpdate) => void;
  @Input() public isModeration: boolean;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource && changes.dataSource.currentValue) {
      this.dataSource = changes.dataSource.currentValue;
    }
  }
}
