import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceProvider } from '../service';
import { ITemplate } from '../templates/ITemplate';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  public serviceUrl = 'templates/';

  constructor(private _serviceProvider: ServiceProvider) {}

  public getAll(): Observable<ITemplate[]> {
    return this._serviceProvider.get<ITemplate[]>(`${this.serviceUrl}getAll`);
  }

  public getAllByType(typeId: number): Observable<ITemplate[]> {
    return this._serviceProvider.get<ITemplate[]>(`${this.serviceUrl}${typeId}`);
  }

  public add(item: ITemplate): Observable<ITemplate> {
    return this._serviceProvider.post<ITemplate>(`${this.serviceUrl}`, item);
  }

  public edit(item: ITemplate): Observable<ITemplate> {
    return this._serviceProvider.put<ITemplate>(`${this.serviceUrl}`, item);
  }

  public delete(itemId: number): Observable<boolean> {
    return this._serviceProvider.delete(`${this.serviceUrl}${itemId}`);
  }

  public updateStar(itemId: number): Observable<boolean> {
    return this._serviceProvider.put<boolean>(`${this.serviceUrl}${itemId}`, null);
  }
}
