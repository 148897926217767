import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AvaContributionDialogComponent } from './ava-contribution-dialog/ava-contribution-dialog.component';

@Component({
  selector: 'app-ava-contribution',
  templateUrl: './ava-contribution.component.html',
  styleUrls: ['./ava-contribution.component.css']
})
export class AvaContributionComponent {
  public total = 78583.88;

  constructor(private _dialog: MatDialog) { }

  public openAvaContribution(): void {
    this._dialog.open(AvaContributionDialogComponent, {
      width: '848px',
      autoFocus: false,
      panelClass: 'modify-template-dialog',
      data: {
      }
    });
  }
}
