/* eslint-disable */
import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { AvasPatient } from '../models/Dashboard';

@Pipe({ name: 'patientPrioritySort' })
export class PatientPrioritySortPipe implements PipeTransform {
  //Sort Priority based upon FollowUpStatus then by last name:
  sortOrder: Record<number, number> = {
    2: 1, //Patient Response
    3: 2, //Completed
    8: 3, //StoppedForTreatmentStatusChange
    4: 4, //Appointment Scheduled
    5: 5, //User Response
    6: 6, //StoppedForFollowUpDeactivated
    1: 7, //Started
    7: 8, //AppointmentScheduled
  };

  transform(patients: AvasPatient[], ...args: any[]) {
    return patients.sort((a, b) => {
      //verify object has property
      if (!isNullOrUndefined(a.followuptype) && !isNullOrUndefined(b.followuptype)) {
        let aFollowUpStatus = a.followuptype.statusid;
        let bFollowUpStatus = b.followuptype.statusid;

        if (!isNullOrUndefined(aFollowUpStatus) && !isNullOrUndefined(bFollowUpStatus))
          if (this.sortOrder[aFollowUpStatus] > this.sortOrder[bFollowUpStatus]) return 1;
          else if (this.sortOrder[aFollowUpStatus] < this.sortOrder[bFollowUpStatus]) return -1;
          //they are the same ID so sort by last name
          else return this.compareString(a.lastname, b.lastname);
      }
      return 0; //fallback/default
    });
  }

  private compareString(a: string, b: string) {
    let aL = a.toLowerCase();
    let bL = b.toLowerCase();
    return aL.localeCompare(bL);
  }
}
