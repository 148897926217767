import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const featureAdapter: EntityAdapter<LocationStoreEntity> = createEntityAdapter<LocationStoreEntity>({
  selectId: (x) => x.id,
  sortComparer: (a: LocationStoreEntity, b: LocationStoreEntity): number => a.displayName.localeCompare(b.displayName),
});

export interface State extends EntityState<LocationStoreEntity> {
  isLoading?: boolean;
  error?: string | unknown;
  selectedLocationId?: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  selectedLocationId: null,
});

export class LocationStoreEntity {
  id: number;
  name: string;
  displayName: string;
  displayAddress: string;
  displayCity: string;
  displayState: string;
  displayZip: string;
  displayPhone: string;
  displayEmail: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  customerName: string;
  brandId: number;
  email: string;
  npsDisplayName: string;
}
