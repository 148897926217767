import { Action, createReducer, on } from '@ngrx/store';
import { AuthStoreActions } from '../auth-store';
import * as UserStoreActions from './actions';
import { State, featureAdapter, initialState } from './state';

const reducer = createReducer(
  initialState,
  //LOAD
  on(UserStoreActions.LoadRequest, (state) => featureAdapter.removeAll({ ...state, isLoading: true, error: null })),
  on(UserStoreActions.LoadSuccess, (state, action) => featureAdapter.addAll(action.users, { ...state, isLoading: false })),
  on(UserStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPSERT
  on(UserStoreActions.UpsertRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(UserStoreActions.UpsertSuccess, (state, action) => featureAdapter.upsertOne(action.user, { ...state, isLoading: false })),
  on(UserStoreActions.UpsertFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //Unlock
  on(UserStoreActions.UnlockRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(UserStoreActions.UnlockSuccess, (state, action) =>
    featureAdapter.updateOne({ id: action.teamMemberId, changes: { isLocked: false } }, { ...state, isLoading: false, error: null })
  ),
  on(UserStoreActions.UnlockFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  on(AuthStoreActions.LoginUserInfoSuccess, (state, action) => ({ ...state, loggedUserId: action.userInfo.teamMemberId })),
  on(AuthStoreActions.Logout, (state) => ({ ...state, loggedUserId: null }))
);

export function featureReducer(state: State | undefined, action: Action): State {
  return reducer(state, action);
}
