/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LOCATIONS_FEATURE_KEY } from './featurekey';
import { featureAdapter, LocationStoreEntity, State } from './state';

export const selectLocationsState = createFeatureSelector<State>(LOCATIONS_FEATURE_KEY);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectLocationsState);
export const selectLocationIds = selectIds;
export const selectLocationTotal = selectTotal;
export const selectAllLocations = selectAll;
export const selectAllLocationsEntities = selectEntities;

export const selectLocationById = (id: number) =>
  createSelector(selectAllLocations, (allLocations: LocationStoreEntity[]) => {
    if (allLocations) {
      return allLocations.find((c) => c.id === id);
    } else {
      return null;
    }
  });

export const selectLocationsForBrandId = (id: number) =>
  createSelector(selectAllLocations, (locations) => (locations.length > 0 ? locations.filter((l) => l.brandId == id) : []));

export const getSelectedLocationId = createSelector(selectLocationsState, (state) => state.selectedLocationId);

export const getSelectedLocation = createSelector(selectLocationsState, (state) => state.entities[state.selectedLocationId]);

export const selectLocationsError = createSelector(selectLocationsState, (state) => state.error);

export const selectLocationsIsLoading = createSelector(selectLocationsState, (state) => state.isLoading);
