import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatButton, MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { CommunicationType, ModalData, PatientDetailsArea } from 'src/app/modal/modal-container/ModalData';
import { ILead } from 'src/app/models/AvaContext';
import { LocationStoreSelectors, RootStoreState } from 'src/app/root-store';
import { AuthStoreSelectors } from 'src/app/root-store/auth-store';
import { NewPatientAppointment } from 'src/app/services/api.service';
import { ModalContainerService } from 'src/app/services/modal-container.service';
import { NewPatientSchedulerService } from 'src/app/services/new-patient-scheduler.service';
import { SubSink } from 'subsink';
import { PatientService } from './../../services/patient.service';
import { Appointment } from './modal/appointment.model';
import { DialogBookingDetailsComponent } from './modal/dialog-booking-details.component';

@Component({
  selector: 'app-newpatientscheduler',
  templateUrl: './newpatientscheduler.component.html',
  styleUrls: ['./newpatientscheduler.component.css'],
  providers: [PatientService],
})
export class NewpatientschedulerComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public displayedColumns: string[] = [
    'patientName',
    'responsibleParty',
    '[Actions]',
    'hasInsurance',
    'hearAboutUs',
    'appointmentDate',
    'bookingDate',
    '[Booking Details]',
    '[Recorded?]',
    'isHidden',
  ];
  public pmsIsNpsReady: boolean;
  public pmsName: string;
  public scheduledAppiontmentsDataSource: MatTableDataSource<any>;
  public rawData: NewPatientAppointment[];
  private _subs = new SubSink();
  public filters = new FormGroup({
    showHidden: new FormControl(false),
    searchText: new FormControl(''), // NYI
  });

  constructor(
    private _dialog: MatDialog,
    private _store$: Store<RootStoreState.State>,
    private _snackbar: MatSnackBar,
    private _newPatientSchedulerService: NewPatientSchedulerService,
    private _modalContainerService: ModalContainerService
  ) {}

  ngOnInit(): void {
    this.scheduledAppiontmentsDataSource = new MatTableDataSource();
    this.scheduledAppiontmentsDataSource.filterPredicate = (data: NewPatientAppointment, filterValue: string | null) => {
      if (data == null || filterValue == null) return true;
      const filters = JSON.parse(filterValue);
      let filterCount = 0;
      let truthyCount = 0;

      if ('searchText' in filters && data.lead != null && filters.searchText != '') {
        filterCount++;
        const searchText = filters.searchText.toLowerCase();
        truthyCount += +(data.lead.firstName.toLowerCase().includes(searchText) || data.lead.lastName.toLowerCase().includes(searchText));
      }

      if ('showHidden' in filters) {
        if (!filters.showHidden) {
          filterCount++;
          truthyCount += +!data.isHidden;
        }
      }

      // Every filter in the chain that is active (filterCount) must return truth (truthyCount) or entry is filtered
      return truthyCount == filterCount;
    };

    // Set filter and update filter on datasource whenever filter changes
    this.scheduledAppiontmentsDataSource.filter = JSON.stringify(this.filters.value);
    this.filters.valueChanges.subscribe((_) => {
      this.scheduledAppiontmentsDataSource.filter = JSON.stringify(this.filters.value);
    });

    this._subs.sink = this._store$.select(LocationStoreSelectors.getSelectedLocation).subscribe((result) => {
      if (!result) {
        return;
      }

      this._store$
        .select(AuthStoreSelectors.selectTeamMemberId)
        .pipe(
          take(1),
          switchMap((teamMemberId) => this._newPatientSchedulerService.getAppointments(result.id, teamMemberId))
        )
        .subscribe((result) => {
          if (result.status) {
            this.pmsIsNpsReady = result.pmsIsNpsReady;
            this.pmsName = result.pmsName;
            this.rawData = result.data;
            const data = this._mapRawDataToViewModel(result.data);
            setTimeout(() => {
              this.scheduledAppiontmentsDataSource.data = data;
              this.scheduledAppiontmentsDataSource.sort = this.sort;
            }, 3000);
          }
        });
    });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public onClickRecordAppointment(appointment: Appointment): void {
    this._newPatientSchedulerService.recordPms(appointment.newPatientAppointmentId, appointment.isRecorded).subscribe(
      (result) => {
        if (result.status) {
          this._snackbar.open('PMS Booked status was successfully updated.', 'OK', { duration: 5000 });
        } else {
          appointment.isRecorded = !appointment.isRecorded;
          this._snackbar.open(`PMS Booked status has not been updated.`, 'OK', { duration: 5000 });
        }
      },
      () => {
        this._snackbar.open('There was an error while updating the PMS Booked status', 'OK', { duration: 5000 });
      }
    );
  }

  public toggleNewPatientAppointment(newPatientAppointmentId: number, btn: MatButton): void {
    btn.disabled = true;
    const ind = this.rawData.findIndex((x) => x.newPatientAppointmentId == newPatientAppointmentId);
    this._newPatientSchedulerService.hideNewPatientAppointment(newPatientAppointmentId, !this.rawData[ind].isHidden).subscribe({
      error: () => (btn.disabled = false),
      complete: () => {
        btn.disabled = false;
        this.rawData[ind].isHidden = !this.rawData[ind].isHidden;
        this.scheduledAppiontmentsDataSource.data = this._mapRawDataToViewModel(this.rawData);
      },
    });
  }

  public viewBookingDetails(details: Appointment): void {
    const npData = this.rawData.find((x) => x.leadId === details.leadInfo.leadId);
    this._dialog.open(DialogBookingDetailsComponent, {
      backdropClass: 'whitebackdrop',
      panelClass: 'no-pad-dialog',
      data: npData,
    });
  }

  public openCommunicationModal(lead: ILead, communicationType: CommunicationType): void {
    this._modalContainerService.open_dialog(<ModalData>{
      id: lead.patientId || lead.leadId,
      isLead: lead.patientId == null,
      patientDetailsArea: PatientDetailsArea.Communication,
      communicationType: communicationType ? communicationType : CommunicationType.Default,
    });
  }

  private _mapRawDataToViewModel(rawData: NewPatientAppointment[]) {
    return rawData.map((x) => ({
      patientName: x.lead.firstName + ' ' + x.lead.lastName,
      responsibleParty:
        x.lead.responsiblePartyFirstName || x.lead.responsiblePartyLastName
          ? `${x.lead.responsiblePartyFirstName} ${x.lead.responsiblePartyLastName}`
          : '',
      hasInsurance: x.hasInsurance,
      leadSource: x.leadSource ? x.leadSource.leadSourceName : null,
      hearAboutUs: x.leadSource ? x.leadSource.leadSourceName : null,
      appointmentDate: x.appointmentDate,
      startTime: x.startTime,
      isRecorded: x.isRecorded,
      cellPhone: x.lead.cellPhone,
      leadInfo: { leadId: x.leadId, patientId: x.lead.patientId },
      newPatientAppointmentId: x.newPatientAppointmentId,
      isHidden: x.isHidden,
      bookingDate: x.bookingDate,
    }));
  }
}
