import { RequireOnlyOne } from 'src/app/utility/RequireOnlyOne';

export type ModalData = RequireOnlyOne<IModalData, 'selectedIndex' | 'patientDetailsArea'>;

export interface IModalData {
  id: number;
  isLead: boolean;
  selectedIndex?: number;
  patientDetailsArea?: PatientDetailsArea;
  communicationType: CommunicationType;
}

export enum CommunicationType {
  Default,
  Email,
  Message,
  LogCall,
  AddNote,
}

export enum PatientDetailsArea {
  Details = 'details',
  Communication = 'communications',
  Financial = 'financials',
}
