/* eslint-disable */
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CookieModule } from 'ngx-cookie';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth.guard';
import { AutomationsModule } from './automations/automations.module';
import { NewBlastSentModalComponent } from './blasts/new-blasts/new-blast-modals/new-blast-sent-modal/new-blast-sent-modal.component';
import { FeatureFlagModule } from './directives/featureFlag.module';
import { FeatureFlagGuardService } from './guards/featureFlagGuard.service';
import { LoginModule } from './login/login.module';
import { BridgeSyncFailedComponent } from './modal/bridge-sync-failed/bridge-sync-failed.component';
import { ConfirmationDialogComponent } from './modal/confirmation-dialog/confirmation-dialog.component';
import { ImagePopupModalComponent } from './modal/image-popup-modal/image-popup-modal.component';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { PublicModule } from './public/public.module';
import { RootStoreModule } from './root-store';
import { ServiceProvider } from './service';
import { API_BASE_URL } from './services/api.service';
import { AppConfig } from './services/configuration.service';
import { FeatureFlagService } from './services/feature-flag.service';
import { PatientSearchService } from './services/patient-search.service';
import { LogoutService } from './shared/logoutService';
import { TemplateDialogComponent } from './templates/template-dialog/template-dialog.component';
import { TemplatesModule } from './templates/templates.module';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    TemplateDialogComponent,
    ConfirmationDialogComponent,
    NewBlastSentModalComponent,
    BridgeSyncFailedComponent,
    BridgeSyncFailedComponent,
    ImagePopupModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    MatInputModule,
    LoginModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    RootStoreModule,
    FeatureFlagModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    MatSnackBarModule,
    SharedPipesModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    TemplatesModule,
    MatButtonModule,
    PublicModule,
    MatTooltipModule,
    AutomationsModule,
    CookieModule.forRoot(),
  ],
  entryComponents: [
    TemplateDialogComponent,
    ConfirmationDialogComponent,
    NewBlastSentModalComponent,
    BridgeSyncFailedComponent,
    ImagePopupModalComponent,
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    ServiceProvider,
    AuthGuard,
    LogoutService,
    PatientSearchService,
    FeatureFlagService,
    FeatureFlagGuardService,
    {
      provide: API_BASE_URL,
      useValue: environment.ServerPath.slice(0, -1), //nswag clients do not like trailing slash '/'
    },
  ],
  bootstrap: [AppComponent],
  exports: [MatTableModule],
})
export class AppModule {}
