import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AvasWin } from 'src/app/models/Dashboard';

@Component({
  selector: 'app-win-modal',
  templateUrl: './win-modal.component.html',
  styleUrls: ['./win-modal.component.css']
})
export class WinModal implements OnInit {
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	public displayedColumns: string[] = ['name', 'dob', 'responsible'];
	public patientDataSource: MatTableDataSource<any>;
	public patient_data: AvasWin[] = []

	constructor(public matDialogRef: MatDialogRef<WinModal>, @Inject(MAT_DIALOG_DATA) public data: { name: string, details: AvasWin[]}) {
		this.patient_data = this.data.details;
	}

	ngOnInit(): void {
		this.patientDataSource = new MatTableDataSource(this.patient_data);
		this.patientDataSource.sort = this.sort;
	}
}
