import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SignalRStoreEffectsService } from './effects';
import { SIGNALR_FEATURE_KEY } from './featurekey';
import { NotificationCenterHubEffectsService } from './notification-center-hub/effects';
import { NotificationsHubEffectsService } from './notification-hub/effects';
import { featureReducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(SIGNALR_FEATURE_KEY, featureReducer),
    EffectsModule.forFeature([NotificationsHubEffectsService, NotificationCenterHubEffectsService, SignalRStoreEffectsService]),
  ],
})
export class SignalrHubStoreModule {}
