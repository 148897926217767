import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ServiceProvider } from '../service';
import { ContractTemplate } from '../settings/financial/models';
import { IContractTemplateDto } from './api.service';

@Injectable({ providedIn: 'root' })
export class ContractTemplateApiService implements ContractTemplateApiService {
  constructor(private _serviceProvider: ServiceProvider) {}

  getContractTemplates(brandId: number): Observable<IContractTemplateDto[]> {
    return this._serviceProvider
      .get<ContractTemplate[]>(`ContractTemplate`, { brandId: brandId.toString() })
      .pipe(take(1));
  }

  getContractTemplate(contractTemplateId: number): Observable<IContractTemplateDto> {
    return this._serviceProvider.get<IContractTemplateDto>(`ContractTemplate/${contractTemplateId}`).pipe(take(1));
  }

  addContractTemplate(template: IContractTemplateDto): Observable<IContractTemplateDto> {
    return this._serviceProvider.post<IContractTemplateDto>(`ContractTemplate`, template).pipe(take(1));
  }

  updateContractTemplate(template: IContractTemplateDto): Observable<IContractTemplateDto> {
    return this._serviceProvider.put<IContractTemplateDto>(`ContractTemplate/${template.contractTemplateId}`, template).pipe(take(1));
  }

  deleteContractTemplate(contractTemplateId: number): Observable<boolean> {
    return this._serviceProvider.delete<boolean>(`ContractTemplate/${contractTemplateId}`).pipe(take(1));
  }
}
