/* eslint-disable */
import { HttpErrorResponse } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { GetCommunicationAlertMessage, canSendEmail, canSendSms, isResponsiblePartyForAdult } from '../helpers/patient-communication';
import { AutoSetRemind } from '../models/AutoSetRemind';
import { FollowUpCategoryEnum, FollowUpTypeEnum, Language } from '../models/AvaContext';
import { ActionType } from '../models/Communication';
import { AvasPatient } from '../models/Dashboard';
import { IDropDownOption } from '../models/IDropDownOption';
import { MultiPatientDetailsResponse } from '../models/MultiPatientDetailsResponse';
import {
  BrandStoreSelectors,
  FollowUpCategoryStoreSelectors,
  LocationStoreSelectors,
  RootStoreState,
  UserStoreEntity,
  UserStoreSelectors,
} from '../root-store';
import { AuthStoreSelectors } from '../root-store/auth-store';
import { ServiceProvider } from '../service';
import { FollowUp, FollowUpCategory, GetPatientByIdDto, IPerson, IResponsibleParty, ResponsibleParty } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { AvaSelectOption } from '../shared/ava-select';
import { ResponsiblePartyConfirmDialogComponent } from './responsible-party-confirm-dialog/responsible-party-confirm-dialog.component';

export abstract class FollowUpModalComponent implements OnInit {
  public openDropdownId: String = '';
  dropdownPos = 'bottom';

  AutoSetRemindObj = <AutoSetRemind>{};

  patient: IPerson;
  patients: AvasPatient[];
  leads: AvasPatient[];
  get isMultiMode(): boolean {
    return (this.patients || []).length > 0 || (this.leads || []).length > 0;
  }
  get editableEntityModalTitle(): string {
    return this.isMultiMode ? `${this._multiModeCount}` : `${this.patient && this.patient.firstName} ${this.patient && this.patient.lastName}`;
  }
  private get _multiModeCount() {
    let count = 0;
    count += this.patients ? this.patients.length : 0;
    count += this.leads ? this.leads.length : 0;
    return count;
  }
  get patientName(): string {
    return this.isMultiMode ? 'selected patients' : this.patient && this.patient.firstName;
  }
  patientsIsLead() {
    return (this.patient && !this.patient.patientId && !!this.patient.leadId) || (this.patients || []).some((x) => x.isLead);
  }
  responsibleParties: ResponsibleParty[];
  selectedResponsibleParty: IResponsibleParty;
  selectedTeamMember: UserStoreEntity;
  selectedFollowUpCategory: FollowUpCategory;
  ajaxIsBusy: boolean;
  canSendMessage: boolean;
  canSendEmail: boolean;
  canSendSms: boolean;
  private _startDate: Date;
  public get startDate(): Date {
    return this._startDate;
  }
  public set startDate(value: Date) {
    this._startDate = value;
    this.AutoSetRemindObj.date = moment(value).toISOString();
  }
  message: string;
  followUpSequences: FollowUp[];
  languages: Language[];

  get TODAY(): Date {
    return moment().startOf('day').toDate();
  }
  get TOMORROW(): Date {
    return moment(this.TODAY).add(1, 'day').toDate();
  }

  followUpCategories$ = this._store$.select(FollowUpCategoryStoreSelectors.selectAllFollowUpCategory).pipe(
    filter((x) => !!x && x.length > 0),
    map((x) => x.filter((x) => x.followUpCategoryId <= FollowUpCategoryEnum.Misc))
  );
  followUpCategoriesOptions$ = this.followUpCategories$.pipe(map((x) => x.map((y) => new AvaSelectOption(y.name, y.followUpCategoryId))));

  responsiblePartyOptions: IDropDownOption[] = [];
  get responsiblePartyOptions2(): AvaSelectOption[] {
    return this.patient && !!this.patient.patientResponsiblePartyLinks
      ? this.patient.patientResponsiblePartyLinks.map(
          (item) =>
            new AvaSelectOption(
              item.responsibleParty.firstName + ' ' + item.responsibleParty.lastName + (item.isDefault ? ' (primary)' : ''),
              item.responsiblePartyId
            )
        )
      : [];
  }

  teamMembers$ = this._store$.select(UserStoreSelectors.selectAllUsers);
  teamMemberOptions$: Observable<AvaSelectOption[]> = this.teamMembers$.pipe(
    map((users) => users.filter((u) => u.isActive).map((u) => new AvaSelectOption(`${u.firstName} ${u.lastName}`, u.id)))
  );

  languageOptions: IDropDownOption[] = [];
  get languageOptions2(): AvaSelectOption[] {
    return this.languages ? this.languages.map((item) => new AvaSelectOption(item.name, item.languageId)) : [];
  }

  sequenceOptions: IDropDownOption[] = [];
  sequenceOptions2: AvaSelectOption[] = [];

  private _teamMemberId$ = this._store$.select(AuthStoreSelectors.selectTeamMemberId);
  private _customerId$ = this._store$.select(AuthStoreSelectors.selectUserInfo).pipe(map((userInfo) => userInfo && userInfo.customerId));

  get treatmentStatus(): string {
    if (this.patient) {
      if (!this.patient.leadId) {
        return this.patient.treatmentStatus ? this.patient.treatmentStatus.name : '';
      } else {
        if (this.patient.isVirtualConsult) {
          return 'Virtual Consult';
        } else if (this.patient.isNewPatientScheduler) {
          return 'New Patient Scheduler';
        } else {
          return '';
        }
      }
    }
  }

  constructor(
    protected data: GetPatientByIdDto | MultiPatientDetailsResponse,
    protected _matDialogRef: MatDialogRef<any>,
    protected authService: AuthService,
    protected remoteService: ServiceProvider,
    protected snackBar: MatSnackBar,
    protected dialog: MatDialog,
    protected _store$: Store<RootStoreState.State>,
    followUpType: FollowUpTypeEnum
  ) {
    this.AutoSetRemindObj = new AutoSetRemind();
    this.AutoSetRemindObj.followUpTypeId = followUpType;
  }

  ngOnInit() {
    this.startDate = new Date();
    if ((this.data as GetPatientByIdDto).patient != null) {
      this.patient = (this.data as GetPatientByIdDto).patient;
    }
    this.patients = (this.data as MultiPatientDetailsResponse).patients;
    this.leads = (this.data as MultiPatientDetailsResponse).leads;

    this.followUpSequences = this.data.followUpSequences;

    this.languages = this.data.languages;
    this.AutoSetRemindObj.patientIds = (this.patients || []).map((x) => x.id);
    this.AutoSetRemindObj.leadIds = (this.leads || []).map((x) => x.id);
    this._customerId$.pipe(take(1)).subscribe((customerId) => (this.AutoSetRemindObj.customerId = customerId));

    if (this.patient) {
      this.AutoSetRemindObj.locationOffset = this.patient.location.offset;
      this.AutoSetRemindObj.patientId = !!this.patient.patientId ? this.patient.patientId : this.patient.leadId;
      this.AutoSetRemindObj.ispatient = !!this.patient.patientId;

      // Set up responsible parties
      this.responsiblePartyOptions = !!this.patient.patientResponsiblePartyLinks
        ? this.patient.patientResponsiblePartyLinks.map((item) => ({
            id: item.responsiblePartyId,
            value: item.responsibleParty.firstName + ' ' + item.responsibleParty.lastName + (item.isDefault ? ' (primary)' : ''),
          }))
        : [];

      let primaryResponsibleParty = this.patient.patientResponsiblePartyLinks.find((r) => r.isDefault);
      if (this.patient.isChild) {
        if (this.patient.patientResponsiblePartyLinks && this.patient.patientResponsiblePartyLinks.length && !primaryResponsibleParty) {
          primaryResponsibleParty = this.patient.patientResponsiblePartyLinks[0];
        }
      } else {
        this.responsiblePartyOptions.unshift({ id: null, value: '(none)' });
      }
      this.setResponsibleParty(primaryResponsibleParty ? primaryResponsibleParty.responsiblePartyId : null);
    } else {
      this._store$.select(LocationStoreSelectors.getSelectedLocationId).subscribe((selectedLocationId) => {
        this.remoteService.get<{ offset: number }>(`avasfollowup/getlocation/${selectedLocationId}`, null, 'text').subscribe((location) => {
          this.AutoSetRemindObj.locationOffset = location.offset;
        });
      });
    }

    // Set up languages
    this.languageOptions = this.languages.map((item) => ({
      id: item.languageId,
      value: item.name,
    }));

    if ('defaultFollowUpCategoryId' in this.data && this.data.defaultFollowUpCategoryId != null) {
      this.setFollowUpCategory(this.data.defaultFollowUpCategoryId);
    } else if (this.patient) {
      this.setFollowUpCategory(
        this.patient.followUpLink && this.patient.followUpLink.followUpCategoryId ? this.patient.followUpLink.followUpCategoryId : null
      );
    }

    if (this.patient) {
      this.setlanguage(this.patient.languageId);
    }
    this.setconsultant();
  }
  closeModal(data: boolean = false): void {
    this._matDialogRef.close(data);
  }

  setResponsibleParty(id: number) {
    this.selectedResponsibleParty =
      id !== null && this.patient.patientResponsiblePartyLinks
        ? this.patient.patientResponsiblePartyLinks.find((r) => r.responsiblePartyId === id).responsibleParty
        : null;
    this.AutoSetRemindObj.responsiblePartryid = this.selectedResponsibleParty ? this.selectedResponsibleParty.responsiblePartyId : null;
    this.checkCanSendMessage();

    if (isResponsiblePartyForAdult(this.patient, this.selectedResponsibleParty)) {
      const ref = this.dialog.open(ResponsiblePartyConfirmDialogComponent, {
        maxWidth: 474,
        minHeight: 264,
        panelClass: 'ava-dialog',
      });
      ref.afterClosed().subscribe((keepResponsible: boolean) => {
        if (!keepResponsible) {
          this.setResponsibleParty(null);
        }
      });
    }
  }

  setconsultant(id: number | null = null) {
    this.teamMembers$.pipe(take(1), withLatestFrom(this._teamMemberId$)).subscribe(([teamMembers, activeTeamMemberId]) => {
      const teamMemberId = id || activeTeamMemberId;
      this.selectedTeamMember = teamMembers.find((r) => r.id === teamMemberId);
      this.AutoSetRemindObj.userId = teamMemberId;
    });
  }

  setFollowUpCategory(id: number) {
    this.followUpCategories$
      .pipe(
        tap((followUpCategories) =>
          !id
            ? (this.AutoSetRemindObj.followUpCategoryId = followUpCategories[0].followUpCategoryId)
            : (this.AutoSetRemindObj.followUpCategoryId = id)
        ),
        switchMap((_) => this._store$.select(BrandStoreSelectors.getSelectedBrandId)),
        take(1)
      )
      .subscribe((brandId) => {
        // Update follow-up sequence options
        this.sequenceOptions2 = this.followUpSequences
          .filter((f) => f.followUpCategoryId === id && f.brandId == brandId)
          .map((f) => new AvaSelectOption(f.name, f.followUpId));

        if (this.sequenceOptions2.length > 0) this.setFollowUp(this.sequenceOptions2[0].value as number);
        this.checkCanSendMessage();
      });
  }

  setFollowUp(id: number) {
    this.AutoSetRemindObj.followUpId = id;
  }

  setlanguage(id: number) {
    this.AutoSetRemindObj.languageId = id;
  }

  SelectedDate(date) {
    this.AutoSetRemindObj.date = date;
  }

  checkCanSendMessage() {
    this.canSendEmail = canSendEmail(this.patient, this.patients, this.selectedResponsibleParty);
    this.canSendSms = canSendSms(this.patient, this.patients, this.selectedResponsibleParty);
    this.canSendMessage = this.canSendEmail || this.canSendSms;

    let actions: ActionType[];

    // CONDITION: Auto
    if (this.AutoSetRemindObj.followUpTypeId === FollowUpTypeEnum.Auto) {
      actions = [ActionType.email, ActionType.message, ActionType.notificaiton];

      // CONDITION: Set
    } else if (this.AutoSetRemindObj.followUpTypeId === FollowUpTypeEnum.Set) {
      actions = [<ActionType>this.AutoSetRemindObj.actionId];
    }

    this.message = GetCommunicationAlertMessage(
      this.patient,
      this.patients,
      this.selectedResponsibleParty,
      this.AutoSetRemindObj.followUpCategoryId,
      actions
    );
  }

  toggleDropdown(id, e) {
    if (this.openDropdownId != id) {
      this.openDropdownId = id;
      let window_height = window.innerHeight;
      let dialog_height = window_height * (95 / 100);
      let available_space = dialog_height - e.y;
      setTimeout(() => {
        let dropdownObj;
        if (e.target.nextSibling.classList.contains('dropdown')) {
          dropdownObj = e.target.nextSibling;
        } else if (e.target.parentNode.nextSibling.classList.contains('dropdown')) {
          dropdownObj = e.target.parentNode.nextSibling;
        }
        let elem_h = dropdownObj.scrollHeight;
        elem_h = elem_h > 150 ? 150 : elem_h;
        if (available_space >= elem_h) {
          this.dropdownPos = 'bottom';
        } else {
          this.dropdownPos = 'top';
        }
      }, 1);
    } else {
      this.openDropdownId = '';
    }
  }

  showMessage(message, action?, panelClass?) {
    this.snackBar.open(message, action || 'Ok', {
      duration: 3000,
      panelClass: panelClass ? [panelClass] : ['blue-snackbar'],
    });
  }

  submit() {
    if (!this.ajaxIsBusy) {
      this.ajaxIsBusy = true;
      const saveDataUrl = this.isMultiMode ? 'AvasFollowUp/AvaMultiFollowUp_Set_Reminder_Post' : 'AvasFollowUp/AvaFollowUp_Set_Reminder_Post';
      this.remoteService.post<boolean>(saveDataUrl, this.AutoSetRemindObj).subscribe(
        (res) => {
          if (res) {
            this.closeModal(true);
            switch (this.AutoSetRemindObj.followUpTypeId) {
              case FollowUpTypeEnum.Auto:
                this.showMessage(`Successfully added ${this.patientName} to auto follow-up!`);
                break;
              case FollowUpTypeEnum.Set:
                this.showMessage(
                  `Successfully scheduled ${this.AutoSetRemindObj.actionId === ActionType.email ? 'email' : 'text'} for ${this.patientName}!`
                );
                break;
              case FollowUpTypeEnum.Remind:
                this.showMessage(`Successfully set reminder for ${this.patientName}!`);
                break;
              default:
                break;
            }
          } else {
            // this.showMessage(res.message, '');
            console.log('Unexpected response code');
          }
          this.ajaxIsBusy = false;
        },
        (err: HttpErrorResponse) => {
          this.showMessage(err.error.message, 'OK');
          this.ajaxIsBusy = false;
        }
      );
    }
  }
  closeDropdowns() {
    this.openDropdownId = '';
  }
}
