import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { ClickConfirmTemplateComponent } from './click-confirm-template/click-confirm-template.component';
import { ClickConfirmDirective } from './click-confirm.directive';

@NgModule({
  declarations: [ClickConfirmDirective, ClickConfirmTemplateComponent],
  imports: [CommonModule, MatButtonModule, OverlayModule, MatIconModule],
  exports: [ClickConfirmDirective],
  entryComponents: [ClickConfirmTemplateComponent],
})
export class ClickConfirmModule {}
