/* eslint-disable */
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ServiceProvider} from '../../../service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class QuestionnaireService {
  api = 'v1/';

  constructor(public http: ServiceProvider) {
  }

  create(data) {
    return this.http.post(this.api + 'Questionnaires', data).pipe(
      map((res: any) => {
        if (!res.id) {
          data.id = res;
          res = data;
        }
        return res;
      })
    );
  }

  update(data) {
    return this.http.put(this.api + 'Questionnaires', data);
  }

  delete(id) {
    return this.http.delete(this.api + 'Questionnaires/' + id);
  }

  getAllTree(answerId) {
    return this.http.get(this.api + `Questionnaires/${answerId}/hierarchy` + '/botmessenger/' + answerId);
  }

  get(id, botMessangerId) {
    return this.http.get(this.api + 'Questionnaires/' + id + '/botmessenger/' + botMessangerId);
  }

  copy(answerId, destQuestionnaireId, removeSourceNode) {
    return this.http.post(this.api + `Questionnaires/copy/${answerId}/${destQuestionnaireId}/${removeSourceNode}`);
  }

}
