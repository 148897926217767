import { ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as SignalRHubStoreActions from './actions';
import { SIGNALR_EVENTS } from './events';

type SignalrEventTypeOperatorResult<T> = (source$: Observable<Action>) => Observable<[ReturnType<typeof SignalRHubStoreActions.Event>, T]>;

export function ofSignalrEventType<T = unknown>(event: (keyof typeof SIGNALR_EVENTS)[]): SignalrEventTypeOperatorResult<T>;
export function ofSignalrEventType<T = unknown>(event: keyof typeof SIGNALR_EVENTS): SignalrEventTypeOperatorResult<T>;
export function ofSignalrEventType<T = unknown>(
  event: keyof typeof SIGNALR_EVENTS | (keyof typeof SIGNALR_EVENTS)[]
): SignalrEventTypeOperatorResult<T> {
  return (source$) =>
    source$.pipe(
      ofType(SignalRHubStoreActions.Event),
      filter((action) => {
        if (event instanceof Array) return event.map((x) => SIGNALR_EVENTS[x] as string).includes(action.eventName);
        else return action.eventName == SIGNALR_EVENTS[event];
      }),
      map((action) => [action, <T>action.data])
    );
}
