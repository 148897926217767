/* eslint-disable */
import { PipeTransform, Pipe } from "@angular/core";
import { TreatmentStatus, FollowUpCategoryTreatmentStatusLink, FollowUpTreatmentStatusLink } from "../models/AvaContext";

@Pipe({
  name: 'orderByLinked',
  pure: false
})
export class OrderByLinkedPipe implements PipeTransform {
  //                                     FollowUpCategoryTreatmentStatusLink || FollowUpTreatmentStatusLink             FollowUpCategoryId || FollowUpId
  transform(statuses: SimpleLinkChild[], links: SimpleLinkRelationship[], filterId: number) : SimpleLinkObject[] {
    if (statuses.length == 0)
      return statuses.map(s => <SimpleLinkObject>{parentId: null, child: s});

    const filteredLinks: SimpleLinkRelationship[] = links.filter(l => l.parentId == filterId);

    return statuses.sort((a, b) => {
      //check if they are linked to this category
      const aInc = filteredLinks.findIndex(l => l.childId == a.id) > -1;
      const bInc = filteredLinks.findIndex(l => l.childId == b.id) > -1;

      if (aInc == bInc) //they are either both included or neither are included so determine sort by name
        return a.name > b.name ? 1 : -1;

      //we know they aren't the same so...
      return aInc ? -1 : 1; //..if 'a' is the one included then it comes before otherwise its sorted after
    })
      .map(child => { //remap to an object that has an indication of whether its included or not
        const ind = links.findIndex(l => l.childId == child.id);
        return <SimpleLinkObject> {
          parentId: ind > -1 ? links[ind].parentId : null,
          child: child
        };
      });
  }
}

export interface SimpleLinkObject{
  parentId: number;
  child: SimpleLinkChild;
}

export interface SimpleLinkRelationship {
  parentId: number;
  childId: number;
}

export interface SimpleLinkChild {
  id: number;
  name: string;
}
