/* eslint-disable */
import { Injectable } from '@angular/core';
import {ServiceProvider} from '../../../service';
import { Observable } from 'rxjs';
import { AvaChatStatistics } from 'src/app/models/AvaChatStatistics';

export enum ChatStatus {
  Initiated,
  Requested,
  Accepted,
  Missed
}

@Injectable({providedIn: 'root'})
export class ChatBotService {
  api = 'v1/';

  constructor(public http: ServiceProvider) {
  }

  createChat(chat) {
    return this.http.post(this.api + 'BotMessengers', chat);
  }

  updateChat(chat) {
    return this.http.put(this.api + 'BotMessengers', chat);
  }

  deleteChat(id) {
    return this.http.delete(this.api + 'BotMessengers/' + id);
  }

  getAllChats(brandId: number) {
    return this.http.get(this.api + 'BotMessengers/getAllChats/' + brandId);
  }

  getChat(id) {
    return this.http.get(this.api + 'BotMessengers/' + id);
  }

  cloneChat(chat) {
    return this.http.post(this.api + 'BotMessengers/' + 'clone/' + chat.id, {});
  }

  chatInitialization(chatId) {
    const data = {
      botMessengerId : chatId,
      status: ChatStatus.Initiated
    };
    return this.http.post(this.api + 'BotMessengers/' + 'saveAnalytics/' + chatId, data);
  }

  liveChatRequest(chatId) {
    const data = {
      botMessengerId : chatId,
      status: ChatStatus.Requested
    };
    return this.http.post(this.api + 'BotMessengers/' + 'saveAnalytics/' + chatId, data);
  }

  liveChatAccepted(chatId) {
    const data = {
      botMessengerId : chatId,
      status: ChatStatus.Accepted
    };
    return this.http.post(this.api + 'BotMessengers/' + 'saveAnalytics/' + chatId, data);
  }

  getChatStatistics(botMessangerId, params?: {startDate?: string, endDate?: string}): Observable<AvaChatStatistics> {
    return this.http.get(this.api + 'BotMessengers/getStatistics/' + botMessangerId, params);
  }

  geAllStatistics(params?: {startDate?: string, endDate?: string}): Observable<AvaChatStatistics> {
    return this.http.get(this.api + 'BotMessengers/getAllStatistics', params);
  }
}
