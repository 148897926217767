/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAction, props } from '@ngrx/store';
import { createSignalRHub, startSignalRHub } from 'ngrx-signalr-core';
import { HUB_DEFINITION } from './hub';

export const Init = createAction('[Dashboard Hub] Init');

// SignalR Hub Actions
export const CreateHub = (clientName: string, tokenFactory: () => string | Promise<string>) =>
  createSignalRHub({
    hubName: HUB_DEFINITION.hubName,
    url: HUB_DEFINITION.url.replace('{ClientName}', clientName),
    options: {
      accessTokenFactory: tokenFactory,
    },
  });

export const StartHub = (clientName: string) =>
  startSignalRHub({
    hubName: HUB_DEFINITION.hubName,
    url: HUB_DEFINITION.url.replace('{ClientName}', clientName),
  });

export const SendToChatBot = createAction(
  '[Dashboard Hub] SendToChatBot Dispatch',
  props<{
    chatType: string;
    helpSessionRequestId: number;
    liveRequestStatus: string;
    teamMemberId: number;
    acceptedBy: number;
    teamMemberName: string;
  }>()
);

export const SendToChatBotComplete = createAction('[Dashboard Hub] SendToChatBot Complete');
