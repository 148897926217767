export const NOTIFICATION_EVENTS = {
  MessageSentNotification: 'MessageSentNotification' as const,
  MessageSentCommunication: 'MessageSentCommunication' as const,
  MessageSentRangeCommunication: 'MessageSentRangeCommunication' as const,
  CallLoggedNotification: 'CallLoggedNotification' as const,
  MessageReceivedNotification: 'MessageReceivedNotification' as const,
  MessageReceivedCommunication: 'MessageReceivedCommunication' as const,
  ReceivedCallNotification: 'ReceivedCallNotification' as const,
  FollowUpAdded: 'FollowUpAdded' as const,
  FollowUpAddedRange: 'FollowUpAddedRange' as const,
  FollowUpRemoved: 'FollowUpRemoved' as const,
  FollowUpRemovedRange: 'FollowUpRemovedRange' as const,
  BlastSent: 'BlastSent' as const,
};
