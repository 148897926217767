import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { ComponentBase } from 'src/app/core/ComponentBase';
import { TreatmentTypeDto } from 'src/app/services/api.service';
import { CaseItemListMode, ICaseItemListComponent } from '../case-item-list/case-item-list.component';
import { CasePresentationService } from '../case-presentation.service';

@Component({
  selector: 'app-case-treatment-type-list',
  templateUrl: './case-treatment-type-list.component.html',
  styleUrls: ['./case-treatment-type-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CaseTreatmentTypeListComponent extends ComponentBase implements ICaseItemListComponent<TreatmentTypeDto>, OnDestroy {
  @Input('treatmentTypes') items: TreatmentTypeDto[];
  @Input('mode') mode: CaseItemListMode = 'select';
  @Output('treatmentActivated') itemActivated = new EventEmitter<TreatmentTypeDto>();
  @Output('treatmentDeleted') itemDeleted = new EventEmitter<TreatmentTypeDto>();
  @Input('selected') selectedItems: TreatmentTypeDto[] = [];
  @Output('selectedChange') selectedItemsChange = new EventEmitter<TreatmentTypeDto[]>();

  constructor(private _casePresentationService: CasePresentationService) {
    super();
  }

  __editItem(item: TreatmentTypeDto): void {
    this.__subSink.sink = this._casePresentationService.openTreatmentTypeEditorDialog(item).subscribe((result) => {
      if (result) {
        const ind = this.items.findIndex((x) => x.id == item.id);
        this.items[ind] = result;

        // Emit change to ensure parent has up to date items
        this.selectedItemsChange.emit(this.selectedItems);
      }
    });
  }
}
