import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/ComponentBase';
import { PatientClient, PatientSearchResult } from 'src/app/services/api.service';
import { ModalContainerService } from 'src/app/services/modal-container.service';
import { CommunicationType, ModalData, PatientDetailsArea } from '../../modal/modal-container/ModalData';
import { PatientSearchViewModel } from '../../models/PatientSearch';

@Component({
  selector: 'app-casepresentation',
  templateUrl: 'casepresentation.component.html',
  styleUrls: ['casepresentation.component.css'],
})
export class CasepresentationComponent extends ComponentBase {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public pageSize: number = 25;
  public displayedColumns: string[] = ['name', 'age', 'treatmentStatus', 'action'];
  public dataSource: MatTableDataSource<PatientSearchResult>;
  public searchValue: string;
  public PatientDetailsArea = PatientDetailsArea;
  private _clientData: PatientSearchResult[];
  private _pageIndex: number = 1;

  constructor(public actionDialog: MatDialog, private _modalContainerService: ModalContainerService, private _patientClient: PatientClient) {
    super();
  }

  public searchData(key: string): void {
    if (key && key.length > 2) {
      this._patientClient
        .patient_CasePresentation(key, this.pageSize, this._pageIndex)
        .pipe(take(1))
        .subscribe((result) => {
          this._clientData = result.patients;
          this._pageIndex = result.pageNumber;
          this.paginator.length = result.totalCount;
          this.dataSource = new MatTableDataSource(this._clientData);
        });
    }
  }

  public openModal(
    area: PatientDetailsArea,
    patient: PatientSearchViewModel,
    communicationType: CommunicationType = CommunicationType.Default
  ): void {
    this._modalContainerService.open_dialog(<ModalData>{
      id: patient.patientId,
      isLead: patient.isLead,
      patientDetailsArea: area,
      communicationType: communicationType,
    });
  }

  public onPaginateChange(event: PageEvent): void {
    this._pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.searchData(this.searchValue);
  }
}
