import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthStoreEffects } from './effects';
import { AUTH_FEATURE_KEY } from './featurekey';
import { featureReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature(AUTH_FEATURE_KEY, featureReducer), EffectsModule.forFeature([AuthStoreEffects])],
})
export class AuthStoreModule {}
