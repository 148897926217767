import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password-success-msg',
  templateUrl: './forgot-password-success-msg.component.html',
  styleUrls: ['./forgot-password-success-msg.component.css'],
})
export class ForgotPasswordSuccessMsgComponent {
  constructor(private _router: Router) {}

  backToLogin(): void {
    this._router.navigate(['login']);
  }
}
