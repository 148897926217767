/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CONTRACT_TEMPLATES_FEATURE_KEY } from './featurekey';
import { ContractTemplateStoreEntity, featureAdapter, State } from './state';

export const selectContractTemplatesState = createFeatureSelector<State>(CONTRACT_TEMPLATES_FEATURE_KEY);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectContractTemplatesState);
export const selectContractTemplateIds = selectIds;
export const selectContractTemplateTotal = selectTotal;
export const selectAllContractTemplates = selectAll;
export const selectAllContractTemplatesEntities = selectEntities;

export const selectContractTemplateById = (id: number) =>
  createSelector(selectAllContractTemplates, (allContractTemplates: ContractTemplateStoreEntity[]) => {
    if (allContractTemplates) {
      return allContractTemplates.find((c) => c.contractTemplateId === id);
    } else {
      return null;
    }
  });

export const selectContractTemplatesForBrandId = (id: number) =>
  createSelector(selectAllContractTemplates, (x) => (x.length > 0 ? x.filter((l) => l.brandId == id) : []));

export const selectContractTemplatesError = createSelector(selectContractTemplatesState, (state) => state.error);

export const selectContractTemplatesIsLoading = createSelector(selectContractTemplatesState, (state) => state.isLoading);

export const selectContractTemplatesIsLoaded = createSelector(selectContractTemplatesState, (state) => state.isLoaded);
