/* eslint-disable */
import {Pipe, PipeTransform} from '@angular/core';
import {TreatmentType} from "../models/TreatmentType";

@Pipe({
  name: 'parseTreatmentJson'
})

export class TreatmentTypeJsonConverter implements PipeTransform {
  transform(value): TreatmentType {
    return JSON.parse(value).treatment;
  }
}
