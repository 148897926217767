import { Component, ContentChild, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { filter, switchMapTo, take } from 'rxjs/operators';
import { EditModeDirective } from '../edit-mode.directive';
import { ViewModeDirective } from '../view-mode.directive';

@Component({
  selector: 'edit-in-place',
  templateUrl: './edit-in-place.component.html',
  styleUrls: ['./edit-in-place.component.scss'],
  host: {
    style: 'max-width: 100%',
  },
})
export class EditInPlaceComponent implements OnInit, OnDestroy {
  @Output() update = new EventEmitter();
  @ContentChild(ViewModeDirective, { static: true }) viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective, { static: true }) editModeTpl: EditModeDirective;

  private _viewModeHandlerSubscription: Subscription;
  private _editModeHandlerSubscription: Subscription;

  public mode: EditInPlaceMode = 'view';
  public editMode = new Subject();
  public editMode$ = this.editMode.asObservable();

  constructor(private _host: ElementRef<HTMLElement>) {}

  get currentView(): TemplateRef<unknown> {
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }

  ngOnInit(): void {
    this._viewModeHandler();
    this._editModeHandler();
  }

  ngOnDestroy(): void {
    this._viewModeHandlerSubscription ? this._viewModeHandlerSubscription.unsubscribe() : null;
    this._editModeHandlerSubscription ? this._editModeHandlerSubscription.unsubscribe() : null;
  }

  private get _element(): HTMLElement {
    return this._host.nativeElement;
  }

  private _viewModeHandler() {
    this._viewModeHandlerSubscription = fromEvent(this._element, 'dblclick').subscribe(() => {
      this.mode = 'edit';
      this.editMode.next(true);
    });
  }

  private _editModeHandler() {
    const clickOutside$ = fromEvent(document, 'click').pipe(
      filter(({ target }) => this._element.contains(<HTMLElement>target) === false),
      take(1)
    );

    this._editModeHandlerSubscription = this.editMode$.pipe(switchMapTo(clickOutside$)).subscribe((_) => {
      this.update.next();
      this.mode = 'view';
    });
  }

  public toViewMode(): void {
    this.update.next();
    this.mode = 'view';
  }

  @HostListener('keyup.enter')
  private _onEnter(): void {
    this.toViewMode();
  }
}

export type EditInPlaceMode = 'view' | 'edit';
