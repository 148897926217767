import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { GetTracksForDashboardDto } from 'src/app/models/GetTracksForDashboardDto';
import { TrackGroup } from 'src/app/models/NewPatientTracker';
import { NewPatientTrackerSettings } from 'src/app/models/NewPatientTrackerSettings';
import { LocationStoreSelectors, RootStoreState } from 'src/app/root-store';
import { DataService } from 'src/app/services/data.service';
import { SubSink } from 'subsink';
import { ServiceProvider } from '../../service';
import { AvaFollowUpOptions } from '../enums/avaFollowUpOptions.enum';
import { SurveyReportComponent } from '../newpatienttracker/survey-report/survey-report.component';
import { TrackerSearchComponent, TrackerTableData } from './tracker-search/tracker-search.component';

@Component({
  selector: 'app-newpatienttracker',
  templateUrl: './newpatienttracker.component.html',
  styleUrls: ['./newpatienttracker.component.css'],
})
export class NewpatientTrackerComponent implements OnInit, OnDestroy {
  //#region Props
  public avaFollowUpOptions = AvaFollowUpOptions;
  public showTargets = false;

  public remoteTargets: Observable<string[]> = this._remoteService.get<string[]>('NewPatientTracker/GetConnectedGophers'); //gopher targets to send forms url to;
  public _trackerSettings: NewPatientTrackerSettings[];
  public trackingTables: TrackerTableData[];
  private _subs = new SubSink(); //to help prevent memory leak with subscriptions we'll dump them here
  private _dataTable14past = new MatTableDataSource<TrackGroup>();
  private _dataTable7next = new MatTableDataSource<TrackGroup>();
  private _dataTableFuture = new MatTableDataSource<TrackGroup>();
  private _appointmentStatuses: { appointmentStatusId: number; name: string; code: string; avaAppointmentStatuses: number[] }[];
  private _treatmentStatuses: { treatmentStatusId: number; name: string }[] = [];
  private _customerId: string;
  private _noShowStatusIds: number[] = [];
  private _followUpPatientTrackId: number;

  public get treatmentStatuses(): { treatmentStatusId: number; name: string }[] {
    return this._treatmentStatuses;
  }
  public get noShowStatusIds(): number[] {
    return this._noShowStatusIds;
  }
  //#endregion

  constructor(
    private _remoteService: ServiceProvider,
    private _store$: Store<RootStoreState.State>,
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _dataService: DataService
  ) {
    this._dataService.getMessageForAvaModalClose().subscribe((result) => {
      if (result.event && this._followUpPatientTrackId) {
        const index = this._dataTable14past.data.findIndex((x) => x.track.newPatientTrackId === this._followUpPatientTrackId);
        if (index !== -1) {
          this._dataTable14past.data[index].patient.followUpLinkId = 0;
          this._dataTable14past.data = this._dataTable14past.data.slice();
        }
      }
    });
  }

  ngOnInit(): void {
    this._subs.sink = this._store$
      .select(LocationStoreSelectors.getSelectedLocationId)
      .pipe(
        distinctUntilChanged(),
        filter((x) => !!x)
      )
      .subscribe((locationId) => {
        //set track reload to happen when location changes
        this._loadTracks(locationId);
      });

    this.trackingTables = [
      //iterate on this to generate each table so that we don't need to copy paste
      {
        name: 'Past 14 Days',
        dataTable: this._dataTable14past,
        selection: new SelectionModel<number>(true),
        isWorking: {},
      },
      {
        name: 'Next 7 Days',
        dataTable: this._dataTable7next,
        selection: new SelectionModel<number>(true),
        isWorking: {},
      },
      {
        name: 'Next 30 Days',
        dataTable: this._dataTableFuture,
        selection: new SelectionModel<number>(true),
        isWorking: {},
      },
    ];
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public openGopherTerminal(): void {
    window.open(`/public/gopher/${this._customerId}`);
  }

  private _sortByTrackAppointmentHandler(
    aAppointment: typeof TrackGroup.prototype.appointments[0],
    bAppointment: typeof TrackGroup.prototype.appointments[0]
  ): number {
    if (aAppointment && bAppointment) {
      return moment(aAppointment.dateTime).diff(moment(bAppointment.dateTime));
    } else if (aAppointment && !bAppointment) {
      return -1;
    } else {
      return 1;
    }
  }

  public openSurveyReportDialog(): void {
    this._dialog.open(SurveyReportComponent);
  }

  public getWorkingCount(tableIndex: number): number {
    const keys = Object.keys(this.trackingTables[tableIndex].isWorking);
    const active = keys.filter((key) => this.trackingTables[tableIndex].isWorking[key]);
    return active.length;
  }

  private _getTrackAppointment(group: TrackGroup): typeof TrackGroup.prototype.appointments[0] {
    if (group.appointments.length > 0) {
      const appointment = group.appointments.find((app) => app.appointmentId == group.track.appointmentId);
      return appointment;
    }
    return null;
  }

  public openPatientLookup(): void {
    this._dialog.open(TrackerSearchComponent, { data: { sortFn: this._sortByTrackAppointmentHandler, apptFn: this._getTrackAppointment } });
  }

  //#region Track Observables
  private _patientTracks$ = new BehaviorSubject<TrackGroup[]>([]);
  private get _patientTracks14Previous$(): Observable<TrackGroup[]> {
    return this._patientTracks$.pipe(
      map((tracks) =>
        tracks
          .filter(
            (t) =>
              t.appointments.length > 0 &&
              t.appointments.find((a) => a.appointmentId == t.track.appointmentId) &&
              moment(t.appointments.find((a) => a.appointmentId == t.track.appointmentId).dateTime).isBefore(moment(), 'days')
          )
          .sort((a, b) => this._sortByTrackAppointmentHandler(this._getTrackAppointment(a), this._getTrackAppointment(b)))
      )
    );
  }

  private _patientTrackNext7$(newPatientStatusIds: number[]): Observable<TrackGroup[]> {
    return this._patientTracks$.pipe(
      map((tracks) =>
        tracks
          .filter(
            (t) =>
              t.appointments.length > 0 &&
              newPatientStatusIds.indexOf(t.patient.treatmentStatusId) > -1 &&
              t.appointments.find((a) => a.appointmentId == t.track.appointmentId) &&
              moment(t.appointments.find((a) => a.appointmentId == t.track.appointmentId).dateTime).isSameOrAfter(moment(), 'days') &&
              moment(t.appointments.find((a) => a.appointmentId == t.track.appointmentId).dateTime).isSameOrBefore(moment().add(7, 'days'), 'day')
          )
          .sort((a, b) => this._sortByTrackAppointmentHandler(this._getTrackAppointment(a), this._getTrackAppointment(b)))
      )
    );
  }

  private _patientTrackFuture$(newPatientStatusIds: number[]): Observable<TrackGroup[]> {
    return this._patientTracks$.pipe(
      map((tracks) =>
        tracks
          .filter(
            (t) =>
              t.appointments.length > 0 &&
              newPatientStatusIds.indexOf(t.patient.treatmentStatusId) > -1 &&
              t.appointments.find((a) => a.appointmentId == t.track.appointmentId) &&
              moment(t.appointments.find((a) => a.appointmentId == t.track.appointmentId).dateTime).isAfter(moment().add(7, 'days'), 'days')
          )
          .sort((a, b) => this._sortByTrackAppointmentHandler(this._getTrackAppointment(a), this._getTrackAppointment(b)))
      )
    );
  }
  //#endregion

  private _bindPatientTracks(trackerSettings: NewPatientTrackerSettings[]): void {
    //update tables when tracks update (such as location change)
    this._subs.sink = this._patientTracks14Previous$.subscribe((tracks) => (this._dataTable14past.data = tracks));
    this._subs.sink = this._patientTrackNext7$(trackerSettings && trackerSettings[0] ? trackerSettings[0].newPatientStatusIds : []).subscribe(
      (tracks) => (this._dataTable7next.data = tracks)
    );
    this._subs.sink = this._patientTrackFuture$(trackerSettings && trackerSettings[0] ? trackerSettings[0].newPatientStatusIds : []).subscribe(
      (tracks) => (this._dataTableFuture.data = tracks)
    );
  }

  private _loadTracks(LocationId: number): void {
    this._subs.sink = this._remoteService.get(`NewPatientTracker/GetTracksForDashboard/${LocationId}`).subscribe(
      (response: GetTracksForDashboardDto) => {
        this._treatmentStatuses = response.treatmentStatuses;
        this._appointmentStatuses = response.appointmentStatuses;
        if (this._appointmentStatuses) {
          this._noShowStatusIds = this._appointmentStatuses.filter((x) => x.avaAppointmentStatuses.includes(2)).map((x) => x.appointmentStatusId); //ID 2 is AvaStatus "Missed"
        }
        this._customerId = response.customerId;
        this._trackerSettings = response.trackerSettings;
        this._patientTracks$.next(response.newPatientTracks);
        this._bindPatientTracks(this._trackerSettings);
      },
      (e: HttpErrorResponse) => {
        this._snackbar.open(`There was an issue when trying to contact the server: ${e.error.message}`, 'Ok', { duration: 3000 });
      }
    );
  }
}
