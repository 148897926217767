import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { AutomationFollowupPatientStoreEntity, AvaPatientInfo } from './state';

export const LoadPendingPatientsRequest = createAction('[AutomationFollowup] LoadPendingPatients Request', props<{ locationId: number }>());
export const LoadPendingPatientsSuccess = createAction(
  '[AutomationFollowup] LoadPendingPatients Success',
  props<{ patients: AutomationFollowupPatientStoreEntity[] }>()
);
export const LoadPendingPatientsFailure = createAction('[AutomationFollowup] LoadPendingPatients Failure', props<{ error: string | unknown }>());

export const LoadAvasPatientsRequest = createAction('[AutomationFollowup] LoadAvasPatients Request', props<{ locationId: number }>());
export const LoadAvasPatientsSuccess = createAction(
  '[AutomationFollowup] LoadAvasPatients Success',
  props<{ patients: AutomationFollowupPatientStoreEntity[]; patientCounts: AvaPatientInfo }>()
);
export const LoadAvasPatientsFailure = createAction('[AutomationFollowup] LoadAvasPatients Failure', props<{ error: string | unknown }>());

export const SetBellFlags = createAction('[AutomationFollow] SetBellFlags', props<{ bell: boolean; personId: number; isLead: boolean }>());
export const UpdatePerson = createAction('[AutomationFollowup]', props<{ update: Update<AutomationFollowupPatientStoreEntity> }>());

export const AddPerson = createAction('[AutomationFollowup] AddPerson', props<{ person: AutomationFollowupPatientStoreEntity }>());
export const AddPersons = createAction('[AutomationFollowup] AddPersons', props<{ persons: AutomationFollowupPatientStoreEntity[] }>());
export const RemovePerson = createAction('[AutomationFollowup] RemovePerson', props<{ personId: string }>());
export const RemovePersons = createAction('[AutomationFollowup] RemovePersons', props<{ personIds: string[] }>());
