import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-blast-sent-modal',
  templateUrl: './new-blast-sent-modal.component.html',
  styleUrls: ['./new-blast-sent-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NewBlastSentModalComponent {
  constructor(
    public dialogRef: MatDialogRef<NewBlastSentModalComponent>,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { blastName: string }) {}

    public goToBlastsHistory(): void {
      this._router.navigate(['dashboard/blasts']);
      this.dialogRef.close();
    }
}
