import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TemplatesService } from 'src/app/services/templates.service';
import { ITemplate } from '../ITemplate';
import { ITemplateUpdate } from '../ITemplateUpdate';
import { TemplateDialogComponent } from '../template-dialog/template-dialog.component';
import { TemplateAction } from '../TemplateAction';
import { TemplatesHelper } from '../TemplatesHelper';
import { TemplateType } from '../TemplateType';

@Component({
  selector: 'app-templates-table',
  templateUrl: './templates-table.component.html',
  styleUrls: ['./templates-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TemplatesTableComponent implements OnChanges, OnInit {
  @Input() private _templateType: TemplateType;
  @Input() public dataSource: ITemplate[] = [];
  @Input() public isModeration: boolean;
  @Input() public tableTitle: string;
  @Output() valueChange = new EventEmitter<ITemplateUpdate>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public tableDataSource: MatTableDataSource<ITemplate>;
  public displayedColumns = ['stars', 'createDate', 'actions'];
  public expandedElement: ITemplate;

  constructor(
    private _dialog: MatDialog,
    private _templatesDialogRef: MatDialogRef<TemplatesTableComponent>,
    private _tempaltesService: TemplatesService,
    private _templatesHelper: TemplatesHelper
  ) {
    this._templatesHelper.getTableData().subscribe((message) => {
      if (message && message.data) {
        this.dataSource = message.data;
        this.tableDataSource = new MatTableDataSource(this.dataSource);
        this.tableDataSource.sort = this.sort;
        this.sortData(this.sort);
      }
    });
  }

  public ngOnInit(): void {
    this.sort.sort({ id: 'stars', start: 'desc' } as MatSortable);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource && changes.dataSource.currentValue) {
      this.tableDataSource = new MatTableDataSource(changes.dataSource.currentValue);
      this.tableDataSource.sort = this.sort;
      this.sortData(this.sort);
    }
  }

  public newTemplate(): void {
    const dialogRef = this._dialog.open(TemplateDialogComponent, {
      width: '848px',
      autoFocus: false,
      panelClass: 'modify-template-dialog',
      data: {
        template: null,
        templateTypeKey: this._templateType.toString(),
        isModeration: this.isModeration,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.valueChange.emit({ template: data.template, action: TemplateAction.Add });
      }
    });
  }

  public editTemplate(template: ITemplate): void {
    const dialogRef = this._dialog.open(TemplateDialogComponent, {
      width: '848px',
      autoFocus: false,
      panelClass: 'modify-template-dialog',
      data: {
        template: template,
        templateTypeKey: this._templateType.toString(),
        isModeration: this.isModeration,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.valueChange.emit({ template: data.template, action: data.action });
      }
    });
  }

  public sortData(sort: Sort): void {
    if (!sort.active || sort.direction === '' || !this.dataSource) {
      return;
    }

    this.dataSource.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'stars':
          return this._compareStars(a, b, isAsc);
        case 'date':
          return this._compareDates(a, b, isAsc);
        default:
          return 0;
      }
    });
    this.tableDataSource.data = this.dataSource;
  }

  public applyTemplate(element: ITemplate): void {
    event.stopPropagation();
    this._templatesDialogRef.close(element);
  }

  public updateStar(element: ITemplate): void {
    event.stopPropagation();
    this._tempaltesService.updateStar(element.id).subscribe((_) => {
      element.isStarred = !element.isStarred;
      this.sortData(this.sort);
    });
  }

  private _compareDates(a: ITemplate, b: ITemplate, isAsc: boolean): number {
    if (a.createDate === b.createDate) {
      return (a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1) * (isAsc ? 1 : -1);
    }

    return (a.createDate < b.createDate ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private _compareStars(a: ITemplate, b: ITemplate, isAsc: boolean): number {
    if (a.isStarred === b.isStarred) {
      return a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1;
    }
    return (a.isStarred ? 1 : -1) * (isAsc ? 1 : -1);
  }
}
