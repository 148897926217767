/* eslint-disable */
export enum MergeField {
  Noop = '',
  PatientFirstName = "[[Patient's First Name]]",
  PatientLastName = "[[Patient's Last Name]]",
  PatientEmail = "[[Patient's Email]]",
  PatientCellPhone = "[[Patient's Cell Phone]]",
  ResponsibleFirstName = "[[Responsible party's First Name]]",
  ResponsibleLastName = "[[Responsible party's Last Name]]",
  LocationName = '[[Location Name]]',
  LocationPhone = '[[Location Phone Number]]',
  LocationAddress = '[[Location Address]]',
  LocationCity = '[[Location City]]',
  LocationState = '[[Location State]]',
  LocationZip = '[[Location Zip]]',
  TeamMemberFirstName = '[[Team First Name]]',
  TeamMemberLastName = '[[Team Last Name]]',
  PracticeName = '[[Practice Name]]',
  AppointmentDate = '[[Appointment Date]]',
  AppointmentTime = '[[Appointment Time]]',
  AppointmentDuration = '[[Appointment Duration]]',
  CustomerLogo = '[[Customer Logo]]',
  PmsName = '[[PMS Name]]',
  HasInsurance = '[[Has Insurance]]',
  CreateDate = '[[Create Date]]',
  FormsUrl = '[[Forms Url]]',
  SurveyUrl = '[[Survey Url]]',
  NPSUrl = '[[NPS Url]]',
  PatientPortalShortUrl = '[[Patient Portal ShortUrl]]',
  PatientPortalUrl = '[[Patient Portal Url]]',
}

export type MergeFieldHelper = { [key in keyof typeof MergeField]: { name: string; mergeField: MergeField } };

export const MERGE_FIELD_HELPER: MergeFieldHelper = {
  Noop: { name: '', mergeField: MergeField.Noop },
  PatientFirstName: { name: 'Patient First Name', mergeField: MergeField.PatientFirstName },
  PatientLastName: { name: 'Patient Last Name', mergeField: MergeField.PatientLastName },
  PatientEmail: { name: 'Patient Email', mergeField: MergeField.PatientEmail },
  PatientCellPhone: { name: 'Patient Cell Phone', mergeField: MergeField.PatientCellPhone },
  ResponsibleFirstName: { name: 'Responsible FirstName', mergeField: MergeField.ResponsibleFirstName },
  ResponsibleLastName: { name: 'Responsible Last Name', mergeField: MergeField.ResponsibleLastName },
  LocationName: { name: 'Location Name', mergeField: MergeField.LocationName },
  LocationPhone: { name: 'Location Phone', mergeField: MergeField.LocationPhone },
  LocationAddress: { name: 'Location Address', mergeField: MergeField.LocationAddress },
  LocationCity: { name: 'Location City', mergeField: MergeField.LocationCity },
  LocationState: { name: 'Location State', mergeField: MergeField.LocationState },
  LocationZip: { name: 'Location Zip', mergeField: MergeField.LocationZip },
  TeamMemberFirstName: { name: 'Team First Name', mergeField: MergeField.TeamMemberFirstName },
  TeamMemberLastName: { name: 'Team Last Name', mergeField: MergeField.TeamMemberLastName },
  PracticeName: { name: 'Practice Name', mergeField: MergeField.PracticeName },
  AppointmentDate: { name: 'Appointment Date', mergeField: MergeField.AppointmentDate },
  AppointmentTime: { name: 'Appointment Time', mergeField: MergeField.AppointmentTime },
  AppointmentDuration: { name: 'Appointment Duration', mergeField: MergeField.AppointmentDuration },
  CustomerLogo: { name: 'Customer Logo', mergeField: MergeField.CustomerLogo },
  PmsName: { name: 'Pms Name', mergeField: MergeField.PmsName },
  HasInsurance: { name: 'Has Insurance', mergeField: MergeField.HasInsurance },
  CreateDate: { name: 'Create Date', mergeField: MergeField.CreateDate },
  FormsUrl: { name: 'Forms Url', mergeField: MergeField.FormsUrl },
  SurveyUrl: { name: 'Survey Url', mergeField: MergeField.SurveyUrl },
  NPSUrl: { name: 'NPS Url', mergeField: MergeField.NPSUrl },
  PatientPortalShortUrl: { name: 'Patient Portal Short Url', mergeField: MergeField.PatientPortalShortUrl },
  PatientPortalUrl: { name: 'Patient Portal', mergeField: MergeField.PatientPortalUrl },
};

export enum FinancialMergeField {
  FeeOverview = '[[FeeOverview]]',
}

export type FinancialMergeFieldHelper = { [key in keyof typeof FinancialMergeField]: { name: string; mergeField: FinancialMergeField } };

export const FINANCIAL_MERGE_FIELDS: FinancialMergeFieldHelper = {
  FeeOverview: { name: 'Fee Overview', mergeField: FinancialMergeField.FeeOverview },
};
