import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { FollowUpCategoryEnum, FollowUpTypeEnum } from 'src/app/models/AvaContext';
import { FollowUpStatusEnum } from 'src/app/models/FollowUpStatusEnum';
import { RootStoreState } from 'src/app/root-store';
import { GetPatientByIdDto } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { ServiceProvider } from '../../service';
import { FollowUpModalComponent } from '../followup-modal';

@Component({
  selector: 'app-auto-modal',
  templateUrl: './auto-modal.component.html',
  styleUrls: ['./auto-modal.component.css'],
})
export class AutoModal extends FollowUpModalComponent implements OnInit {
  alternateModel = false;
  confirmAction = false;
  get canAddToFollowUp(): boolean {
    return !(this.ajaxIsBusy || (this.patientsIsLead() && this.AutoSetRemindObj.followUpCategoryId !== FollowUpCategoryEnum.NewLead));
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: GetPatientByIdDto,
    _matDialogRef: MatDialogRef<AutoModal>,
    remoteService: ServiceProvider,
    snackBar: MatSnackBar,
    authService: AuthService,
    dialog: MatDialog,
    private _dataService: DataService,
    store$: Store<RootStoreState.State>
  ) {
    super(data, _matDialogRef, authService, remoteService, snackBar, dialog, store$, FollowUpTypeEnum.Auto);
  }

  ngOnInit() {
    super.ngOnInit();
    this.alternateModel =
      this.patient &&
      this.patient.followUpLink &&
      this.patient.followUpLink.followUpTypeId === 1 &&
      this.patient.followUpLink.followUpStatusId != FollowUpStatusEnum.Completed;
  }

  continueToAutoSequence(): void {
    const post_obj = {
      patientLeadid: this.AutoSetRemindObj.patientId,
      is_lead: !this.AutoSetRemindObj.ispatient,
    };

    if (!this.ajaxIsBusy) {
      this.ajaxIsBusy = true;
      this.remoteService.post<boolean>('AvasFollowUp/AvaPatientForResumeOption', post_obj).subscribe(
        (res) => {
          this.confirmAction = true;
          this._dataService.sendAutoSequenceResumedMessage(post_obj.patientLeadid);

          this.closeModal();
          this.ajaxIsBusy = false;
        },
        (err) => {
          this.ajaxIsBusy = false;
        }
      );
    }
  }
  startNewAutoSequence(): void {
    this.alternateModel = false;
  }
}
