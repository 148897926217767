import { DataSource } from '@angular/cdk/collections';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EMPTY, Observable, Subject, combineLatest, noop, of } from 'rxjs';
import { shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/ComponentBase';
import { IPerson } from 'src/app/models/AvaContext';
import {
  AvaSliderDto,
  CasePresentationClient,
  CreatePatientContractDto,
  PatientContractClient,
  PatientContractDto,
  SignPatientContractDto,
} from 'src/app/services/api.service';
import { FinancialService } from 'src/app/shared/financial/financial.service';
import {
  CreatePatientContractDialogComponent,
  ICreatePatientContractDialogData,
  ICreatePatientContractDialogResult,
} from '../modals/create-patient-contract-dialog/create-patient-contract-dialog.component';
import { IViewPatientContractDialogData, ViewPatientContractDialogComponent } from '../modals/view-patient-contract/view-patient-contract.component';

@Component({
  selector: 'app-submitted-contracts',
  templateUrl: './submitted-contracts.component.html',
  styleUrls: ['./submitted-contracts.component.scss'],
  host: {
    '[class.disabled]': 'this.disabled',
  },
})
export class SubmittedContractsComponent extends ComponentBase implements OnDestroy {
  private _patient: IPerson;
  public get patient(): IPerson {
    return this._patient;
  }
  @Input('patient')
  public set patient(value: IPerson) {
    this._patient = value;
    this._loadPatientContracts();
  }
  private _patientContracts: PatientContractDto[];

  private _dataStream = new Subject<PatientContractDto[]>();
  public dataSource = new ContractDataSource<PatientContractDto>(this._dataStream.asObservable());
  public displayColumns = ['id', 'created', 'sliderTreatments', 'signed', 'actions'];

  @Input('disabled') disabled: boolean = false;

  constructor(
    private _patientContractClient: PatientContractClient,
    private _dialog: MatDialog,
    private _financialService: FinancialService,
    private _casePresentationClient: CasePresentationClient
  ) {
    super();
  }

  private _loadPatientContracts(): void {
    if (this._patient) {
      this.__subSink.sink = this._patientContractClient.patientContract_GetPatientContracts(this._patient.id).subscribe((patientContracts) => {
        this._patientContracts = patientContracts;
        this._dataStream.next(this._patientContracts);
      });
    }
  }

  assertItemType(element: unknown): PatientContractDto {
    return element as PatientContractDto;
  }

  viewContract(patientContract: PatientContractDto): void {
    this._dialog.open(ViewPatientContractDialogComponent, {
      data: <IViewPatientContractDialogData>{
        patientId: this._patient.id,
        patientContractId: patientContract.patientContractId,
      },
      maxWidth: 900,
      maxHeight: '90vh',
    });
  }

  signContract(patientContract: PatientContractDto): void {
    this.__subSink.sink = (patientContract.avaSliderId
      ? this._casePresentationClient.casePresentation_GetAvaSliderBySliderId(patientContract.avaSliderId)
      : of(<AvaSliderDto>null)
    )
      .pipe(
        switchMap((avaSlider) =>
          this._financialService.openContractViewDialog(patientContract, avaSlider && avaSlider.acceptDate ? avaSlider.acceptedSettings : null)
        ),
        switchMap((result) => (result ? of(result) : EMPTY)),
        switchMap((x) => combineLatest([of(x), this._financialService.openSignatureDialog('full')])),
        switchMap(([result, finalSignature]) =>
          this._patientContractClient.patientContract_SignPatientContract(
            new SignPatientContractDto({
              patientContractId: patientContract.patientContractId,
              signatures: result.signatures,
              finalSignature: finalSignature.value,
              checkboxes: result.checkboxes,
            }),
            patientContract.patientContractId,
            patientContract.patientId
          )
        )
      )
      .subscribe();
  }

  deleteContract(patientContractId: number): void {
    this.__working();
    this.__subSink.sink = this._patientContractClient
      .patientContract_DeletePatientContract(this._patient.id, patientContractId)
      .pipe(take(1))
      .subscribe(
        () => this._loadPatientContracts(),
        noop,
        () => this.__doneWorking()
      );
  }

  private _createPatientContract(patientId: number, contractTemplateId: number, avaSliderId: number = null): Observable<PatientContractDto> {
    return this._patientContractClient
      .patientContract_CreatePatientContract(
        patientId,
        new CreatePatientContractDto({
          contractTemplateId,
          avaSliderId,
        })
      )
      .pipe(take(1));
  }

  __openNewPatientContractDialog(): void {
    const data: ICreatePatientContractDialogData = {
      patientId: this._patient.id,
    };
    this.__subSink.sink = this._dialog
      .open<CreatePatientContractDialogComponent, ICreatePatientContractDialogData, ICreatePatientContractDialogResult>(
        CreatePatientContractDialogComponent,
        { data, width: '600px' }
      )
      .afterClosed()
      .pipe(
        tap(() => this.__working()),
        switchMap((result) => (result ? this._createPatientContract(result.patientId, result.contractTemplateId, result.avaSliderId) : EMPTY))
      )
      .subscribe(
        () => this._loadPatientContracts(),
        noop,
        () => this.__doneWorking()
      );
  }
}

class ContractDataSource<T> extends DataSource<T> {
  private _dataStream: Observable<T[]>;

  constructor(dataStream: Observable<T[]>) {
    super();
    this._dataStream = dataStream;
  }

  connect(): Observable<T[]> {
    return this._dataStream.pipe(shareReplay(1));
  }

  disconnect(): void {
    // Nothing to do
  }
}
