/* eslint-disable */
import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'app-confirm-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {

  constructor(public _matDialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  message = this.data.message;

  ngOnInit() {
  }

  no(): void {
    this._matDialogRef.close(null);
  }

  yes() {
    this._matDialogRef.close('cancel');
  }
}
