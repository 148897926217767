/* eslint-disable */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, } from '@angular/router';
import { TemplatesComponent } from './templates.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { TemplatesTableComponent } from './templates-table/templates-table.component';
import { TemplatesLibraryComponent } from './templates-library/templates-library.component';

const routes: Routes = [
	{ path: '', component: TemplatesComponent}
];

@NgModule({
  declarations: [
    TemplatesComponent,
    TemplatesTableComponent,
    TemplatesLibraryComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    MatSortModule,
    MatExpansionModule,
    MatButtonModule
  ],
  entryComponents: [TemplatesLibraryComponent, TemplatesTableComponent],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ]
})

export class TemplatesModule { }
