import { Component, HostListener } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  // prettier-ignore
  /* eslint-disable no-useless-escape */
  noAjaxSpinnerUrls: string[] = [
    '\/api\/Notification',
    '\/api\/public\/NPT\/(?:.*)\/(?:.*)+',
    '\/api\/Blast'
  ];
  /* eslint-enable no-useless-escape */

  constructor(private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) {
    this._matIconRegistry.addSvgIcon('circle-checkbox', this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/circle-checkbox.svg'));
    const _ht = window.innerHeight;
    document.documentElement.style.setProperty('--innerHeight', _ht + 'px');

    this._loadScript(environment.emergePayAssetUrl + 'cip-hosted-modal.js');
  }

  @HostListener('window:resize', ['$event'])
  onResize(_: Event): void {
    const _ht = window.innerHeight;
    document.documentElement.style.setProperty('--innerHeight', _ht + 'px');
  }

  private _loadScript(url: string) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
