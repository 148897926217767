import { Component, Input } from '@angular/core';
import { AdjustmentItem, PaymentOptionDto } from 'src/app/services/api.service';

@Component({
  selector: 'app-case-payment-option-editor',
  templateUrl: './case-payment-option-editor.component.html',
  styleUrls: ['./case-payment-option-editor.component.scss'],
})
export class CasePaymentOptionEditorComponent {
  @Input('value') paymentOption: PaymentOptionDto;

  __addDiscount(): void {
    this.paymentOption.discount.push(
      new AdjustmentItem({
        amount: 0,
        isPercent: false,
        description: '',
        includeDownPayment: false,
      })
    );
  }

  __removeDiscount(discount: AdjustmentItem, index: number): void {
    this.paymentOption.discount.splice(index, 1);
  }

  __addAddOn(): void {
    this.paymentOption.addOns.push(
      new AdjustmentItem({
        amount: 0,
        isPercent: false,
        description: '',
        includeDownPayment: false,
      })
    );
  }

  __removeAddOn(addOn: AdjustmentItem, index: number): void {
    this.paymentOption.addOns.splice(index, 1);
  }
}
