/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-responsible-party-confirm-dialog',
  templateUrl: './responsible-party-confirm-dialog.component.html',
  styleUrls: ['./responsible-party-confirm-dialog.component.css']
})
export class ResponsiblePartyConfirmDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ResponsiblePartyConfirmDialogComponent>) { }

  ngOnInit() {
  }

  onYesClicked() {
    this.dialogRef.close(true);
  }

  onNoClicked() {
    this.dialogRef.close(false);
  }

}
