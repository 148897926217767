import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'dialog-vc-wins',
  templateUrl: './dialog-vc-wins.html',
  styleUrls: ['./dialog-vc-wins.css'],
})
export class DialogVCWins implements AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public displayedColumns: string[] = ['firstName', 'cellPhone', 'leadSource', 'createDate'];
  public modalname = '';
  public searchCriteria: string;
  public searchResult: IPatientData[];
  public patientDataSource: MatTableDataSource<IPatientData>;
  public nameSort = true;
  public dobSort = false;
  public responsible_party = false;
  public patientData: IPatientData[] = [];
  public filterStartDay: Date = null;
  public filterEndDay: Date = null;
  public isDateRangePickerOpen = false;
  public patientsFor90DaysCount = 0;
  public selectedDaysFromNow = 90;
  constructor(public matDialogRef: MatDialogRef<DialogVCWins>, @Inject(MAT_DIALOG_DATA) public data: IDialogVCWinsData) {}

  ngOnInit(): void {
    this.patientData = this.data.modaldata;
    this.modalname = this.data.modalname;

    setTimeout(() => {
      this.patientDataSource = new MatTableDataSource(this.patientData);
      this.filterDataByDateRangePreset(90);
      this.patientsFor90DaysCount = this.patientDataSource.data.length;
      this.patientDataSource.sort = this.sort;
    });
  }

  ngAfterViewInit(): void {
    if (this.patientDataSource) {
      this.patientDataSource.sort = this.sort;
    }
  }

  public searchPatient(searchstring: string): void {
    this.searchCriteria = searchstring;
    const searchstr = searchstring.toLowerCase();
    const searchStrSplit = searchstr.split(' ');
    const filterSearchArr = searchStrSplit.filter((item) => item);

    if (searchstr.length >= 2) {
      const locationFilter = this.patientData;

      if (filterSearchArr.length == 1) {
        if (locationFilter.length > 0) {
          this.searchResult = locationFilter.filter((item) =>
            filterSearchArr.some((srch) => (item.firstName + ' ' + item.lastName).toLowerCase().includes(srch))
          );
        }
      } else if (filterSearchArr.length > 1) {
        const nameArr = { fname: filterSearchArr[0], lname: filterSearchArr[1] };
        if (locationFilter.length > 0) {
          this.searchResult = locationFilter.filter(
            (item) => item.firstName.toLowerCase().includes(nameArr.fname) && item.lastName.toLowerCase().includes(nameArr.lname)
          );
        }
      }

      if (this.searchResult && this.searchResult.length > 0) {
        this.patientDataSource.data = this.searchResult;
      } else {
        this.patientDataSource.data = this.patientData;
      }
    } else {
      this.patientDataSource.data = this.patientData;
    }
  }

  public onChangeStartDate(startDate: Date): void {
    if (startDate.toString() !== '') {
      this.filterStartDay = new Date(startDate);
      this.filterData();
    } else {
      this.filterStartDay = null;
    }
  }

  public onChageEndDate(endDate: Date): void {
    if (endDate.toString() !== '') {
      this.filterEndDay = new Date(endDate);
      this.filterData();
    } else {
      this.filterEndDay = null;
    }
  }

  public filterData(): void {
    if (this.filterStartDay && this.filterEndDay) {
      this.patientDataSource.data = this.patientData.filter(
        (x: { createDate: Date }) => new Date(x.createDate) >= this.filterStartDay && new Date(x.createDate) <= this.filterEndDay
      );
    }
  }

  public filterDataByDateRangePreset(daysFromNow: number): void {
    this.selectedDaysFromNow = daysFromNow;
    this.isDateRangePickerOpen = false;
    const currentDate = new Date();
    const startDate = new Date(currentDate.setDate(currentDate.getDate() - daysFromNow));
    this.patientDataSource.data = this.patientData.filter((x: { createDate: Date }) => new Date(x.createDate) >= startDate);
  }

  public cancel(): void {
    this.matDialogRef.close(null);
  }

  public toggleDateRangeSelector(): void {
    this.isDateRangePickerOpen = !this.isDateRangePickerOpen;
  }
}

export interface IPatientData {
  firstName: string;
  lastName: string;
  createDate: Date;
  leadSource: string;
}

export interface IDialogVCWinsData {
  modaldata: IPatientData[];
  modalname: string;
}
