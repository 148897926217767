/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, HostBinding, Input, OnDestroy, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploaderComponent),
      multi: true,
    },
  ],
})
export class ImageUploaderComponent implements OnDestroy, ControlValueAccessor {
  //#region forms stuff
  @HostBinding('attr.id') externalId = '';

  private _ID = '';
  @Input() set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }
  get id(): string {
    return this._ID;
  }

  @Input('value') _value = '';
  get value(): string {
    return this._value;
  }
  set value(val: string) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  onChange: any = () => {};
  registerOnChange(fn): void {
    this.onChange = fn;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  onTouched: any = () => {};
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  disabled: boolean = false;
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @Input() required: boolean = false;
  //#endregion

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @Input('maxSize') maxSize: number = 100;
  @Input('forceResize') forceResize: boolean = false;
  @Input('thumbnail') showThumbnail: boolean = true;
  @Input('thumbnailMaxSize') thumbnailMaxSize = '100px';

  @Output('image') imageOutput = new Subject<string>(); //outputs image as base64 string

  subs = new SubSink();
  public showSpinner: boolean = false;

  //on input change, get the file, read it, resize, and store base64 image in form
  saveImage(eve: Event): void {
    this.showSpinner = true;
    const p = new Promise((resolve) => {
      const input = eve.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        //ensure input has files
        //we will read the file client side
        const reader: FileReader = new FileReader();
        reader.onloadend = () => {
          this.subs.sink = this.resizeImage(reader.result as string, this.maxSize).subscribe((resizedImg) => {
            this.value = resizedImg;
            this.imageOutput.next(this.value);
            this.showSpinner = false;
            resolve;
          });
        };
        reader.readAsDataURL(input.files[0]); //read as base64, may change later
      }
    });
  }

  //takes a base64 encoded image string and resizes it by drawing onto a different sized canvas
  resizeImage(imgData: string, maxWidth: number): Observable<string> {
    const img = new Image();
    img.src = imgData;
    return fromEvent(img, 'load') //wait for image to load data before drawing to canvas from it
      .pipe(
        map(() => {
          //remap observable from onload event to our processed data url
          const canvas = document.createElement('canvas');
          const longSide = Math.max(img.width, img.height);
          let scaleFactor = maxWidth / longSide;

          if (longSide < maxWidth && !this.forceResize) scaleFactor = 1; //don't scale if beneath maxWidth and forced rescale is off

          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;

          //draw image onto downsized canvas and get new image data from it
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          return ctx.canvas.toDataURL();
        })
      );
  }
}
