import { AfterContentInit, Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Language, Lead } from 'src/app/models/AvaContext';
import { RootStoreState, TenantSettingsStoreSelectors, UserStoreSelectors } from 'src/app/root-store';
import { IPerson, NotificationPersonDto } from 'src/app/services/api.service';
import { PatientService } from 'src/app/services/patient.service';
import { LogCallService } from '../log-call.service';
import { CommunicationType, ModalData, PatientDetailsArea } from './ModalData';

@Component({
  selector: 'PatientCommon-dialog',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.css'],
})
export class PatientDetailsModal implements OnInit, AfterContentInit {
  @Input() patientData: any = {};

  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;

  public get isMobileDisplay(): boolean {
    return window.innerWidth < 768;
  }

  patient: IPerson;
  communicationModel: NotificationPersonDto;
  teamMembers = this._store$.select(UserStoreSelectors.selectAllUsers);
  languages: Language[];
  nextFollowUpMessageDate: Date;
  isLead: boolean;
  currentNavIndex: number = 0;
  dashboardAPIShouldRefresh = false;

  public optionArray = [PatientDetailsArea.Financial, PatientDetailsArea.Details, PatientDetailsArea.Communication] as const;
  public includeLogCall: boolean;

  constructor(
    public _matDialogRef: MatDialogRef<PatientDetailsModal>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private _patientService: PatientService,
    private _logCallService: LogCallService,
    private _store$: Store<RootStoreState.State>
  ) {
    this._logCallService.getLogCallButtonClick().subscribe(() => this.openCommunicationLogModal());
  }

  public ngOnInit(): void {
    this.data = Object.assign(this.data || {}, this.patientData);

    this._patientService.getPatientDetails(this.data.id, this.data.isLead).subscribe((response) => {
      this.patient = response.patient;
      this.communicationModel = new NotificationPersonDto(this.patient);
      this.isLead = this.patient instanceof Lead;
      this.patient.isLead = this.patient instanceof Lead;
      this.languages = response.languages;
      if (response.nextFollowUpMessageDate) {
        this.nextFollowUpMessageDate = new Date(response.nextFollowUpMessageDate);
      }
    });

    // Determine area to show by default
    if (this.data.patientDetailsArea) {
      this.currentNavIndex = this.optionArray.indexOf(this.data.patientDetailsArea);
    } else {
      this.currentNavIndex = this.data.selectedIndex;
    }

    this.isLead = this.data.isLead;
    this.includeLogCall = this.data.communicationType === CommunicationType.LogCall;
  }

  public ngAfterContentInit(): void {
    this.tabGroup.selectedIndex = this.currentNavIndex;
  }

  public cancel(): void {
    this._matDialogRef.close(this.dashboardAPIShouldRefresh);
  }

  public processDisplayArray(name: PatientDetailsArea): string {
    switch (name) {
      case 'details':
        return 'Details';
      case 'communications':
        return 'Messaging';
      case 'financials':
        return 'Financials';
      default:
        return '';
    }
  }

  public showModalContent(dir: 'next' | 'prev'): void {
    if (dir == 'next') {
      if (this.currentNavIndex < this.optionArray.length - 1) this.currentNavIndex++;
    } else if (dir == 'prev') {
      if (this.currentNavIndex > 0) this.currentNavIndex--;
    }
  }

  public isNavButtonNextVisible(index: number): boolean {
    return index < this.optionArray.length - 1;
  }

  public isNavButtonPrevVisible(index: number): boolean {
    return index > 0 && !!this.optionArray[index - 1];
  }

  public openCommunicationLogModal(): void {
    const index = this.optionArray.indexOf(PatientDetailsArea.Communication);
    if (index > -1) {
      this.currentNavIndex = index;
    }
  }

  public updatePatient(patient: IPerson): void {
    this.patient = patient;
    this.communicationModel = new NotificationPersonDto(patient);
  }

  public getCommunicationDefault(): string {
    switch (this.data.communicationType) {
      case CommunicationType.Default:
        return 'default';
      case CommunicationType.Email:
        return 'email';
      case CommunicationType.Message:
        return 'msg';
      case CommunicationType.LogCall:
        return 'log call';
      case CommunicationType.AddNote:
        return 'note';
    }
  }

  public getCloseColor(): string {
    switch (this.optionArray[this.currentNavIndex]) {
      case 'details':
      case 'financials':
        return '#FFF';
      case 'communications':
        return '#3C3C3C';
      default:
        return '#000';
    }
  }

  __isFinancialDisabled(): Observable<boolean> {
    return this._store$.select(TenantSettingsStoreSelectors.IsFinancialEnabled).pipe(map((res) => !res));
  }
}
