import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TemplateType } from '../TemplateType';
import { ITemplate } from '../ITemplate';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TemplateAction } from '../TemplateAction';
import { ConfirmationDialogComponent } from 'src/app/modal/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-template-dialog',
  templateUrl: './template-dialog.component.html',
  styleUrls: ['./template-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class TemplateDialogComponent implements OnInit {
  public templateTypes: KeyValuePair[] = [];
  public isNew: boolean;
  public dialogTitle: string;
  public templateForm: FormGroup;
  public isModeration: boolean;
  private _confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { template: ITemplate, templateTypeKey: string, isModeration: boolean} = null,
    private _dialogRef: MatDialogRef<TemplateDialogComponent>,
    private _fb: FormBuilder,
    public dialog: MatDialog) {
    this.isNew = !this._data.template;
    this.dialogTitle = !this.isNew ? 'Edit Template' : 'New Template';
    this.isModeration = this._data.isModeration;
  }

  public ngOnInit(): void {
    this.templateTypes = this._getTemplateTypes();
    const type = this.templateTypes.find(_ => _.key === this._data.templateTypeKey);

    this.templateForm = this._fb.group({
      type: [type, Validators.required],
      title: [this.isNew ? null : this._data.template.title, Validators.required],
      text: [this.isNew ? null : this._data.template.content, Validators.required]
    });
  }

  public drag(ev: DragEvent): void {
    const target = ev.target as Element;
    const text = target.getAttribute('data-text');
    ev.dataTransfer.setData('Text', text);
  }

  public onSaveClick(): void {
    if (!this.templateForm.valid) {
      this.templateForm.get('title').markAsTouched();
      this.templateForm.get('text').markAsTouched();
      return;
    }

    const template = <ITemplate>{
      templateType: this.templateForm.get('type').value.key,
      title: this.templateForm.get('title').value,
      content: this.templateForm.get('text').value
    };

    if (!this.isNew) {
      template.id = this._data.template.id;
      template.createDate = this._data.template.createDate;
      template.isStarred = this._data.template.isStarred;
    }

    this._dialogRef.close({template: template, action: this.isNew ? TemplateAction.Add : TemplateAction.Edit});
  }

  public onDeleteClick(): void {
    if (this.isNew) {
      this._dialogRef.close(null);
      return;
    }

    this._confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'confirmation-dialog-component',
      width: '358px',
      height: '208px',
      data: {
        text: 'Are you sure you want to delete this template?',
        buttonConfirmText: 'Confirm'
      }
    });

    this._confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._dialogRef.close({template: <ITemplate>{id: this._data.template.id}, action: TemplateAction.Delete});
      }
      this._confirmationDialogRef = null;
    });
  }

  private _getTemplateTypes(): KeyValuePair[] {
    const keys = Object.keys(TemplateType);
    return keys.slice(0, keys.length / 2).map(k => <KeyValuePair>{
      key: k,
      value: TemplateType[k]});
  }
}

interface KeyValuePair {
  key: string;
  value: string;
}
