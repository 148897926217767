import { Action, createReducer, on } from '@ngrx/store';
import * as AuthStoreActions from './actions';
import { State, initialState } from './state';

const reducer = createReducer(
  initialState,
  //LOGIN
  on(AuthStoreActions.Logout, (state) => initialState),
  on(AuthStoreActions.LoginAuthSuccess, (state, action) => ({ ...state, token: action.token, error: null, isLoggedIn: null, tokenDate: new Date() })),
  on(AuthStoreActions.LoginUserInfoSuccess, (state, action) => ({ ...state, userInfo: action.userInfo, error: null, isLoggedIn: null })),
  on(AuthStoreActions.LoginSuccess, (state) => ({ ...state, error: null, isLoggedIn: true })),
  on(AuthStoreActions.LoginFailure, (state, action) => ({ ...state, error: action.error, isLoggedIn: false })),
  on(AuthStoreActions.RefreshToken, (state) => ({ ...state, error: null, isLoggedIn: null })),
  on(AuthStoreActions.RefreshTokenSuccess, (state, action) => ({ ...state, token: action.token, isLoggedIn: true, tokenDate: new Date() })),
  on(AuthStoreActions.RefreshTokenFailure, (state, action) => ({ ...state, token: null, isLoggedIn: false, error: action.error }))
);

export function featureReducer(state: State | undefined, action: Action): State {
  return reducer(state, action);
}
