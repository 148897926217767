import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthService, AvaUser } from 'src/app/services/auth.service';
import { ChatTranscript } from '../automations/models/ChatTranscript.model';
import { LeadFromChatBot } from '../automations/models/LeadFromChatBot.model';
import { Lead } from '../models/AvaContext';
import {
  ChatBotSetting,
  HelpCategory,
  HelpChatHistory,
  HelpContactDetailsData,
  HelpContent,
  HelpDomain,
  HelpRequestType,
  HelpSessionRequest,
  HelpSessionResponse,
  HelpTranscriptHistory,
  LiveChatDetails,
  TextMessageRequested,
} from '../models/AvaMultiContext';
import { ServiceProvider } from '../service';
import { PreviewContent } from '../settings/chat-bot-response-setting/chat-bot-response-setting.component';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  auth_user: AvaUser;
  selected_global_location_id: number;
  chatBotIcon: ChatBotIcon[] = [
    { id: 0, name: 'ChatBot_icon1', filePath: './assets/images/Ava_chat/ChatBot_icon1.svg' },
    { id: 1, name: 'ChatBot_icon2', filePath: './assets/images/Ava_chat/ChatBot_icon2.svg' },
    { id: 2, name: 'ChatBot_icon3', filePath: './assets/images/Ava_chat/ChatBot_icon3.svg' },
    { id: 3, name: 'ChatBot_icon4', filePath: './assets/images/Ava_chat/ChatBot_icon4.svg' },
    { id: 4, name: 'ChatBot_icon5', filePath: './assets/images/Ava_chat/ChatBot_icon5.svg' },
    { id: 5, name: 'ChatBot_icon6', filePath: './assets/images/Ava_chat/ChatBot_icon6.svg' },
  ];
  chatContentResponse: HelpContent[] = [
    { helpContentId: 1, text: "Hey, i'm Ava!", helpContentTypeId: null, isCustomizable: false },
    {
      helpContentId: 2,
      text: 'I’m just a robot but I’m here to help.Select an option below to get started.',
      helpContentTypeId: null,
      isCustomizable: false,
    },
  ];
  chatContentRequest: HelpCategory[] = [
    { helpCategoryId: 1, name: 'Schedule an appointment', description: '' },
    { helpCategoryId: 2, name: 'Get information ', description: '' },
    { helpCategoryId: 3, name: 'Do a free virtual consult', description: '' },
    { helpCategoryId: 4, name: 'Something else', description: '' },
  ];

  previewContent: PreviewContent[] = [
    { Response: 'Are they a child or a teenager?', Request: 'No' },
    { Response: 'Would you like to know more about clear aligners?', Request: 'Yes' },
    { Response: 'Are they a child or a teenager?', Request: 'Yes' },
    { Response: 'Would you like more details?', Request: 'Yes, in-office visit' },
    { Response: 'What information are you interested in?', Request: 'Pricing' },
    { Response: 'Great! What can I help you with?', Request: 'Insurance' },
  ];
  sequenceHeading: string[] = ['First message', 'Second message', 'Third message', 'Fourth message'];
  chatContentRequests: HelpCategory[] = [
    { helpCategoryId: 1, name: 'Yes', description: '' },
    { helpCategoryId: 2, name: 'Not now', description: '' },
  ];

  private _subject = new Subject<{ chatTranscript: ChatTranscript; helpSessionId: number; endChat: boolean }>();

  constructor(private _remoteService: ServiceProvider, private _authSerivce: AuthService) {
    this.auth_user = this._authSerivce.User;
  }

  public sendMsg(chatTranscript: ChatTranscript, helpSessionId: number, endChat: boolean): void {
    this._subject.next({ chatTranscript: chatTranscript, helpSessionId: helpSessionId, endChat: endChat });
  }

  public receiveMsg(): Observable<{ chatTranscript: ChatTranscript; helpSessionId: number; endChat: boolean }> {
    return this._subject.asObservable();
  }

  public clearMsg(): void {
    this._subject.next();
  }

  startHelpSession(chatId = ''): Observable<number> {
    return this._remoteService.get<number>('HelpChat/StartHelpSession/' + chatId);
  }

  getChatBotSetting(): Observable<SettingData> {
    return this._remoteService.get<SettingData>('Help/GetChatBotSetting');
  }
  getChatBotSettingForChatBot(): Observable<ChatBotSetting> {
    return this._remoteService.get<ChatBotSetting>('HelpChat/GetChatBotSetting');
  }

  postChatBotSettingSave(chatBotSetting: ChatBotSetting): Observable<ReturnStatus> {
    return this._remoteService.post<ReturnStatus>(`Help/SaveChatBotSetting`, chatBotSetting);
  }

  getResponseOfUserInput(userInput: UserInput, botMessengerId: string): Observable<HelpResponse> {
    return this._remoteService.post<HelpResponse>(`HelpChat/GetResponseOfUserInput/${botMessengerId}`, userInput);
  }

  savePhoneOrEmailForNewLead(emailAndPhoneForNewLead: EmailAndPhoneForNewLead, botMessengerId: string): Observable<unknown> {
    return this._remoteService.post<unknown>(`HelpChat/SavePhoneOrEmailForNewLead/${botMessengerId}`, emailAndPhoneForNewLead);
  }

  getLastConversationsContent(helpSessionRequestId: number, botMessengerId: string): Observable<ChatTranscript[]> {
    return this._remoteService.get<ChatTranscript[]>(`HelpChat/GetLastConversationsContent/${helpSessionRequestId}/${botMessengerId}`);
  }

  generateLeadFromChatBot(leadFromChatBot: LeadFromChatBot, botMessengerId: string | number): Observable<number> {
    return this._remoteService.post<number>(`HelpChat/GenerateLeadFromChatBot/${botMessengerId}`, leadFromChatBot);
  }
  saveAutomatedChatTranscript(
    helpCategoryId: number,
    helpSessionId: number,
    helpContentList: string[],
    inputText: string,
    botMessengerId: string | number
  ): Observable<ReturnStatus> {
    const transcript: Transcript = {
      helpCategoryId: helpCategoryId,
      helpSessionId: helpSessionId,
      helpContentList: helpContentList,
      inputText: inputText,
    };
    return this._remoteService.post<ReturnStatus>(`HelpChat/SaveAutomatedChatTranscripts/botmessenger/${botMessengerId}`, transcript);
  }

  saveLiveChatTranscript(
    helpSessionId: number,
    helpTranscriptSenderId: number,
    textMessage: string,
    botMessengerId: string | number
  ): Observable<ReturnStatus> {
    const liveTranscript: LiveTranscript = { helpSessionId: helpSessionId, helpTranscriptSenderId: helpTranscriptSenderId, textMessage: textMessage };
    return this._remoteService.post<ReturnStatus>(`HelpChat/SaveLiveChatTranscript/botmessenger/${botMessengerId}`, liveTranscript);
  }

  getResponseContentSettingDataByResponseTypeId(selectedContentTypeId: number): Observable<HelpContent[]> {
    return this._remoteService.get<HelpContent[]>(
      `Help/GetResponseContentSettingDataByResponseTypeId/selectedContentTypeId/${selectedContentTypeId}`
    );
  }

  setResponseContentSetting(helpResponseContent: HelpContent[]): Observable<ReturnStatus> {
    return this._remoteService.post<ReturnStatus>(`Help/SetResponseContentSettingData`, helpResponseContent);
  }

  getInitialData(): Observable<HelpCategoryContentList> {
    return this._remoteService.get<HelpCategoryContentList>(`HelpChat/GetInitialMessage`);
  }

  GetAnalyticsData(startDate: Date, endDate: Date): Observable<AnalyticsData> {
    const timePeriod: TimePeriod = { startDate: startDate, endDate: endDate };
    return this._remoteService.post<AnalyticsData>(`Help/GetAnalyticsData`, timePeriod);
  }

  getContactDetails(startDate: Date, endDate: Date, botMessengerId: string | number): Observable<ContactDetailsData> {
    const timePeriod = { startDate: startDate, endDate: endDate, botMessengerId };
    return this._remoteService.post<ContactDetailsData>(`Help/GetContactDetailsData`, timePeriod);
  }

  getTeamMemberAvailability(): Observable<ReturnStatus> {
    return this._remoteService.get<ReturnStatus>(`HelpChat/GetTeamMemberAvailability`);
  }

  getHelpTranscriptHistory(helpSessionId: number): Observable<TranscriptHistory> {
    return this._remoteService.get<TranscriptHistory>(`Help/GetTranscriptHistoryData/helpSessionId/${helpSessionId}`);
  }
  getRequestedLiveChat(helpSessionId: number, acceptedBy: number): Observable<ChatHistory> {
    return this._remoteService.get<ChatHistory>(`Help/GetRequestedLiveChatData/helpSessionId/${helpSessionId}/acceptedBy/${acceptedBy}`);
  }

  liveChatRequestTimer(liveChatRequestId: number): Observable<number> {
    return this._remoteService.get<number>(`Help/LiveChatRequestTimer/liveChatRequestId/${liveChatRequestId}`);
  }

  saveLiveChatRequest(liveChatDetails: LiveChatDetails): Observable<number> {
    return this._remoteService.post<number>(`HelpChat/SaveLiveChatRequest`, liveChatDetails);
  }

  updateHelpSessionRequestStatus(helpSessionId: number): Observable<void> {
    return this._remoteService.post<void>(`Help/UpdateHelpSessionRequestStatus`, helpSessionId);
  }

  saveRequestResponseDetails(helpSessionResponse: HelpSessionResponse): Observable<SaveRequestResponseDetails> {
    return this._remoteService.post<SaveRequestResponseDetails>(`Help/SaveRequestResponseDetails`, helpSessionResponse);
  }

  getRequestTypes(): Observable<HelpRequestType[]> {
    return this._remoteService.get<HelpRequestType[]>(`HelpChat/GetRequestType`);
  }

  getLiveChatRequests(teamMemberId: number): Observable<LiveChatData[]> {
    return this._remoteService.get<LiveChatData[]>(`Help/GetLiveChatRequests/teamMemberId/${teamMemberId}`);
  }

  getAcceptedLiveChats(teamMemberId: number): Observable<AcceptedLiveChatData[]> {
    return this._remoteService.get<AcceptedLiveChatData[]>(`Help/GetAcceptedLiveChats/teamMemberId/${teamMemberId}`);
  }

  getContactLaterDetails(teamMemberId: number, locationId: number): Observable<HelpContactLaterList> {
    return this._remoteService.get<HelpContactLaterList>(`Help/GetContactLaterRequests/teamMemberId/${teamMemberId}/locationId/${locationId}`);
  }

  saveAcceptedRequestResponse(helpSessionResponse: HelpSessionResponse): Observable<ReturnStatus> {
    return this._remoteService.post<ReturnStatus>(`Help/SaveAcceptedRequestResponse`, helpSessionResponse);
  }

  uploadImage(formData: FormData, teamMemberId: number, teamMemberName: string): Observable<UploadImage> {
    return this._remoteService.post<UploadImage>(
      `TeamSettings/UploadProfilePicture/teamMemberId/${teamMemberId}/teamMemberName/${teamMemberName}`,
      formData
    );
  }

  getHelpContentCategoriesByChatRequestStatus(
    helpSessionId: number,
    helpRequestId: number,
    requestStatus: string,
    acceptedBy: number,
    botMessengerId: string
  ): Observable<HelpResponse> {
    return this._remoteService.get<HelpResponse>(
      `HelpChat/GetHelpResponseByChatRequestStatus/helpSessionId/${helpSessionId}/helpRequestId/${helpRequestId}/requestStatus/${requestStatus}/acceptedBy/${acceptedBy}/${botMessengerId}`
    );
  }

  getStaticDataForChatBotSetting(): ChatBotSettingStaticData {
    const staticData: ChatBotSettingStaticData = {
      chatBotIcon: this.chatBotIcon,
      chatContentResponse: this.chatContentResponse,
      chatContentRequest: this.chatContentRequest,
    };
    return staticData;
  }
  getStaticDataForChatBotResponseSetting(): ChatBotResponseSettingStaticData {
    const staticData: ChatBotResponseSettingStaticData = {
      chatBotIcon: this.chatBotIcon,
      previewContent: this.previewContent,
      sequenceHeading: this.sequenceHeading,
      chatContentRequests: this.chatContentRequests,
    };
    return staticData;
  }

  saveDomainName(helpDomain: HelpDomain): Observable<number> {
    return this._remoteService.get<number>(
      `Help/SaveDomainName/helpDomainId/${helpDomain.helpDomainId}/name/${helpDomain.name}/chatBotSettingId/${helpDomain.chatBotSettingId}`
    );
  }
  getTextMessageDetails(helpSessionId: number, teamMemberId: number): Observable<HelpRequestedTextMessage> {
    return this._remoteService.get<HelpRequestedTextMessage>(
      `Help/GetTextMessageDetails/helpSessionId/${helpSessionId}/teamMemberId/${teamMemberId}`
    );
  }

  getStartOverContent(answerId: string | number, helpCategoryId: string | number): Observable<unknown> {
    return this._remoteService.get(`HelpChat/startover/${answerId}/${helpCategoryId}`);
  }
}

export class SaveRequestResponseDetails {
  teamMemberName: string;
  helpSessionResponseId: number;
}

export class UploadImage {
  status: boolean;
  filePath: string;
}

export class ReturnStatus {
  status: boolean;
  message: string;
}

export class HelpCategoryContentList {
  helpCategoryList: HelpCategory[];
  helpContentList: string[];
}

export class TimePeriod {
  startDate: Date;
  endDate: Date;
}

export class Transcript {
  helpCategoryId: number;
  helpSessionId: number;
  helpContentList: string[];
  inputText: string;
}

export class LiveTranscript {
  helpSessionId: number;
  helpTranscriptSenderId: number;
  textMessage: string;
}

export class AnalyticsData {
  chatsInitiated: number;
  helpCategoryAnalyticsList: HelpCategoryCountList;
  humanChatsRequested: number;
  humanChatsAccepted: number;
}

export class HelpCategoryCountList {
  helpCategoryId: number;
  count: number;
}

export class LiveChatData {
  helpSessionRequestId: number;
  helpSessionId: number;
  requesterName: string;
}

export class AcceptedLiveChatData extends LiveChatData {
  lead: Lead;
  chatHistory: ChatTranscript[];
  waitingForResponse: boolean;
  isActive: boolean;
}

export class HelpContactLaterList {
  acceptedRequests: AcceptedRequests[];
  teamAlerts: HelpSessionRequest[];
}

export class ContactDetailsData {
  contactLists: HelpContactDetailsData[];
  requestedStatus: HelpContactDetailsData[];
}

export class TranscriptHistory {
  contactChatHistory: HelpTranscriptHistory[];
}

export class ChatHistory {
  requestedLiveChats: HelpChatHistory[];
  requestAcceptedMsg: HelpChatHistory[];
}
export class AcceptedRequests {
  helpSessionResponseId: number;
  helpSessionRequest: HelpSessionRequest;
}

export class UserInput {
  helpCategoryId: number;
  helpSessionId: number;
  helpSessionRequestId: number;
  text: string;
  isTeamMemberOnline: boolean;
  leadSource: string;

  constructor(
    helpSessionId: number,
    helpCategoryId: number,
    helpSessionRequestId: number,
    text: string,
    isTeamMemberOnline: boolean,
    leadSource: string
  ) {
    this.helpCategoryId = helpCategoryId;
    this.helpSessionId = helpSessionId;
    this.helpSessionRequestId = helpSessionRequestId;
    this.text = text;
    this.isTeamMemberOnline = isTeamMemberOnline;
    this.leadSource = leadSource;
  }
}

export class EmailAndPhoneForNewLead {
  email: string;
  phone: string;
  helpSessionRequestId: number;

  constructor(email: string, phone: string, helpSessionRequestId: number) {
    this.email = email;
    this.phone = phone;
    this.helpSessionRequestId = helpSessionRequestId;
  }
}

export class HelpResponse {
  helpCategoryList: HelpCategory[];
  helpContentList: string[];
  helpSessionRequestId: number;
  signalRAction: SignalRAction;
}

export enum SignalRAction {
  none,
  liveChatRequest,
  contactDetails,
  startLiveChat,
  RemoveTeamAlert,
  createNewSession,
  firstAndLastNameProvided,
}
export class ChatBotSettingStaticData {
  chatBotIcon: ChatBotIcon[];
  chatContentResponse: HelpContent[];
  chatContentRequest: HelpCategory[];
}
export class ChatBotResponseSettingStaticData {
  chatBotIcon: ChatBotIcon[];
  previewContent: PreviewContent[];
  sequenceHeading: string[];
  chatContentRequests: HelpCategory[];
}
export class SettingData {
  chatBotSetting: ChatBotSetting;
  domainList: HelpDomain[];
}
export class HelpRequestedTextMessage {
  requestedTextMessage: TextMessageRequested[];
}

export class ChatBotIcon {
  id: number;
  name: string;
  filePath: string;
}
