import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material';
import { AvaSelectComponent } from './ava-select.component';

@NgModule({
  declarations: [AvaSelectComponent],
  imports: [CommonModule, MatSelectModule, FormsModule],
  exports: [AvaSelectComponent],
})
export class AvaSelectModule {}
