/* eslint-disable */
import { AbstractControl } from "@angular/forms";
import { isNullOrUndefined } from "util";

const ssnRegEx = /^\d{3}-\d{2}-\d{4}$/gi;

export function SsnValidator(control: AbstractControl) {
  if (isNullOrUndefined(control.value) || control.value == '')
    return null;

  return ssnRegEx.test(control.value) ? null : { 'ssn': { 'value': control.value } };
}
