/* eslint-disable */
export interface FileViewModel {
  fileId: number;
  name: string;
  extension: string;
  location: string;
  medium: Medium;
}

export enum Medium {
  Other = 0,
  Image = 1,
  Video = 2,
  Audio = 3
}
