import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { SIGNALR_HUB_UNSTARTED, mergeMapHubToAction } from 'ngrx-signalr-core';
import { merge } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { RootStoreState } from '../..';
import { AuthStoreActions, AuthStoreSelectors } from '../../auth-store';
import { ofHubName } from '../OfHubName';
import * as SignalRHubStoreActions from '../actions';
import * as NotificationsHubActions from './actions';
import { NOTIFICATION_EVENTS } from './events';
import { HUB_DEFINITION } from './hub';

@Injectable({ providedIn: 'root' })
export class NotificationsHubEffectsService {
  constructor(private _actions$: Actions, private _store$: Store<RootStoreState.State>, private _authService: AuthService) {}

  ngrxOnInitEffects(): Action {
    return NotificationsHubActions.Init();
  }

  createHubOnInitEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.LoginSuccess),
      withLatestFrom(this._store$.select(AuthStoreSelectors.selectClientName)),
      map(([_, clientName]) =>
        NotificationsHubActions.CreateHub(clientName, () =>
          this._authService
            .ValidateTokenObs()
            .pipe(
              switchMap(() => this._store$.select(AuthStoreSelectors.selectOAuth)),
              take(1),
              map((x) => x.access_token)
            )
            .toPromise()
        )
      )
    )
  );

  onCreateStartHubEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SIGNALR_HUB_UNSTARTED),
      ofHubName(HUB_DEFINITION.hubName),
      mergeMapHubToAction(({ hub }) => {
        // const messageSentEvent$ =

        const hubEvents$ = Object.values(NOTIFICATION_EVENTS).map((value) =>
          hub.on(value).pipe(map((data) => SignalRHubStoreActions.Event({ eventName: value, data: data })))
        );

        return merge(
          ...hubEvents$,
          this._store$.select(AuthStoreSelectors.selectClientName).pipe(
            take(1),
            map((clientName) => NotificationsHubActions.StartHub(clientName))
          )
        );
      })
    )
  );
}
