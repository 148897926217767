import { InjectionToken } from '@angular/core';

export type AvaDomain = 'AVA3' | 'AVAGP';

const _navigationMenuByDomain: { [key: string]: AvaDomain } = {
  'ava3.dentma.com': 'AVA3',
  'avagp.dentma.com': 'AVAGP',
  'qa.dentma.com': 'AVA3',
  'avagp-qa.dentma.com': 'AVAGP',
  localhost: 'AVA3', // Modify this during testing to switch modes
  // Add more domains here
};

export const AVA_DOMAIN_VALUE = _navigationMenuByDomain[window.location.hostname];

export const AVA_DOMAIN = new InjectionToken<AvaDomain>('AvaDomain', { providedIn: 'root', factory: () => AVA_DOMAIN_VALUE });
