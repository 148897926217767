import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { PaymentOptionDto } from 'src/app/services/api.service';

@Component({
  selector: 'app-payment-option-editor-dialog',
  templateUrl: './payment-option-editor-dialog.component.html',
  styleUrls: ['./payment-option-editor-dialog.component.scss'],
})
export class PaymentOptionEditorDialogComponent {
  get __paymentOption(): PaymentOptionDto {
    return this._data.paymentOption;
  }
  set __paymentOption(value: PaymentOptionDto) {
    this._data.paymentOption = value;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private _data: IPaymentOptionEditorDialogData) {}
}

export interface IPaymentOptionEditorDialogData {
  paymentOption: PaymentOptionDto;
}
