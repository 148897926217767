import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password-success',
  templateUrl: './reset-password-success.component.html',
  styleUrls: ['./reset-password-success.component.css'],
})
export class ResetPasswordSuccessComponent {
  constructor(private _router: Router) {}

  backToLogin(): void {
    this._router.navigate(['login']);
  }
}
