import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';

@Directive({ selector: '[featureIf]'})
export class FeatureIfDirective {
    constructor(private _templateRef: TemplateRef<any>, private _viewContainer: ViewContainerRef, private _featureFlagService: FeatureFlagService) { }

    @Input() set featureIf(featureName: string) {
        if (this._featureFlagService.featureOn(featureName)) {
          this._viewContainer.createEmbeddedView(this._templateRef);
        }
        else{
          this._viewContainer.clear();
        }
      }
  }
