/* eslint-disable */
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { catchError, mergeMap, switchMap, take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { RootStoreState } from './root-store';
import { AuthStoreSelectors } from './root-store/auth-store';
import { AuthService } from './services/auth.service';
import { LogoutService } from './shared/logoutService';

@Injectable()
export class ServiceProvider {
  private BASE_URL: string = 'api/';
  private _oAuth$ = combineLatest([this._store$.select(AuthStoreSelectors.selectOAuth), this._store$.select(AuthStoreSelectors.selectClientName)]);

  constructor(
    public http: HttpClient,
    public router: Router,
    private LogoutService: LogoutService,
    private authService: AuthService,
    private _store$: Store<RootStoreState.State>
  ) {}

  get<T>(
    url: string,
    params: HttpParams | { [param: string]: string | string[] } = null,
    responseType: 'blob' | 'arraybuffer' | 'text' | 'json' = null
  ): Observable<T> {
    return this.authService.ValidateTokenObs().pipe(
      switchMap(() => this._oAuth$.pipe(take(1))),
      mergeMap(([auth, clientName]) => {
        const headers = new HttpHeaders({
          Authorization: auth ? auth.token_type + ' ' + auth.access_token : '',
          'x-client-name': clientName || '',
        });

        let options = { headers: headers, params: params };
        if (responseType) options['responseType'] = responseType;
        return this.http.get<T>(`${environment.ServerPath}${this.BASE_URL}${url}`, options);
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) this.LogoutService.logout();
        throw err;
      })
    );
  }

  post<T>(url: string, data?: any): Observable<T> {
    return this.authService.ValidateTokenObs().pipe(
      switchMap(() => this._oAuth$.pipe(take(1))),
      mergeMap(([auth, clientName]) => {
        const headers = new HttpHeaders({
          Authorization: auth ? auth.token_type + ' ' + auth.access_token : '',
          'x-client-name': clientName || '',
        });
        return this.http.post<T>(`${environment.ServerPath}${this.BASE_URL}${url}`, data, { headers: headers });
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) this.LogoutService.logout();
        throw err;
      })
    );
  }

  delete<T>(url: string): Observable<T> {
    return this.authService.ValidateTokenObs().pipe(
      switchMap(() => this._oAuth$.pipe(take(1))),
      mergeMap(([auth, clientName]) => {
        const headers = new HttpHeaders({
          Authorization: auth ? auth.token_type + ' ' + auth.access_token : '',
          'x-client-name': clientName || '',
        });
        return this.http.delete<T>(`${environment.ServerPath}${this.BASE_URL}${url}`, { headers: headers });
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) this.LogoutService.logout();
        throw err;
      })
    );
  }

  put<T>(url: string, data?: any): Observable<T> {
    return this.authService.ValidateTokenObs().pipe(
      switchMap(() => this._oAuth$.pipe(take(1))),
      mergeMap(([auth, clientName]) => {
        const headers = new HttpHeaders({
          Authorization: auth ? auth.token_type + ' ' + auth.access_token : '',
          'x-client-name': clientName || '',
        });
        return this.http.put<T>(`${environment.ServerPath}${this.BASE_URL}${url}`, data, { headers: headers });
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) this.LogoutService.logout();
        throw err;
      })
    );
  }

  patch<T>(url: string, data?: any): Observable<T> {
    return this.authService.ValidateTokenObs().pipe(
      switchMap(() => this._oAuth$.pipe(take(1))),
      mergeMap(([auth, clientName]) => {
        const headers = new HttpHeaders({
          Authorization: auth ? auth.token_type + ' ' + auth.access_token : '',
          'x-client-name': clientName || '',
        });
        return this.http.patch<T>(`${environment.ServerPath}${this.BASE_URL}${url}`, data, { headers: headers });
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) this.LogoutService.logout();
        throw err;
      })
    );
  }

  getData<T = unknown>(url: string): Promise<T> {
    return this.authService
      .ValidateTokenObs()
      .pipe(
        switchMap(() => {
          return this.get<T>(url);
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status == 401) this.LogoutService.logout();
          throw err;
        })
      )
      .toPromise();
  }

  deleteData(url: string) {
    return this.authService
      .ValidateTokenObs()
      .pipe(
        switchMap(() => {
          return this.delete(url);
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status == 401) this.LogoutService.logout();
          throw err;
        })
      )
      .toPromise();
  }

  postData<T = unknown>(url: string, data, urlEncodedBody = false): Promise<T> {
    return this.authService
      .ValidateTokenObs()
      .pipe(
        switchMap(() => {
          if (urlEncodedBody) data = new HttpParams({ fromObject: data });
          return this.post<T>(url, data);
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status == 401) this.LogoutService.logout();
          throw err;
        })
      )
      .toPromise();
  }
}
