import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChartOptions } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { LocationStoreSelectors, RootStoreState } from 'src/app/root-store';
import { ServiceProvider } from 'src/app/service';
import { SubSink } from 'subsink';
import { SurveyReport } from '../../models/SurveyReport.model';

@Component({
  selector: 'app-survey-report',
  templateUrl: './survey-report.component.html',
  styleUrls: ['./survey-report.component.css'],
})
export class SurveyReportComponent implements OnInit, OnDestroy {
  public _subs = new SubSink();
  public report: SurveyReport;
  public startDate: Date;
  public endDate: Date;
  public locationId: number;
  //charts
  public chartLabels: Label[] = ['Pre-Appointment', 'Arrival', 'Appointment', 'Referral', 'Points Missed'];
  public chartLabelsIndividual: Label[] = ['Score', 'Missed'];
  public chartOptions: ChartOptions = {
    cutoutPercentage: 80,
    rotation: -180,
    aspectRatio: 1,
    tooltips: {
      callbacks: {
        label: this._labelCallBack,
      },
    },
  };
  public chartColors: Color[] = [{ backgroundColor: ['#13a9de', '#bdcfd1', '#e32120', '#0e2336', '#FFFFFF'] }];
  public chartDataSets: {
    total: SingleDataSet;
    totalLabel: { grade: string; percentage: string };
    preAppointment: SingleDataSet;
    preAppointmentLabel: { grade: string; percentage: string };
    arrival: SingleDataSet;
    arrivalLabel: { grade: string; percentage: string };
    appointment: SingleDataSet;
    appointmentLabel: { grade: string; percentage: string };
    referral: SingleDataSet;
    referralLabel: { grade: string; percentage: string };
  };

  constructor(private _remoteService: ServiceProvider, private _store$: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this._subs.sink = this._store$
      .select(LocationStoreSelectors.getSelectedLocationId)
      .pipe(
        distinctUntilChanged(),
        filter((x) => !!x)
      )
      .subscribe((locationId) => (this.locationId = locationId));
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public loadReport(LocationId: number, StartDate: Date = null, EndDate: Date = null): void {
    let params = new HttpParams();
    if (StartDate) params = params.append('StartDate', StartDate.toJSON());
    if (EndDate) params = params.append('EndDate', EndDate.toJSON());

    this._subs.sink = this._remoteService
      .get<SurveyReport>(`NewPatientTracker/GetSurveyReport/${LocationId}`, params)
      .pipe(
        map((response) => {
          response.startDate = new Date(response.startDate);
          response.endDate = new Date(response.endDate);
          return response;
        })
      )
      .subscribe((response) => {
        this.report = response;
        this.startDate = this.report.startDate;
        this.endDate = this.report.endDate;
        this._buildChart();
      });
  }

  public getOutOfTheSurveyInfoInPercents(value: number, count: number): number {
    return (value / count) * 100;
  }

  public getOutOfTheSurveyInfoInPercentsValue(value: number, count: number): number {
    return parseFloat(Math.round((value / count) * 100).toFixed(2));
  }

  private _buildChart(): void {
    this.chartDataSets = {
      total: null,
      totalLabel: { grade: null, percentage: null },
      preAppointment: null,
      preAppointmentLabel: { grade: null, percentage: null },
      arrival: null,
      arrivalLabel: { grade: null, percentage: null },
      appointment: null,
      appointmentLabel: { grade: null, percentage: null },
      referral: null,
      referralLabel: { grade: null, percentage: null },
    };

    const totalValue =
      this.report.scores.preAppointment + this.report.scores.arrival + this.report.scores.appointment + this.report.scores.referral * 2;
    const totalPerc = totalValue / (this.report.count * 105);
    this.chartDataSets.total = [
      this.report.scores.preAppointment,
      this.report.scores.arrival,
      this.report.scores.appointment,
      this.report.scores.referral * 2,
      this.report.count * 105 - totalValue,
    ];
    this.chartDataSets.totalLabel.grade = this._getGrade(totalPerc);
    this.chartDataSets.totalLabel.percentage = (totalPerc * 100).toFixed(1) + '%';

    const preAppointmentPerc = this.report.scores.preAppointment / (this.report.count * 20);
    this.chartDataSets.preAppointment = [this.report.scores.preAppointment, this.report.count * 20 - this.report.scores.preAppointment];
    this.chartDataSets.preAppointmentLabel.grade = this._getGrade(preAppointmentPerc);
    this.chartDataSets.preAppointmentLabel.percentage = (preAppointmentPerc * 100).toFixed(1) + '%';

    const arrivalPerc = this.report.scores.arrival / (this.report.count * 20);
    this.chartDataSets.arrival = [this.report.scores.arrival, this.report.count * 20 - this.report.scores.arrival];
    this.chartDataSets.arrivalLabel.grade = this._getGrade(arrivalPerc);
    this.chartDataSets.arrivalLabel.percentage = (arrivalPerc * 100).toFixed(1) + '%';

    const appointmentPerc = this.report.scores.appointment / (this.report.count * 35);
    this.chartDataSets.appointment = [this.report.scores.appointment, this.report.count * 35 - this.report.scores.appointment];
    this.chartDataSets.appointmentLabel.grade = this._getGrade(appointmentPerc);
    this.chartDataSets.appointmentLabel.percentage = (appointmentPerc * 100).toFixed(1) + '%';

    const referralPerc = this.report.scores.referral / (this.report.count * 20);
    this.chartDataSets.referral = [this.report.scores.referral, this.report.count * 15 - this.report.scores.referral];
    this.chartDataSets.referralLabel.grade = this._getGrade(referralPerc);
    this.chartDataSets.referralLabel.percentage = (referralPerc * 100).toFixed(1) + '%';
  }

  private _labelCallBack(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string {
    const dataset = data.datasets[tooltipItem.datasetIndex];
    const total = (dataset.data as number[]).reduce((a, b) => a + b);
    const currentValue = dataset.data[tooltipItem.index] as number;
    const percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
    return `${data.labels[tooltipItem.index]} (${percentage}%)`;
  }

  private _getGrade(score: number): string {
    score = score * 100;
    if (score >= 96) return 'A+';
    else if (score >= 93) return 'A';
    else if (score >= 90) return 'A-';
    else if (score >= 86) return 'B+';
    else if (score >= 83) return 'B';
    else if (score >= 80) return 'B-';
    else if (score >= 76) return 'C+';
    else if (score >= 73) return 'C';
    else if (score >= 70) return 'C-';
    else if (score >= 66) return 'D+';
    else if (score >= 63) return 'D';
    else if (score >= 60) return 'D-';
    else if (score >= 56) return 'F+';
    else if (score >= 53) return 'F-';

    return 'F-';
  }
}
