import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/ComponentBase';
import { BrandStoreSelectors, LocationStoreSelectors, RootStoreState, UserStoreSelectors } from 'src/app/root-store';
import { AvaSliderDto, IPerson } from 'src/app/services/api.service';

@Component({
  selector: 'app-send-slider-dialog',
  templateUrl: './send-slider-dialog.component.html',
  styleUrls: ['./send-slider-dialog.component.scss'],
})
export class SendSliderDialogComponent extends ComponentBase {
  __sendForm = this._fb.group({
    teamMemberId: this._fb.control(null, [Validators.required]),
    recipient: this._fb.group({
      firstName: this._fb.control(null),
      lastName: this._fb.control(null),
      to: this._fb.control(null, [Validators.required]),
    }),
    subject: this._fb.control(DEFAULT_SUBJECT, [Validators.minLength(2)]),
    body: this._fb.control(null, [Validators.minLength(2)]),
    buttonHtml: this._fb.control([
      `\n\nClick the Link Below\n<p><a href="[[hostName]]/public/patient" style="text-decoration: none; width:105px; padding:15px; background:#2295FF;height:25px; color:white;">Setup Your Payment Plan</a></p>`,
    ]),
    footer: this._fb.control(null, [Validators.minLength(2)]),
    responsiblePartyId: this._fb.control(null),
  });

  private _activeBrand$ = this._store$.select(BrandStoreSelectors.getSelectedBrand);
  private _activeLocation$ = this._store$.select(LocationStoreSelectors.getSelectedLocation);
  private _loggedUser$ = this._store$.select(UserStoreSelectors.selectLoggedUser);
  __teamMembers$ = this._store$.select(UserStoreSelectors.selectAllUsers).pipe(
    map((users) => users.filter((x) => x.isActive)),
    withLatestFrom(this._loggedUser$),
    map(([users, loggedUser]) => {
      this.__sendForm.patchValue({
        teamMemberId: loggedUser.id,
      });
      this.__generateBody();
      return users;
    })
  );
  get __recipientOptions(): ISendSliderRecipientOption[] {
    return (
      this._data.recipientOptions && this._data.recipientOptions.filter((x) => !this.__isNullOrUndefined(x.recipient.to) && x.recipient.to != '')
    );
  }

  constructor(@Inject(MAT_DIALOG_DATA) private _data: ISendSliderDialogData, private _fb: FormBuilder, private _store$: Store<RootStoreState.State>) {
    super();
  }

  __generateBody(): void {
    this.__subSink.sink = combineLatest([
      this._activeBrand$,
      this._activeLocation$,
      this._store$.select(UserStoreSelectors.selectUserById(this.__sendForm.get('teamMemberId').value)),
    ])
      .pipe(take(1))
      .subscribe(([activeBrand, activeLocation, user]) => {
        const mergeFields: { field: string; value: string }[] = [
          { field: 'recipientFirstName', value: this.__sendForm.get('recipient.firstName').value || '' },
          { field: 'patientFirstName', value: this._data.patient.firstName },
          { field: 'brandName', value: activeBrand.name },
          { field: 'teamFirstName', value: user.firstName },
          { field: 'teamLastName', value: user.lastName },
          { field: 'locationEmail', value: activeLocation.displayEmail || '' },
          { field: 'locationAddress', value: activeLocation.displayAddress || '' },
          { field: 'locationCity', value: activeLocation.displayCity || '' },
          { field: 'locationState', value: activeLocation.displayState || '' },
          { field: 'locationZip', value: activeLocation.displayZip || '' },
        ];
        this.__sendForm.patchValue({
          subject: this._replaceMergeFields(DEFAULT_SUBJECT, mergeFields),
          body: this._replaceMergeFields(DEFAULT_BODY, mergeFields),
          footer: this._replaceMergeFields(DEFAULT_FOOTER, mergeFields),
        });
      });
  }

  private _replaceMergeFields(content: string, mergeFields: { field: string; value: string }[]): string {
    mergeFields.forEach((mergeField) => {
      content = content.replace(`[[${mergeField.field}]]`, mergeField.value);
    });
    return content;
  }

  __setRecipient(option: ISendSliderRecipientOption): void {
    this.__sendForm.patchValue({
      recipient: {
        firstName: option.recipient.firstName,
        lastName: option.recipient.lastName,
        to: option.recipient.to,
      },
      responsiblePartyId: option.responsiblePartyId,
    });
    this.__generateBody();
  }

  __compareWith(_: ISendSliderRecipientOption, optionItem: ISendSliderRecipientOption): boolean {
    if (this.__sendForm) {
      const formRecipient = this.__sendForm.get('recipient').value as ISendSliderRecipient;
      const formResponsiblePartyId = this.__coerceNumberProperty(this.__sendForm.get('responsiblePartyId').value, <number>null);
      return (
        optionItem.recipient.firstName == formRecipient.firstName &&
        optionItem.recipient.lastName == formRecipient.lastName &&
        optionItem.recipient.to == formRecipient.to &&
        optionItem.responsiblePartyId == formResponsiblePartyId
      );
    }
  }

  __trackByField<T>(trackField: keyof T): (index: number, item: T) => unknown {
    return this.__trackByFieldFn(trackField);
  }
}

export interface ISendSliderDialogData {
  avaSlider: AvaSliderDto;
  defaultRecipient: ISendSliderRecipientOption;
  defaultSubject?: string;
  defaultBody?: string;
  defaultFooter?: string;
  recipientOptions?: ISendSliderRecipientOption[];
  patient: IPerson;
}

export interface ISendSliderDialogResult {
  teamMemberId: number;
  recipient: ISendSliderRecipient;
  subject: string;
  body: string;
  buttonHtml: string;
  footer: string;
  responsiblePartyId?: number;
}

export interface ISendSliderRecipient {
  firstName?: string;
  lastName?: string;
  to: string;
}

export type ISendSliderRecipientOption = { recipient: ISendSliderRecipient; responsiblePartyId?: number };

const DEFAULT_SUBJECT = `Treatment Payment Options for [[patientFirstName]]`;
const DEFAULT_BODY = `Hello [[recipientFirstName]],

Thank you for choosing [[brandName]]. We hope your visit was enjoyable.

Below is a link to the patient portal where you can set up your custom treatment and payment plan.

Please let me know if you have any questions!`;
const DEFAULT_FOOTER = `[[teamFirstName]] [[teamLastName]]
\n[[brandName]]\n[[locationEmail]]\n[[locationAddress]]
[[locationCity]], [[locationState]] [[locationZip]]`;
