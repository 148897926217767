import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPerson } from 'src/app/models/AvaContext';
import { RootStoreState, TenantSettingsStoreSelectors } from 'src/app/root-store';

@Component({
  selector: 'app-patient-financial',
  templateUrl: './patient-financial.component.html',
  styleUrls: ['./patient-financial.component.scss'],
})
export class PatientFinancialComponent {
  @Input('patient') patient: IPerson;

  constructor(private _store$: Store<RootStoreState.State>) {}

  __isLedgersDisabled(): Observable<boolean> {
    return this._store$.select(TenantSettingsStoreSelectors.IsLedgersEnabled).pipe(map((x) => !x));
  }
  __isSlidersDisabled(): Observable<boolean> {
    return this._store$.select(TenantSettingsStoreSelectors.IsSlidersEnabled).pipe(map((x) => !x));
  }
  __isContractsDisabled(): Observable<boolean> {
    return this._store$.select(TenantSettingsStoreSelectors.IsContractsEnabled).pipe(map((x) => !x));
  }
}
