import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface IAvaSelectOption {
  label: string | number;
  value: unknown;
}

export class AvaSelectOption implements IAvaSelectOption {
  label: string | number;
  value: unknown;

  constructor(label: string, value: unknown) {
    this.label = label;
    this.value = value;
  }
}

@Component({
  selector: 'ava-select',
  templateUrl: 'ava-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AvaSelectComponent),
    },
  ],
})
export class AvaSelectComponent implements ControlValueAccessor {
  //#region ControlValueAccessor props
  private _value: unknown;
  public get value(): unknown {
    return this._value;
  }
  public set value(value: unknown) {
    if (this._value != value) {
      this._value = value;
      this._changed(value);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _changed: (x: unknown) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _touched: () => void = () => {};

  isDisabled: boolean = false;
  //#endregion

  @Input('labelMax') labelMax: number = 2;

  private _multiple: boolean;
  @Input('multiple')
  public get multiple(): boolean {
    return this._multiple;
  }
  public set multiple(value: boolean) {
    //handle converting string value of "true" or "false"
    this._multiple = String(value).toLowerCase() === 'true';
  }

  @Input('placeholder') placeholder: string;

  private _options: IAvaSelectOption[];
  @Input('options')
  public get options(): AvaSelectOption[] {
    return this._options;
  }
  public set options(value: AvaSelectOption[]) {
    this._options = value;
  }

  @Input('compareWith') compareWith: (a: unknown, b: unknown) => boolean = (a: unknown, b: unknown) => a == b;

  @Input('colorMode') colorMode: 'light' | 'dark' = 'dark';

  public filterTriggerDisplay(): string {
    const max = this.labelMax || 2;
    if (this._multiple && this._options && this._value) {
      const selectedOptions = this._getSelectedOptions();
      let label = selectedOptions
        .slice(0, max)
        .map((x) => x.label)
        .join(', ');
      if (selectedOptions.length > max) label += ` and ${selectedOptions.length - max} more...`;
      return label;
    }
  }

  private _getSelectedOptions(): IAvaSelectOption[] {
    const selection = this._value as unknown[];
    return this._options.filter((x) => selection.some((y) => this.compareWith(x.value, y)));
  }

  //#region ControlValueAccessor functions
  writeValue(obj: unknown): void {
    this._value = obj;
  }
  registerOnChange(fn: (value: unknown) => void): void {
    this._changed = fn;
  }
  registerOnTouched(fn: () => void): void {
    this._touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  //#endregion

  valueChanged(output: unknown): void {
    if (this._changed) this._changed(output);
    if (this._touched) this._touched();
  }

  trackByFn(index: number): number {
    return index;
  }
}
