/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAction } from '@ngrx/store';
import { createSignalRHub, startSignalRHub } from 'ngrx-signalr-core';
import { HUB_DEFINITION } from './hub';

export const Init = createAction('[Notifications Hub] Init');

export const CreateHub = (clientName: string, tokenFactory: () => string | Promise<string>) =>
  createSignalRHub({
    hubName: HUB_DEFINITION.hubName,
    url: HUB_DEFINITION.url.replace('{ClientName}', clientName),
    options: {
      accessTokenFactory: tokenFactory,
    },
  });

export const StartHub = (clientName: string) =>
  startSignalRHub({
    hubName: HUB_DEFINITION.hubName,
    url: HUB_DEFINITION.url.replace('{ClientName}', clientName),
  });
