import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import {
  AutoPayProcessStatusEnum,
  AvaSliderAcceptedSettings,
  CasePresentationClient,
  LedgerRollIntervalEnum,
  PatientLedgerPaymentPlanDto,
  PatientLedgerPaymentPlanItemDto,
  PaymentPlanClient,
} from 'src/app/services/api.service';
import { RequireAtLeastOne } from 'src/app/utility/RequireAtLeastOne';
import { RoundNumber } from 'src/app/utility/RoundNumber';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-payment-plan-dialog',
  templateUrl: './payment-plan-dialog.component.html',
  styleUrls: ['./payment-plan-dialog.component.scss'],
})
export class PaymentPlanDialogComponent implements OnInit, OnDestroy {
  public isWorking$ = new Subject<boolean>();
  private _subSink = new SubSink();
  public paymentPlan: PatientLedgerPaymentPlanDto;
  public acceptedPatientSliders$ = this.patientSliders();
  public readonly TOMORROW = moment().startOf('day').add(1, 'day').toDate();

  public creatorMode: boolean = false;
  public creatorForm = new FormGroup({
    interval: new FormControl(LedgerRollIntervalEnum.Monthly),
    amount: new FormControl(0),
  });
  public creatorItems: PatientLedgerPaymentPlanItemDto[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: PaymentPlanDialogData,
    private _dialogRef: MatDialogRef<PaymentPlanDialogComponent>,
    private _paymentPlanClient: PaymentPlanClient,
    private _casePresentationClient: CasePresentationClient,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.isWorking$.next(true);
    this._subSink.sink = this._paymentPlanClient.paymentPlan_GetPaymentPlan(this._data.patientId, this._data.ledgerId).subscribe((result) => {
      this.paymentPlan = result;
      this.isWorking$.next(false);
    });
  }

  ngOnDestroy(): void {
    this._subSink.unsubscribe();
  }

  dueNow(): number {
    return this.paymentPlan.dueCurrentAmount + this.paymentPlan.due30Amount + this.paymentPlan.due60Amount + this.paymentPlan.due90Amount;
  }

  planItems(): PatientLedgerPaymentPlanItemDto[] {
    return this.paymentPlan && this.paymentPlan.items.sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime());
  }

  patientSliders(): Observable<SliderSettingsViewModel[]> {
    return this._casePresentationClient.casePresentation_GetAvaSliderByPatient(this._data.patientId).pipe(
      take(1),
      map((sliders) => sliders.filter((x) => x.acceptDate != null)),
      map((acceptedSettings) => acceptedSettings.map((x) => <SliderSettingsViewModel>{ ...x.acceptedSettings, acceptDate: x.acceptDate })),
      map((acceptedSettings) => acceptedSettings.filter((x) => !x.paymentOptions[0].payInFull)),
      shareReplay()
    );
  }

  planIsValid(): boolean {
    if (!this.paymentPlan) return false;

    // Total Due cannot be less than zero
    if (this.dueNow() < 0) return false;

    // Future due cannot be less than zero
    if (this.paymentPlan.dueFutureAmount < 0) return false;

    // Due Now and Due Future must equal the current balance
    if (this.paymentPlan.balanceAmount != this.dueNow() + this.paymentPlan.dueFutureAmount) return false;

    return true;
  }

  importFromSlider(acceptedSettings: SliderSettingsViewModel, startDate: Date): void {
    if (startDate == null) {
      return;
    }

    const months = acceptedSettings.monthsAmountSelected;
    const dueFuture = this.paymentPlan.dueFutureAmount;
    const newPlanItems = this._generatePlanItems({ startDate, dueFuture, times: months, interval: LedgerRollIntervalEnum.Monthly });

    this.paymentPlan.items = this.paymentPlan.items.filter((x) => x.isProcessed == true).concat(newPlanItems);
  }

  updateFutureDue(): void {
    this.paymentPlan.dueFutureAmount = this.paymentPlan.balanceAmount - this.dueNow();
  }

  processedStatusText(status: AutoPayProcessStatusEnum): string {
    switch (status) {
      case AutoPayProcessStatusEnum.NotProcessed:
        return 'Not Processed';
      case AutoPayProcessStatusEnum.ProcessFailed:
        return 'Autopay Failed';
      case AutoPayProcessStatusEnum.ProcessSkipped:
        return 'Autopay Skipped';
      case AutoPayProcessStatusEnum.ProcessSucceeded:
        return 'Autopay Succeeded';
    }
  }

  processedStatusIcon(status: AutoPayProcessStatusEnum): string {
    switch (status) {
      case AutoPayProcessStatusEnum.NotProcessed:
        return 'pending';
      case AutoPayProcessStatusEnum.ProcessFailed:
        return 'error_outline';
      case AutoPayProcessStatusEnum.ProcessSkipped:
        return 'next_plan';
      case AutoPayProcessStatusEnum.ProcessSucceeded:
        return 'task_alt';
    }
  }

  processedStatusColor(status: AutoPayProcessStatusEnum): string {
    switch (status) {
      case AutoPayProcessStatusEnum.NotProcessed:
      case AutoPayProcessStatusEnum.ProcessSkipped:
        return null;
      case AutoPayProcessStatusEnum.ProcessFailed:
        return 'warn';
      case AutoPayProcessStatusEnum.ProcessSucceeded:
        return 'primary';
    }
  }

  savePaymentPlan(): void {
    this.isWorking$.next(true);
    this._subSink.sink = this._paymentPlanClient
      .paymentPlan_CreateOrUpdatePaymentPlan(this._data.patientId, this._data.ledgerId, this.paymentPlan)
      .subscribe(
        (result) => {
          if (result) {
            this._dialogRef.close(true);
          }
        },
        () => {
          this.isWorking$.next(false);
          this._snackBar.open('There was a problem while trying to save the payment plan.', 'OK', { duration: 4000 });
        }
      );
  }

  intervalSelectOptions(): { value: LedgerRollIntervalEnum; label: string }[] {
    return Object.keys(LedgerRollIntervalEnum)
      .filter((x) => Number.isNaN(parseInt(x)))
      .map((x) => ({
        value: LedgerRollIntervalEnum[x],
        label: x,
      }));
  }

  intervalOptionTrackFn(item: { value: LedgerRollIntervalEnum; label: string }): number {
    return item.value;
  }

  updateCreator(interval: LedgerRollIntervalEnum, amount: number, startDate: Date): void {
    this.creatorItems = [];

    const dueFuture = this.paymentPlan.dueFutureAmount;
    this.creatorItems = this._generatePlanItems({ startDate, dueFuture, amount, interval });
  }

  acceptCreatorItems(): void {
    this.paymentPlan.items = this.paymentPlan.items.filter((x) => x.isProcessed).concat(this.creatorItems);
    this.creatorItems = [];
    this.creatorMode = false;
  }

  coerceNumber(value: unknown): number {
    return coerceNumberProperty(value);
  }

  private _generatePlanItems(
    options: RequireAtLeastOne<
      { startDate: Date; dueFuture: number; times?: number; amount: number; interval: LedgerRollIntervalEnum },
      'times' | 'amount'
    >
  ) {
    let momentInterval: moment.unitOfTime.DurationConstructor;
    switch (options.interval) {
      case LedgerRollIntervalEnum.Weekly:
        momentInterval = 'week';
        break;
      case LedgerRollIntervalEnum.Monthly:
        momentInterval = 'month';
        break;
      case LedgerRollIntervalEnum.Quarterly:
        momentInterval = 'quarter';
        break;
      case LedgerRollIntervalEnum.Yearly:
        momentInterval = 'year';
        break;
    }

    const date = moment(options.startDate).startOf('day');
    if (options.times) {
      return Array(options.times)
        .fill(null)
        .map((_, ind) => {
          const item = new PatientLedgerPaymentPlanItemDto({
            ledgerPaymentPlanItemId: 0,
            dueDate: date.toDate(),
            // Weird logic for amountDue is so that last payment accurately matches how much is left and rounding errors don't go over or under a few cents the balance
            // Subtracts total of all previous months to find last item payment
            amountDue:
              ind + 1 == options.times
                ? RoundNumber(options.dueFuture - RoundNumber(options.dueFuture / options.times, 2) * ind, 2)
                : RoundNumber(options.dueFuture / options.times, 2),
            isProcessed: false,
            autoPayProcessStatus: AutoPayProcessStatusEnum.NotProcessed,
          });
          date.add(1, momentInterval);
          return item;
        });
    } else if (options.amount) {
      const items: PatientLedgerPaymentPlanItemDto[] = [];
      let remainingFutureBalance = options.dueFuture;
      while (remainingFutureBalance > 0) {
        items.push(
          new PatientLedgerPaymentPlanItemDto({
            ledgerPaymentPlanItemId: 0,
            dueDate: date.toDate(),
            // Weird logic for amountDue is so that last payment accurately matches how much is left and rounding errors don't go over or under a few cents the balance
            // Subtracts total of all previous months to find last item payment
            amountDue: remainingFutureBalance - options.amount >= 0 ? options.amount : remainingFutureBalance,
            isProcessed: false,
            autoPayProcessStatus: AutoPayProcessStatusEnum.NotProcessed,
          })
        );
        remainingFutureBalance = remainingFutureBalance - options.amount;
        date.add(1, momentInterval);
      }
      return items;
    }
    throw new Error('You must specify either <times> or <amount>');
  }
}

export interface PaymentPlanDialogData {
  patientId: number;
  ledgerId: number;
}

type SliderSettingsViewModel = AvaSliderAcceptedSettings & { acceptDate: Date };
