import { createAction, props } from '@ngrx/store';
import { UserStoreEntity } from './models';

export const Init = createAction('[Users] User Store Init');

export const LoadRequest = createAction('[Users] Load Request');
export const LoadSuccess = createAction('[Users] Load Success', props<{ users: UserStoreEntity[] }>());
export const LoadFailure = createAction('[Users] Load Failure', props<{ error: string | unknown }>());

export const UpsertRequest = createAction('[Users] Upsert Request', props<{ user: UserStoreEntity }>());
export const UpsertSuccess = createAction('[Users] Upsert Success', props<{ user: UserStoreEntity }>());
export const UpsertFailure = createAction('[Users] Upsert Failure', props<{ user: UserStoreEntity; error: string | unknown }>());

export const UnlockRequest = createAction('[Users] Unlock Request', props<{ teamMemberId: number }>());
export const UnlockSuccess = createAction('[Users] Unlock Success', props<{ teamMemberId: number }>());
export const UnlockFailure = createAction('[Users] Unlock Failure', props<{ teamMemberId: number; error: string | unknown }>());
