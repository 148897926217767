// Accurate rounding function adapted from https://stackoverflow.com/a/12830454
export function RoundNumber(num: number, scale: number): number {
  if (!('' + num).includes('e')) {
    return +(Math.round(+(num + 'e+' + scale)) + 'e-' + scale);
  } else {
    const arr = ('' + num).split('e');
    let sig = '';
    if (+arr[1] + scale > 0) {
      sig = '+';
    }
    return +(Math.round(+(+arr[0] + 'e' + sig + (+arr[1] + scale))) + 'e-' + scale);
  }
}
