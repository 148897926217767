import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { NewPatientAppointment } from '../models/AvaContext';
import { ServiceProvider } from '../service';
import { NewPatientAppointmentTypeDto, NewPatientSchedulerClient } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class NewPatientSchedulerService {
  public serviceUrl = 'NewPatientScheduler/';

  constructor(private _serviceProvider: ServiceProvider, private _newPatientSchedulerClient: NewPatientSchedulerClient) {}

  public recordPms(newPatientAppointmentId: number, isRecorded: boolean): Observable<{ status: boolean }> {
    return this._serviceProvider.get(`${this.serviceUrl}UpdateNewTableApp/npsid/${newPatientAppointmentId}/IsRecorded/${isRecorded}`);
  }

  public getAppointments(globalLocationId: number, teamMemberId: number): Observable<NewPatientAppointmentTypeDto> {
    return this._newPatientSchedulerClient.newPatientScheduler_GetNewPatient_AppointmentType(globalLocationId, teamMemberId).pipe(take(1));
  }

  public getPatientDataByAppointment(appointment: NewPatientAppointment): Observable<{ patientId: number; isLead: boolean }> {
    return this._serviceProvider.post<{ patientId: number; isLead: boolean }>(`${this.serviceUrl}GetPatientDataByAppointment`, appointment);
  }

  public hideNewPatientAppointment(newPatientAppointmentId: number, isHidden: boolean): Observable<boolean> {
    return this._newPatientSchedulerClient.newPatientScheduler_HideNewPatientAppointment(newPatientAppointmentId, isHidden);
  }
}

export interface NewPatientAppointmentType {
  status: boolean;
  data: NewPatientAppointment[];
  pmsName: string;
  pmsIsNpsReady: boolean;
}
