import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HubConnection } from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { Subscription, forkJoin } from 'rxjs';
import { filter, finalize, switchMap, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { ChatTranscript } from 'src/app/dashboard/dashboard.component';
import { isSameDate } from 'src/app/helpers/date-helpers';
import { GetCommunicationAlertMessage, canSendEmail, canSendSms, isResponsiblePartyForAdult } from 'src/app/helpers/patient-communication';
import { FollowUpModalData } from 'src/app/models/AutoSetRemind';
import { AvasPatient } from 'src/app/models/Dashboard';
import { EventConstants } from 'src/app/models/Event';
import { LocationStoreSelectors, RootStoreState, SIGNALR_EVENTS, UserStoreEntity } from 'src/app/root-store';
import { AuthStoreSelectors } from 'src/app/root-store/auth-store';
import { NotificationPersonDto, NotificationResponsiblePartyDto } from 'src/app/services/api.service';
import { AcceptedLiveChatData, HelpService } from 'src/app/services/help.service';
import { ModalContainerService } from 'src/app/services/modal-container.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TemplatesService } from 'src/app/services/templates.service';
import { ITemplateContextComponent } from 'src/app/templates/ITemplateContextComponent';
import { TemplateType } from 'src/app/templates/TemplateType';
import { TemplatesHelper } from 'src/app/templates/TemplatesHelper';
import { TemplatesLibraryComponent } from 'src/app/templates/templates-library/templates-library.component';
import { SubSink } from 'subsink';
import { ActionType, ChatDetail, Communication, CommunicationHistory, FutureChatDetail } from '../../models/Communication';
import { Medium } from '../../models/FileViewModel';
import { ServiceProvider } from '../../service';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { DropdownComponent } from './dropdown/dropdown.component';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CommunicationComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges, ITemplateContextComponent {
  private _subSink = new SubSink();

  error_msg = '';
  CommunicationData: Communication = <Communication>{};

  msgHistoryArr: CommunicationHistory[] = [];

  futureComunicationArr: FutureChatDetail[];
  Medium = Medium;

  public msg_theme: any = { bgcolor: '#2295FF' };
  public email_theme: any = { bgcolor: '#00E1C7' };
  public note_theme: any = { bgcolor: '#F6CC61' };
  public avachat_theme: any = { bgcolor: '#FF5858' };
  public grey_theme_bgcolor: '#C4C4C4';

  public isMessagePanelExpanded: boolean;

  note_option: any = [
    { id: 'Log Call', option: 'Log Call' },
    { id: 'Note', option: 'Note' },
  ];

  // 5	call log - voicemail	NULL	1
  // 6	call log - skipped call	NULL	1
  // 7	call log - spoke with the patient	NULL	1
  // 8	call log - other	NULL	1
  // 9	add note	NULL	1

  logcall_option: any = [
    { id: '7', option: 'Spoke with patient' },
    { id: '5', option: 'Left a voicemail' },
    { id: '6', option: 'Skipped call' },
    { id: '8', option: 'Other' },
  ];

  avaBtnOptions: any[] = ['Auto', 'Set', 'Remind'];

  selectedSender: UserStoreEntity;
  showSender_dd = false;
  msg_date: any;

  noteSelected = false;
  showSubNoteOption = false;
  msgSelected = false;
  emailSelected = false;
  avaChatSelected = false;
  showMsgOption = false;
  avaBtnOption = false;
  historyHover: any;
  futureHover: any;
  defaultSender = false;
  callLogIcon = false;

  futureCommunication = false;
  responsiblePartyOptions: { id: number; option: string }[];
  ajaxIsBusy: boolean;
  isSendingMessage: boolean;
  officeNumberChanged: boolean;
  selectedResponsibleParty: NotificationResponsiblePartyDto;

  @ViewChild('modalwindow', { static: true }) modalwindow: ElementRef<HTMLDivElement>;
  @ViewChild('bottomsection', { static: true }) bottomsection: ElementRef<HTMLDivElement>;
  @ViewChildren('note') noteSelector: QueryList<DropdownComponent>;
  @ViewChild('scrollbar', { static: true }) scrollbar: CdkVirtualScrollViewport;

  private _noteSelector: DropdownComponent;
  private selectorSubscription: Subscription;
  private offset = 55;
  private emailActionId = 1;
  private messageActionId = 3;

  public topSectionHeight: number = 0;
  public futureSectionHeight = 0;
  public hideMessagingBox: boolean;
  public bottomHight: number = 0;

  patientAge: number;

  @Input() defaultcommunication: any;
  @Input() patient_detail: NotificationPersonDto;
  @Input() patients: AvasPatient[];
  @Input() teamMembers: UserStoreEntity[];
  get teamMembersActive(): UserStoreEntity[] {
    return this.teamMembers.filter((x) => x.isActive);
  }
  @Input() connection: HubConnection;
  @Input() expandedFromNotificationCenter: boolean;
  @Input() acceptedLiveChat: AcceptedLiveChatData;
  @Input() acceptedTeamMemberId: number;
  @Input() embeddedCommunication: boolean;
  @Input() followUpId: number;
  @Output() isMessageSendFromTeamMember = new EventEmitter();

  get isLead(): boolean {
    return this.patient_detail.leadId !== null && this.patient_detail.isLead;
  }
  private _teamMemberId$ = this._store$.select(AuthStoreSelectors.selectTeamMemberId);

  addNewMessages = (model: ChatDetail[]) => {
    (model || []).forEach((d) => this.addNewMessage(d, true));
    this.scrollToBottom();
  };
  addNewMessage = (model: ChatDetail, noscroll = false) => {
    // CONDITION: This message does not belong to the current patient
    if ((!model.isLead && model.patientId !== this.patient_detail.patientId) || this.isLead !== model.isLead) {
      return;
    }

    this.isSendingMessage = false;
    const last = this.msgHistoryArr.length ? this.msgHistoryArr[this.msgHistoryArr.length - 1] : null;

    // Add to existing element in msgHistoryArr if there are messages from today
    if (last && isSameDate(last.date, new Date())) {
      if (last.chatdetail && last.chatdetail.length) {
        //validation for the same message from callback
        if (
          last.chatdetail[last.chatdetail.length - 1].msg_content == model.msg_content &&
          (new Date(model.msgdate).getTime() - new Date(last.chatdetail[last.chatdetail.length - 1].msgdate).getTime()) / 1000 < 5
        ) {
          return;
        }

        last.chatdetail.push(model);
      } else {
        last.chatdetail = [model];
      }
    } else {
      // Otherwise, add a new element
      this.msgHistoryArr.push({
        date: new Date(model.msgdate),
        chatdetail: [model],
      });
    }
    if (!noscroll) {
      this.scrollToBottom();
    }
  };

  constructor(
    private remoteService: ServiceProvider,
    private notificationService: NotificationService,
    private ModalContainerService: ModalContainerService,
    private dialog: MatDialog,
    public templatesHelper: TemplatesHelper,
    public templatesService: TemplatesService,
    private helpService: HelpService,
    private _store$: Store<RootStoreState.State>
  ) {
    this._subSink.sink = this.helpService.receiveMsg().subscribe((message) => {
      if (message && this.acceptedLiveChat && message.helpSessionId === this.acceptedLiveChat.helpSessionId) {
        const chatDetail = this.transformChatTranscriptToChatDetail(message.chatTranscript, this.acceptedLiveChat);
        this.msgHistoryArr[0].chatdetail.push(chatDetail);
        this.hideMessagingBox = message.endChat;
        this.scrollToBottom();
      }
    });
  }

  ngOnInit() {
    if (this.patient_detail.patientId != null) {
      this.CommunicationData.patientId = this.patient_detail.patientId;
      this.CommunicationData.leadId = null;
    } else {
      this.CommunicationData.leadId = this.patient_detail.leadId;
      this.CommunicationData.patientId = null;
      if (this.patient_detail.isAnonymous && !this.embeddedCommunication) {
        this.displayAlert("I don't recognize the sender who initiated this conversation.");
      }
    }

    this.patient_detail.patientResponsiblePartyLinks = this.patient_detail.patientResponsiblePartyLinks || [];

    this.patientAge = this.patient_detail.age;
    if (!this.embeddedCommunication) {
      // temp
      this.GetAllCommunicationDetailsFromAPI();
    }
    this.msg_date = new Date();

    // Handle sent messages
    this._subSink.add(
      this.notificationService.ofEvent(SIGNALR_EVENTS.MessageSentCommunication).subscribe((data) => this.addNewMessage(data)),
      this.notificationService.ofEvent(SIGNALR_EVENTS.MessageSentRangeCommunication).subscribe((data) => this.addNewMessages(data)),
      this.notificationService.ofEvent(SIGNALR_EVENTS.MessageReceivedCommunication).subscribe((data) => this.addNewMessage(data))
    );

    this.responsiblePartyOptions = this.patient_detail.patientResponsiblePartyLinks.map((r) => ({
      id: r.responsiblePartyId,
      option: `${r.responsibleParty.firstName} ${r.responsibleParty.lastName} ${r.isAvaPrimary ? '(primary)' : ''}`,
    }));

    if (this.patient_detail.isChild) {
      const primaryRp = this.patient_detail.patientResponsiblePartyLinks.find((r) => r.isAvaPrimary);
      this.ResponsiblePartySelection(primaryRp ? primaryRp.responsiblePartyId : null);
    } else {
      this.responsiblePartyOptions.unshift({ id: null, option: '(none)' }); // Add an empty option to de-select responsible party
    }
  }

  ngOnDestroy(): void {
    this._subSink.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.acceptedLiveChat && changes.acceptedLiveChat.currentValue) {
      this.getRequestedLiveChatData(changes.acceptedLiveChat.currentValue);
    }
    if (changes.expandedFromNotificationCenter) {
      if (this.expandedFromNotificationCenter) {
        this.GetAllCommunicationDetailsFromAPI();
        this.expandedFromNotificationCenter = false;
      }
      this.scrollToBottom();
    }
  }

  GetAllCommunicationDetailsFromAPI() {
    const PostObj = {
      IsPatient: !this.isLead,
      CommonID: this.CommunicationData.patientId || this.CommunicationData.leadId,
      CurrentLocationId: null,
    };

    this._subSink.sink = this._store$
      .select(LocationStoreSelectors.getSelectedLocationId)
      .pipe(
        take(1),
        tap((locationId) => (PostObj.CurrentLocationId = locationId)),
        switchMapTo(this.remoteService.post<any>(`Patient/CommunicationDetails`, PostObj)),
        withLatestFrom(this._teamMemberId$)
      )
      .subscribe(([res, teamMemberId]) => {
        if (res.status) {
          const SelectedSystemLoggedInUSerArrayIndex = this.teamMembers.findIndex((item) => item.id === teamMemberId);
          this.msgHistoryArr = res.msgHistoryArr;
          this.officeNumberChanged = res.officePhoneNumberChanged;
          this.initializeSender(SelectedSystemLoggedInUSerArrayIndex);

          this.scrollToBottom();
        }
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.selectMsgType(this.defaultcommunication);
      if (this.defaultcommunication === 'note') {
        this.selectorSubscription = this.noteSelector.changes.subscribe((comps: QueryList<DropdownComponent>) => {
          setTimeout(() => {
            this._noteSelector = comps.first;
            this._noteSelector.selectedOption(1);
            this.selectorSubscription.unsubscribe();
          });
        });
      } else if (this.defaultcommunication === 'log call') {
        this.closeFutureSchedule();
        this.selectorSubscription = this.noteSelector.changes.subscribe((comps: QueryList<DropdownComponent>) => {
          setTimeout(() => {
            this._noteSelector = comps.first;
            this._noteSelector.selectedOption(0);
            this.selectorSubscription.unsubscribe();
          });
        });
      }
    });
  }

  ResponsiblePartySelection(val: number): void {
    this.CommunicationData.responsiblePartyId = val;
    if (val) {
      this.selectedResponsibleParty = this.patient_detail.patientResponsiblePartyLinks.find((r) => r.responsiblePartyId === val).responsibleParty;
    } else {
      this.selectedResponsibleParty = null;
    }

    this.displayCommunicationAlert();
  }

  SendCommunication(): void {
    if (!this.canSendMessage() || this.ajaxIsBusy) {
      return;
    }

    this.ajaxIsBusy = true;
    this.error_msg = null;
    this.isSendingMessage = true;

    const formData = new FormData();
    for (const prop in this.CommunicationData) {
      if (this.CommunicationData[prop] === null) {
        continue;
      }
      formData.append(prop, this.CommunicationData[prop]);
    }

    if (this.patient_detail.patientId != null) {
      formData.set('patientId', this.patient_detail.patientId.toString());
    }
    if (this.patient_detail.leadId != null) {
      formData.set('leadId', this.patient_detail.leadId.toString());
    }

    this._subSink.sink = forkJoin({
      locationId: this._store$.select(LocationStoreSelectors.getSelectedLocationId).pipe(take(1)),
      teamMemberId: this._teamMemberId$.pipe(take(1)),
    })
      .pipe(
        take(1),
        tap((res) => {
          formData.append('locationId', res.locationId.toString());
          formData.append('teamMemberId', res.teamMemberId.toString());
        }),
        switchMapTo(this.remoteService.post<HttpResponse<any>>('Communication', formData)),
        finalize(() => (this.ajaxIsBusy = false))
      )
      .subscribe(
        () => {
          if (this.CommunicationData.actionId == this.messageActionId)
            this.addNewMessage(<ChatDetail>{
              patientId: this.CommunicationData.patientId != null ? this.CommunicationData.patientId : this.CommunicationData.leadId,
              msgdate: new Date().toISOString(),
              msg_content: this.CommunicationData.content,
              sender_firstname: this.selectedSender.firstName,
              sender_lastname: this.selectedSender.lastName,
              isLead: this.CommunicationData.leadId !== null,
              communicationtype: this.CommunicationData.actionId == this.messageActionId ? 'msg' : 'email',
              msg_dir: 'sent',
            });
          this.CommunicationData.content = null;
          this.CommunicationData.subjectLine = null;
          this.CommunicationData.file = null;
          this.error_msg = null;
          this.isSendingMessage = false;
          this.isSendingMessage = false;
          this.isMessageSendFromTeamMember.emit(this.followUpId);
        },
        () => {
          this.error_msg = 'Something went wrong.';
          this.isSendingMessage = false;
        }
      );
  }

  initializeSender(index) {
    this.selectedSender = this.teamMembers[index];
    this.CommunicationData.teamMemberId = this.selectedSender.id;
  }

  selectSender(sender: UserStoreEntity): void {
    this.selectedSender = this.teamMembers.find((x) => x.id == sender.id);
    this.CommunicationData.teamMemberId = this.selectedSender.id;
    this.showSender_dd = false;
  }

  openSender_dd() {
    this.showSender_dd = this.showSender_dd === true ? false : true;
  }

  selectMsgType(type: string) {
    this.error_msg = null;

    if (type === 'note') {
      this.noteSelected = true;
      this.msgSelected = false;
      this.emailSelected = false;
      this.showMsgOption = true;
      this.isMessagePanelExpanded = true;
      this.CommunicationData.actionId = ActionType.notificaiton;
      this.CommunicationData.eventId = EventConstants.AddNote;
    } else if (type === 'msg') {
      this.noteSelected = false;
      this.msgSelected = true;
      this.emailSelected = false;
      this.isMessagePanelExpanded = true;
      this.CommunicationData.actionId = ActionType.message;
      this.CommunicationData.eventId = EventConstants.SmsSend;

      this.displayCommunicationAlert();

      // CONDITION: Office number has changed since last contact
      if (this.officeNumberChanged) {
        this.displayAlert(`It looks like your Ava phone number has changed since the last time you were in contact with
        ${this.patient_detail.firstName}. Consider re-introducing yourself in your next text message to ensure
        ${this.patient_detail.firstName} knows who you are.`);
      }
    } else if (type === 'email') {
      this.noteSelected = false;
      this.msgSelected = false;
      this.emailSelected = true;
      this.isMessagePanelExpanded = true;
      this.CommunicationData.actionId = ActionType.email;
      this.CommunicationData.eventId = EventConstants.EmailSend;

      this.displayCommunicationAlert();
    } else if (type === 'log call') {
      this.noteSelected = true;
      this.msgSelected = false;
      this.emailSelected = false;
      this.CommunicationData.actionId = ActionType.notificaiton;
      this.CommunicationData.eventId = null;
    } else if (type === 'ava chat') {
      this.avaChatSelected = true;
      this.isMessagePanelExpanded = true;
      this.showMsgOption = false;
    } else {
      this.noteSelected = false;
      this.msgSelected = false;
      this.emailSelected = false;
      this.CommunicationData.actionId = null;
      this.CommunicationData.eventId = null;
    }
    this.showSubNoteOption = false;
    this.showMsgOption = false;
  }

  showMsgType() {
    this.showMsgOption = this.showMsgOption === true ? false : true;
  }

  showAvaBtnOptions() {
    this.avaBtnOption = this.avaBtnOption === true ? false : true;
  }

  selectAvaBtnOption(indx) {
    const followup_type = {
      name: '',
      status: '',
    };

    if (this.patient_detail.followUpLink && this.patient_detail.followUpLink.followUpType && this.patient_detail.followUpLink.followUpStatus) {
      followup_type.name = this.patient_detail.followUpLink.followUpType.name;
      followup_type.status = this.patient_detail.followUpLink.followUpStatus.name;
    }

    const data: FollowUpModalData = new FollowUpModalData({
      id: this.isLead ? this.patient_detail.leadId : this.patient_detail.patientId,
      isLead: this.isLead,
      followUpId: this.patient_detail.followUpLink ? this.patient_detail.followUpLink.followUpId : null,
      followUpCategoryId: null,
    });

    this.ModalContainerService.openFollowupDialog(this.avaBtnOptions[indx], data);
    this.avaBtnOption = false;
  }

  public getShortName(firstName: string, lastName: string): string {
    return `${firstName && firstName.length ? firstName[0] : ''}${lastName && lastName.length ? lastName[0] : ''}`;
  }

  public changeBorderCol(msgType: any): string {
    return typeof msgType === 'object' ? this.borderColor(msgType.communicationtype) : this.borderColor(msgType);
  }

  private borderColor(type: string): string {
    if (type === 'note') {
      return this.note_theme['bgcolor'];
    } else if (type === 'msg') {
      return this.msg_theme['bgcolor'];
    } else if (type === 'email') {
      return this.email_theme['bgcolor'];
    } else if (type === 'ava-chat') {
      return this.avachat_theme['bgcolor'];
    }
    return this.grey_theme_bgcolor;
  }

  public getCurrentColor(): string {
    return this.noteSelected
      ? this.note_theme['bgcolor']
      : this.msgSelected
      ? this.msg_theme['bgcolor']
      : this.emailSelected
      ? this.email_theme['bgcolor']
      : this.avaChatSelected
      ? this.avachat_theme['bgcolor']
      : this.grey_theme_bgcolor;
  }

  onClickedOutside(which) {
    if (which === 'ava-btn') {
      this.avaBtnOption = false;
    } else if (which === 'senderdd') {
      this.showSender_dd = false;
    } else if (which === 'comtype') {
      this.showMsgOption = false;
    }
  }

  getSelectedOption(evt) {
    if (this.noteSelected) {
      if (evt === 'Log Call') {
        this.showSubNoteOption = true;
        this.callLogIcon = true;
      } else {
        if (evt === 'Note') {
          this.CommunicationData.eventId = EventConstants.AddNote;
        }
        this.showSubNoteOption = false;
        this.callLogIcon = false;
      }
    }
  }

  closeFutureSchedule() {
    if (this.futureCommunication) {
      this.futureCommunication = false;
      this.isMessagePanelExpanded = false;
    }
    this.isMessagePanelExpanded = !this.futureCommunication;
    this.showMsgOption = true;

    this.scrollToBottom();
  }

  toggleFutureCommunication() {
    this.futureCommunication = !this.futureCommunication;
    this.isMessagePanelExpanded = false;
    const id = this.patient_detail.isLead ? this.patient_detail.leadId : this.patient_detail.patientId;
    if (this.futureCommunication) {
      this._subSink.sink = this.remoteService
        .get<FutureChatDetail[]>(`Patient/FutureCommunicationDetails/${this.patient_detail.patientId || this.patient_detail.leadId}/${this.isLead}`)
        .subscribe((data) => (this.futureComunicationArr = data));
    }
    setTimeout(() => {
      this.futureSectionHeight = this.futureCommunication ? this.topSectionHeight / 5 : 0;
    });
  }

  getLogCallEvent(evt) {
    this.CommunicationData.eventId = Number.parseInt(evt);
  }

  canUseCommunication(type: string): boolean {
    if (type === 'msg') {
      if (this.patient_detail.isChild) {
        return !!this.patient_detail.patientResponsiblePartyLinks.find((r) => r.responsibleParty && r.responsibleParty.canSendSms);
      } else {
        return this.patient_detail.canSendSms;
      }
    }
    if (type === 'email') {
      if (this.patient_detail.isChild) {
        return !!this.patient_detail.patientResponsiblePartyLinks.find((r) => r.responsibleParty && r.responsibleParty.canSendEmail);
      }
      return this.patient_detail.canSendEmail;
    }

    return false;
  }

  canSendMessage(): boolean {
    if (this.msgSelected) {
      return (
        !!this.CommunicationData.content &&
        !!this.CommunicationData.content.length &&
        canSendSms(this.patient_detail, this.patients, this.selectedResponsibleParty)
      );
    }
    if (this.emailSelected) {
      return (
        ((this.CommunicationData.content && this.CommunicationData.content.length) || this.CommunicationData.file) &&
        this.CommunicationData.subjectLine &&
        this.CommunicationData.subjectLine.length &&
        canSendEmail(this.patient_detail, this.patients, this.selectedResponsibleParty)
      );
    }
    if (this.showSubNoteOption) {
      // Call log options selcted
      return (
        this.CommunicationData.eventId === EventConstants.CallLogNew ||
        this.CommunicationData.eventId === EventConstants.CallLogOther ||
        this.CommunicationData.eventId === EventConstants.CallLogSkippedCall ||
        this.CommunicationData.eventId === EventConstants.CallLogSpkokeWithPatient ||
        this.CommunicationData.eventId === EventConstants.CallLogVoicemail
      );
    }
    if (this.noteSelected || this.avaChatSelected) {
      return !!this.CommunicationData.content;
    }

    return false;
  }

  public isButtonDisabled(): boolean {
    return (!this.msgSelected && !this.emailSelected && !this.noteSelected) || (this.noteSelected && this.showSubNoteOption);
  }

  public onFileSelect(input: HTMLInputElement): void {
    const file = input.files[0];
    this.CommunicationData.file = file;

    if (file.size > 1000000) {
      this.displayAlert('This file is larger than 1MB and will be sent as a link at the end of your text message.');
    }
  }

  public deleteAttachment(): void {
    this.CommunicationData.file = null;
  }

  public openTemplatesLibrary(): void {
    let type: TemplateType;
    if (this.emailSelected) {
      type = TemplateType.Email;
    }
    if (this.noteSelected) {
      type = TemplateType.Note;
    } else if (this.msgSelected) {
      type = TemplateType.Message;
    }

    this.templatesHelper.templateTableData = [];
    this.templatesHelper.templateTableData.push(this.templatesHelper.getTemplateTableDataByType(type));

    const dialogRef = this.dialog.open(TemplatesLibraryComponent, {
      width: '900px',
      panelClass: 'template-library-dialog',
      autoFocus: false,
    });

    const instance = dialogRef.componentInstance;
    instance.dataSource = this.templatesHelper.templateTableData;

    const updateFunction = this.templatesHelper.updateData.bind(this);
    instance.updateFunction = updateFunction;

    this._subSink.sink = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.CommunicationData.content = data.content;
        if (type === TemplateType.Email) {
          this.CommunicationData.subjectLine = data.title;
        }
      }
    });
  }

  displayAlert(message: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: message,
      width: '300px',
    });
  }

  displayCommunicationAlert() {
    const action = <ActionType>this.CommunicationData.actionId;

    if (
      (action === ActionType.email && !canSendEmail(this.patient_detail, this.patients, this.selectedResponsibleParty)) ||
      (action === ActionType.message && !canSendSms(this.patient_detail, this.patients, this.selectedResponsibleParty))
    ) {
      const message = GetCommunicationAlertMessage(this.patient_detail, this.patients, this.selectedResponsibleParty, null, [action]);
      this.error_msg = message;
    } else if (isResponsiblePartyForAdult(this.patient_detail, this.selectedResponsibleParty)) {
      this.error_msg = 'It is NOT reccomended that you communicate with this responsible party because the patient is an adult (18 or older)';
    } else {
      this.error_msg = null;
    }
  }

  public getTooltipText(msgHistoryItem: ChatDetail): string {
    return msgHistoryItem.communicationtype === 'email' ? 'EMAIL BOUNCED' : 'TEXT NOT DELIVERED';
  }

  scrollToBottom() {
    setTimeout(() => {
      this.scrollbar.scrollTo({ bottom: 0 });
    });
  }

  public sendLiveChatMsg(content?: string): void {
    const chatContent = <ChatTranscript>{ content: content == null ? this.CommunicationData.content : content, sender: 'system' };

    this._subSink.sink = this._store$
      .select(AuthStoreSelectors.selectUserInfo)
      .pipe(
        take(1),
        filter((x) => !!x),
        tap((userInfo) =>
          this.connection
            .send(
              'liveChatTeamMember',
              this.acceptedLiveChat.helpSessionRequestId,
              this.acceptedLiveChat.helpSessionId,
              content == null ? this.CommunicationData.content : content,
              userInfo.customerId
            )
            .then(() => {
              this.CommunicationData.content = '';
              this.helpService.sendMsg(chatContent, this.acceptedLiveChat.helpSessionId, false);
            })
        ),
        switchMap((_) =>
          this.helpService.saveLiveChatTranscript(this.acceptedLiveChat.helpSessionId, this.acceptedTeamMemberId, this.CommunicationData.content, 1)
        )
      )
      .subscribe((_) => {
        this.scrollToBottom();
      });
  }

  private getRequestedLiveChatData(acceptedLiveChat: AcceptedLiveChatData): void {
    const communicationHistory = <CommunicationHistory>{
      date: new Date(),
      chatdetail: [],
    };

    if (acceptedLiveChat.chatHistory[0].firstName != '' || acceptedLiveChat.chatHistory[0].lastName != '') {
      this.patient_detail.firstName = acceptedLiveChat.chatHistory[0].firstName;
      this.patient_detail.lastName = acceptedLiveChat.chatHistory[0].lastName;
    }

    acceptedLiveChat.chatHistory.forEach((element) => {
      communicationHistory.chatdetail.push(this.transformChatTranscriptToChatDetail(element, acceptedLiveChat));
    });

    this.msgHistoryArr = [communicationHistory];

    setTimeout(() => {
      this.scrollToBottom();
    }, 10);
  }

  private transformChatTranscriptToChatDetail(chatTranscript: ChatTranscript, liveChatRequest: AcceptedLiveChatData): ChatDetail {
    return <ChatDetail>{
      msgdate: typeof chatTranscript.sendOn == 'object' ? chatTranscript.sendOn.toISOString() : chatTranscript.sendOn,
      communicationtype: 'ava-chat',
      sender_firstname:
        chatTranscript.sender === 'system'
          ? chatTranscript.firstName == null
            ? sessionStorage.getItem(`acceptedTeamMemberName${liveChatRequest.helpSessionRequestId}`).split(' ')[0]
            : chatTranscript.firstName
          : chatTranscript.firstName,
      sender_lastname:
        chatTranscript.sender === 'system'
          ? chatTranscript.lastName == null
            ? sessionStorage.getItem(`acceptedTeamMemberName${liveChatRequest.helpSessionRequestId}`).split(' ')[1]
            : chatTranscript.lastName
          : chatTranscript.lastName,
      msg_dir: chatTranscript.sender === 'system' ? 'sent' : 'received',
      msg_content: chatTranscript.content,
      patientId: this.patient_detail.patientId || this.patient_detail.leadId,
      isLead: true,
      files: [],
    };
  }
}
