/* eslint-disable */
export enum EventConstants     {
  SmsSend = 1,
  SmsReceieved = 2,
  EmailSend = 3,
  EmailReceieved = 4,
  CallLogVoicemail = 5,
  CallLogSkippedCall = 6,
  CallLogSpkokeWithPatient = 7,
  CallLogOther = 8,
  AddNote = 9,
  NewPatientScheduler = 10,
  Win = 11,
  CallNotificationSent = 12,
  CallLogNew = 13,
  NewBlasts = 14,
  SmsSendFailed = 17,
  EmailSendFailed = 18
}
