import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClickConfirmTemplateComponent } from './click-confirm-template/click-confirm-template.component';
import { ClickConfirm } from './click-confirm.interface';

@Directive({
  selector: '[avaClickConfirm]',
})
export class ClickConfirmDirective implements OnDestroy, OnInit {
  @Output('avaClickConfirm') avaClickConfirm: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input('avaClickConfirmText') avaClickConfirmText: string = 'Confirm?';
  @Input('avaClickConfirmTemplate') avaClickConfirmTemplate: 'default';
  @Input('avaClickPositiveButtonText') avaClickPositiveButtonText: string;
  @Input('avaClickNegativeButtonText') avaClickNegativeButtonText: string;
  @Input('avaShowImage') avaShowImage: boolean;

  @HostListener('click', ['$event']) onClick($event: MouseEvent): void {
    this._lastEvent = $event;
    $event.preventDefault();
    $event.stopPropagation();
    this.openConfirm();
  }

  private _lastEvent: MouseEvent;
  private _overlayRef: OverlayRef;
  private _componentPortal: ComponentPortal<ClickConfirm>;
  private _templatePortal: TemplatePortal<ClickConfirm>;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private _elref: ElementRef, private _overlay: Overlay) {}

  ngOnInit(): void {
    this._buildOverlay();
    this._componentPortal = new ComponentPortal(this.portalTemplate);
  }

  ngOnDestroy(): void {
    if (this._overlayRef.hasAttached) this._overlayRef.detach();
  }

  private _buildOverlay(): void {
    this._overlayRef = this._overlay.create({
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._elref)
        .withPositions([{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' }]),
      minWidth: 'fit-content',
      hasBackdrop: false,
      disposeOnNavigation: true,
    });
  }

  get portalTemplate(): Type<ClickConfirm> {
    // Add alternate custom confirm templates here
    switch (this.avaClickConfirmTemplate) {
      default:
        return ClickConfirmTemplateComponent;
    }
  }

  openConfirm(): void {
    if (!this._overlayRef.hasAttached()) {
      const componentRef = this._overlayRef.attach(this._componentPortal);
      componentRef.instance.confirmText = this.avaClickConfirmText;
      componentRef.instance.positiveButtonText = this.avaClickPositiveButtonText;
      componentRef.instance.negativeButtonText = this.avaClickNegativeButtonText;
      componentRef.instance.showImage = this.avaShowImage;
      const sub = componentRef.instance.confirm.pipe(takeUntil(this._destroy$)).subscribe((result) => (result ? this.confirm() : this.cancel()));
      componentRef.onDestroy(() => sub.unsubscribe());
    }
  }

  confirm(): void {
    this.avaClickConfirm.next(this._lastEvent);
    this._overlayRef.detach();
  }

  cancel(): void {
    this._overlayRef.detach();
  }
}
