import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren } from '@angular/core';
import { MatCheckboxChange } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { CommunicationType, ModalData, PatientDetailsArea } from 'src/app/modal/modal-container/ModalData';
import { FollowUpModalData, FollowUpMultiModalData } from 'src/app/models/AutoSetRemind';
import { FollowUpTypeEnum } from 'src/app/models/AvaContext';
import { AvasPatient } from 'src/app/models/Dashboard';
import { IDropDownOption } from 'src/app/models/IDropDownOption';
import { SIGNALR_EVENTS } from 'src/app/root-store';
import { DataService } from 'src/app/services/data.service';
import { MODAL_TYPE, ModalContainerService } from 'src/app/services/modal-container.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AvaSelectOption } from 'src/app/shared/ava-select';
import { SubSink } from 'subsink';
import { ServiceProvider } from '../../../service';
import { WinModal } from './../modal/win-modal/win-modal.component';
import { IFollowUpTypeFilter } from './IFollowUpTypeFilter';

declare type SortableFieldType = { name: string; field?: keyof AvasPatient; preprocessor?: (unknown) => string };
@Component({
  selector: 'app-avapatient-card',
  templateUrl: './ava-patient.component.html',
  styleUrls: ['./ava-patient.component.css'],
})
export class AvaPatientCardComponent implements OnInit, OnDestroy {
  @ViewChildren('actionmenu') Actionmenu: any;
  @ViewChildren('stop') Stop: ElementRef<HTMLDivElement>[];
  @ViewChildren('recordlist') recordList: ElementRef<any>[];
  @ViewChildren('grayrecordlist') grayRecordList: ElementRef<HTMLDivElement>[];
  @Input() itemSelectedNotification?: Observable<any>;
  @Input() cardId: any;
  @Input() cardHeading: any;
  @Input() wins: AvasPatient[];
  @Input() consultants: IDropDownOption[];
  @Input() redgraycount: 0;
  @Input() followUpId: number;
  @Input() followUpCategoryId: number;
  @Input() stopSide: string;
  @Output() onItemSelected = new EventEmitter();

  private _cardList: AvasPatient[];
  @Input('cardList')
  set cardList(value: AvasPatient[]) {
    this._cardList = value;
    this.selectedPatients = this.selectedPatients.filter((x) => this._cardList.indexOf(x) !== -1); // remove all data which not in filter
  }
  get cardList(): AvasPatient[] {
    return this._cardList;
  }

  public idClickedRow: string = '';
  public selectedTeamMember: IDropDownOption;
  public filteredPatients: AvasPatient[];
  public blueCount: number;
  public redCount: number;
  public followUpTypeFilters: IFollowUpTypeFilter[] = [
    { followUpType: FollowUpTypeEnum.Auto, name: 'Auto', isChecked: false },
    { followUpType: FollowUpTypeEnum.Set, name: 'Set', isChecked: false },
    { followUpType: FollowUpTypeEnum.Remind, name: 'Remind', isChecked: false },
  ];
  private _avapShowCardPatients = false;
  public sortableFields: SortableFieldType[] = [
    { name: 'Name', preprocessor: this.formatDisplayName.bind(this) },
    { name: 'Visit Date', field: 'visitdate' },
    { name: 'Assigned User', field: 'userid', preprocessor: this.getConsultantNameById.bind(this) },
    { name: 'Sequence', field: 'followuptype', preprocessor: (data: { name: string }): string => data.name },
  ];
  public isShowMultiRemoveWindow = false;
  public isShowConfirmMultiHandlingWindow = false;
  public isMultiActionOpen = false;
  public multiHandlingMode?: MODAL_TYPE | 'remove' = null;
  public searchFilter: string = '';
  public multiHandlingModes: AvaSelectOption[] = [
    new AvaSelectOption('auto', 'auto'),
    new AvaSelectOption('set', 'set'),
    new AvaSelectOption('remind', 'remind'),
  ];
  public selectedPatients: AvasPatient[] = [];
  public get avapShowCardPatients(): boolean {
    return this._avapShowCardPatients;
  }
  public set avapShowCardPatients(value: boolean) {
    this._avapShowCardPatients = value;
    this.selectedPatients = [];
    this.searchFilter = '';
  }
  public prevSort: string = null;
  public avapShowFilter = false;
  private _subSink = new SubSink();
  public PatientDetailsArea = PatientDetailsArea;
  public CommunicationType = CommunicationType;

  public get consultantSelectOptions(): AvaSelectOption[] {
    if (this.consultants) return this.consultants.map((c) => new AvaSelectOption(c.value, c));
  }
  get isAllSelected(): boolean {
    return this.selectedPatients.length === this.filteredPatients.length;
  }

  constructor(
    private _modalContainerService: ModalContainerService,
    public dialog: MatDialog,
    private _remoteService: ServiceProvider,
    private _notifcationService: NotificationService,
    private _dataService: DataService
  ) {}

  ngOnInit(): void {
    this._initializeFilters();
    this._subSink.add(
      this._notifcationService.ofEvent(SIGNALR_EVENTS.FollowUpAdded).subscribe(() => this._filterPatients()),
      this._notifcationService.ofEvent(SIGNALR_EVENTS.FollowUpAddedRange).subscribe(() => this._filterPatients()),
      this._notifcationService.ofEvent(SIGNALR_EVENTS.FollowUpRemoved).subscribe(() => this._filterPatients()),
      this._notifcationService.ofEvent(SIGNALR_EVENTS.FollowUpRemovedRange).subscribe(() => this._filterPatients())
    );

    if (this.itemSelectedNotification) {
      this._subSink.sink = this.itemSelectedNotification.subscribe((cardId) => {
        this._checkDisplayStateForShowActionMenu(cardId);
      });
    }

    this._dataService.getAutoSequenceResumedMessage().subscribe((patientId) => {
      if (patientId) {
        const patient = this.cardList.find((x) => x.id === patientId);
        if (patient) {
          const index = this.cardList.indexOf(patient);
          patient.appschedule.scheduleAndFollowUpStopped = false;
          patient.appschedule.schedule = false;
          patient.appschedule.rgwithbell = false;
          patient.appschedule.rgwithoutbell = false;
          this.cardList[index] = patient;
          this._dataService.clearAutoSequenceResumedMessage();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._subSink.unsubscribe();
  }

  public cancelMultiHandling(): void {
    this.isShowConfirmMultiHandlingWindow = false;
    this.isShowMultiRemoveWindow = false;
    this.resetMultiHandlingDdValue();
  }

  resetMultiHandlingDdValue() {
    this.multiHandlingMode = null;
  }

  public confirmMultiHandling(): void {
    this.isShowConfirmMultiHandlingWindow = false;
    this.showAvaMultiFollowupModal();
    this.resetMultiHandlingDdValue();
  }

  public confirmMultiRemove(): void {
    const isLead = this.selectedPatients.every((x) => x.isLead);
    const ids = this.selectedPatients.map((x) => x.id);
    this.resetMultiHandlingDdValue();
    this._remoteService.post<any>(`avasfollowup/removerangefromteamdecision/${this.followUpCategoryId}/${isLead}`, ids).subscribe(() => {
      if (this.cardList) {
        const cardData = this.cardList;
        this.cardList = [];
        this.cardList = cardData.filter((x) => !ids.includes(x.id));
        this.setFilter();
        this.selectedPatients = [];
      }
    });
    this.isShowMultiRemoveWindow = false;
  }

  public isPatientSelected(patient: AvasPatient) {
    return this.selectedPatients.indexOf(patient) !== -1;
  }

  public selectRow(event: MatCheckboxChange, patient: AvasPatient) {
    if (event.checked) {
      if (!this.selectedPatients.some((x) => x == patient)) {
        this.selectedPatients.push(patient);
      }
    } else {
      this.selectedPatients = this.selectedPatients.filter((x) => x !== patient);
    }
  }

  public setFilter() {
    this._filterPatients();
    this.selectedPatients = this.selectedPatients.filter((x) => this.filteredPatients.indexOf(x) !== -1); // remove all data which not in filter
  }

  public selectAll(event: MatCheckboxChange) {
    this.selectedPatients = event.checked ? this.filteredPatients : [];
  }

  public startHandleSelected(): void {
    if (!this.multiHandlingMode) {
      return;
    }
    if (this.multiHandlingMode == 'remove') {
      this.startRemoveSelected();
      return;
    }
    this.isShowConfirmMultiHandlingWindow = true;
  }

  public startRemoveSelected() {
    this.isShowMultiRemoveWindow = true;
  }

  public showAvaMultiFollowupModal(): void {
    if (!this.multiHandlingMode || this.multiHandlingMode == 'remove') {
      return;
    }
    const data = this.populateFollowUpMultiModalData();
    this._modalContainerService
      .openFollowupDialog(this.multiHandlingMode, data)
      .pipe(
        take(1),
        switchMap((r) => r.afterClosed())
      )
      .subscribe((r) => {
        if (r) this.hideMoreCardPatients();
      });
  }

  public sortCardList(sortParams: SortableFieldType): void {
    let direction = 1;
    if (sortParams.field === this.prevSort) {
      this.prevSort = null;
      direction = -1;
    } else {
      this.prevSort = sortParams.field;
    }
    this.filteredPatients.sort((a: AvasPatient, b: AvasPatient): number => {
      let aData = sortParams.field ? a[sortParams.field] : a;
      let bData = sortParams.field ? b[sortParams.field] : b;
      if (sortParams.preprocessor) {
        aData = sortParams.preprocessor(aData);
        bData = sortParams.preprocessor(bData);
      }
      if (aData == bData) {
        return 0;
      }
      return (aData > bData ? 1 : -1) * direction;
    });
  }

  private populateFollowUpMultiModalData(): FollowUpMultiModalData {
    return new FollowUpMultiModalData({
      isPatient: true,
      ids: (this.selectedPatients || []).map((x) => x.id),
      patients: this.selectedPatients.slice(),
      isLead: (this.selectedPatients || []).every((x) => x.isLead),
      followUpId: this.followUpId,
      followUpCategoryId: this.followUpCategoryId,
    });
  }

  public showPatientDetailsModal(
    details: AvasPatient,
    area: PatientDetailsArea,
    communicationType: CommunicationType = CommunicationType.Default
  ): void {
    this.idClickedRow = '';
    this._modalContainerService.open_dialog(<ModalData>{
      id: details.id,
      isLead: details.isLead,
      patientDetailsArea: area,
      communicationType: communicationType,
    });
  }

  public showMoreCardPatients(): void {
    this._filterPatients();
    this.avapShowCardPatients = true;
  }

  public selectConsultant(id: number): void {
    // this.selectedTeamMember = this.consultants.find((f) => f.id === id);
    this.showMoreCardPatients();
  }

  public hideMoreCardPatients(): void {
    this.avapShowCardPatients = false;
    this._initializeFilters();
  }

  public showWins(name: string): void {
    this.dialog.open(WinModal, {
      backdropClass: 'whitebackdrop',
      panelClass: 'no-pad-dialog',
      data: { name: name, details: this.wins },
      autoFocus: false,
    });
  }

  public showActionmenu(id: string): void {
    if (this.idClickedRow === id) {
      this.idClickedRow = '';
      this.onItemSelected.emit({ cardId: '' });
    } else {
      this.idClickedRow = id;
      this.onItemSelected.emit({ cardId: this.cardId });
    }
  }

  public stopPatient(event: { target: { parentElement: { parentElement: any } } }): void {
    this.recordList.forEach((child) => {
      child.nativeElement.classList.remove(['stoppatient-opened', 'clicked']);
    });

    const recordList = event.target.parentElement.parentElement;
    recordList.classList.add('stoppatient-opened');
  }

  public closeStop(action: string, patient: AvasPatient = null): void {
    if (action === 'confirmStop') {
      this._remoteService.post<boolean>(`avasfollowup/removefromfollowup/${patient.id}/${patient.isLead}`).subscribe((result) => {
        if (this.cardList) {
          const cardData = this.cardList;
          this.cardList = [];
          this.cardList = cardData.filter((x) => x !== patient);
        }
      });
    }

    this.grayRecordList.forEach((child) => {
      child.nativeElement.classList.remove('stoppatient-opened');
    });

    this.recordList.forEach((child) => {
      child.nativeElement.classList.remove('stoppatient-opened');
    });
  }

  public countItems(length: number): number {
    const count = length - this.redgraycount;
    return count < 0 ? 0 : count;
  }

  public stopPopupPatient(event: { target: { parentElement: { parentElement: { getElementsByClassName: (arg0: string) => any } } } }): void {
    const stopElement = event.target.parentElement.parentElement.getElementsByClassName('stop');
    stopElement[0].setAttribute('class', 'stop popup show');
  }

  public closePopupStop(event: any, action: string, patient: AvasPatient): void {
    if (action === 'confirmStop') {
      this._remoteService.post<boolean>(`avasfollowup/removefromfollowup/${patient.id}/${patient.isLead}`).subscribe((result) => {
        if (this.filteredPatients) {
          const patientsData = this.filteredPatients;
          this.filteredPatients = [];
          this.filteredPatients = patientsData.filter((x) => x !== patient);
        }
        if (this.cardList) {
          const cardData = this.cardList;
          this.cardList = [];
          this.cardList = cardData.filter((x) => x !== patient);
        }
      });
    }

    const stopElement = event.target.parentElement.parentElement;
    stopElement.setAttribute('class', 'stop');
  }

  public showAvaFollowupModal(type: 'auto' | 'set' | 'remind', patient: { id: any; isLead: any }): void {
    this._modalContainerService.openFollowupDialog(type, this._setAvaFollowupObj(patient));
  }

  public clickOutsideEvents(): void {
    this.recordList.forEach((child) => {
      child.nativeElement.setAttribute('class', 'record-list');
    });

    this.grayRecordList.forEach((child) => {
      child.nativeElement.setAttribute('class', 'record-list');
    });
  }

  public getConsultantNameById(id: number): string {
    let name = '';
    if (id !== undefined) {
      for (let i = 0; i < this.consultants.length; i++) {
        if (id === this.consultants[i].id) {
          name = this.consultants[i].value;
          break;
        }
      }
    }

    return name;
  }

  public updateFilter(filter: IFollowUpTypeFilter): void {
    filter.isChecked = !filter.isChecked;
    this._filterPatients();
  }

  public formatDisplayName(patient: AvasPatient): string {
    let name = '';

    // CONDITION: patient has a first name and the length of the full name is greater than 13 characters
    if (patient.firstname && patient.firstname.length + patient.lastname.length > 13) {
      name = `${patient.firstname[0]}.`;

      // CONDITION: patient has multiple last names
      if (patient.lastname.trim().indexOf(' ') >= 0) {
        const lastNames = patient.lastname.split(' ');

        // Abbreviate proceeding last names
        for (let i = 0; i < lastNames.length - 1; i++) {
          name += ` ${lastNames[i][0]}.`;
        }

        name += ` ${lastNames[lastNames.length - 1]}`; // Append the final last name
      } else if (patient.lastname.indexOf('-') >= 0) {
        name += ` ${patient.lastname.substring(0, patient.lastname.indexOf('-'))}`;
      } else {
        name += ` ${patient.lastname}`;
      }
    } else {
      name = `${patient.firstname} ${patient.lastname}`;
    }

    return name;
  }

  compareFn(a: IDropDownOption, b: IDropDownOption): boolean {
    if (a && b) {
      return a.id == b.id;
    }
    return false;
  }

  private _initializeFilters(): void {
    for (const filter of this.followUpTypeFilters) {
      filter.isChecked = false;
    }
    this.selectedTeamMember = { value: 'All', id: null };
    this._filterPatients();
  }

  private _checkDisplayStateForShowActionMenu(cardId: number): void {
    if (this.cardId != cardId) {
      this.idClickedRow = '';
    }
  }

  private _showPopupActionmenu(event: { target: { parentElement: { getElementsByClassName: (arg0: string) => any } } }): void {
    const actionMenuElement = event.target.parentElement.getElementsByClassName('action-menu');

    this.Stop.forEach((child) => {
      child.nativeElement.setAttribute('class', 'stop');
    });

    this.Actionmenu.forEach((child: { nativeElement: { classList: { remove: (arg0: string[]) => void } } }) => {
      child.nativeElement.classList.remove(['popup', 'show']);
    });

    actionMenuElement[0].classList.add(['action-menu', 'popup show']);
  }

  private _setAvaFollowupObj(obj: { id: any; isLead: any }): FollowUpModalData {
    return new FollowUpModalData({
      id: obj.id,
      isLead: obj.isLead,
      followUpId: this.followUpId,
      followUpCategoryId: this.followUpCategoryId,
    });
  }

  private _filterPatients(): void {
    const selectedTypeFilters = this.followUpTypeFilters.filter((f) => f.isChecked).map((f) => (p: AvasPatient) => p.followuptype.name === f.name);
    let isMatch: boolean;
    this.filteredPatients = [];
    this.redCount = 0;
    this.blueCount = 0;

    for (let i = 0, iLen = this.cardList.length; i < iLen; i++) {
      isMatch = !selectedTypeFilters.length;
      const patient = this.cardList[i];

      for (let j = 0, jLen = selectedTypeFilters.length; j < jLen; j++) {
        if (selectedTypeFilters[j](patient)) {
          isMatch = true;
          break;
        }
      }

      if (this.selectedTeamMember && this.selectedTeamMember.id) {
        isMatch = isMatch && patient.userid === this.selectedTeamMember.id;
      }
      if (this.searchFilter) {
        isMatch = isMatch && this.formatDisplayName(patient).toLocaleLowerCase().includes(this.searchFilter.toLocaleLowerCase());
      }

      if (isMatch) {
        if (patient.appschedule.rgwithbell || patient.appschedule.rgwithoutbell || patient.appschedule.scheduleAndFollowUpStopped) {
          this.redCount++;
        } else {
          this.blueCount++;
        }
        this.filteredPatients.push(patient);
      }
    }
  }

  public trackByFn(ind: number, a: AvasPatient): number {
    return a.id;
  }
}
