import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IFollowUpCategoryDto } from 'src/app/services/api.service';

export const featureAdapter: EntityAdapter<FollowUpCategoryStoreEntity> = createEntityAdapter<FollowUpCategoryStoreEntity>({
  selectId: (x) => x.followUpCategoryId,
  sortComparer: (a: FollowUpCategoryStoreEntity, b: FollowUpCategoryStoreEntity): number => a.followUpCategoryId - b.followUpCategoryId,
});

export interface State extends EntityState<FollowUpCategoryStoreEntity> {
  isLoading?: boolean;
  error?: string | unknown;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});

export type FollowUpCategoryStoreEntity = IFollowUpCategoryDto;
