import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DigitalSignatureComponent } from '../digital-signature/digital-signature.component';

@Component({
  selector: 'app-signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.scss'],
})
export class SignatureDialogComponent {
  @ViewChild('signature', { static: true }) signatureElement: DigitalSignatureComponent;
  public touched: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: SignatureDialogData, private _dialogRef: MatDialogRef<SignatureDialogComponent>) {
    if (!this.data) this.data = { type: 'full' };
  }

  done(): void {
    this.signatureElement.generateImage();
    this._dialogRef.close(<SignatureDialogResult>{
      type: this.data.type,
      value: this.touched ? this.signatureElement.value : null,
    });
  }
}

export type SignatureType = 'initials' | 'full';

export interface SignatureDialogData {
  type: SignatureType;
  currentValue?: string;
}

export interface SignatureDialogResult {
  type: SignatureType;
  value: string;
}
