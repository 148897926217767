/* eslint-disable */
export interface ICommunication {
  cellPhone: string;
  email: string;
  canSendEmail: boolean;
  canSendSms: boolean;
  isCommunicationOptOut: boolean;
}

export interface IPerson extends ICommunication {
  firstName: string;
  lastName: string;
  birthDate: Date;
  email: string;
  cellPhone: string;
  teamMemberId: number;
  followUpLinkId: number;
  languageId: number;
  isChild: boolean;
  useChildMessaging: boolean;
  isLead: boolean;
  age: number;
  canSendEmail: boolean;
  canSendSms: boolean;
  isAvaChat: boolean;
  isVirtualConsult: boolean;
  isAnonymous: boolean;

  location: Location;
  teamMember: TeamMember;
  followUpLink: FollowUpLink;

  patientResponsiblePartyLinks: PatientResponsiblePartyLink[];

  readonly id: number;
  readonly isNewPatientScheduler: boolean;
}

export interface IPatient extends IPerson {
  patientId: number;
  preferredName: string;
  pmsId: string;
  homePhone: string;
  firstVisitDate: Date;
  lastVisitDate: Date;
  treatmentStatusId: number;
  recallDate: Date;
  leadId: number;
  teamDecisionHidden: boolean;
  bridgeId: string;

  treatmentStatus: TreatmentStatus;
  lead: ILead;

  appointments: Appointment[];
}

export interface TreatmentStatus {
  treatmentStatusId: number;
  name: string;
}

export interface AppointmentStatus {
  appointmentStatusId: number;
  name: string;
}

export interface TeamMember extends ICommunication {
  teamMemberId: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface FollowUpCategory {
  followUpCategoryId: number;
  name: string;

  followUps: FollowUp[];
}

export enum FollowUpCategoryEnum {
  NewLead = 1,
  NoShow = 2,
  Recall = 3,
  Pending = 4,
  Misc = 5,
  NewPatientThankYou = 6,
  AppointmentReminder = 7,
  NewPatientWelcome = 8,
  NewPatientForms = 9,
  NewPatientSurvey = 10,
  Financial = 11,
}

export interface FollowUpCategoryTreatmentStatusLink {
  followUpCategoryId: number;
  treatmentStatusId: number;
}

export interface FollowUp {
  followUpId: number;
  brandId: number;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  isDefault: boolean;
  isDoNotAutoStopEnabled: boolean;
  followUpCategoryId: number;

  followUpSteps: FollowUpStep[];
}

export interface FollowUpStep {
  followUpStepId: number;
  followUpId: number;
  actionId: number;
  delay: number;
  sendTime: string;
  stepOrder: number;

  followUpStepContents: FollowUpStepContent[];
}

export enum ActionEnum {
  Email = 1,
  Notification = 2,
  Message = 3,
}

export interface FollowUpStepContent {
  followUpStepContentId: number;
  followUpStepId: number;
  languageId: number;
  subjectAdult: string;
  contentAdult: string;
  subjectChild: string;
  contentChild: string;
}

export interface FollowUpLink {
  followUpId: number;
  followUpTypeId: number;
  followUpStatusId: number;
  startDate: Date;
  createDate: Date;
  followUpCategoryId: number;
  followUpLinkId: number;
  isDoNotAutoStopEnabled: boolean;

  followUp: FollowUp;
  followUpType: FollowUpType;
  followUpStatus: FollowUpStatus;
  followUpCategory: FollowUpCategory;
}

export interface FollowUpStatus {
  followUpStatusId: number;
  name: string;
}

export interface FollowUpType {
  followUpTypeId: number;
  name: string;
}

export interface FollowUpTreatmentStatusLink {
  followUpId: number;
  treatmentStatusId: number;
}

export interface FollowUpAppointmentTypeLink {
  followUpId: number;
  appointmentTypeId: number;
}

export interface Location {
  locationId: number;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  displayName: string;
  displayAddress: string;
  displayCity: string;
  displayState: string;
  displayZip: string;
  displayPhone: string;
  displayEmail: string;
  virtualConsultLogo: string;
  newPatientSchedulerLogo: string;
  casePresentationLogo: string;
  pmsId: string;
  npsIsVisible: boolean;
  longitude: number;
  latitude: number;
  bridgeId: string;
  offset: number;

  // AppointmentTypeLocationLinks: AppointmentTypeLocationLink[];
  // AppointmentStatusAvaLocationLinks: AppointmentStatusAvaLocationLink[];
  teamMemberLocationLinks: TeamMemberLocationLink[];
  // Appointments: Appointment[];
  // BlastHistories: BlastHistory[];
  // FollowUpTreatments: FollowUpTreatmentStatusLink[];
  // ScheduleAvailabilities: ScheduleAvailability[];
}

export interface TeamMemberLocationLink {
  teamMemberId: number;
  locationId: number;

  teamMember: TeamMember;
  location: Location;
}

export interface Language {
  languageId: number;
  name: string;
  isDefault: boolean;
}

export interface ILead extends IPerson {
  leadId: number;
  responsiblePartyFirstName: string;
  responsiblePartyLastName: string;
  patientId: number;
  isVisible: boolean;
  isNewPatientScheduler: boolean;
  isVirtualConsult: boolean;
  isAnonymous: boolean;

  patient: IPatient;
}

export interface PatientResponsiblePartyLink {
  patientId: number;
  responsiblePartyId: number;
  isPrimary: boolean;
  isAvaPrimary: boolean;
  isDefault: boolean;
  bridgeId: string;

  patient: IPatient;
  responsibleParty: ResponsibleParty;
}

export interface IResponsibleParty extends ICommunication {
  responsiblePartyId: number;
  pmsId: string;
  firstName: string;
  lastName: string;
  email: string;
  cellPhone: string;
  bridgeId: string;

  patientResponsiblePartyLinks: PatientResponsiblePartyLink[];
}

export class ResponsibleParty implements IResponsibleParty {
  responsiblePartyId: number;
  pmsId: string;
  firstName: string;
  lastName: string;
  email: string;
  cellPhone: string;
  bridgeId: string;
  canSendEmail: boolean;
  canSendSms: boolean;
  isCommunicationOptOut: boolean;

  patientResponsiblePartyLinks: PatientResponsiblePartyLink[];
}

export interface Appointment {
  appointmentId: number;
  patientId: number;
  dateTime: Date;
  appointmentTypeId: number;
  appointmentStatusId: number;
  locationId: number;
  duration: number;
  isRemindersCancelled: boolean;
}

export interface AppointmentType {
  appointmentTypeId: number;
  duration: number;
  description: string;
  code: string;
  forScheduler: boolean;
  pmsId: string;
  bridgeId: string;
}

export interface AppointmentTypeDto {
  appointmentTypeId: number;
  duration: number;
  description: string;
  code: string;
  forScheduler: boolean;
  pmsId: string;
  bridgeId: string;
  subscriptionName: string;
}

export interface PatientFollowUpRemovalLink {
  patientId: number;
  followUpId: number;
}

export interface NewPatientAppointment {
  newPatientAppointmentId: number;
  startTime: string;
  howDidYouHearAboutUs: string;
  hasInsurance: boolean;
  isGroupVisit: boolean;
  isRecorded: boolean;
  endTime: string;
  appointmentDate: Date;
  bookingDate: Date;
  lead: ILead;
  leadSource: LeadSource;
  leadId: number;
  leadSourceDetails: string;
}

export class Patient implements IPatient {
  get id(): number {
    return this.patientId;
  }
  isNewPatientScheduler: boolean;
  patientId: number;
  preferredName: string;
  pmsId: string;
  homePhone: string;
  firstVisitDate: Date;
  lastVisitDate: Date;
  treatmentStatusId: number;
  recallDate: Date;
  leadId: number;
  teamDecisionHidden: boolean;
  bridgeId: string;
  treatmentStatus: TreatmentStatus;
  lead: ILead;
  firstName: string;
  lastName: string;
  birthDate: Date;
  email: string;
  cellPhone: string;
  teamMemberId: number;
  followUpLinkId: number;
  languageId: number;
  isChild: boolean;
  useChildMessaging: boolean;
  isLead: boolean;
  age: number;
  canSendEmail: boolean;
  canSendSms: boolean;
  isCommunicationOptOut: boolean;
  address: string;
  zip: string;
  isAvaChat: boolean = false;
  isVirtualConsult: boolean = false;
  isAnonymous: boolean = false;

  location: Location;
  teamMember: TeamMember;
  followUpLink: FollowUpLink;
  appointments: Appointment[];
  patientResponsiblePartyLinks: PatientResponsiblePartyLink[];
}

export class Lead implements ILead {
  get id(): number {
    return this.leadId;
  }
  leadId: number;
  responsiblePartyFirstName: string;
  responsiblePartyLastName: string;
  patientId: number;
  isVisible: boolean;
  patient: IPatient;
  firstName: string;
  lastName: string;
  birthDate: Date;
  email: string;
  cellPhone: string;
  teamMemberId: number;
  followUpLinkId: number;
  languageId: number;
  isChild: boolean;
  useChildMessaging: boolean;
  isLead: boolean;
  isNewPatientScheduler: boolean;
  isVirtualConsult: boolean;
  isAvaChat: boolean;
  age: number;
  canSendEmail: boolean;
  canSendSms: boolean;
  isCommunicationOptOut: boolean;
  isAnonymous: boolean;
  location: Location;
  teamMember: TeamMember;
  followUpLink: FollowUpLink;
  patientResponsiblePartyLinks: PatientResponsiblePartyLink[];
}

export enum FollowUpTypeEnum {
  Auto = 1,
  Set = 2,
  Remind = 3,
}

export interface LeadSource {
  leadSourceId: number;
  leadSourceName: string;
}
