import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { LocationStoreSelectors, RootStoreState } from 'src/app/root-store';
import { GetTransactionQueryParams, RCMClient, TransactionTypeEnum } from 'src/app/services/api.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-rcm-dashboard',
  templateUrl: './rcm-dashboard.component.html',
  styleUrls: ['./rcm-dashboard.component.css'],
})
export class RcmDashboardComponent implements OnInit, OnDestroy {

  grossReceivedTotal: number;
  grossReceivedResults: number;
  
  achTotalAmount: number;
  achTotalResults: number;
  achData: {
    lastName: string;
    firstName: string;
    birthday: Date;
    dueDate: Date;
    dateProcessed: any;
    amount: any;
    dueNow: number;
    totalDue: number;
  }[];

  creditCardTotalAmount: number;
  creditCardResults: number;
  creditCardData: {
    lastName: string;
    firstName: string;
    birthday: Date;
    dueDate: Date;
    dateProcessed: any;
    amount: any;
    dueNow: number;
    totalDue: number;
  }[];

  debitCrdTotalAmount: number;
  debitCardResults: number;
  debitCardData: {
    lastName: string;
    firstName: string;
    birthday: Date;
    dueDate: Date;
    dateProcessed: any;
    amount: any;
    dueNow: number;
    totalDue: number;
  }[];

  contractsNeedingActionResult:any=0;
  contractsNeedingActionData:{
    lastName: string;
    firstName: string;
    birthday: Date;
    contractComplete: Date;
    dueNow: number;
    contractAmt: number;
    paymentPlanSetup: string;
    type:string;
  }[];

  failedPayResult: number;
  failedPayData: {
    lastName: string;
    firstName: string;
    birthday: Date;
    dueDate: Date;
    failDate: any;
    paymentType: any;
    paymentAmount: any;
    dueNow: number;
    totalDue: number;
  }[];


  noPayDownResults: number;
  payPlanNotSetupResult: number;
  payPlanNotSetupData: {
    lastName: string;
    firstName: string;
    birthday: Date;
    contractComplete: Date;
    downPaymentReceived: any;
    downPaymentAmt: any;
    dueNow: number;
    contractAmt: number;
  }[];
  noPaySourceResult: number;
  noPaySourceData: {
    lastName: string;
    firstName: string;
    birthday: Date;
    contractComplete: Date;
    dueNow: number;
    contractAmt: number;
    paymentPlanSetup: string;
  }[];
  noPayDownResult: number;
  noPayDownData: {
    lastName: string;
    firstName: string;
    birthday: Date;
    contractComplete: Date;
    contractAmt: any;
  }[];

 

  getTransactionsParams: GetTransactionQueryParams;

  // all tables structures

  acmDisplayedColumns: string[] = ['lastName', 'firstName', 'birthday', 'dueDate', 'dateProcessed', 'amount', 'dueNow', 'totalDue', 'pmsTransfer'];
  acmDataSource = new MatTableDataSource();

  creditCardDisplayedColumns: string[] = ['lastName','firstName','birthday','dueDate','dateProcessed','amount','dueNow','totalDue','pmsTransfer'];
  creditCardDataSource = new MatTableDataSource();

  debitCardDisplayedColumns: string[] = ['lastName','firstName','birthday','dueDate','dateProcessed','amount','dueNow','totalDue','pmsTransfer'];
  debitCardDataSource = new MatTableDataSource();

  failedPayDisplayedColumns: string[] = ['lastName', 'firstName', 'birthday', 'failDate', 'paymentAmount', 'paymentType'];
  failedPayDataSource = new MatTableDataSource();

  contractNeedingActionDisplayedColumns: string[] = ['lastName','firstName','birthday','contractComplete','contractAmt','paymentDue','paymentPlanSetup'];
  contractNeedingActionPayDataSource = new MatTableDataSource();



  noPayDownDisplayedColumns: string[] = ['lastName', 'firstName', 'birthday', 'contractComplete', 'contractAmt'];
  noPayDownDataSource = new MatTableDataSource();

  payPlanNotSetupDisplayedColumns: string[] = [
    'lastName',
    'firstName',
    'birthday',
    'contractComplete',
    'contractAmt',
    'paymentDue',
    'paymentPlanSetup',
  ];
  payPlanNotSetupDataSource = new MatTableDataSource();

  noPaySourceDisplayedColumns: string[] = [
    'lastName',
    'firstName',
    'birthday',
    'contractComplete',
    'contractAmt',
    'downPaymentReceived',
    'downPaymentAmt',
    'paymentPlanSetup',
  ];
  noPaySourceDataSource = new MatTableDataSource();

  

  // dates management

  dateRangeValue: { startDate: Date | string | null; endDate: Date | string | null };
  rangeInpShow: boolean = false;
  monthDateValue: Date | string | null;
  firstIgnore: boolean = true;
  activeTab: string = 'today';

  // Sortings

  private _achSort: MatSort;
  @ViewChild('achSort', {
    read: '',
    static: false,
  })
  set matSortAcm(ms: MatSort) {
    this._achSort = ms;
    this.acmDataSource.sort = this._achSort;
  }
  private _creditCardSort: MatSort;
  @ViewChild('creditCardSort', {
    read: '',
    static: false,
  })
  set matSortCC(ms: MatSort) {
    this._creditCardSort = ms;
    this.creditCardDataSource.sort = this._creditCardSort;
  }

  private _debitCardSort: MatSort;
  @ViewChild('debitCardSort', {
    read: '',
    static: false,
  })
  set matSortDC(ms: MatSort) {
    this._debitCardSort = ms;
    this.debitCardDataSource.sort = this._debitCardSort;
  }

  private _failedPaySort: MatSort;
  @ViewChild('failedPaySort', {
    read: '',
    static: false,
  })
  set matSortFP(ms: MatSort) {
    this._failedPaySort = ms;
    this.failedPayDataSource.sort = this._failedPaySort;
  }

  private _contractNeedingActioonSort: MatSort;
  @ViewChild('contractNeedingActioonSort', {
    read: '',
    static: false,
  })
  set matSortCna(ms: MatSort) {
    this._contractNeedingActioonSort = ms;
    this.contractNeedingActionPayDataSource.sort = this._contractNeedingActioonSort;
  }

  private _noPayDownSort: MatSort;
  @ViewChild('noPayDownSort', {
    read: '',
    static: false,
  })
  set matSortNPD(ms: MatSort) {
    this._noPayDownSort = ms;
    this.noPayDownDataSource.sort = this._noPayDownSort;
  }

  private _payPlanNotSetupSort: MatSort;
  @ViewChild('payPlanNotSetupSort', {
    read: '',
    static: false,
  })
  set matSortPPNS(ms: MatSort) {
    this._payPlanNotSetupSort = ms;
    this.payPlanNotSetupDataSource.sort = this._payPlanNotSetupSort;
  }

  private _noPaySourceSort: MatSort;
  @ViewChild('noPaySourceSort', {
    read: '',
    static: false,
  })
  set matSortNPS(ms: MatSort) {
    this._noPaySourceSort = ms;
    this.noPaySourceDataSource.sort = this._noPaySourceSort;
  }

  private _subsink = new SubSink();

  constructor(private _liveAnnouncer: LiveAnnouncer, private _rcmService: RCMClient, private _store$: Store<RootStoreState.State>) {
    this.getTransactionsParams = new GetTransactionQueryParams();
  }

  ngOnInit(): void {
    this._subsink.sink = this._store$
      .select(LocationStoreSelectors.getSelectedLocationId)
      .pipe(filter((x) => !!x))
      .subscribe((locationId) => {
        //set track reload to happen when location changes
        this.getTransactionsParams.locationId = locationId;
        this.todayAlertApi();
      });
  }

  ngOnDestroy(): void {
    this._subsink.unsubscribe();
  }

  getRcmDataByApi(): void {
    this._rcmService.rCM_GetTransactionsByBrandAndLocation(this.getTransactionsParams).subscribe((res) => {
      this.contractsNeedingActionData = [];
      //====================Gross Received====================
      this.debitCardData = [];
      this.achData = [];
      this.creditCardData = [];
      for (const transaction of res.grossReceived) {
        if (
          transaction.paymentType.toLowerCase().includes('credit card')
        )
        {
          this.creditCardData.push({
            lastName: transaction.lastName,
            firstName: transaction.firstName,
            birthday: transaction.birthDate,
            dueDate: transaction.dueDate,
            dateProcessed: transaction.dueDate,
            amount: transaction.amount,
            dueNow: transaction.dueNow,
            totalDue: transaction.totalDue,
          });
        }

        if (
          transaction.paymentType.toLowerCase().includes('ach')
        )
        {
          this.achData.push({
            lastName: transaction.lastName,
            firstName: transaction.firstName,
            birthday: transaction.birthDate,
            dueDate: transaction.dueDate,
            dateProcessed: transaction.dueDate,
            amount: transaction.amount,
            dueNow: transaction.dueNow,
            totalDue: transaction.totalDue,
          });
        }
      }

      this.acmDataSource = new MatTableDataSource(this.achData);
      this.achTotalAmount = this.achData.reduce((acc, item) => acc + item.amount, 0);
      this.achTotalResults = this.achData.length;

      this.creditCardDataSource = new MatTableDataSource(this.creditCardData);
      this.creditCardTotalAmount = this.creditCardData.reduce((acc, item) => acc + item.amount, 0);
      this.creditCardResults = this.creditCardData.length;

      this.debitCardDataSource = new MatTableDataSource(this.debitCardData);
      this.debitCrdTotalAmount = this.debitCardData.reduce((acc, item) => acc + item.amount, 0);
      this.debitCardResults = this.debitCardData.length;

      this.grossReceivedTotal = this.achTotalAmount + this.creditCardTotalAmount + this.debitCrdTotalAmount;
      this.grossReceivedResults = this.achTotalResults + this.creditCardResults + this.debitCardResults;
      //====================Gross Received====================

      //====================Failed Payment====================
      this.failedPayData = [];
      for (const transaction of res.failedPayment) {
        let transactionType = 'N/A';
        if(transaction.ledgerApiTransactions != null && transaction.ledgerApiTransactions != undefined){
          let transactionType = transaction.ledgerApiTransactions[0].transactionType;
          switch (transactionType) {
            case TransactionTypeEnum.AchReturn:
              transactionType = TransactionTypeEnum[TransactionTypeEnum.AchReturn];
              break;
            case TransactionTypeEnum.AchSale:
              transactionType = TransactionTypeEnum[TransactionTypeEnum.AchSale];
              break;
            case TransactionTypeEnum.AchSaveAccount:
              transactionType = TransactionTypeEnum[TransactionTypeEnum.AchSaveAccount];
              break;
            case TransactionTypeEnum.CreditAuth:
              transactionType = TransactionTypeEnum[TransactionTypeEnum.CreditAuth];
              break;
            case TransactionTypeEnum.CreditReturn:
              transactionType = TransactionTypeEnum[TransactionTypeEnum.CreditReturn];
              break;
            case TransactionTypeEnum.CreditSale:
              transactionType = TransactionTypeEnum[TransactionTypeEnum.CreditSale];
              break;
            case TransactionTypeEnum.CreditSaveCard:
              transactionType = TransactionTypeEnum[TransactionTypeEnum.CreditSaveCard];
              break;
            case TransactionTypeEnum.None:
              transactionType = TransactionTypeEnum[TransactionTypeEnum.None];
              break;
            case TransactionTypeEnum.TokenizedPayment:
              transactionType = TransactionTypeEnum[TransactionTypeEnum.TokenizedPayment];
              break;
            case TransactionTypeEnum.TokenizedVoid:
              transactionType = TransactionTypeEnum[TransactionTypeEnum.TokenizedVoid];
              break;
            case TransactionTypeEnum.TokenizedReturn:
              transactionType = TransactionTypeEnum[TransactionTypeEnum.TokenizedReturn];
              break;
          }
        }
        
       
        this.failedPayData.push({
          lastName: transaction.lastName,
          firstName: transaction.firstName,
          birthday: transaction.birthDate,
          dueDate: transaction.dueDate,
          failDate: transaction.dueDate,
          paymentType: transactionType,
          paymentAmount: transaction.dueNow,
          dueNow: transaction.dueNow,
          totalDue: transaction.dueNow,
        });
      }
      this.failedPayDataSource = new MatTableDataSource(this.failedPayData);
      this.failedPayResult = this.failedPayData.length;
      //====================Failed Payment====================
      

      //====================No Payment Source====================
      for (const transaction of res.noPaymentSource) {
        this.contractsNeedingActionData.push({
          lastName: transaction.lastName,
          firstName: transaction.firstName,
          birthday: transaction.birthDate,
          contractComplete: transaction.dueDate,
          contractAmt:0,
          dueNow: transaction.dueNow,
          paymentPlanSetup: 'Yes',
          type:'No Payment Source'
        });
      }
      //====================NO Payment Source====================

      //====================Payment Plan Not Setup====================
      for (const transaction of res.paymentPlanNotSetup) {
        this.contractsNeedingActionData.push({
          lastName: transaction.lastName,
          firstName: transaction.firstName,
          birthday: transaction.birthDate,
          contractComplete: transaction.dueDate,
          contractAmt:0,
          dueNow: transaction.dueNow,
          paymentPlanSetup: 'No',
          type:'Payment Plan Not Setup'
        });
      }
      
      //====================Payment Plan Not Setup====================

      //====================NO PAY DOWN====================
      this.noPayDownData = [];
      for (const transaction of res.noDownPaymentReceived) {
        this.contractsNeedingActionData.push({
          lastName: transaction.lastName,
          firstName: transaction.firstName,
          birthday: transaction.birthDate,
          contractComplete: transaction.dueDate,
          contractAmt:0,
          dueNow: transaction.dueNow,
          paymentPlanSetup: 'No',
          type:'No Down Payment'
        });
      }
      this.contractNeedingActionPayDataSource = new MatTableDataSource(this.contractsNeedingActionData);
      this.contractsNeedingActionResult = this.contractsNeedingActionData.length;
      //====================NO PAY DOWN====================
    });
  }

  announceSortChange(sortState: Sort): void {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  dateSelect(): void {
    this.firstIgnore = !this.firstIgnore;
    if (this.firstIgnore) {
      this.rangeAlertAPi();
    }
  }

  // alerts

  todayAlertApi(): void {
    this.activeTab = 'today';
    const startDate = new Date();
    startDate.setUTCHours(0, 0, 0, 0);

    const endDate = new Date();
    endDate.setUTCHours(23, 23, 23, 23);

    this.getTransactionsParams.startDate = new Date(startDate);
    this.getTransactionsParams.endDate = new Date(endDate);

    this.getRcmDataByApi();
  }

  weekAlertApi(): void {
    this.activeTab = 'seven';
    const last7thDay = new Date();
    last7thDay.setDate(last7thDay.getDate() - 6);

    this.getTransactionsParams.startDate = new Date(last7thDay);
    this.getTransactionsParams.endDate = new Date();

    this.getRcmDataByApi();
  }

  thirtyAlertApi(): void {
    this.activeTab = 'thirty';
    const last30thhDay = new Date();
    last30thhDay.setDate(last30thhDay.getDate() - 30);

    this.getTransactionsParams.startDate = new Date(last30thhDay);
    this.getTransactionsParams.endDate = new Date();

    this.getRcmDataByApi();
  }

  monthAlertApi(): void {
    this.activeTab = 'month';

    this.getTransactionsParams.startDate = new Date(this.monthDateValue);
    this.getTransactionsParams.endDate = new Date();

    this.getRcmDataByApi();
  }

  rangeAlertAPi(): void {
    this.activeTab = 'range';
    this.rangeInpShow = false;
    this.firstIgnore = true;

    this.getTransactionsParams.startDate = new Date(this.dateRangeValue.startDate);
    this.getTransactionsParams.endDate = new Date(this.dateRangeValue.endDate);

    this.getRcmDataByApi();
  }
}
