/* eslint-disable */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { ValidateEmail } from 'src/app/helpers/EmailValidation';
import { FollowUpTypeEnum } from 'src/app/models/AvaContext';
import { LocationStoreSelectors, RootStoreState } from 'src/app/root-store';
import { AuthService } from 'src/app/services/auth.service';
import { ServiceProvider } from '../../service';
import { FollowUpModalComponent } from '../followup-modal';

@Component({
  selector: 'app-remind-modal',
  templateUrl: './remind-modal.component.html',
  styleUrls: ['./remind-modal.component.css'],
})
export class RemindModal extends FollowUpModalComponent implements OnInit {
  constructor(
    _matDialogRef: MatDialogRef<RemindModal>,
    snackBar: MatSnackBar,
    remoteService: ServiceProvider,
    authService: AuthService,
    dialog: MatDialog,
    store$: Store<RootStoreState.State>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    super(data, _matDialogRef, authService, remoteService, snackBar, dialog, store$, FollowUpTypeEnum.Remind);
  }

  ngOnInit() {
    super.ngOnInit();
    this._store$.select(LocationStoreSelectors.getSelectedLocationId).subscribe((selectedLocationId) => {
      var getOffsetFunc = this.isMultiMode
        ? () => this.remoteService.get<string>(`avasfollowup/getlocaldatestringtomorrowforlocationid/${selectedLocationId}`, null, 'text')
        : () => this.remoteService.get<string>(`avasfollowup/getlocaldatestringtomorrow/${this.patient.location.offset}`, null, 'text');
      getOffsetFunc().subscribe(
        (date) => {
          this.AutoSetRemindObj.date = date;
          this.startDate = new Date(date + 'T00:00:00');
        },
        (err) => {
          this.startDate.setDate(this.startDate.getDate() + 1);
        }
      );
    });
    this.startDate.setDate(this.startDate.getDate() + 1);
  }

  checkCanSendMessage() {
    this.canSendEmail =
      this.selectedTeamMember && this.selectedTeamMember.isActive && this.selectedTeamMember && ValidateEmail(this.selectedTeamMember.email);
  }

  setconsultant(id: number) {
    super.setconsultant(id);
    this.checkCanSendMessage();
  }

  submit() {
    if (!this.canSendEmail) {
      return;
    }
    super.submit();
  }
}
