import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { switchMap, take } from 'rxjs/operators';
import { GetTracksForDashboardDto } from 'src/app/models/GetTracksForDashboardDto';
import { TrackGroup } from 'src/app/models/NewPatientTracker';
import { LocationStoreSelectors, RootStoreState } from 'src/app/root-store';
import { ServiceProvider } from 'src/app/service';

export class TrackerTableData {
  name: string;
  dataTable: MatTableDataSource<TrackGroup>;
  selection: SelectionModel<number>;
  isWorking: { [newPatientTrackId: number]: boolean };
}

@Component({
  selector: 'app-tracker-search',
  templateUrl: './tracker-search.component.html',
  styleUrls: ['./tracker-search.component.scss'],
})
export class TrackerSearchComponent implements OnInit {
  public StartDate: Date = null;
  public EndDate: Date = null;
  public trackTable: TrackerTableData;
  public remoteTargets = this._serviceProvider.get<string[]>('NewPatientTracker/GetConnectedGophers').pipe(take(1));

  private _treatmentStatuses: { treatmentStatusId: number; name: string }[];
  public get treatmentStatuses(): { treatmentStatusId: number; name: string }[] {
    return this._treatmentStatuses;
  }

  private _noShowStatusIds: number[] = [];
  public get noShowStatusIds(): number[] {
    return this._noShowStatusIds;
  }

  constructor(
    private _serviceProvider: ServiceProvider,
    private _store$: Store<RootStoreState.State>,
    @Inject(MAT_DIALOG_DATA) private _dialogData: { sortFn: (a: unknown, b: unknown) => number; apptFn: (a: unknown) => unknown }
  ) {}

  ngOnInit(): void {
    this.StartDate = moment().add(-14, 'days').startOf('day').toDate();
    this.EndDate = moment().add(37, 'days').startOf('day').toDate();
  }

  search(): void {
    if (!this.StartDate || !this.EndDate) return;

    this._store$
      .select(LocationStoreSelectors.getSelectedLocationId)
      .pipe(
        take(1),
        switchMap((selectedLocationId) =>
          this._serviceProvider
            .get<GetTracksForDashboardDto>(`NewPatientTracker/GetTracksForDashboard/${selectedLocationId}`, {
              StartDate: this.StartDate.toISOString(),
              EndDate: this.EndDate.toISOString(),
            })
            .pipe(take(1))
        )
      )
      .subscribe((dto) => {
        this._treatmentStatuses = dto.treatmentStatuses;
        if (dto.appointmentStatuses) {
          this._noShowStatusIds = dto.appointmentStatuses.filter((x) => x.avaAppointmentStatuses.includes(2)).map((x) => x.appointmentStatusId); //ID 2 is AvaStatus "Missed"
        }
        this.trackTable = {
          dataTable: new MatTableDataSource<TrackGroup>(
            dto.newPatientTracks.sort((a, b) => this._dialogData.sortFn(this._dialogData.apptFn(a), this._dialogData.apptFn(b)))
          ),
          isWorking: {},
          name: '',
          selection: new SelectionModel<number>(true),
        };
      });
  }
}
