import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, share, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { AvasPatient, AvasPatientsResponse, TeamDecisionResponse } from 'src/app/models/Dashboard';
import { ServiceProvider } from 'src/app/service';
import { NotificationFollowUpModelDto } from 'src/app/services/api.service';
import { LocationStoreActions, LocationStoreSelectors } from '../location-store';
import * as RootStoreState from '../root-state';
import { ofSignalrEventType, SIGNALR_EVENTS } from '../signalr-store';
import * as AutomationFollowupStoreActions from './actions';
import * as AutomationFollowupStoreSelectors from './selectors';
import { AutomationFollowupPatientCard, AutomationFollowupPatientStoreEntity } from './state';

@Injectable({ providedIn: 'root' })
export class DashboardHubStoreEffectsService {
  constructor(private _actions$: Actions, private _http: ServiceProvider, private _store$: Store<RootStoreState.State>) {}

  // Send load request for both pending and ava patients after a location has been selected
  // Location will be selected automatically on load so this doubles as the OnLoad event
  loadPatientsOnLocationSelectEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocationStoreActions.SelectSuccess),
      switchMap((action) => [
        AutomationFollowupStoreActions.LoadPendingPatientsRequest({ locationId: action.location.id }),
        AutomationFollowupStoreActions.LoadAvasPatientsRequest({ locationId: action.location.id }),
      ])
    )
  );

  // Load pending patients on Request
  loadPendingPatientsRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AutomationFollowupStoreActions.LoadPendingPatientsRequest),
      switchMap((action) =>
        this._http.get<TeamDecisionResponse>(`avasfollowup/TeamDecisionNewLead/Location/${action.locationId}`).pipe(
          map((result) => {
            const patients: AutomationFollowupPatientStoreEntity[] = [].concat(
              result.teamMisc.map<AutomationFollowupPatientStoreEntity>((p) => ({ ...p, cardArea: 'Pending', card: 'Misc' })),
              result.teamNewLead.map<AutomationFollowupPatientStoreEntity>((p) => ({ ...p, cardArea: 'Pending', card: 'NewLead' })),
              result.teamNoShow.map<AutomationFollowupPatientStoreEntity>((p) => ({ ...p, cardArea: 'Pending', card: 'NoShow' })),
              result.teamPending.map<AutomationFollowupPatientStoreEntity>((p) => ({ ...p, cardArea: 'Pending', card: 'Pending' })),
              result.teamRecall.map<AutomationFollowupPatientStoreEntity>((p) => ({ ...p, cardArea: 'Pending', card: 'Recall' }))
            );

            return AutomationFollowupStoreActions.LoadPendingPatientsSuccess({ patients: patients });
          }),
          catchError((err: HttpErrorResponse) =>
            of(AutomationFollowupStoreActions.LoadPendingPatientsFailure({ error: 'Unable to load Pending cards' }))
          )
        )
      ),
      share()
    )
  );

  // Load ava patients on Request
  loadAvasPatientsRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AutomationFollowupStoreActions.LoadAvasPatientsRequest),
      switchMap((action) =>
        this._http.get<AvasPatientsResponse>(`avasfollowup/AvasPatientNewLead/Location/${action.locationId}`).pipe(
          map((result) => {
            const patients: AutomationFollowupPatientStoreEntity[] = [].concat(
              result.avasPatientMisc.map<AutomationFollowupPatientStoreEntity>((p) => ({ ...p, cardArea: 'Ava', card: 'Misc' })),
              result.avasPatientNewLead.map<AutomationFollowupPatientStoreEntity>((p) => ({ ...p, cardArea: 'Ava', card: 'NewLead' })),
              result.avasPatientNoShow.map<AutomationFollowupPatientStoreEntity>((p) => ({ ...p, cardArea: 'Ava', card: 'NoShow' })),
              result.avasPatientPending.map<AutomationFollowupPatientStoreEntity>((p) => ({ ...p, cardArea: 'Ava', card: 'Pending' })),
              result.avasPatientRecall.map<AutomationFollowupPatientStoreEntity>((p) => ({ ...p, cardArea: 'Ava', card: 'Recall' }))
            );

            return AutomationFollowupStoreActions.LoadAvasPatientsSuccess({
              patients: patients,
              patientCounts: {
                Misc: result.redgraycount.patientMisc,
                NewLead: result.redgraycount.patientNewLead,
                NoShow: result.redgraycount.patientNoShow,
                Pending: result.redgraycount.patientPending,
                Recall: result.redgraycount.patientRecall,
              },
            });
          }),
          catchError((err: HttpErrorResponse) => of(AutomationFollowupStoreActions.LoadAvasPatientsFailure({ error: "Unable to load Ava's cards" })))
        )
      ),
      share()
    )
  );

  // update red bell on message sent
  updateRedBellEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofSignalrEventType<NotificationFollowUpModelDto>([SIGNALR_EVENTS.MessageReceivedNotification, SIGNALR_EVENTS.MessageSentNotification]),
      switchMap(([action, data]) =>
        this._store$.select(AutomationFollowupStoreSelectors.selectAvasPatientById(data.personId, data.isLead)).pipe(
          take(1),
          map((person) =>
            AutomationFollowupStoreActions.UpdatePerson({
              update: {
                id: (person.isLead ? 'Lead_' : 'Patient_') + person.id,
                changes: {
                  ...person,
                  appschedule: {
                    ...person.appschedule,
                    rgwithbell:
                      !person.appschedule.isDoNotAutoStopFollowUpEnabled && action.eventName == SIGNALR_EVENTS.MessageReceivedNotification
                        ? true
                        : false,
                    rgwithoutbell:
                      !person.appschedule.isDoNotAutoStopFollowUpEnabled && action.eventName == SIGNALR_EVENTS.MessageReceivedNotification
                        ? person.appschedule.rgwithoutbell
                        : true,
                  },
                },
              },
            })
          )
        )
      )
    )
  );

  followupAddedEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofSignalrEventType<AvasPatient>([SIGNALR_EVENTS.FollowUpAdded]),
      withLatestFrom(this._store$.select(LocationStoreSelectors.getSelectedLocationId)),
      //ignore if person is attached to location other than current
      filter(([[_, data], locationId]) => data.location.id == locationId),
      switchMap(([value]) => [value]),
      switchMap(([_, data]) => [
        //Dispatch remove before adding
        AutomationFollowupStoreActions.RemovePerson({ personId: (data.isLead ? 'Lead_' : 'Patient_') + data.id }),
        AutomationFollowupStoreActions.AddPerson({
          person: { ...data, card: data.followuptype.category.replace(' ', '') as AutomationFollowupPatientCard, cardArea: 'Ava' },
        }),
      ])
    )
  );
  followupAddedRangeEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofSignalrEventType<AvasPatient[]>([SIGNALR_EVENTS.FollowUpAddedRange]),
      withLatestFrom(this._store$.select(LocationStoreSelectors.getSelectedLocationId)),
      //ignore if person is attached to location other than current
      filter(([[_, datas], locationId]) => datas.every((data) => data.location.id == locationId)),
      switchMap(([value]) => [value]),
      switchMap(([_, datas]) => [
        //Dispatch remove before adding
        AutomationFollowupStoreActions.RemovePersons({ personIds: datas.map((data) => (data.isLead ? 'Lead_' : 'Patient_') + data.id) }),
        AutomationFollowupStoreActions.AddPersons({
          persons: datas.map((data) => {
            return { ...data, card: data.followuptype.category.replace(' ', '') as AutomationFollowupPatientCard, cardArea: 'Ava' };
          }),
        }),
      ])
    )
  );
  followupRemovedEffects$ = createEffect(() =>
    this._actions$.pipe(
      ofSignalrEventType<AvasPatient>(SIGNALR_EVENTS.FollowUpRemoved),
      withLatestFrom(this._store$.select(LocationStoreSelectors.getSelectedLocationId)),
      //ignore if person is attached to location other than current
      filter(([[_, data], locationId]) => data.location.id == locationId),
      switchMap(([value]) => [value]),
      map(([_, data]) => AutomationFollowupStoreActions.RemovePerson({ personId: (data.isLead ? 'Lead_' : 'Patient_') + data.id }))
    )
  );
  followupRemovedRangeEffects$ = createEffect(() =>
    this._actions$.pipe(
      ofSignalrEventType<AvasPatient[]>(SIGNALR_EVENTS.FollowUpRemovedRange),
      withLatestFrom(this._store$.select(LocationStoreSelectors.getSelectedLocationId)),
      //ignore if person is attached to location other than current
      filter(([[_, datas], locationId]) => datas.every((data) => data.location.id == locationId)),
      switchMap(([value]) => [value]),
      map(([_, datas]) =>
        AutomationFollowupStoreActions.RemovePersons({ personIds: datas.map((data) => (data.isLead ? 'Lead_' : 'Patient_') + data.id) })
      )
    )
  );
}
