import { AvasPatient } from './Dashboard';

/* eslint-disable */
export class AutoSetRemind {
  subject: string = '';
  content: string = '';
  userId: number;
  responsiblePartryid: number;
  date: string = '';
  time: string = '';
  patientId: number;
  patientIds: number[];
  leadIds: number[];
  sequenceId: number;
  actionId: number; //send via
  followUpStatusId: number;
  followUpTypeId: number;
  followUpCategoryId: number;
  followUpId: number;
  languageId: number;
  ispatient: boolean;
  customerId: number;
  locationOffset: number;
}

export class FollowUpModalData {
  id: number;
  isLead: boolean;
  followUpId: number;
  followUpCategoryId: number;
  constructor(obj?: Partial<FollowUpModalData>) {
    if (obj != null) {
      Object.assign(this, obj);
    }
  }
}
export class FollowUpMultiModalData {
  ids: number[];
  patients: AvasPatient[];
  leads: AvasPatient[];
  isLead: boolean;
  isPatient: boolean;
  followUpId: number;
  followUpCategoryId: number;
  constructor(obj?: Partial<FollowUpMultiModalData>) {
    if (obj != null) {
      Object.assign(this, obj);
    }
  }
}
