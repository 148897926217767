import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { SIGNALR_HUB_UNSTARTED, findHub, hubNotFound, mergeMapHubToAction } from 'ngrx-signalr-core';
import { merge, of } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { RootStoreState } from '../..';
import { AuthStoreActions, AuthStoreSelectors } from '../../auth-store';
import { ofHubName } from '../OfHubName';
import * as SignalRHubStoreActions from '../actions';
import * as NotificationCenterHubStoreActions from './actions';
import { NOTIFICATION_CENTER_EVENTS } from './events';
import { HUB_DEFINITION } from './hub';

@Injectable({ providedIn: 'root' })
export class NotificationCenterHubEffectsService {
  constructor(private _actions$: Actions, private _store$: Store<RootStoreState.State>, private _authService: AuthService) {}

  ngrxOnInitEffects(): Action {
    return NotificationCenterHubStoreActions.Init();
  }

  // Build Hub
  createHubOnLoginEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.LoginSuccess),
      withLatestFrom(this._store$.select(AuthStoreSelectors.selectClientName)),
      map(([_, clientName]) =>
        NotificationCenterHubStoreActions.CreateHub(clientName, () =>
          this._authService
            .ValidateTokenObs()
            .pipe(
              switchMap(() => this._store$.select(AuthStoreSelectors.selectOAuth)),
              take(1),
              map((x) => x.access_token)
            )
            .toPromise()
        )
      )
    )
  );

  // Start Hub
  onCreateStartHubEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SIGNALR_HUB_UNSTARTED),
      ofHubName(HUB_DEFINITION.hubName),
      mergeMapHubToAction(({ hub }) => {
        const hubEvents$ = Object.values(NOTIFICATION_CENTER_EVENTS).map((value) =>
          hub.on(value).pipe(map((data) => SignalRHubStoreActions.Event({ eventName: value, data: data })))
        );

        return merge(
          ...hubEvents$,
          this._store$.select(AuthStoreSelectors.selectClientName).pipe(
            take(1),
            map((clientName) => NotificationCenterHubStoreActions.StartHub(clientName))
          )
        );
      })
    )
  );

  // Send SendToChatBot event up to hub
  sendToChatBotEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationCenterHubStoreActions.SendToChatBot),
      withLatestFrom(this._store$.select(AuthStoreSelectors.selectClientName)),
      switchMap(([action, clientName]) => {
        const hubUrl = HUB_DEFINITION.url.replace('{ClientName}', clientName);
        const hub = findHub(HUB_DEFINITION.hubName, hubUrl);
        if (!hub) return of(hubNotFound(HUB_DEFINITION));

        return hub.send(NOTIFICATION_CENTER_EVENTS.sendToChatBot, [
          action.chatType,
          action.helpSessionRequestId,
          action.liveRequestStatus,
          action.teamMemberId,
          action.acceptedBy,
          action.teamMemberName,
        ]);
      }),
      map(() => NotificationCenterHubStoreActions.SendToChatBotComplete())
    )
  );
}
