/* eslint-disable */
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { AuthService } from './services/auth.service';


export const CallbackFollowUp = 'b7eec9ac3ce840f4b59c779334aab34e';
export const CallbackVirtualConsult = '291cfa7b0f8f45e4b80904834019b191';
export const CallbackNewPatientScheduler = 'cb1285d15cb14ddab327745038d8645c';
export const CallbackBlasts = 'f42df2318ea546d2b8b131138c4e0918';

@Injectable()
export class AuthGuard implements CanActivate {


  constructor(private router: Router, private authService: AuthService, private cookieService: CookieService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    switch (route.routeConfig.path) {
      case CallbackFollowUp:
        return this.verifyLogin('/dashboard/automations/ava-followup');
      case CallbackVirtualConsult:
        return this.verifyLogin('/dashboard/automations/virtual-consult');
      case CallbackNewPatientScheduler:
        return this.verifyLogin('/dashboard/automations/new-patient-scheduler');
      case CallbackBlasts:
        return this.verifyLogin('/dashboard/blasts');
    }

    return true;
    // let url: string = state.url;
    // return this.verifyLogin(url);
  }

  verifyLogin(url): boolean {
    if (!this.isLoggedIn()) {
      this.router.navigate(['/login'], { queryParams: { redirect: url } });
      return false;
    } else {
      const tokenRequest = {
        username: this.cookieService.get('user'),
        grant_type: 'authorize_existing_session'
      };
      this.authService.Login(tokenRequest).subscribe(result => {
        if (result.Status) {
          this.router.navigate([url]);
        } else {
          this.router.navigate(['/login'], { queryParams: { redirect: url } });
        }
      });
      return true;
    }
  }

  public isLoggedIn(): boolean {
    const cookies = this.cookieService.getAll();
    for (const key in cookies) {
      if (key.indexOf('intercom-session') >= 0) {
        return true;
      }
    }

    return false;
  }
}
