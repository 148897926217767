/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as moment from 'moment';
import { AUTH_FEATURE_KEY } from './featurekey';
import { State } from './state';

export const selectState = createFeatureSelector<State>(AUTH_FEATURE_KEY);

export const selectBrandsError = createSelector(selectState, (state) => state.error);

export const selectBrandsIsLoading = createSelector(selectState, (state) => state.isLoading);

export const selectOAuth = createSelector(selectState, (state) => state.token);

export const selectAuthHeader = createSelector(selectState, (state) => state.token && state.token.token_type + ' ' + state.token.access_token);

// Seed prevents memoization of value when it can change based on time and not state mutation
export const selectTokenIsValid = (seed: number) =>
  createSelector(selectState, (state) => {
    if (state.token == null || state.tokenDate == null) {
      return false;
    }
    // take 5 seconds of expires_in time to pre-emptively renew token before hard expiry
    const expiresAt = moment(state.tokenDate).add(state.token.expires_in - 5, 'seconds');
    return expiresAt.isAfter(moment());
  });

export const selectClientName = createSelector(selectState, (state) => state.userInfo && state.userInfo.clientName);
export const selectRoles = createSelector(selectState, (state) => state.userInfo && state.userInfo.roles);
export const selectUserInfo = createSelector(selectState, (state) => state.userInfo && state.userInfo);
export const selectUserId = createSelector(selectState, (state) => state.userInfo && state.userInfo.userId);
export const selectTeamMemberId = createSelector(selectState, (state) => state.userInfo && state.userInfo.teamMemberId);
export const selectLoggedIn = createSelector(selectState, (state) => state.isLoggedIn);
export const selectIntercomUser = createSelector(selectState, (state) => state.userInfo && state.userInfo.intercomUser);
export const selectCustomerId = createSelector(selectState, (state) => state.userInfo && state.userInfo.customerId);

export const selectUserIsInRoles = (roles: string[]) =>
  createSelector(selectUserInfo, (userInfo) => {
    if (userInfo == null) return null;
    return roles.reduce((p, n) => p + (userInfo.roles.includes(n) ? 1 : 0), 0) > 0;
  });
export const selectUserIsInRole = (role: string) => selectUserIsInRoles([role]);
