import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { FeatureFlagService } from "../services/feature-flag.service";

@Injectable()
export class FeatureFlagGuardService implements CanActivate {
 
  constructor(private _featureFlagService: FeatureFlagService) { }
 
  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    try {
      const featureFlag = route.data['FeatureFlag'];
      const result = this._featureFlagService.featureOn(featureFlag);
      
      return result;
    }
    catch {
      return false;
    }
  }
}