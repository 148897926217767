import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'digital-signature',
  templateUrl: './digital-signature.component.html',
  styleUrls: ['./digital-signature.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DigitalSignatureComponent),
      multi: true,
    },
  ],
})
export class DigitalSignatureComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  //#region resize functions
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (this.canvas) {
      //resizing a canvas clears its contents so store as image, resize, then redraw
      //because of how this works, line stroke weight will not upscale or downscale properly
      //possible solution: store stroke coordinates and redraw based on that instead of pixel based manipulation
      let img = this._canvasContext.getImageData(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
      this.canvas.nativeElement.width = this.canvas.nativeElement.offsetWidth;
      this.canvas.nativeElement.height = this.canvas.nativeElement.width * this.sizeRatio;
      img = this._rescaleImage(img, this.canvas.nativeElement.width, this.canvas.nativeElement.height, 'nearest');
      this._canvasContext.putImageData(img, 0, 0);
    }
  }

  //"Borrowed" from https://github.com/LinusU/resize-image-data/blob/master/index.js
  //nearest neighbor interpolation, degrades quality quickly but simpler (aka faster)
  //bilinear interpolation, smoother rescaling but will cause bluring on repeated rescaling
  private _rescaleImage(src: ImageData, width: number, height: number, scaleMethod: 'nearest' | 'bilinear' = 'nearest') {
    const dst = new ImageData(width, height);

    if (scaleMethod == 'nearest') {
      let pos = 0;

      for (let y = 0; y < dst.height; y++) {
        for (let x = 0; x < dst.width; x++) {
          const srcX = Math.floor((x * src.width) / dst.width);
          const srcY = Math.floor((y * src.height) / dst.height);

          let srcPos = (srcY * src.width + srcX) * 4;

          dst.data[pos++] = src.data[srcPos++]; // R
          dst.data[pos++] = src.data[srcPos++]; // G
          dst.data[pos++] = src.data[srcPos++]; // B
          dst.data[pos++] = src.data[srcPos++]; // A
        }
      }
    } else if (scaleMethod == 'bilinear') {
      let pos = 0;

      for (let y = 0; y < dst.height; y++) {
        for (let x = 0; x < dst.width; x++) {
          const srcX = (x * src.width) / dst.width;
          const srcY = (y * src.height) / dst.height;

          const xMin = Math.floor(srcX);
          const yMin = Math.floor(srcY);

          const xMax = Math.min(Math.ceil(srcX), src.width - 1);
          const yMax = Math.min(Math.ceil(srcY), src.height - 1);

          dst.data[pos++] = this._interpolateVertical(src, 0, srcX, xMin, xMax, srcY, yMin, yMax); // R
          dst.data[pos++] = this._interpolateVertical(src, 1, srcX, xMin, xMax, srcY, yMin, yMax); // G
          dst.data[pos++] = this._interpolateVertical(src, 2, srcX, xMin, xMax, srcY, yMin, yMax); // B
          dst.data[pos++] = this._interpolateVertical(src, 3, srcX, xMin, xMax, srcY, yMin, yMax); // A
        }
      }
    }

    return dst;
  }

  //#region bilinear interpolation functions
  private _interpolate(k, kMin, kMax, vMin, vMax) {
    return Math.round((k - kMin) * vMax + (kMax - k) * vMin);
  }

  private _interpolateHorizontal(src, offset, x, y, xMin, xMax) {
    const vMin = src.data[(y * src.width + xMin) * 4 + offset];
    if (xMin === xMax) {
      return vMin;
    }

    const vMax = src.data[(y * src.width + xMax) * 4 + offset];
    return this._interpolate(x, xMin, xMax, vMin, vMax);
  }

  private _interpolateVertical(src, offset, x, xMin, xMax, y, yMin, yMax) {
    const vMin = this._interpolateHorizontal(src, offset, x, yMin, xMin, xMax);
    if (yMin === yMax) {
      return vMin;
    }

    const vMax = this._interpolateHorizontal(src, offset, x, yMax, xMin, xMax);
    return this._interpolate(y, yMin, yMax, vMin, vMax);
  }
  //#endregion

  //#region Forms
  @HostBinding('attr.id') externalId = '';

  private _ID = '';
  @Input() set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }

  get id(): string {
    return this._ID;
  }

  @Input('value') _value = '';
  get value(): string {
    return this._value;
  }

  set value(val: string) {
    this._value = val;
    if (this.canvas) {
      const img = new Image();
      img.src = val;
      fromEvent(img, 'load').subscribe(() =>
        this._canvasContext.drawImage(img, 0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)
      );
    }
    this.onChange(val);
    this.onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (val: string) => void = () => {};
  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private _disabled: boolean = false;
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  @Input() required: boolean = false;
  //#endregion

  @Input('background') canvasBackground: string = '#FFF'; //background color of written signature area
  @Input('pen-size') penSize: number = 2;
  @Input('pen-color') penColor: string = '#000';
  @Input('size-ratio') sizeRatio: number = 0.25; //ratio of height compared to width
  private _generateTrigger: EventEmitter<void>;
  @Input('trigger') set generateTrigger(trigger: EventEmitter<void>) {
    //trigger passed in that will listen for when to generate the image from the canvas
    if (trigger == null) this._generateTrigger = new EventEmitter();
    else this._generateTrigger = trigger;
    if (this.subscription != null) this.subscription.unsubscribe();
    this.subscription = this._generateTrigger.subscribe(() => this.generateImage());
  }

  @Output('signatureImage') writtenSignature = new Subject<string>(); //event to listen for the the completed image generation after request

  @ViewChild('signatureCanvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  private _canvasContext: CanvasRenderingContext2D;
  private _drawing: boolean = false; //indicates whether canvas is currently being draw upon based upon mousedown or touchstart
  private _prevX: number;
  private _prevY: number;
  canvasHeight: number = 0; //height of written signature canvas

  subscription: Subscription; //subscription storage for incoming trigger

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this._canvasContext = this.canvas.nativeElement.getContext('2d');
    //canvas size is 4:1 of available space, this currently means that signiture resolution will be less on a smaller screen (like mobile)
    this.canvas.nativeElement.width = this.canvas.nativeElement.offsetWidth;
    this.canvas.nativeElement.height = this.canvas.nativeElement.width * this.sizeRatio;
    //set up pen defaults here
    this._canvasContext.strokeStyle = this.penColor;
    this._canvasContext.lineWidth = this.penSize;
    this._canvasContext.lineCap = 'round';
    this._canvasContext.miterLimit = 0;
    this._canvasContext.lineJoin = 'round';
  }

  //generate data url image from canvas
  generateImage(): void {
    this.value = this.canvas.nativeElement.toDataURL();
    this.writtenSignature.next(this.canvas.nativeElement.toDataURL());
  }

  @Output('draw') onDraw = new EventEmitter<void>();
  draw(event: MouseEvent | TouchEvent, type: string): void {
    event.preventDefault();
    if (this._disabled) return;
    const pos = this._getPos(this.canvas.nativeElement, event); //find where mouse is currently
    switch (type) {
      case 'down': //start drawing
        this._canvasContext.beginPath();
        this._prevX = pos.x;
        this._prevY = pos.y;
        this._drawing = true;
        break;
      case 'up': //end drawing
      case 'out':
        this._drawing = false;
        this._canvasContext.closePath();
        this.generateImage();
        break;
      case 'move': //continue drawing (if pen still down and above canvas)
        if (this._drawing) {
          this._canvasContext.moveTo(this._prevX, this._prevY);
          this._canvasContext.lineTo(pos.x, pos.y);
          this._canvasContext.stroke();
          this._prevX = pos.x;
          this._prevY = pos.y;
        }
    }
    this.onDraw.emit();
  }

  //reset canvas to blank
  @Output('clear') onClear = new EventEmitter<void>();
  clear(): void {
    this.onClear.emit();
    this._canvasContext.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  }

  //get position of mouse or touch in relation to the canvas coords
  private _getPos(canvas: HTMLCanvasElement, evt: MouseEvent | TouchEvent): { x: number; y: number } {
    const rect = canvas.getBoundingClientRect();
    if (evt instanceof MouseEvent) {
      return {
        x: ((evt.clientX - rect.left) / (rect.right - rect.left)) * canvas.width,
        y: ((evt.clientY - rect.top) / (rect.bottom - rect.top)) * canvas.height,
      };
    } else {
      //initial touch event is in evt.touches but as they move the position exists in evt.changedTouches
      const xNow = evt.touches.length > 0 ? evt.touches[0].clientX : evt.changedTouches[0].clientX;
      const yNow = evt.touches.length > 0 ? evt.touches[0].clientY : evt.changedTouches[0].clientY;
      return {
        x: ((xNow - rect.left) / (rect.right - rect.left)) * canvas.width,
        y: ((yNow - rect.top) / (rect.bottom - rect.top)) * canvas.height,
      };
    }
  }
}
