/* eslint-disable */
import { LanguageEnum } from './AvaMultiContext';
import { Lead } from './AvaContext';

export interface VirtualConsultViewModel {
  leadId: number;
  firstName: string;
  lastName: string;
  tags: string;
  description: string;
  createdFrom: string;
  utmSource: string;
  createDate: Date;
  leadStatusId: LeadStatusEnum;
  languageId: LanguageEnum;
  selfies: VirtualConsulFileViewModel[];
  lastActivityDate: Date;
  isExpanded: boolean;
  lead: Lead[];
}

export interface VirtualConsulFileViewModel {
  location: string;
  virtualConsultFileType: VirtualConsultFileTypeEnum;
}

export enum LeadStatusEnum {
  newLead = 1,
  inProgress = 2
}

export enum VirtualConsultFileTypeEnum {
  relaxedJaw,
  naturalSelfie,
  fullTeeth,
  leftSideProfile,
  rightSideProfile,
  upperArch,
  lowerArch
}
