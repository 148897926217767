import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TreatmentTypeDto } from 'src/app/services/api.service';

@Component({
  selector: 'app-treatment-type-editor-dialog',
  templateUrl: './treatment-type-editor-dialog.component.html',
  styleUrls: ['./treatment-type-editor-dialog.component.scss'],
})
export class TreatmentTypeEditorDialogComponent {
  get __treatmentType(): TreatmentTypeDto {
    return this._data.treatmentType;
  }
  set __treatmentType(value: TreatmentTypeDto) {
    this._data.treatmentType = value;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private _data: ITreatmentTypeEditorDialogData) {}
}

export interface ITreatmentTypeEditorDialogData {
  treatmentType: TreatmentTypeDto;
}
