import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  constructor(private _activeRoute: ActivatedRoute, private _router: Router, private _http: HttpClient, private _fb: FormBuilder) {}

  isValidToken: boolean = null;
  errorMessage: string;
  errors: string[];

  resetForm: FormGroup = this._fb.group({
    username: ['', Validators.required],
    token: ['', Validators.required],
    newPassword: ['', [Validators.required, Validators.minLength(8), requireCapitalLetterValidator()]],
    newPasswordConfirm: ['', [Validators.required]],
  });

  ngOnInit(): void {
    this._activeRoute.queryParams.subscribe((routeParams) => {
      this.resetForm.patchValue({ username: routeParams.username, token: routeParams.token });
      this._http
        .post(`${environment.ServerPath}api/ForgotPassword/ValidateToken`, this.resetForm.value)
        .pipe(take(1))
        .subscribe((response: { isValid: boolean }) => (this.isValidToken = response.isValid));
    });
  }

  Reset(): void {
    this.errorMessage = null;
    this.errors = null;
    this._http
      .post(`${environment.ServerPath}api/ForgotPassword/ResetPassword`, this.resetForm.value)
      .pipe(take(1))
      .subscribe(
        () => {
          this._router.navigate(['reset-password-success']);
        },
        (response: HttpErrorResponse) => {
          this.errorMessage = response.error.message;
          this.errors = response.error.errorDetails;
        }
      );
  }
}

function requireCapitalLetterValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    const reg = /([A-Z])/;
    const hasCapitalLetter = reg.test(control.value);
    return !hasCapitalLetter ? { capitalletter: { value: control.value } } : null;
  };
}
