import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DashboardHubStoreEffectsService } from './effects';
import { AUTOMATION_FOLLOWUP_FEATURE_KEY } from './featurekey';
import { featureReducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(AUTOMATION_FOLLOWUP_FEATURE_KEY, featureReducer),
    EffectsModule.forFeature([DashboardHubStoreEffectsService]),
  ],
})
export class DashboardHubStoreModule {}
