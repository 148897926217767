/* eslint-disable */
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-launch-view',
  templateUrl: './launch-view.component.html',
  styleUrls: ['./launch-view.component.css']
})
export class LaunchViewComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }

}
