import { Action, createReducer, on } from '@ngrx/store';
import { AuthStoreActions } from '../auth-store';
import * as ContractTemplateStoreActions from './actions';
import { featureAdapter, initialState, State } from './state';

const reducer = createReducer(
  initialState,
  //LOAD
  on(ContractTemplateStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: state.isLoaded ? !!action.reload : true, error: null })),
  on(ContractTemplateStoreActions.LoadSuccess, (state, action) => {
    return featureAdapter.upsertMany(action.contractTemplates, featureAdapter.removeAll({ ...state, isLoading: false, isLoaded: true }));
  }),
  on(ContractTemplateStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //Add
  on(ContractTemplateStoreActions.AddRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(ContractTemplateStoreActions.AddSuccess, (state, action) =>
    featureAdapter.addOne(action.contractTemplate, { ...state, isLoading: false, error: null })
  ),
  on(ContractTemplateStoreActions.AddFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPDATE
  on(ContractTemplateStoreActions.UpdateRequest, (state) => ({ ...state, isLoading: true })),
  on(ContractTemplateStoreActions.UpdateSuccess, (state, action) =>
    featureAdapter.upsertOne(action.contractTemplate, { ...state, isLoading: false })
  ),
  on(ContractTemplateStoreActions.UpdateFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DELETE
  on(ContractTemplateStoreActions.DeleteRequest, (state) => ({ ...state, isLoading: true })),
  on(ContractTemplateStoreActions.DeleteSuccess, (state, action) =>
    featureAdapter.removeOne(action.contractTemplateId, { ...state, isLoading: false })
  ),
  on(ContractTemplateStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //LOGOUT
  on(AuthStoreActions.Logout, (state) => featureAdapter.removeAll(state))
);

export function featureReducer(state: State | undefined, action: Action): State {
  return reducer(state, action);
}
