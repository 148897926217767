/* eslint-disable @typescript-eslint/naming-convention */
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import * as AuthStoreActions from '../root-store/auth-store/actions';
import * as AuthStoreSelectors from '../root-store/auth-store/selectors';

export class ServiceBase {
  private _store$: Store<unknown>;
  private _actions$: Actions<unknown>;
  private _router: Router;

  constructor(injector: Injector) {
    this._store$ = injector.get(Store);
    this._actions$ = injector.get(Actions);
    this._router = injector.get(Router);
  }

  protected transformOptions(options: HttpOptions): Promise<unknown> {
    return this.ValidateTokenObs()
      .pipe(
        switchMap(() =>
          forkJoin({
            token: this._store$.select(AuthStoreSelectors.selectAuthHeader).pipe(take(1)),
            x_client_name: this._store$.select(AuthStoreSelectors.selectClientName).pipe(take(1)),
          })
        ),
        take(1),
        map((auth) => {
          if (auth) {
            if (this._isNullOrUndefinedOrEmpty(options.headers.get('Authorization'))) {
              if (options.headers.has('Authorization')) options.headers = options.headers.set('Authorization', auth.token);
              else options.headers = options.headers.append('Authorization', auth.token);
            }

            if (this._isNullOrUndefinedOrEmpty(options.headers.get('x-client-name')) && auth.x_client_name) {
              if (options.headers.has('x-client-name')) options.headers = options.headers.set('x-client-name', auth.x_client_name);
              else options.headers = options.headers.append('x-client-name', auth.x_client_name);
            }
          }

          return options;
        })
      )
      .toPromise();
  }

  private _isNullOrUndefinedOrEmpty(value: unknown): boolean {
    return value === null || value === undefined || value === '';
  }

  protected transformResult(
    url: string,
    response: HttpResponse<Blob>,
    processor: (response: HttpResponse<Blob>) => Observable<unknown>
  ): Observable<unknown> {
    //if we got this far and response is 401 then token refresh likley failed; logout.
    if (response.status == 401) this._logout();

    return processor(response);
  }

  private ValidateTokenObs(): Observable<boolean> {
    return this._store$.select(AuthStoreSelectors.selectTokenIsValid(Date.now())).pipe(
      withLatestFrom(this._store$.select(AuthStoreSelectors.selectOAuth)),
      switchMap(([isTokenValid, oAuth]) => {
        // if token is present but not valid, try refresh
        if (!isTokenValid && oAuth != null) {
          setTimeout(() => this._store$.dispatch(AuthStoreActions.RefreshToken()));
          return this._actions$.pipe(ofType(AuthStoreActions.RefreshTokenSuccess, AuthStoreActions.RefreshTokenFailure)).pipe(
            take(1),
            map((action) => (action.type == AuthStoreActions.RefreshTokenSuccess.type ? true : false)),
            catchError((_) => of(false))
          );
        }
        // if token is not present or is present and valid
        return of(true);
      }),
      switchMap((result) => {
        // if token failed to refresh then logout
        if (result == false) {
          this._logout();
          // return empty so follow up request completes without emitting
          return EMPTY;
        }
        return of(true);
      })
    );
  }

  private _logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    this._store$.dispatch(AuthStoreActions.Logout());
    this._router.navigate(['login']);
  }
}

type HttpOptions = {
  body?: unknown;
  headers?: HttpHeaders;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType: 'arraybuffer';
  withCredentials?: boolean;
};
