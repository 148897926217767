import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AvasFollowUpClient } from 'src/app/services/api.service';
import { RootStoreState } from '..';
import { AuthStoreActions, AuthStoreSelectors } from '../auth-store';
import * as FollowUpCategoryStoreActions from './actions';

@Injectable({ providedIn: 'root' })
export class FollowUpCategoryStoreEffects implements OnInitEffects {
  constructor(private _actions$: Actions, private _store$: Store<RootStoreState.State>, private _avasFollowUpClient: AvasFollowUpClient) {}

  ngrxOnInitEffects(): Action {
    return FollowUpCategoryStoreActions.Init();
  }

  init$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FollowUpCategoryStoreActions.Init),
      switchMap(() => this._store$.select(AuthStoreSelectors.selectUserInfo).pipe(take(1))),
      filter((user) => user != null || user != undefined),
      map((user) =>
        FollowUpCategoryStoreActions.LoadRequest({
          teamMemberId: user.teamMemberId,
          isAdmin: user.roles.indexOf('CustomerAdmin') !== -1 || user.roles.indexOf('Administrator') !== -1,
        })
      )
    )
  );

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(FollowUpCategoryStoreActions.LoadRequest, AuthStoreActions.LoginSuccess),
      switchMap((_) =>
        this._avasFollowUpClient.avasFollowUp_FollowUpCategories().pipe(
          map((result) => FollowUpCategoryStoreActions.LoadSuccess({ followUpCategories: result })),
          catchError(() => of(FollowUpCategoryStoreActions.LoadFailure({ error: 'Failed loading locations' })))
        )
      )
    )
  );
}
