import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';
 
@Directive({
  selector: '[RemoveIfFeatureOff]'
})
export class RemoveIfFeatureOffDirective implements OnInit {
  @Input('RemoveIfFeatureOff') featureName: string;
 
  constructor(private _el: ElementRef, private _featureFlagService: FeatureFlagService) {
  }
 
  ngOnInit(): void {
    if (this._featureFlagService.featureOff(this.featureName)) {
      this._el.nativeElement.remove();
    }
  }
}