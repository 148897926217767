import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ChatTranscript } from "src/app/automations/models/ChatTranscript.model";
import { ChatBotSetting, HelpTranscriptHistory } from "src/app/models/AvaMultiContext";
import { HelpService } from "src/app/services/help.service";

@Component({
    selector: 'contact-view-transcript',
    templateUrl: './contact-view-transcript.html',
    styleUrls: ['./contact-view-transcript.css'],
})
export class ContactViewTranscript {
  public chatTranscriptHistory: HelpTranscriptHistory[];
  public chatTranscriptList: ChatTranscript[] = [{ content: '', sender: 'system', sendOn: null, firstName: '', lastName: '' }];
  public chatBotSetting: ChatBotSetting = {
    chatBotSettingId: 1,
    name: '',
    title: '',
    headerColor: '',
    footerColor: '',
    invertTextColor: true,
    invitationMessage: '',
    isActiveInvitation: true,
    iconName: '',
    iconFilePath: '',
    webDomain: '',
  };
  private _chatsessionId: number;
  private _rowHighlight: HTMLElement;

  constructor(private _helpService: HelpService, private _dialogRef: MatDialogRef<ContactViewTranscript>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this._chatsessionId = data.chatSessionId;
    this._getChatBotSetting();
    this._getChatTranscriptHistory(this._chatsessionId);
    this._rowHighlight = data.rowHighlight;
    _dialogRef.disableClose = true;
  }
  
  public closeTranscriptHistory(): void {
    this._rowHighlight.style.backgroundColor = '#F8F8F8';
    this._dialogRef.close();
  }

  private _getChatBotSetting(): void {
    this._helpService.getChatBotSetting().subscribe(
      (result) => {
        this.chatBotSetting = result.chatBotSetting;
      }
    );
  }
  
  private _getChatTranscriptHistory(chatSessionId): void {
    this._helpService.getHelpTranscriptHistory(chatSessionId).subscribe(
      (result) => {
        this.chatTranscriptHistory = result.contactChatHistory;
      }
    );
  }
}
  

  

  