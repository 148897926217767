import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { AutomationsModule } from '../automations/automations.module';
import { NewPatientFormsComponent } from './new-patient-forms/new-patient-forms.component';
import { NewPatientSurveyComponent } from './new-patient-survey/new-patient-survey.component';
import { UrlGopherComponent } from './url-gopher/url-gopher.component';

const routes: Routes = [
  {
    path: 'npt',
    children: [
      {
        path: 'forms/:customerId/:newPatientTrackId',
        component: NewPatientFormsComponent,
      },
      {
        path: 'survey/:customerId/:newPatientTrackId',
        component: NewPatientSurveyComponent,
      },
    ],
  },
  {
    path: 'gopher/:customerId',
    component: UrlGopherComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'patient',
    loadChildren: () => import('./patient-portal/patient-portal.module').then((m) => m.PatientPortalModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), AutomationsModule],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
