import { Action, createReducer, on } from '@ngrx/store';
import { AuthStoreActions } from '../auth-store';
import * as LocationsStoreActions from './actions';
import { State, featureAdapter, initialState } from './state';

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(LocationsStoreActions.LoadRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(LocationsStoreActions.LoadSuccess, (state, action) => {
    return featureAdapter.upsertMany(action.locations, featureAdapter.removeAll({ ...state, isLoading: false }));
  }),
  on(LocationsStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //SELECT
  on(LocationsStoreActions.SelectRequest, (state) => ({ ...state, isLoading: true, error: null, selectedLocationId: null })),
  on(LocationsStoreActions.SelectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedLocationId: action.location.id })),
  on(LocationsStoreActions.SelectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPDATE
  on(LocationsStoreActions.UpdateRequest, (state) => ({ ...state, isLoading: true })),
  on(LocationsStoreActions.UpdateSuccess, (state, action) => featureAdapter.upsertOne(action.location, { ...state, isLoading: false })),
  on(LocationsStoreActions.UpdateFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //LOGOUT
  on(AuthStoreActions.Logout, (state) => featureAdapter.removeAll(state)),

  on(LocationsStoreActions.UpdateNPSDisplayName, (state, action) =>
    featureAdapter.updateMany(
      action.values.map((x) => ({ id: x.locationId, changes: { npsDisplayName: x.npsDisplayName } })),
      state
    )
  )
);

export function featureReducer(state: State | undefined, action: Action): State {
  return reducer(state, action);
}
