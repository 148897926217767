/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BRANDS_FEATURE_KEY } from './featurekey';
import { BrandStoreEntity, featureAdapter, State } from './state';

export const selectBrandsState = createFeatureSelector<State>(BRANDS_FEATURE_KEY);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectBrandsState);
export const selectBrandIds = selectIds;
export const selectBrandsTotal = selectTotal;
export const selectAllBrands = selectAll;
export const selectAllBrandEntities = selectEntities;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectBrandById = (id: number) =>
  createSelector(selectAllBrands, (allBrands: BrandStoreEntity[]) => {
    if (allBrands) {
      return allBrands.find((c) => c.brandId === id);
    } else {
      return null;
    }
  });

export const getSelectedBrandId = createSelector(selectBrandsState, (state) => state.selectedBrandId);

export const getSelectedBrand = createSelector(selectBrandsState, (state) => state.entities[state.selectedBrandId]);

export const selectBrandsError = createSelector(selectBrandsState, (state) => state.error);

export const selectBrandsIsLoading = createSelector(selectBrandsState, (state) => state.isLoading);
