import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { take } from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/ComponentBase';
import {
  ContractSectionContentTemplateDto,
  ContractSectionContentType,
  ContractSectionTemplateDto,
  IAvaSliderAcceptedSettings,
} from 'src/app/services/api.service';
import { SignatureDialogComponent, SignatureDialogData, SignatureDialogResult } from 'src/app/shared/digital-signature';
import { FinancialMergeField } from 'src/app/utility/MergeFields';

@Component({
  selector: 'app-contract-view',
  templateUrl: './contract-view.component.html',
  styleUrls: ['./contract-view.component.scss'],
})
export class ContractViewComponent extends ComponentBase {
  private _contract: IContractViewData;
  public get contract(): IContractViewData {
    return this._contract;
  }
  @Input('contract')
  public set contract(value: IContractViewData) {
    this._contract = value;
    if (this.contract && !this.contract.financial) {
      this.contract.financial = EXAMPLE_FINANCIAL;
    }
  }

  private _readonly: boolean = false;
  public get readonly(): boolean {
    return this._readonly;
  }
  @Input('readonly')
  public set readonly(value: boolean) {
    this._readonly = this.__coerceBooleanProperty(value);
  }

  private _signatures: ContractSignatures = {};
  @Input('signatures') set signatures(val: ContractSignatures) {
    this._signatures = val;
    this.signaturesChange.emit(this._signatures);
  }
  get signatures(): ContractSignatures {
    return this._signatures;
  }

  @Input('logoUrl') logoUrl: string;

  @Input('checkboxes') checkboxes: ContractCheckboxes = {};
  @Output() checkboxesChange = new EventEmitter<ContractCheckboxes>();

  @Output() signaturesChange = new EventEmitter<ContractSignatures>();

  readonly __ContractSectionContentType = ContractSectionContentType;

  constructor(private _dialog: MatDialog, public element: ElementRef) {
    super();
  }

  public FINANCIAL_MERGE_FIELD = FinancialMergeField.FeeOverview;

  __openParagraphSignDialog(content: ContractSectionContentTemplateDto): void {
    const data = <SignatureDialogData>{
      type: 'initials',
      currentValue: this.signatures[content.contractSectionContentTemplateId],
    };
    this.__subSink.sink = this._dialog
      .open(SignatureDialogComponent, { data })
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: SignatureDialogResult) => {
        if (result) this.signatures[content.contractSectionContentTemplateId] = result.value;
      });
  }

  __getSortedSections(): ContractSectionTemplateDto[] {
    return this.contract && this.contract.contractSections && this.contract.contractSections.sort((a, b) => a.order - b.order);
  }

  __getSortedContents(sectionContents: ContractSectionContentTemplateDto[]): ContractSectionContentTemplateDto[] {
    return sectionContents && sectionContents.sort((a, b) => a.order - b.order);
  }

  private _getSignatureRequiredContent(type: ContractSectionContentType): ContractSectionContentTemplateDto[] {
    return this._contract
      ? this._contract.contractSections.reduce(
          (acc, cur) => [...acc, ...cur.contractSectionContentTemplates.filter((x) => x.signatureRequired && x.type == type)],
          <ContractSectionContentTemplateDto[]>[]
        )
      : [];
  }

  public VerifyContractComplete(): boolean {
    const signedContent = Object.keys(this._signatures).map((id) => parseInt(id));
    const checkedContent = Object.keys(this.checkboxes).map((id) => parseInt(id));
    const paragraphsSigned = this._getSignatureRequiredContent(ContractSectionContentType.Text).every(
      (x) => signedContent.includes(x.contractSectionContentTemplateId) && this._signatures[x.contractSectionContentTemplateId] != null
    );
    const paragraphsChecked = this._getSignatureRequiredContent(ContractSectionContentType.Checkbox).every(
      (x) => checkedContent.includes(x.contractSectionContentTemplateId) && this.checkboxes[x.contractSectionContentTemplateId] == true
    );
    return paragraphsSigned && paragraphsChecked;
  }
}

export interface IContractViewData {
  headers: string[];
  contractSections: ContractSectionTemplateDto[];
  financial?: IAvaSliderAcceptedSettings;
  finalSignature?: string;
  signedDate?: Date;
  logoUrl?: string;
  patient?: { firstName: string; lastName: string };
}

export type ContractSignatures = { [key: number]: string };
export type ContractCheckboxes = { [key: number]: boolean };

const EXAMPLE_FINANCIAL: IAvaSliderAcceptedSettings = {
  amountFinanced: 4280,
  downPaymentAmountSelected: 500,
  insuranceCoverageAmount: 1500,
  monthsAmountSelected: 60,
  paymentOptions: [
    {
      name: 'Up to 60 Months',
      addOns: [
        {
          amount: 500,
          description: 'Financing Fee',
          isPercent: false,
          includeDownPayment: false,
        },
      ],
      discount: [],
      downPayment: null,
      lengthMonth: {
        min: 1,
        max: 60,
      },
      monthlyMinimum: 120,
      brandId: null,
      id: null,
      interest: 0,
      notes: null,
      payInFull: false,
    },
  ],
  treatmentTypes: [
    {
      addOns: [
        {
          amount: 450,
          description: 'Retainers for Life',
          isPercent: false,
          includeDownPayment: false,
        },
      ],
      discount: [],
      fee: 5880,
      id: null,
      lengthOfTreatment: {
        min: 18,
        max: 24,
      },
      brandId: null,
      name: 'Braces',
      notes: [],
    },
  ],
};
