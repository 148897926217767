import { Component, OnInit,Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-card-checkbox',
  templateUrl: './card-checkbox.component.html',
  styleUrls: ['./card-checkbox.component.css']
})
export class CardCheckboxComponent implements OnInit {

  @Input() label: string;
  @Input() value: string;
  @Input() selected: boolean;
  @Output() isSelected = new EventEmitter();
	public chkboxSelected = false;

  ngOnInit(): void {
    if(this.selected){
      this.chkboxSelected = true;
    }
  }

  public selectCheckbox(): void {
    this.chkboxSelected = this.chkboxSelected == false ? true : false;
    this.clickOption();
  }

  public clickOption(): void {
    const obj = {'selected':this.chkboxSelected,'value':this.value};
    this.isSelected.emit(obj);
  }

}
