/* eslint-disable */
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'genericFilter'})
export class GenericFilterPipe implements PipeTransform {
  transform(values: any[], filterKey: string, filterValue: any) {
    if (values.length == 0)
      return values;

    if (Object.keys(values[0]).filter(o => o == filterKey).length == 0)
      throw new Error(`Key not found on object: ${filterKey}`);

    return values.filter(o => o[filterKey] == filterValue);
  }
}
