import { HubAction } from 'ngrx-signalr-core';
import { MonoTypeOperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Filter a hub action only by name
 * @param hubName Name of the hub.
 */
export function ofHubName(hubName: string): MonoTypeOperatorFunction<HubAction> {
  // Created as a solution to urls being dynamic with hubs
  if (typeof hubName === 'string') {
    return filter((action) => action.hubName === hubName);
  }
}
