import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, CallbackBlasts, CallbackFollowUp, CallbackNewPatientScheduler, CallbackVirtualConsult } from './auth.guard';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  {
    path: 'login',
    loadChildren: () => import('../app/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../app/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'public',
    loadChildren: () => import('../app/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'embedded-chat',
    loadChildren: () => import('../app/settings/embedded-chat-bot/embedded-chat-bot.module').then((m) => m.EmbeddedChatBotModule),
  },
  {
    path: CallbackFollowUp,
    children: [],
    canActivate: [AuthGuard],
  },
  {
    path: CallbackVirtualConsult,
    children: [],
    canActivate: [AuthGuard],
  },
  {
    path: CallbackNewPatientScheduler,
    children: [],
    canActivate: [AuthGuard],
  },
  {
    path: CallbackBlasts,
    children: [],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
