import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickConfirm } from '../click-confirm.interface';

@Component({
  templateUrl: './click-confirm-template.component.html',
  styleUrls: ['./click-confirm-template.component.css'],
})
export class ClickConfirmTemplateComponent implements ClickConfirm {
  @Output('confirm') confirm: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Input('confirmText') confirmText: string;

  confirmClick(): void {
    this.confirm.next(true);
  }

  cancelClick(): void {
    this.confirm.next(false);
  }
}
