/* eslint-disable */
export function isSameDate(date1: Date | string, date2: Date | string): boolean {
  if (!date1 || !date2) {
    return false;
  }

  const dateLeft = new Date(date1);
  const dateRight = new Date(date2);

  return dateLeft.getDate() === dateRight.getDate()
    && dateLeft.getMonth() === dateRight.getMonth()
    && dateLeft.getFullYear() === dateRight.getFullYear();
}
