/* eslint-disable */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patientfollowup',
  templateUrl: './patientfollowup.component.html',
  styleUrls: ['./patientfollowup.component.css']
})
export class PatientfollowupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
