import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
} from '@angular/material';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { JsonDateInterceptorProvider } from 'src/app/services/json-date.interceptor';
import { CasePresentationModule } from 'src/app/shared/case-presentation/case-presentation.module';
import { ClickConfirmModule } from 'src/app/shared/click-confirm/click-confirm.module';
import { EditInPlaceModule } from 'src/app/shared/edit-in-place/edit-in-place.module';
import { FinancialSharedModule } from 'src/app/shared/financial/financial-shared.module';
import { AvaSlidersComponent } from './ava-sliders/ava-sliders.component';
import { LedgersComponent } from './ledgers/ledgers.component';
import { CreateAutoPayDialogComponent } from './modals/create-auto-pay-dialog/create-auto-pay-dialog.component';
import { CreatePatientContractDialogComponent } from './modals/create-patient-contract-dialog/create-patient-contract-dialog.component';
import { ImportSliderDialogComponent } from './modals/import-slider-dialog/import-slider-dialog.component';
import { LedgerPostDialogComponent } from './modals/ledger-post-dialog/ledger-post-dialog.component';
import { PaymentPlanDialogComponent } from './modals/payment-plan-dialog/payment-plan-dialog.component';
import { ViewPatientContractDialogComponent } from './modals/view-patient-contract/view-patient-contract.component';
import { PatientFinancialComponent } from './patient-financial.component';
import { SubmittedContractsComponent } from './submitted-contracts/submitted-contracts.component';

const MAT_ANGULAR_MODULES = [
  MatTableModule,
  MatIconModule,
  MatButtonModule,
  MatDividerModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatMenuModule,
  MatDialogModule,
  MatTabsModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatButtonToggleModule,
  MatProgressBarModule,
  MatListModule,
  MatDatepickerModule,
  MatSelectModule,
  MatCheckboxModule,
];

@NgModule({
  declarations: [
    PatientFinancialComponent,
    LedgersComponent,
    SubmittedContractsComponent,
    LedgerPostDialogComponent,
    ViewPatientContractDialogComponent,
    CreateAutoPayDialogComponent,
    PaymentPlanDialogComponent,
    AvaSlidersComponent,
    ImportSliderDialogComponent,
    CreatePatientContractDialogComponent,
  ],
  exports: [PatientFinancialComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPipesModule,
    ...MAT_ANGULAR_MODULES,
    ClickConfirmModule,
    SharedPipesModule,
    FinancialSharedModule,
    EditInPlaceModule,
    CasePresentationModule,
  ],
  entryComponents: [
    LedgerPostDialogComponent,
    ViewPatientContractDialogComponent,
    CreateAutoPayDialogComponent,
    PaymentPlanDialogComponent,
    ImportSliderDialogComponent,
    CreatePatientContractDialogComponent,
  ],
  providers: [JsonDateInterceptorProvider],
})
export class PatientFinancialModule {}
