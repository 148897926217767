import { OpenIddictResponseDto, UserDetailsDto } from 'src/app/services/api.service';

export interface State {
  isLoading?: boolean;
  error?: string | unknown;
  token: OpenIddictResponseDto | null;
  tokenDate: Date | null;
  userInfo: UserDetailsDto | null;
  /** true/false: logged in condition; null: uninitialized/working */
  isLoggedIn: boolean | null;
}

export const initialState: State = {
  isLoading: false,
  token: null,
  error: null,
  tokenDate: null,
  userInfo: null,
  isLoggedIn: null,
};
