import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { UserStoreEntity } from './models';

export const featureAdapter = createEntityAdapter<UserStoreEntity>({
  selectId: (user) => user.id,
  sortComparer: (a, b) => a.id - b.id,
});

export interface State extends EntityState<UserStoreEntity> {
  isLoading?: boolean;
  error?: string | unknown;
  selectedUserId?: number;
  loggedUserId?: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  selectedUserId: null,
  loggedUserId: null,
});
