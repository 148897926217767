import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IContractTemplateDto } from 'src/app/services/api.service';

export const featureAdapter: EntityAdapter<ContractTemplateStoreEntity> = createEntityAdapter<ContractTemplateStoreEntity>({
  selectId: (x) => x.contractTemplateId,
  sortComparer: (a: ContractTemplateStoreEntity, b: ContractTemplateStoreEntity): number => a.contractTemplateId - b.contractTemplateId,
});

export interface State extends EntityState<ContractTemplateStoreEntity> {
  isLoading?: boolean;
  error?: string | unknown;
  isLoaded?: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  isLoaded: false,
});

export type ContractTemplateStoreEntity = IContractTemplateDto;
