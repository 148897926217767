import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createReconnectEffect } from 'ngrx-signalr-core';

@Injectable({ providedIn: 'root' })
export class SignalRStoreEffectsService {
  constructor(private _actions$: Actions) {}

  whenDisconnected$ = createReconnectEffect(this._actions$);
}
