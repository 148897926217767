import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentBase } from 'src/app/core/ComponentBase';
import { AdjustmentItem, AvaSliderAcceptedSettings, AvaSliderDto, PaymentOptionDto, TreatmentTypeDto } from 'src/app/services/api.service';

@Component({
  selector: 'app-case-presenter',
  templateUrl: './case-presenter.component.html',
  styleUrls: ['./case-presenter.component.scss'],
  host: {
    class: 'case-presenter',
  },
})
export class CasePresenterComponent extends ComponentBase {
  private _avaSlider: AvaSliderDto;
  @Input('avaSlider')
  public set avaSlider(value: AvaSliderDto) {
    this._avaSlider = value;
    if (value) {
      this._acceptedSettings.insuranceCoverageAmount = value.settings.insuranceCoverageAmount;
    }
  }
  public get avaSlider(): AvaSliderDto {
    return this._avaSlider;
  }

  private _acceptedSettings: AvaSliderAcceptedSettings = new AvaSliderAcceptedSettings({
    amountFinanced: 0,
    downPaymentAmountSelected: 0,
    insuranceCoverageAmount: 0,
    monthsAmountSelected: 0,
    paymentOptions: [],
    treatmentTypes: [],
  });
  @Input('acceptedSettings')
  public set acceptedSettings(value: AvaSliderAcceptedSettings) {
    if (value) {
      this._acceptedSettings = value;
    }
  }
  public get acceptedSettings(): AvaSliderAcceptedSettings {
    return this._acceptedSettings;
  }
  @Output('acceptedSettingsChange') acceptedSettingsChange = new EventEmitter<AvaSliderAcceptedSettings>();

  private _selectedTreatmentType: TreatmentTypeDto;
  public get selectedTreatmentType(): TreatmentTypeDto {
    return this._selectedTreatmentType;
  }
  public set selectedTreatmentType(value: TreatmentTypeDto) {
    this._selectedTreatmentType = value;
    this.acceptedSettings.treatmentTypes = this.__coerceArray(value);
    this.__recalculateFinancedAmount();
    this.acceptedSettings.downPaymentAmountSelected = 0;
  }

  private _selectedPaymentOption: PaymentOptionDto;
  public get selectedPaymentOption(): PaymentOptionDto {
    return this._selectedPaymentOption;
  }
  public set selectedPaymentOption(value: PaymentOptionDto) {
    this._selectedPaymentOption = value;
    this.acceptedSettings.paymentOptions = this.__coerceArray(value);
    this.acceptedSettings.downPaymentAmountSelected = value.payInFull ? 0 : value.downPayment.min;
    this.acceptedSettings.monthsAmountSelected = value.lengthMonth.min;
  }

  @Input('insuranceCoverageAmount')
  public set insuranceCoverageAmount(value: number) {
    this.acceptedSettings.insuranceCoverageAmount;
  }
  public get insuranceCoverageAmount(): number {
    return this.acceptedSettings.insuranceCoverageAmount;
  }

  __allDiscounts(): AdjustmentItem[] {
    if (this.selectedPaymentOption && this.selectedTreatmentType) {
      return this.selectedPaymentOption.discount.concat(this.selectedTreatmentType.discount);
    }
    return [];
  }

  __allAddOns(): AdjustmentItem[] {
    if (this.selectedPaymentOption && this.selectedTreatmentType) {
      return this.selectedPaymentOption.addOns.concat(this.selectedTreatmentType.addOns);
    }
    return [];
  }

  __recalculateFinancedAmount(): void {
    this.acceptedSettings.amountFinanced =
      this._selectedTreatmentType.fee - this.acceptedSettings.downPaymentAmountSelected - this.acceptedSettings.insuranceCoverageAmount;
  }

  __modelUpdated(): void {
    this.acceptedSettingsChange.emit(this._acceptedSettings);
  }
}
