import { createAction, props } from '@ngrx/store';
import { BrandStoreEntity } from './state';

export const Init = createAction('[Brands] Init');

export const RemoveBrand = createAction('[Brands] Remove Brand', props<{ brandId: number }>());
export const AddBrand = createAction('[Brands] Add Brand', props<{ brand: BrandStoreEntity }>());
export const UpdateBrand = createAction('[Brands] Update Brand', props<{ brand: BrandStoreEntity }>());

export const LoadRequest = createAction('[Brands] LoadRequest', props<{ teamMemberId: number; isAdmin: boolean }>());
export const LoadSuccess = createAction('[Brands] LoadSuccess', props<{ brands: BrandStoreEntity[] }>());
export const LoadFailure = createAction('[Brands] LoadFailure', props<{ error: string | unknown }>());

export const SelectRequest = createAction('[Brands] SelectRequest', props<{ id: number }>());
export const SelectSuccess = createAction('[Brands] SelectSuccess', props<{ brand: BrandStoreEntity }>());
export const SelectFailure = createAction('[Brands] SelectFailure', props<{ error: string | unknown }>());

export const AddRequest = createAction(
  '[Brands] AddRequest',
  props<{ brand: Pick<BrandStoreEntity, 'brandId' | 'name' | 'newPatientSchedulerLogo' | 'locations'> }>()
);
export const AddSuccess = createAction('[Brands] AddSuccess', props<{ brand: BrandStoreEntity }>());
export const AddFailure = createAction('[Brands] AddFailure', props<{ error: string | unknown }>());

export const DeleteRequest = createAction('[Brands] DeleteRequest', props<{ brandId: number }>());
export const DeleteSuccess = createAction('[Brands] DeleteSuccess', props<{ brandId: number }>());
export const DeleteFailure = createAction('[Brands] DeleteFailure', props<{ error: string | unknown }>());

export const UpdateRequest = createAction(
  '[Brands] UpdateRequest',
  props<{ brand: Pick<BrandStoreEntity, 'brandId' | 'name' | 'newPatientSchedulerLogo' | 'locations'> }>()
);
export const UpdateSuccess = createAction('[Brands] UpdateSuccess', props<{ brand: BrandStoreEntity }>());
export const UpdateFailure = createAction('[Brands] UpdateFailure', props<{ error: string | unknown }>());

export const SetTeamMemberForAppointmentReminderRequest = createAction(
  '[Brands] SetTeamMemberForAppointmentReminderRequest',
  props<{ brandId: number; teamMemberId: number }>()
);
export const SetTeamMemberForAppointmentReminderSuccess = createAction(
  '[Brands] SetTeamMemberForAppointmentReminderSuccess',
  props<{ brandId: number; teamMemberId: number }>()
);
export const SetTeamMemberForAppointmentReminderFailure = createAction(
  '[Brands] SetTeamMemberForAppointmentReminderFailure',
  props<{ brandId: number; teamMemberId: number; error: string | unknown }>()
);
