import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { DigitalSignatureModule } from '../shared/digital-signature/digital-signature.module';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { MatVerticalStepperScrollerDirective } from './MatVerticalStepperScroller.directive';
import { NewPatientFormsComponent } from './new-patient-forms/new-patient-forms.component';
import { NewPatientSurveyComponent } from './new-patient-survey/new-patient-survey.component';
import { PublicRoutingModule } from './public-routing.module';
import { UrlGopherComponent } from './url-gopher/url-gopher.component';

@NgModule({
  declarations: [
    NewPatientFormsComponent,
    NewPatientSurveyComponent,
    MatVerticalStepperScrollerDirective,
    ImageUploaderComponent,
    UrlGopherComponent,
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    MatStepperModule,
    MatInputModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatListModule,
    MatButtonModule,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTabsModule,
    MatIconModule,
    OverlayModule,
    MatDialogModule,
    MatCardModule,
    MatSelectModule,
    MatTooltipModule,
    MatSliderModule,
    SharedPipesModule,
    NgHttpLoaderModule,
    DigitalSignatureModule,
  ],
})
export class PublicModule {}
