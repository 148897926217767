import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MultiDataSet, PluginServiceGlobalRegistrationAndOptions, Label } from 'ng2-charts';
import { ChartType } from 'chart.js';
import { Tab } from '../../enums/tab.enum';
import { PatientDetailsModel } from '../../models/PatientDetailsModel.model';
import { ChartColor } from '../../models/ChartColor.model';

@Component({
  selector: 'app-ava-contribution-dialog',
  templateUrl: './ava-contribution-dialog.component.html',
  styleUrls: ['./ava-contribution-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AvaContributionDialogComponent implements OnInit {
  public tab = Tab;
  public selectedTab: Tab;
  public total: number;
  public values: number[];
  public headers: string[];
  public chartType: ChartType = 'doughnut';
  public chartLabels: Label[] = [];
  public chartOptions = {
    cutoutPercentage: 70
  };
  public chartColors: ChartColor[] = [{
      backgroundColor: ['#128DFF', '#F8F8F8'],
      borderColor: ['#C4C4C4', '#C4C4C4']
    }
  ];
  public details = [
    <PatientDetailsModel> {
      patientName: 'Brian Myers',
      treatmentStartDate: new Date('2019-11-16T00:00:00'),
      amount: 5544.45
    },
    <PatientDetailsModel> {
      patientName: 'Susie Best',
      treatmentStartDate: new Date('2019-12-16T00:00:00'),
      amount: 3644.45
    },
    <PatientDetailsModel> {
      patientName: 'Thalia Bryant',
      treatmentStartDate: new Date('2019-11-25T00:00:00'),
      amount: 944.45
    },
    <PatientDetailsModel> {
      patientName: 'Zach Whitehead',
      treatmentStartDate: new Date('2019-09-18T00:00:00'),
      amount: 845.45
    },
    <PatientDetailsModel> {
      patientName: 'Ariel Mirano',
      treatmentStartDate: new Date('2019-12-02T00:00:00'),
      amount: 874.45
    }
  ];
  public doughnutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [{
    afterDraw(chart: any): void {
      const percentage = Math.round(chart.tooltip._data.datasets[0].data[0] / chart.tooltip._data.datasets[0].data[1] * 100) + '%';
      const ctx = chart.ctx;

      ctx.textAlign = 'center';
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = '10px Lato';
      ctx.fillStyle = 'black';
      ctx.fillText(percentage, centerX, centerY);
    }
  }];
  private _last30Values = [ 85308.45, 73525.09, 22700.13, 58741.74, 31000.30 ];
  private _last90Values = [ 85474.45, 45554.09, 10000.13, 98744.74, 87877.30 ];
  private _customValues = [ 10445.45, 87878.09, 87465.13, 77774.74, 78944.30 ];
  private _rowHeaders = [ 'Pending (19)', 'Recall (16)', 'No Show (5)', 'New Lead (13)', 'Legacy Blast (7)' ];

  ngOnInit(): void {
    this.selectedTab = Tab.Last30Days;
    this.total = this._last30Values.reduce((a, b) => a + b, 0);
    this.values = this._last30Values;
    this.headers = this._rowHeaders;
  }

  public changeTab(tab: Tab): void {
    this.selectedTab = tab;
    this._getValues();
  }

  public getChartData(index: number): MultiDataSet {
    return <MultiDataSet>[[this.values[index], this.total]];
  }

  private _getValues() {
    switch (this.selectedTab) {
      case Tab.Last30Days:
        this.total = this._last30Values.reduce((a, b) => a + b, 0);
        this.values = this._last30Values;
        break;
      case Tab.Last90Days:
        this.total = this._last90Values.reduce((a, b) => a + b, 0);
        this.values = this._last90Values;
        break;
      case Tab.Custom:
        this.total = this._customValues.reduce((a, b) => a + b, 0);
        this.values = this._customValues;
        break;
    }

    this.headers = this._rowHeaders;
  }
}
