import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import { ClickConfirmModule } from 'src/app/shared/click-confirm/click-confirm.module';
import { DigitalSignatureModule } from '../digital-signature';
import { ContractViewComponent } from './contract-view/contract-view.component';
import { ExampleFeeOverviewComponent } from './example-fee-overview/example-fee-overview.component';
import { FeeOverviewComponent } from './fee-overview/fee-overview.component';
import { FinancialService } from './financial.service';
import { ContractViewDialogComponent } from './modal/contract-view-dialog/contract-view-dialog.component';

const MatAngularModules = [
  MatButtonModule,
  MatInputModule,
  MatIconModule,
  MatListModule,
  MatTableModule,
  MatMenuModule,
  MatToolbarModule,
  MatSelectModule,
  DragDropModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatCardModule,
  MatFormFieldModule,
  MatDialogModule,
];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ...MatAngularModules, ClickConfirmModule, DigitalSignatureModule],
  exports: [ContractViewComponent, ExampleFeeOverviewComponent, FeeOverviewComponent],
  declarations: [ContractViewComponent, ExampleFeeOverviewComponent, FeeOverviewComponent, ContractViewDialogComponent],
  entryComponents: [ContractViewComponent, ContractViewDialogComponent],
  providers: [FinancialService],
})
export class FinancialSharedModule {}
