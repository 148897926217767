import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { IAppConfig } from "../models/Config";

@Injectable()
export class AppConfig {
    static settings: IAppConfig;
    constructor(public _http: HttpClient) {}
    
    load(): void {
        const jsonFile = `assets/config/config.${environment.name}.json`;

        this._http.get<IAppConfig>(jsonFile).subscribe((response) => {
                AppConfig.settings = <IAppConfig>response;
            }
        );
    }
}