import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IBrandDto } from 'src/app/services/api.service';

export const featureAdapter: EntityAdapter<BrandStoreEntity> = createEntityAdapter<BrandStoreEntity>({
  selectId: (x) => x.brandId,
  sortComparer: (a: BrandStoreEntity, b: BrandStoreEntity): number => a.name.localeCompare(b.name),
});

export interface State extends EntityState<BrandStoreEntity> {
  isLoading?: boolean;
  error?: string | unknown;
  selectedBrandId?: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  selectedBrandId: null,
});

export type BrandStoreEntity = IBrandDto;
