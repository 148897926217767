/* eslint-disable */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    let regex: RegExp;
    regex = /[^0-9]/; // Any non-numeric characters
    value = value.replace(regex, '');

    // CONDITION: international phone number;
    if (value.length === 11) {
      regex = /^(\d)(\d{3})(\d{3})(\d{4})$/;
      return value.replace(regex, '+$1 ($2) $3-$4');  // +# (###) ###-####
    } else
    // CONDITION: local number
    if (value.length === 10) {
      regex = /^(\d{3})(\d{3})(\d{4})$/;
      return value.replace(regex, '($1) $2-$3');  // (###) ###-####
    } else {
      return value;
    }
  }
}
