import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, share, switchMap, withLatestFrom } from 'rxjs/operators';
import { ContractTemplateApiService } from 'src/app/services/contractTemplate.service';
import { RootStoreState } from '..';
import * as BrandStoreSelectors from '../brands-store/selectors';
import * as ContractTemplateStoreActions from './actions';
import * as ContractTemplateStoreSelectors from './selectors';

@Injectable({ providedIn: 'root', deps: [] })
export class ContractTemplateStoreEffects implements OnInitEffects {
  constructor(
    private _actions$: Actions,
    private _store$: Store<RootStoreState.State>,
    private _contractTemplateApiService: ContractTemplateApiService
  ) {}

  ngrxOnInitEffects(): Action {
    return ContractTemplateStoreActions.Init();
  }

  loadRequest$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ContractTemplateStoreActions.LoadRequest),
      withLatestFrom(
        this._store$.select(ContractTemplateStoreSelectors.selectContractTemplatesIsLoaded),
        this._store$.select(BrandStoreSelectors.getSelectedBrandId)
      ),
      // Only load if not loaded or forced reload after first load
      filter(([action, isLoaded, selectedBrandId]) => (isLoaded ? action.reload || (action.brandId && action.brandId != selectedBrandId) : true)),
      switchMap(([action, _, selectedBrandId]) =>
        this._contractTemplateApiService.getContractTemplates(action.brandId || selectedBrandId).pipe(
          map((response) => ContractTemplateStoreActions.LoadSuccess({ contractTemplates: response })),
          catchError((res: HttpErrorResponse) => of(ContractTemplateStoreActions.LoadFailure({ error: res.error.message })))
        )
      ),
      share()
    );
  });

  addRequestEffect$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ContractTemplateStoreActions.AddRequest),
      switchMap((action) =>
        this._contractTemplateApiService.addContractTemplate(action.contractTemplate).pipe(
          map((data) => ContractTemplateStoreActions.AddSuccess({ contractTemplate: data })),
          catchError((response: HttpErrorResponse) => of(ContractTemplateStoreActions.AddFailure({ error: response.error.message })))
        )
      ),
      share()
    );
  });

  updateRequestEffect$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ContractTemplateStoreActions.UpdateRequest),
      switchMap((action) =>
        this._contractTemplateApiService.updateContractTemplate(action.contractTemplate).pipe(
          map((data) => ContractTemplateStoreActions.UpdateSuccess({ contractTemplate: data })),
          catchError((response: HttpErrorResponse) => of(ContractTemplateStoreActions.UpdateFailure({ error: response.error.message })))
        )
      ),
      share()
    );
  });

  deleteRequestEffect$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ContractTemplateStoreActions.DeleteRequest),
      switchMap((action) =>
        this._contractTemplateApiService.deleteContractTemplate(action.contractTemplateId).pipe(
          map((data) =>
            data
              ? ContractTemplateStoreActions.DeleteSuccess({ contractTemplateId: action.contractTemplateId })
              : ContractTemplateStoreActions.DeleteFailure({ error: 'Failed to delete Contract Template' })
          ),
          catchError((response: HttpErrorResponse) => of(ContractTemplateStoreActions.DeleteFailure({ error: response.error.message })))
        )
      ),
      share()
    );
  });
}
