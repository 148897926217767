import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export class JsonDateInterceptor implements HttpInterceptor {
  iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<unknown>) => {
          if (event instanceof HttpResponse) {
            const body = event.body;
            this._convertToDate(body);
          }
        },
        (err: unknown) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              //
            }
          }
        }
      )
    );
  }

  private _convertToDate(body: unknown): unknown {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    if (body instanceof Array) {
      return body.map((x) => this._convertToDate(x));
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (typeof value === 'string' && this._isIso8601(value)) {
        body[key] = new Date(value);
      } else if (typeof value === 'object') {
        this._convertToDate(value);
      }
    }
  }

  private _isIso8601(value: string): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    return this.iso8601.test(value);
  }
}

export const JsonDateInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: JsonDateInterceptor,
  multi: true,
};
