import { Action, createReducer, on } from '@ngrx/store';
import { AuthStoreActions } from '../auth-store';
import * as BrandStoreActions from './actions';
import { featureAdapter, initialState, State } from './state';

const reducer = createReducer(
  initialState,
  //LOAD
  on(BrandStoreActions.LoadRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(BrandStoreActions.LoadSuccess, (state, action) => {
    return featureAdapter.upsertMany(action.brands, featureAdapter.removeAll({ ...state, isLoading: false }));
  }),
  on(BrandStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //SELECT
  on(BrandStoreActions.SelectRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(BrandStoreActions.SelectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedBrandId: action.brand.brandId })),
  on(BrandStoreActions.SelectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPDATE
  on(BrandStoreActions.UpdateRequest, (state) => ({ ...state, isLoading: true })),
  on(BrandStoreActions.UpdateSuccess, (state, action) => featureAdapter.upsertOne(action.brand, { ...state, isLoading: false })),
  on(BrandStoreActions.UpdateFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //ADD
  on(BrandStoreActions.AddRequest, (state) => ({ ...state, isLoading: true })),
  on(BrandStoreActions.AddSuccess, (state, action) => featureAdapter.upsertOne(action.brand, { ...state, isLoading: false })),
  on(BrandStoreActions.AddFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DELETE
  on(BrandStoreActions.DeleteRequest, (state) => ({ ...state, isLoading: true })),
  on(BrandStoreActions.DeleteSuccess, (state, action) => featureAdapter.removeOne(action.brandId, { ...state, isLoading: false })),
  on(BrandStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //APPOINTMENT REMENDERS TEAM MEMBER
  on(BrandStoreActions.SetTeamMemberForAppointmentReminderRequest, (state) => ({ ...state, isLoading: true })),
  on(BrandStoreActions.SetTeamMemberForAppointmentReminderSuccess, (state, action) =>
    featureAdapter.updateOne(
      { id: action.brandId, changes: { defaultTMForAppointmentReminderId: action.teamMemberId } },
      { ...state, isLoading: false }
    )
  ),
  on(BrandStoreActions.SetTeamMemberForAppointmentReminderFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //LOGOUT
  on(AuthStoreActions.Logout, (state) => featureAdapter.removeAll(state))
);

export function featureReducer(state: State | undefined, action: Action): State {
  return reducer(state, action);
}
