import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AvaSliderDto } from 'src/app/services/api.service';

@Component({
  selector: 'app-ava-slider-editor-dialog',
  templateUrl: './ava-slider-editor-dialog.component.html',
  styleUrls: ['./ava-slider-editor-dialog.component.scss'],
})
export class AvaSliderEditorDialogComponent {
  get __avaSlider(): AvaSliderDto {
    return this._data && this._data.avaSlider;
  }
  set __avaSlider(value: AvaSliderDto) {
    this._data.avaSlider = value;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private _data: IAvaSliderEditorDialogData) {}
}

export interface IAvaSliderEditorDialogData {
  avaSlider: AvaSliderDto;
}
