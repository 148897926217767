/* eslint-disable */
import { Component, Inject } from '@angular/core';
import { PatientService } from 'src/app/services/patient.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VirtualConsulFileViewModel as VirtualConsultFileViewModel } from 'src/app/models/VirtualConsult';

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup-modal.component.html',
  styleUrls: ['./image-popup-modal.component.css'],
  providers: [PatientService]
})

export class ImagePopupModalComponent {
  public nextImageSrc: String;
  public prevImageSrc: String;
  public curIndex: number;
  public currentFile: VirtualConsultFileViewModel;
  public rotation = 0;
  public imageLabel = [
    'Relaxed jaw, closed mouth selfie',
    'Natural smile selfie',
    'Side profile',
    'Exaggerated full teeth',
    'Left side profile',
    'Right side profile',
    'Upper teeth',
    'Lower teeth'
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { files: VirtualConsultFileViewModel[], selectedFile: VirtualConsultFileViewModel },
    public patient: PatientService) {
    this.currentFile = this.data.selectedFile;
    this.curIndex = this.data.files.indexOf(this.currentFile);
  }

  prevOfIndex() {
    if (this.curIndex - 1 < 0) {
      this.curIndex = 0;
    } else {
      this.curIndex -= 1;
    }
    this.currentFile = this.data.files[this.curIndex];
  }

  nextOfIndex() {
    if (this.curIndex + 1 >= this.data.files.length) {
      this.curIndex = this.data.files.length - 1;
    } else {
      this.curIndex += 1;
    }
    this.currentFile = this.data.files[this.curIndex];
  }

  rotate(deg: number) {
    if (this.rotation === 270 && deg === 90) {
      this.rotation = 0;
    } else if (this.rotation === 0 && deg === -90) {
      this.rotation = 270;
    } else {
      this.rotation += deg;
    }
  }
}
