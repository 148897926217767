import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ImagePopupModalComponent } from 'src/app/modal/image-popup-modal/image-popup-modal.component';
import { CommunicationType, ModalData, PatientDetailsArea } from 'src/app/modal/modal-container/ModalData';
import { FollowUpModalData } from 'src/app/models/AutoSetRemind';
import { LeadStatusEnum, VirtualConsulFileViewModel as VirtualConsultFileViewModel, VirtualConsultViewModel } from 'src/app/models/VirtualConsult';
import { LocationStoreSelectors, RootStoreState, SIGNALR_EVENTS } from 'src/app/root-store';
import { ModalContainerService, MODAL_TYPE } from 'src/app/services/modal-container.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SubSink } from 'subsink';
import { ChatDetail } from '../../models/Communication';
import { SMSData } from '../../models/SmsData';
import { ServiceProvider } from '../../service';
import { DataService } from '../../services/data.service';
import { PatientService } from './../../services/patient.service';
import { DialogDataExampleDialog } from './modal/dialog-data-example-dialog';

@Component({
  selector: 'app-virtualconsult',
  templateUrl: './virtualconsult.component.html',
  styleUrls: ['./virtualconsult.component.css'],
  providers: [PatientService],
})
export class VirtualconsultComponent implements OnInit, OnDestroy {
  constructor(
    private _remoteService: ServiceProvider,
    private _dataService: DataService,
    private _modalContainerService: ModalContainerService,
    private _store$: Store<RootStoreState.State>,
    public dialog: MatDialog,
    private _notificationService: NotificationService
  ) {}
  public newConsPatientsData: VirtualConsultViewModel[] = [];
  public progressConsPatientsData: VirtualConsultViewModel[] = [];
  public videoRecordLink: string;
  public sMSData = <SMSData>{};
  public dispSendMsgContainer = false;
  public selectedProgressCons = 0;
  public selectedNewCons = 0;
  private _subs = new SubSink();

  ngOnInit(): void {
    this._loadAll();
    this._dataService.getMessageForAvaModalClose().subscribe(() => {
      this._loadAll();
    });
    this._subs.sink = this._notificationService.ofEvent(SIGNALR_EVENTS.MessageSentCommunication).subscribe((data) => this._onMessageSent(data));
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public askRemove(id: number, actionFor: string, name: string): void {
    const dialogRef = this.dialog.open(DialogDataExampleDialog, {
      backdropClass: 'whitebackdrop',
      panelClass: 'no-pad-dialog',
      data: { id: id, action_for: actionFor, name: name },
    });

    dialogRef.afterClosed().subscribe((_res) => {
      if (_res != undefined && _res != null) {
        this._removeLead(_res);
      }
    });
  }

  public clickAccordian(id: number): void {
    this.selectedProgressCons = 0;
    this._closeOthersAccrodian('new_cons_' + id);
    let is_open = true;

    if (this.selectedNewCons != 0 && this.selectedNewCons == id) {
      is_open = false;
    }
    if (is_open) {
      this.selectedNewCons = id;
    } else {
      this.selectedNewCons = 0;
    }
  }

  public showAvaFollowupModal(type: MODAL_TYPE, patient: VirtualConsultViewModel): void {
    this._modalContainerService.openFollowupDialog(type, this._setAvaFollowupObj(patient));
  }

  public openInProgress(id: number): void {
    if (!id) {
      return;
    }

    this.selectedNewCons = 0;
    this._closeOthersAccrodian('prog_cons_' + id);

    if (this.selectedProgressCons === 0 || this.selectedProgressCons !== id) {
      this.selectedProgressCons = id;
    } else {
      this.selectedProgressCons = 0;
      return;
    }
  }

  public openImagePopup(virtualConsult: VirtualConsultViewModel, file: VirtualConsultFileViewModel): void {
    this.dialog.open(ImagePopupModalComponent, {
      data: { files: virtualConsult.selfies, selectedFile: file },
    });
  }

  public openCommunicationModal(leadId: number): void {
    this._modalContainerService.open_dialog(<ModalData>{
      id: leadId,
      isLead: true,
      patientDetailsArea: PatientDetailsArea.Communication,
      communicationType: CommunicationType.Default,
    });
  }

  private _onMessageSent = (model: ChatDetail) => {
    if (!model.isLead) {
      return;
    }

    const newVC = this.newConsPatientsData.find((l) => l.leadId === model.patientId);

    if (newVC) {
      // Move VC from "new" to "in progress"
      this.newConsPatientsData.splice(this.newConsPatientsData.indexOf(newVC), 1);
      this.progressConsPatientsData.unshift(newVC);
      newVC.lastActivityDate = new Date();
    }
  };

  private _loadAll(): void {
    this._subs.sink = this._store$
      .select(LocationStoreSelectors.getSelectedLocationId)
      .pipe(
        filter((id) => !!id && id > 0),
        distinctUntilChanged()
      )
      .subscribe((locationId) => {
        if (!locationId) return;
        this._subs.sink = this._remoteService.get<VirtualConsultViewModel[]>(`VirtualConsult/Leads/${locationId}`).subscribe((response) => {
          this.selectedProgressCons = 0;
          this.selectedNewCons = 0;
          this._closeAllOpeneedAccrodian();
          this.newConsPatientsData = [];
          this.progressConsPatientsData = [];

          response.forEach((vc) => {
            if (vc.leadStatusId === LeadStatusEnum.newLead) {
              this.newConsPatientsData.push(vc);
            } else if (vc.leadStatusId === LeadStatusEnum.inProgress) {
              this.progressConsPatientsData.push(vc);
            }
          });
        });
      });
  }

  private _setAvaFollowupObj(obj: VirtualConsultViewModel): FollowUpModalData {
    return new FollowUpModalData({
      id: obj.leadId,
      isLead: true,
      followUpId: null,
      followUpCategoryId: null,
    });
  }

  private _removeLead(id: number): boolean {
    if (id == undefined) {
      return false;
    }
    this._remoteService.postData(`VirtualConsult/RemoveLead/${id}`, {}).then((_res) => {
      const res = JSON.parse(JSON.stringify(_res));

      if (res.status) {
        this.selectedProgressCons = 0;
        for (let i = 0; i < this.progressConsPatientsData.length; i++) {
          if (this.progressConsPatientsData[i].leadId === id) {
            this.progressConsPatientsData.splice(i, 1);
            break;
          }
        }

        for (let i = 0; i < this.newConsPatientsData.length; i++) {
          if (this.newConsPatientsData[i].leadId === id) {
            this.newConsPatientsData.splice(i, 1);
            break;
          }
        }
      }
    });
  }

  private _closeAllOpeneedAccrodian(): void {
    const accordian = document.getElementsByClassName('details');
    for (let i = 0; i < accordian.length; i++) {
      if (accordian[i].hasAttribute('open')) {
        accordian[i].removeAttribute('open');
      }
    }
  }

  private _closeOthersAccrodian(accrodian_id): void {
    if (accrodian_id == undefined) {
      return;
    }

    const accordian = document.getElementsByClassName('details');

    for (let i = 0; i < accordian.length; i++) {
      const accorId = accordian[i].getAttribute('id');
      if (accorId != accrodian_id && accordian[i].hasAttribute('open')) {
        accordian[i].removeAttribute('open');
      }
    }
  }
}
