/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from '@ngrx/store';
import { getSelectedBrandId, selectAllBrands } from './brands-store/selectors';
import { selectAllContractTemplates } from './contract-templates-store/selectors';
import { LocationStoreSelectors } from './location-store';
import { selectAllLocations, selectLocationIds } from './location-store/selectors';
import { selectAllUsers, selectLoggedUser, selectLoggedUserId, selectUserById } from './user-store/selectors';

//All Errors
export const selectError = createSelector(LocationStoreSelectors.selectLocationsError, (locationError) => {
  return locationError;
});

//All IsLoading
export const selectIsLoading = createSelector(LocationStoreSelectors.selectLocationsIsLoading, (locationLoading: boolean) => {
  return locationLoading;
});

// Cross Feature-Store Selectors
// Place selectors here that pull from multiple feature stores to help avoid any circular dependancy issues

/**
 * Get an array of Locations for a user based upon linked locations an access level
 * @param teamMemberId
 * @returns Selector of LocationStoreEntity[]
 */
export const getLocationsForUser = (teamMemberId: number) =>
  createSelector(
    getLocationIdsForUser(teamMemberId),
    selectAllLocations,
    (ids, locations) => locations && locations.filter((l) => ids.includes(l.id))
  );

/**
 * Get an array of all locations available to the currently logged in user across all brands
 */
export const selectLocationsForActiveUser = createSelector(selectLoggedUser, selectAllLocations, (user, locations) => {
  if (!user || !locations) return [];

  if (user.accessLevel == 'CustomerAdmin' || user.accessLevel == 'Administrator') return locations;

  const locationIds = user.locationIds.split(',').map((id) => Number(id));
  return locations.filter((l) => locationIds.includes(l.id));
});

/**
 * Get an array of Brands where the user has a linked location within the brand (empty brands are filtered out)
 * @param teamMemberId
 * @returns Selector of BrandStoreEntity[]
 */
export const selectNonEmptyBrandsForUser = (teamMemberId: number) =>
  createSelector(selectAllBrands, getLocationsForUser(teamMemberId), (brands, userLocations) => {
    // `Set` here reduces brandIds to distinct Ids
    const brandIds = new Set(userLocations.map((l) => l.brandId));
    return brands.filter((b) => brandIds.has(b.brandId));
  });

/**
 * Get an array of Brands where the brand contains at least one location filtered based upon logged user
 */
export const selectNonEmptyBrandsForActiveUser = createSelector(
  selectAllBrands,
  selectAllUsers,
  selectAllLocations,
  selectLoggedUserId,
  (brands, users, locations, userId) => {
    const user = users && users.find((u) => u.id == userId);
    if (!user || !locations) return [];

    const locationIds = user.locationIds.split(',').map((id) => Number(id));
    const brandIds = locations.filter((l) => locationIds.includes(l.id)).map((l) => l.brandId);
    return brands && brands.filter((b) => brandIds.includes(b.brandId));
  }
);

/**
 * Get the locations for the currently active Brand filtered by the users access level and linked locations
 * @param teamMemberId
 * @returns Selector of LocationStoreEntity[]
 */
export const getActiveBrandLocationsForUser = (teamMemberId: number) =>
  createSelector(
    getLocationsForUser(teamMemberId),
    getSelectedBrandId,
    (locations, brandId) => locations && locations.filter((l) => l.brandId == brandId)
  );

/**
 * Get an array of locationIds available to the user
 * @param teamMemberId
 * @returns Selector of number
 */
export const getLocationIdsForUser = (teamMemberId: number) =>
  createSelector(selectUserById(teamMemberId), selectLocationIds, (user, locationIds) => {
    if (user.accessLevel != 'CustomerAdmin' && user.accessLevel != 'Administrator')
      return user && user.locationIds && user.locationIds.split(',').map((id) => Number(id));
    else return locationIds as number[];
  });

/**
 * Get an array of brandIds for the user
 * @param teamMemberId
 * @returns Selector of number[]
 */
export const getNonEmptyBrandIdsForUser = (teamMemberId: number) =>
  createSelector(selectUserById(teamMemberId), selectAllLocations, (user, locations) => {
    let userLocationIds: number[];
    if (user.accessLevel != 'CustomerAdmin' && user.accessLevel != 'Administrator') {
      //Non-admins have their locations filter down
      userLocationIds = user && user.locationIds && user.locationIds.split(',').map((id) => Number(id));
    } else {
      //admins will get all brandIds that have greater than zero locations
      userLocationIds = [...new Set(locations.map((l) => l.brandId))];
    }
    const userBrandIds = new Set(locations.filter((l) => userLocationIds.includes(l.id)).map((l) => l.brandId));
    return [...userBrandIds];
  });

/**
 * Select all ContractTemplates limiting to those of the currently active Brand
 */
export const selectContractTemplatesForActiveBrand = createSelector(
  getSelectedBrandId,
  selectAllContractTemplates,
  (brandId, contractTemplates) => contractTemplates && contractTemplates.filter((x) => x.brandId == brandId)
);
