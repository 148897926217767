import { Action, createReducer, on } from '@ngrx/store';
import { AuthStoreActions } from '../auth-store';
import * as FollowUpCategoryStoreActions from './actions';
import { State, featureAdapter, initialState } from './state';

const reducer = createReducer(
  initialState,
  //LOAD
  on(FollowUpCategoryStoreActions.LoadRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(FollowUpCategoryStoreActions.LoadSuccess, (state, action) => {
    return featureAdapter.upsertMany(action.followUpCategories, featureAdapter.removeAll({ ...state, isLoading: false }));
  }),
  on(FollowUpCategoryStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //LOGOUT
  on(AuthStoreActions.Logout, (state) => featureAdapter.removeAll(state))
);

export function featureReducer(state: State | undefined, action: Action): State {
  return reducer(state, action);
}
