import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  constructor(private _router: Router, private _http: HttpClient) {}
  username = new FormControl('', [Validators.required]);

  error_msg: string = '';

  getUsernameMessage(): string {
    return this.username.hasError('required') ? 'Email is required' : '';
  }

  backToLogin(): void {
    this._router.navigate(['login']);
  }

  sendResetLink(): void {
    if (this.username.value != '')
      this._http
        .post(`${environment.ServerPath}api/ForgotPassword/RequestReset/`, { username: this.username.value })
        .pipe(take(1))
        .subscribe(
          () => {
            this._router.navigate(['forgot-password-success-msg']);
          },
          (response: HttpErrorResponse) => (this.error_msg = response.error.message)
        );
  }
}
