/* eslint-disable */
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css'],
})
export class AlertDialogComponent {
  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) message: string) {
    this.message = message;
  }
}
