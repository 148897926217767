/* eslint-disable */
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LogCallService {
    private subject = new Subject<any>();

    public logCallButtonClicked(): void {
        this.subject.next();
    }

    public getLogCallButtonClick(): Observable<any> {
        return this.subject.asObservable();
    }
}
