/* eslint-disable */
import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from './../../services/data.service';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.css'],
})
export class DateSelectorComponent implements OnInit {
  @Input() type: any;
  @Input() calenderSequence: any;
  @Input() startDate: any;
  @Input() endDate: any;
  @Output() SelectedDate = new EventEmitter();

  weeks: any[] = [];
  currentMonth: any;
  dateSelected: any;
  dd_id: any;

  userseldate: any;

  showCalender: boolean = false;
  subscription: Subscription;

  startRange: any;
  endRange: any;
  otherStartRange: any;
  calenderPos = 'bottom';
  constructor(private dataService: DataService) {
    this.currentMonth = new Date();
  }

  ngOnInit() {
    this.userseldate = this.startDate;
    this.generateCalendar();
    this.subscription = this.dataService.getMessage<{ dd_id; calenderSequence; dateSelected }>().subscribe((message) => {
      if (message.event == 'dropdownstate') {
        if (this.dd_id != message.data.dd_id) {
          this.showCalender = false;
        }
      }

      if (message.event == 'calenderevent' && this.type == 'Between') {
        if (message.data.calenderSequence == 'start') {
          this.startRange = message.data.dateSelected;
        } else if (message.data.calenderSequence == 'end') {
          this.endRange = message.data.dateSelected;
        }
      }

      if (message.event == 'calenderFilter') {
        this.userseldate = new Date();
        this.weeks = [];
        this.startRange = null;
        this.endRange = null;
        this.dateSelected = null;
        this.generateCalendar();
        this.checkFilter();
      }
    });
  }

  closeCalender() {
    this.showCalender = false;
  }

  date_selected() {
    this.userseldate = this.dateSelected.date;
    this.SelectedDate.emit(this.formatDateString(this.userseldate));
    this.showCalender = false;
  }

  generateCalendar() {
    this.dd_id = +new Date() + (Math.floor(Math.random() * 1000) + 1);

    let dynamic_dt = new Date(this.currentMonth);
    let firstDay = new Date(dynamic_dt.getFullYear(), dynamic_dt.getMonth(), 1);
    let lastDay = new Date(dynamic_dt.getFullYear(), dynamic_dt.getMonth() + 1, 0);

    let numOfDays: any = lastDay.getDate() - firstDay.getDate();
    let numOfWeek: any = Math.ceil(numOfDays / 7);

    let week = [];

    let _startDate = new Date(firstDay);
    let start_weekGrid = 0;
    let firstDay_WeekGrid = start_weekGrid - _startDate.getDay();

    if (firstDay_WeekGrid < 0) {
      for (let j = 0; j < Math.abs(firstDay_WeekGrid); j++) {
        week.push({ weekdate: null, selected: false, invalid: true, disable: true });
      }
    }

    for (let i = 0; i <= numOfDays; i++) {
      let startDate_week = new Date(firstDay);
      startDate_week.setDate(firstDay.getDate() + i);
      let disable = false;
      if (this.startDate && this.startDate != '') {
        let sDt = new Date(this.startDate);
        if (this.formatDateString(sDt) > this.formatDateString(startDate_week)) {
          disable = true;
        }
      }
      if (this.endDate && this.endDate != '') {
        let eDt = new Date(this.endDate);
        if (this.formatDateString(eDt) < this.formatDateString(startDate_week)) {
          disable = true;
        }
      }
      week.push({ weekdate: startDate_week, selected: false, invalid: false, disable: disable });

      let day_of_month = startDate_week.getDay() + 1;

      if (day_of_month % 7 == 0) {
        this.weeks.push(week);
        week = [];
      }

      if (i == numOfDays) {
        this.weeks.push(week);
        week = [];
      }
    }
  }
  formatDateObj(dateObj) {
    let _y = dateObj.getFullYear();
    let _m = dateObj.getMonth() + 1 ? dateObj.getMonth() + 1 : dateObj.getMonth() + 1;
    //_m = _m > 9 ? _m : '0'+ _m;
    let _d = dateObj.getDate();
    //_d = _d > 9 ? _d : '0'+ _d;
    console.log(_y, _m, _d);
    return new Date(_y, _m, _d, 0, 0, 0);
  }
  formatDateString(dateObj) {
    let _y = dateObj.getFullYear();
    let _m = dateObj.getMonth() + 1 ? dateObj.getMonth() + 1 : dateObj.getMonth() + 1;
    _m = _m > 9 ? _m : '0' + _m;
    let _d = dateObj.getDate();
    _d = _d > 9 ? _d : '0' + _d;
    return _y + '-' + _m + '-' + _d;
  }
  gotoAnotherMonth(dir) {
    if (dir == 'prev') {
      this.currentMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() - 1, 1);
    } else {
      this.currentMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + 1, 1);
    }

    this.weeks = [];
    this.generateCalendar();
    this.checkFilter();
  }

  toggleCalender(e) {
    this.showCalender = this.showCalender == true ? false : true;
    if (this.showCalender) {
      let window_height = window.innerHeight;
      let dialog_height = window_height * (95 / 100);
      let available_space = dialog_height - e.y;
      if (e.x <= 480) {
        if (available_space >= 250) {
          this.calenderPos = 'bottom';
        } else {
          this.calenderPos = 'top';
        }
      } else if (e.x <= 768) {
        if (available_space >= 350) {
          this.calenderPos = 'bottom';
        } else {
          this.calenderPos = 'top';
        }
      } else {
        if (available_space >= 400) {
          this.calenderPos = 'bottom';
        } else {
          this.calenderPos = 'top';
        }
      }
    }
    this.checkFilter();
    this.dataService.sendMessage('dropdownstate', { dd_id: this.dd_id, state: this.showCalender });
    console.log(e);
  }

  onClickedOutside(evt) {
    this.showCalender = false;
  }

  selectedDateClick(d, weekindx, dayIndx) {
    if (d != null) {
      if (this.dateSelected) {
        let _weekindx: any = this.dateSelected.weekindx;
        let _dayIndx: any = this.dateSelected.dayIndx;
        this.weeks[_weekindx][_dayIndx]['selected'] = false;
      }

      this.weeks[weekindx][dayIndx]['selected'] = true;
      this.dateSelected = { date: d, weekindx: weekindx, dayIndx: dayIndx };
      this.dataService.sendMessage('calenderevent', { calenderSequence: this.calenderSequence, dateSelected: this.dateSelected });
      this.checkFilter();
    }
  }

  checkFilter() {
    if (this.dateSelected) {
      for (let i = 0; i < this.weeks.length; i++) {
        for (let j = 0; j < this.weeks[i].length; j++) {
          let weekdate = this.weeks[i][j].weekdate;
          if (weekdate != null) {
            if (this.type == 'After') {
              if (this.endRange) {
                this.weeks[this.endRange.weekindx][this.endRange.dayIndx].selected = false;
              }

              if (new Date(weekdate.toDateString()) < new Date(this.dateSelected.date.toDateString())) {
                this.weeks[i][j].invalid = true;
              } else {
                this.weeks[i][j].invalid = false;
              }
            } else if (this.type == 'Before') {
              if (this.endRange) {
                this.weeks[this.endRange.weekindx][this.endRange.dayIndx].selected = false;
              }

              if (new Date(weekdate.toDateString()) > new Date(this.dateSelected.date.toDateString())) {
                this.weeks[i][j].invalid = true;
              } else {
                this.weeks[i][j].invalid = false;
              }
            } else if (this.type == 'Between') {
              console.log(this.startRange, this.endRange, this.otherStartRange);

              if (this.startRange && this.endRange) {
                this.weeks[i][j].selected = false;

                if (
                  new Date(weekdate.toDateString()) >= new Date(this.startRange.date.toDateString()) &&
                  new Date(weekdate.toDateString()) <= new Date(this.endRange.date.toDateString())
                ) {
                  this.weeks[i][j].invalid = false;

                  let _weekDate = new Date(weekdate);
                  let _startRange = new Date(this.startRange.date);
                  let _endRange = new Date(this.endRange.date);

                  if (
                    _weekDate.getDate() == _startRange.getDate() &&
                    _weekDate.getMonth() == _startRange.getMonth() &&
                    _weekDate.getFullYear() == _startRange.getFullYear()
                  ) {
                    this.weeks[i][j].selected = true;
                  } else if (
                    _weekDate.getDate() == _endRange.getDate() &&
                    _weekDate.getMonth() == _endRange.getMonth() &&
                    _weekDate.getFullYear() == _endRange.getFullYear()
                  ) {
                    this.weeks[i][j].selected = true;
                  }
                } else {
                  this.weeks[i][j].invalid = true;
                }
              }
            }
          }
        }
      }
    }
  }

  checkBetweenDates(weekdate, dayindx, dir) {
    let applyBorderRadius: boolean = false;
    let endBorderRadius: boolean = false;

    if (weekdate != null && this.type == 'Between') {
      if (
        new Date(weekdate.toDateString()) >= new Date(this.startRange.date.toDateString()) &&
        new Date(weekdate.toDateString()) <= new Date(this.endRange.date.toDateString())
      ) {
        let _weekDate = new Date(weekdate);
        let _startRange = new Date(this.startRange.date);
        let _endRange = new Date(this.endRange.date);

        if (dir == 'start') {
          if (
            _weekDate.getDate() == _startRange.getDate() &&
            _weekDate.getMonth() == _startRange.getMonth() &&
            _weekDate.getFullYear() == _startRange.getFullYear()
          ) {
            applyBorderRadius = true;
          } else if (dayindx == 0) {
            applyBorderRadius = true;
          } else {
            applyBorderRadius = false;
          }

          return applyBorderRadius;
        } else {
          if (
            _weekDate.getDate() == _endRange.getDate() &&
            _weekDate.getMonth() == _endRange.getMonth() &&
            _weekDate.getFullYear() == _endRange.getFullYear()
          ) {
            endBorderRadius = true;
          } else if (dayindx == 6) {
            endBorderRadius = true;
          } else {
            endBorderRadius = false;
          }

          return endBorderRadius;
        }
      }
    }
  }

  showGreyBg(dt, weekIndx, dayIndx, dir) {
    let _hidebg: boolean = false;

    if (dt != null) {
      if (dir == 'start') {
        let weekdt = new Date(dt);
        let startRange_dt = new Date(this.startRange.date);
        let endRange_dt = new Date(this.endRange.date);

        if (
          weekdt.getDate() == startRange_dt.getDate() &&
          weekdt.getMonth() == startRange_dt.getMonth() &&
          weekdt.getFullYear() == startRange_dt.getFullYear() &&
          this.startRange.dayIndx == dayIndx &&
          this.startRange.weekindx == weekIndx &&
          dayIndx == 6
        ) {
          _hidebg = true;
        } else {
          _hidebg = false;
        }
      } else {
        if (this.endRange.dayIndx == dayIndx && this.endRange.weekindx == weekIndx && dayIndx == 0) {
          _hidebg = true;
        } else {
          _hidebg = false;
        }
      }
    }

    return _hidebg;
  }

  getDateSuffix() {
    let suffix = 'th',
      day = formatDate(this.dateSelected.date, 'dd', 'en-US');
    if (day === '01' || day === '21' || day === '31') {
      suffix = 'st';
    } else if (day === '02' || day === '22') {
      suffix = 'nd';
    } else if (day === '03' || day === '23') {
      suffix = 'rd';
    }

    return suffix;
  }
}
