import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, switchMap } from 'rxjs/operators';
import { AVA_DOMAIN, AvaDomain } from 'src/app/core/ava-domain.provider';
import { LocationStoreSelectors, RootStoreState } from 'src/app/root-store';
import { AutomationsClient, NewLeadDto, NewPatientAppointmentDto } from 'src/app/services/api.service';
import { SubSink } from 'subsink';
import { ServiceProvider } from '../../service';
import { DialogVCWins, IDialogVCWinsData } from '../modal/dialog-vc-wins/dialog-vc-wins';

@Component({
  selector: 'app-automations',
  templateUrl: './automations.component.html',
  styleUrls: ['./automations.component.css'],
})
export class AutomationsComponent implements OnInit, OnDestroy {
  public leaddata: NewLeadDto[] = [];
  public npsdata: NewPatientAppointmentDto[] = [];
  private _subs = new SubSink();
  private _nptdata: unknown[] = [];
  public get isAvaGP(): boolean {
    return this._avaDomain == 'AVAGP';
  }

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _remoteService: ServiceProvider,
    private _store$: Store<RootStoreState.State>,
    private _automationsClient: AutomationsClient,
    @Inject(AVA_DOMAIN) private _avaDomain: AvaDomain
  ) {}

  ngOnInit(): void {
    this._subs.sink = this._store$
      .select(LocationStoreSelectors.getSelectedLocationId)
      .pipe(
        filter((id) => id != null && id != undefined),
        switchMap((locationId) => this._automationsClient.automations_Past90days(locationId, null, null))
      )
      .subscribe((res) => {
        if (res.status) {
          const currentDate = new Date();
          const startDate = new Date(currentDate.setDate(currentDate.getDate() - 90));
          this.leaddata = res.lead.filter((x) => new Date(x.createDate) >= startDate);
          this.npsdata = (res.item && res.item.filter((x) => new Date(x.createDate) >= startDate)) || [];
        }
      });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public isCurrentUrl(curUrl: string): boolean {
    if ('/dashboard/automations' + curUrl == this._router.url) {
      return true;
    }

    return false;
  }

  public gotoURL(url: string): void {
    this._router.navigate(['/dashboard/automations/' + url]);
  }

  public showWins(section: string): void {
    let title = '';
    let patient = [];

    if (section === 'tracker') {
      title = 'New Patients';
      patient = this._nptdata;
    } else if (section === 'vc') {
      title = 'Virtual Consults Submitted';
      patient = this.leaddata;
    } else if (section === 'nps') {
      title = 'New Patients Scheduled Online';
      patient = this.npsdata;
    }

    this.dialog.open<DialogVCWins, IDialogVCWinsData>(DialogVCWins, {
      backdropClass: 'whitebackdrop',
      panelClass: 'no-pad-dialog',
      data: { modaldata: patient, modalname: title },
    });
  }
}
