import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'dialog-data-example-dialog',
    templateUrl: './dialog-data-example-dialog.html',
    styleUrls: ['./dialog-data-example-dialog.css'],
})
export class DialogDataExampleDialog {
  constructor(private _dialogRef: MatDialogRef<DialogDataExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  
  public removePatient(id: number): void {
      this._dialogRef.close(id);
    }
}