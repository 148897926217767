import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataService {
  private _subject = new Subject<DataServiceEvent>();
  private _subjectForAvaModalClose = new Subject<DataServiceEvent>();
  private _subjectForCommunicationModalClose = new Subject<DataServiceEvent>();
  private _resumeAutoSequenceSubject = new Subject<number>();

  sendMessage(event: string, message: unknown): void {
    this._subject.next({ event: event, data: message });
  }

  sendMessageForAvaModalClose(event: string, message: unknown): void {
    this._subjectForAvaModalClose.next({ event: event, data: message });
  }

  sendMessageForCommunicationModalClose(event: string, message: unknown): void {
    this._subjectForCommunicationModalClose.next({ event: event, data: message });
  }

  clearMessage(): void {
    this._subject.next();
  }

  getMessage<T>(): Observable<DataServiceEvent<T>> {
    return this._subject.asObservable();
  }

  getMessageForAvaModalClose<T>(): Observable<DataServiceEvent<T>> {
    return this._subjectForAvaModalClose.asObservable();
  }

  getMessageForCommunicationModalClose<T>(): Observable<DataServiceEvent<T>> {
    return this._subjectForCommunicationModalClose.asObservable();
  }

  public sendAutoSequenceResumedMessage(patientId: number): void {
    this._resumeAutoSequenceSubject.next(patientId);
  }

  public getAutoSequenceResumedMessage(): Observable<number> {
    return this._resumeAutoSequenceSubject.asObservable();
  }

  public clearAutoSequenceResumedMessage(): void {
    this._resumeAutoSequenceSubject.next();
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface DataServiceEvent<T = any> {
  event: string;
  data: T;
}
