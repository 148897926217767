import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { DigitalSignatureComponent } from './digital-signature/digital-signature.component';
import { SignatureDialogComponent } from './signature-dialog/signature-dialog.component';

@NgModule({
  declarations: [DigitalSignatureComponent, SignatureDialogComponent],
  exports: [DigitalSignatureComponent, SignatureDialogComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatButtonModule, MatDialogModule],
  entryComponents: [SignatureDialogComponent],
})
export class DigitalSignatureModule {}
