import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Observable, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { DigitalSignatureComponent } from 'src/app/shared/digital-signature/digital-signature/digital-signature.component';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { DateValidator } from '../../helpers/DateValidator';
import { PhoneNumberValidator } from '../../helpers/PhoneValidator';
import { SsnValidator } from '../../helpers/SSNValidator';
import { UsStates } from '../../helpers/UsStates';

@Component({
  selector: 'app-new-patient-forms',
  templateUrl: './new-patient-forms.component.html',
  styleUrls: ['./new-patient-forms.component.css'],
})
export class NewPatientFormsComponent implements OnDestroy, AfterViewInit {
  ngAfterViewInit(): void {
    this.subs.sink = this.formStepper.selectionChange.subscribe((event: StepperSelectionEvent) => {
      if (event.previouslySelectedIndex != 0)
        //don't save when moving from Pin step
        this.saveForm(event.previouslySelectedStep.stepControl, event.selectedStep == this.formStepper.steps.last);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  customerId: string;
  newPatientTrackId: string;
  patientDetailsSSN: string = '';
  responsiblePartySSN: string = '';
  responsiblePartyAltSSN: string = '';
  settings: PatientFormsSettings;
  subs = new SubSink();
  form: FormGroup;
  stateOptions = UsStates.arr;
  medicalConditions: string[] = [
    'Latex Allergy',
    'Tonsils or Adenoids Removed',
    'Allergies/ Sinus Trouble',
    'Artificial Heart Valves',
    'HIV Positive/ AIDS',
    'TMJ Problems',
    'Osteoporosis',
    'Emotional Problems/ Psychiatric Treatment',
  ];
  medicalStatuses: { name: string; value: string }[] = [
    { name: 'Is the patient pregnant?', value: 'Patient pregnant' },
    { name: 'Has the patient reached puberty', value: 'Reached puberty' },
    { name: 'Thumb, finger, or lip sucking?', value: 'Thumb, finger, or lip sucking' },
    { name: 'Mouth-breathing when awake or asleep?', value: 'Mouth breathing' },
    { name: 'Any injuries to the face, mouth, or teeth?', value: 'Facial injury' },
    { name: 'Any pain or popping when opening mouth?', value: 'Poping jaw' },
    { name: 'Are you aware of an uncomfortable or bad bite?', value: 'Uncomfortable or bad bite' },
    { name: 'Any missing or extra teeth?', value: 'Missing or extra teeth' },
  ];
  requestSignature = new EventEmitter<any>();
  startOverEnabled = false;
  changeEvent = new EventEmitter<any>();
  touchUI = false;
  birthdateStartAt = new Date(Date.now()).getFullYear() - 20;
  phoneNumberRegion = 'US';

  @ViewChild(MatStepper, { static: true }) formStepper: MatStepper;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private _http: HttpClient,
    public snackbar: MatSnackBar,
    public domSanitizer: DomSanitizer,
    private _spinner: SpinnerVisibilityService
  ) {
    this.subs.sink = this._activatedRoute.paramMap.subscribe((params) => {
      this.customerId = params.get('customerId');
      this.newPatientTrackId = params.get('newPatientTrackId');
      this.loadSettings();
    });

    this.form = this._fb.group({
      pin: [null, [Validators.required, Validators.minLength(4)]],
      version: ['1.0'], //Not a functional property but should be updated with each major change to forms to perhaps help with backwards compatability of stored forms
      basicInfo: this._fb.group({
        patientFirstName: [null, Validators.required],
        patientLastName: [null, Validators.required],
        patientBirthdate: [null, Validators.required],
        gender: [null, Validators.required],
      }),
      patientDetails: this._fb.group({
        street: [null, Validators.required],
        cellPhone: [null, PhoneNumberValidator(this.phoneNumberRegion)],
        city: [null, Validators.required],
        workPhone: [null, PhoneNumberValidator(this.phoneNumberRegion)],
        state: [null, Validators.required],
        email: [null],
        zipcode: [null, Validators.required],
        employer: [null],
        ssn: [null, SsnValidator],
      }),
      currentDentist: this._fb.group({
        dentistName: [null],
        lastCleaning: [null],
        officeName: [null],
        referralName: [null],
      }),
      responsibleParty: this._fb.group({
        hasResponsible: [null],
        firstName: [null],
        lastName: [null],
        birthdate: [null, DateValidator.Date],
        ssn: [null, SsnValidator],
        relationship: [null],
        maritalStatus: [null],
        street: [null],
        cellPhone: [null, PhoneNumberValidator(this.phoneNumberRegion)],
        city: [null],
        workPhone: [null, PhoneNumberValidator(this.phoneNumberRegion)],
        state: [null],
        email: [null],
        zipcode: [null],
        employer: [null],
        hasOtherFamily: [null],
        emergencyContact: [false],
        otherFamily: [null],
      }),
      responsiblePartyAlt: this._fb.group({
        hasResponsibleAlt: [null],
        firstName: [null],
        lastName: [null],
        birthdate: [null, DateValidator.Date],
        ssn: [null, SsnValidator],
        relationship: [null],
        maritalStatus: [null],
        street: [null],
        cellPhone: [null, PhoneNumberValidator(this.phoneNumberRegion)],
        city: [null],
        workPhone: [null, PhoneNumberValidator(this.phoneNumberRegion)],
        state: [null],
        email: [null],
        zipcode: [null],
        employer: [null],
        emergencyContact: [false],
      }),
      insurance: this._fb.group({
        hasInsurance: [null],
        policyHolder: [null],
        insuranceCompanyName: [null],
        policyId: [null],
        policyGroupId: [null],
        insuranceCompanyPhone: [null, PhoneNumberValidator(this.phoneNumberRegion)],
        cardFront: [null],
        cardBack: [null],
      }),
      insuranceAlt: this._fb.group({
        hasInsuranceAlt: [null],
        policyHolder: [null],
        insuranceCompanyName: [null],
        policyId: [null],
        policyGroupId: [null],
        insuranceCompanyPhone: [null, PhoneNumberValidator(this.phoneNumberRegion)],
        cardFront: [null],
        cardBack: [null],
      }),
      medical: this._fb.group({
        conditions: [null],
        statuses: [null],
        medications: [null],
        otherConditions: [null],
        drugAllergies: [null],
        osteoporosisMedication: [false],
      }),
      outsideConsult: this._fb.group({
        receivingConsult: [false, Validators.required],
        treatmentPurpose: [null],
      }),
      agreement1: [false, Validators.requiredTrue],
      agreement2: [false, Validators.requiredTrue],
      agreement3: [false, Validators.requiredTrue],
      protectedRelease: this._fb.group({
        additionalContact: [null],
        additionalContactRelationship: [null],
        additionalContactPhone: [null, PhoneNumberValidator(this.phoneNumberRegion)],
        photoConsent: [true, Validators.required],
      }),
      selfie: this._fb.group({
        image: [null],
      }),
      consent: this._fb.group({
        agreement: [null, Validators.requiredTrue],
        signature: [null, Validators.required],
        signatureImage: [null],
      }),
    });
  }

  datePickerFilter(date: Date | null): boolean {
    if (!date) {
      return false;
    }
    const dateMs = date.getTime();
    const noowMs = Date.now();

    // Returns true if date is before today but after 100 years ago
    return dateMs < noowMs && dateMs > noowMs - 3155760000000;
  }

  loadSettings(): void {
    this.subs.sink = this._http
      .get<PatientFormsSettings>(`${environment.ServerPath}api/public/npt/${this.customerId}/${this.newPatientTrackId}/forms/settings`)
      .subscribe((response) => {
        this.settings = response;
      });
  }

  //Check pin against any existing form data that has been entered and populate if correct, present reset if not, or simply begin the new form
  verifyPin(): void {
    if (!this.form.get('pin').value) {
      this.snackbar.open('Please enter a pin before continuing', 'OK', { duration: 3000 });
      return;
    }

    this._spinner.show();
    this.subs.sink = this._http
      .post(
        `${environment.ServerPath}api/public/NPT/${this.customerId}/${this.newPatientTrackId}/forms`,
        { pin: this.form.get('pin').value },
        { observe: 'response' }
      )
      .subscribe(
        (response) => {
          if (response.status != 204) {
            //If no content then it means this is the first time starting forms, otherwise update form with stored values
            this.form.patchValue(response.body);
          }
          this.formStepper.next();
          this._spinner.hide();
        },
        (err: HttpErrorResponse) => {
          this._spinner.hide();
          if (err.status == 400) {
            this.snackbar.open(
              'The pin you entered does not match the pin previously entered. You may try again or start over with new forms.',
              'Ok',
              { duration: 10000 }
            );
            this.startOverEnabled = true;
          } else if (err.status == 409) {
            this.snackbar.open('Your forms appear to have already been submitted.', 'OK', { duration: 10000 });
          } else {
            this.snackbar.open(
              'Sorry, there appears to be an issue with contact the server at the moment. Please contact the office for further assistance.',
              'Ok',
              { duration: 10000 }
            );
          }
        }
      );
  }

  //emit on the event to get the image data from signiture component
  signatureCapture(signatureEvent: string): void {
    this.form.get('consent').patchValue({ signatureImage: signatureEvent });
  }

  isNullOrEmpty(value: string): boolean {
    return !value || value == undefined || value == '' || value.length == 0;
  }

  //save form to S3
  saveForm(control, final: boolean = false): void {
    //control will be a FormControlLike but can't be strongly typed because of Angular Material
    if ((control && control.dirty) || !control || final) {
      if (!this.isNullOrEmpty(this.form.value.patientDetails.ssn)) {
        this.patientDetailsSSN = this.form.value.patientDetails.ssn;
      }
      if (!this.isNullOrEmpty(this.form.value.responsibleParty.ssn)) {
        this.responsiblePartySSN = this.form.value.responsibleParty.ssn;
      }
      if (!this.isNullOrEmpty(this.form.value.responsiblePartyAlt.ssn)) {
        this.responsiblePartyAltSSN = this.form.value.responsiblePartyAlt.ssn;
      }
      if (!final) {
        const request = this.form.value;
        //strip out SSNs on temporary saves
        request.patientDetails.ssn = '';
        request.responsibleParty.ssn = '';
        request.responsiblePartyAlt.ssn = '';
      } else {
        this.form.value.patientDetails.ssn = this.patientDetailsSSN;
        this.form.value.responsibleParty.ssn = this.responsiblePartySSN;
        this.form.value.responsiblePartyAlt.ssn = this.responsiblePartyAltSSN;
      }
      this.subs.sink = this._http
        .put(
          `${environment.ServerPath}api/public/NPT/${this.customerId}/${this.newPatientTrackId}/forms${final ? '?complete=true' : ''}`,
          this.form.value
        )
        .subscribe(() => {
          if (control) this.form.markAsPristine();
          if (final) this.snackbar.open('Form Saved', 'OK', { duration: 1000 });
        });
      if (final) {
        //set all steps as uneditable so that navigation is stopped on final step
        this.formStepper.steps.forEach((step, ind) => {
          if (ind < this.formStepper.steps.length - 1) step.editable = false;
        });
      }
    }
  }

  @ViewChild('snapshotVideo', { static: true }) snapshotVideo: ElementRef<HTMLVideoElement>;
  snapshotStream: MediaStream;
  startSnapshotCamera(): void {
    if (navigator.mediaDevices.getUserMedia) {
      //check to make sure its supported by this browswer
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: 'user' } })
        .then((stream) => {
          this.form.get('selfie').patchValue({ image: null });
          this.snapshotVideo.nativeElement.srcObject = stream;
          this.snapshotStream = stream;
        })
        .catch(() => {
          this.snackbar.open('There was a problem accessing your camera. Please upload a picture instead.', 'Ok', { duration: 3000 });
        });
    } else {
      this.snackbar.open('Your camera cannot be currently accessed by your browser. Please upload an image instead.', 'Ok', { duration: 3000 });
    }
  }

  stopSnapshotCamera(): void {
    if (this.snapshotStream) {
      this.snapshotStream.getTracks().forEach((t) => t.stop());
    }
    this.snapshotVideo.nativeElement.srcObject = null;
  }

  resetSnapshotCamera(): void {
    this.snapshotVideo.nativeElement.play();
    this.form.get('selfie').patchValue({ image: null });
  }

  takePicture(): void {
    //check if image already taken, reset if true
    if (this.form.get('selfie.image').value) {
      this.resetSnapshotCamera();
      return;
    }
    this.snapshotVideo.nativeElement.pause(); //pause video to emulate taking a picture
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = this.snapshotVideo.nativeElement.videoWidth;
    canvas.height = this.snapshotVideo.nativeElement.videoHeight;
    ctx.drawImage(this.snapshotVideo.nativeElement, 0, 0, canvas.width, canvas.height); //draw video to canvas for generating image
    const imageData = ctx.canvas.toDataURL();

    this.subs.sink = this.resizeImage(imageData, 400, true) //resize and save image into form
      .subscribe((resized) => this.form.get('selfie').patchValue({ image: resized }));
  }

  //takes a base64 encoded image string and resizes it by drawing onto a different sized canvas
  resizeImage(imgData: string, maxWidth: number, forceResize: boolean): Observable<string> {
    const img = new Image();
    img.src = imgData;
    return fromEvent(img, 'load') //wait for image to load data before drawing to canvas from it
      .pipe(
        map(() => {
          //remap observable from onload event to our processed data url
          const canvas = document.createElement('canvas');
          let scaleFactor = maxWidth / img.width;

          const longSide = img.width > img.height ? img.width : img.height;
          if (longSide < maxWidth && !forceResize) scaleFactor = 1; //don't scale if beneath maxWidth and forced rescale is off

          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;

          //draw image onto downsized canvas and get new image data from it
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          return ctx.canvas.toDataURL();
        })
      );
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    //bubble up for url gopher
    if (window.top != window) {
      const e = new KeyboardEvent('keyup', { key: event.key, shiftKey: event.shiftKey, ctrlKey: event.ctrlKey });
      window.top.dispatchEvent(e);
    }
  }

  alertCheckboxNotChecked(checkbox: MatCheckbox): void {
    if (!checkbox.checked) {
      this.snackbar.open(`Check "${checkbox._elementRef.nativeElement.innerText}" to continue.`, 'Ok', { duration: 10000 });
    }
  }

  alertSignatureNotSigned(signature: DigitalSignatureComponent): void {
    if (!signature.value) {
      this.snackbar.open('Signature is required.', 'Ok', { duration: 1000 });
    }
  }

  hasAddendum(): boolean {
    return this.settings.agreementAddendum != null && this.settings.agreementAddendum.trim().length > 0;
  }
}

class PatientFormsSettings {
  logoUrl: string;
  appointmentPolicy: string;
  informedConsent: string;
  orthodonticInsurance: string;
  practiceName: string;
  locationName: string;
  agreementAddendum: string;
}
