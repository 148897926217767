import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
} from '@angular/material';
import { FinancialSharedModule } from 'src/app/shared/financial/financial-shared.module';
import { CaseEditorComponent } from './case-editor/case-editor.component';
import { CaseItemListComponent } from './case-item-list/case-item-list.component';
import { CasePaymentOptionEditorComponent } from './case-payment-option-editor/case-payment-option-editor.component';
import { CasePaymentOptionListComponent } from './case-payment-option-list/case-payment-option-list.component';
import { CasePresentationService } from './case-presentation.service';
import { CasePresenterComponent } from './case-presenter/case-presenter.component';
import { CaseTitleDirective } from './case-title.directive';
import { CaseTreatmentTypeEditorComponent } from './case-treatment-type-editor/case-treatment-type-editor.component';
import { CaseTreatmentTypeListComponent } from './case-treatment-type-list/case-treatment-type-list.component';
import { AvaSliderEditorDialogComponent } from './modal/ava-slider-editor-dialog/ava-slider-editor-dialog.component';
import { CasePresentDialogComponent } from './modal/case-present-dialog/case-present-dialog.component';
import { PaymentOptionEditorDialogComponent } from './modal/payment-option-editor-dialog/payment-option-editor-dialog.component';
import { SendSliderDialogComponent } from './modal/send-slider-dialog/send-slider-dialog.component';
import { TreatmentTypeEditorDialogComponent } from './modal/treatment-type-editor-dialog/treatment-type-editor-dialog.component';

const AngularModules = [
  MatFormFieldModule,
  MatButtonModule,
  MatProgressBarModule,
  MatIconModule,
  MatListModule,
  MatDialogModule,
  MatSlideToggleModule,
  MatInputModule,
  MatCheckboxModule,
  MatSliderModule,
  MatSelectModule,
];

@NgModule({
  declarations: [
    CasePaymentOptionListComponent,
    CaseTreatmentTypeListComponent,
    CaseEditorComponent,
    CasePresenterComponent,
    CaseTitleDirective,
    CaseItemListComponent,
    CaseTreatmentTypeEditorComponent,
    TreatmentTypeEditorDialogComponent,
    PaymentOptionEditorDialogComponent,
    CasePaymentOptionEditorComponent,
    CasePresentDialogComponent,
    AvaSliderEditorDialogComponent,
    SendSliderDialogComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FinancialSharedModule, ...AngularModules],
  exports: [
    CasePaymentOptionListComponent,
    CaseTreatmentTypeListComponent,
    CaseEditorComponent,
    CasePresenterComponent,
    CaseTitleDirective,
    CaseTreatmentTypeEditorComponent,
    CasePaymentOptionEditorComponent,
  ],
  providers: [CasePresentationService],
  entryComponents: [
    TreatmentTypeEditorDialogComponent,
    PaymentOptionEditorDialogComponent,
    CasePresentDialogComponent,
    AvaSliderEditorDialogComponent,
    SendSliderDialogComponent,
  ],
})
export class CasePresentationModule {}
