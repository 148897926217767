import { Injectable } from '@angular/core';
import { AppConfig } from './configuration.service';

@Injectable()
export class FeatureFlagService {
  featureOff(featureName: string): boolean {
    if ((AppConfig.settings != null && AppConfig.settings.features != null) && Object.prototype.hasOwnProperty.call(AppConfig.settings.features, featureName)) {
        return !AppConfig.settings.features[featureName];
    }

    return false;
  }
 
  featureOn(featureName: string): boolean {
    return !this.featureOff(featureName);
  }
}