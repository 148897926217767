import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewPatientAppointment } from 'src/app/models/AvaContext';

@Component({
  selector: 'dialog-booking-details',
  templateUrl: './dialog-booking-details.component.html',
  styleUrls: ['./dialog-booking-details.component.css'],
})

export class DialogBookingDetailsComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: NewPatientAppointment) { }

  isDefaultDate(date: string | Date): boolean {
    if (typeof (date) === 'string') {
      return date.match(/^(.+)T/)[1] === '0001-01-01';
    } else {
      const d = <Date>date;
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
      return d.getTime() === new Date(1, 1, 1).getTime();
    }
  }
}

