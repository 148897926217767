import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, share } from 'rxjs/operators';
import { AutoModal } from '../modal/auto-modal/auto-modal.component';
import { CommunicationType, ModalData, PatientDetailsArea } from '../modal/modal-container/ModalData';
import { PatientDetailsModal } from '../modal/modal-container/modal-container.component';
import { RemindModal } from '../modal/remind-modal/remind-modal.component';
import { SetModal } from '../modal/set-modal/set-modal.component';
import { FollowUpModalData, FollowUpMultiModalData } from '../models/AutoSetRemind';
import { MultiPatientDetailsResponse } from '../models/MultiPatientDetailsResponse';
import { FollowUpLink, GetPatientByIdDto } from './api.service';
import { DataService } from './data.service';
import { PatientService } from './patient.service';

export type MODAL_TYPE = 'auto' | 'set' | 'remind';

@Injectable({
  providedIn: 'root',
})
export class ModalContainerService {
  constructor(
    public dialog: MatDialog,
    private _dataService: DataService,
    private _patientService: PatientService,
    private _activatedRoute: ActivatedRoute
  ) {
    this._activatedRoute.queryParamMap
      .pipe(
        filter((params) => params.has('patientId')),
        map((params) => parseInt(params.get('patientId'))),
        distinctUntilChanged((a, b) => a === b)
      )
      .subscribe((patientId) => {
        if (patientId > 0) {
          this.open_dialog({
            id: patientId,
            isLead: false,
            communicationType: CommunicationType.Default,
            patientDetailsArea: PatientDetailsArea.Details,
          });
        }
      });
  }

  open_dialog(modalData: ModalData): void {
    const width = window.outerWidth < 676 ? '100vw' : undefined;

    const dialogRef = this.dialog.open(PatientDetailsModal, {
      backdropClass: 'whitebackdrop',
      panelClass: 'action-modal',
      data: modalData,
      autoFocus: false,
      disableClose: true,
      width: width,
      maxWidth: width,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._dataService.sendMessageForCommunicationModalClose('modal_closed', '');
      }
    });
  }

  openFollowupDialog(
    name: MODAL_TYPE,
    data: FollowUpModalData | FollowUpMultiModalData,
    forceCategory: boolean = false
  ): Observable<MatDialogRef<unknown>> {
    switch (name.toLowerCase()) {
      case 'auto':
        return this.showModal(data, AutoModal, forceCategory);
      case 'set':
        return this.showModal(data, SetModal, forceCategory);
      case 'remind':
        return this.showModal(data, RemindModal, forceCategory);
      default:
        break;
    }
  }

  showModal(
    data: FollowUpModalData | FollowUpMultiModalData,
    component: Type<AutoModal | SetModal | RemindModal>,
    forceCategory: boolean = false
  ): Observable<MatDialogRef<unknown>> {
    const callback = (res: GetPatientByIdDto | MultiPatientDetailsResponse) => {
      // CONDITION: This modal was opened from an Team Decision card
      if (data.followUpId && 'patient' in res)
        if (forceCategory || (res.patient != null && !res.patient.followUpLink)) {
          res.patient.followUpLink = <FollowUpLink>{ followUpId: null, followUpCategoryId: data.followUpCategoryId }; // Preserve the patient's suggested follow-up
        }

      const autoDialogRef = this.dialog.open(component, {
        backdropClass: 'whitebackdrop',
        panelClass: 'avafollow-up-modal',
        data: res,
        autoFocus: false,
        disableClose: true,
      });
      autoDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this._dataService.sendMessageForAvaModalClose(result, 'modal_closed');
        }
      });
      return autoDialogRef;
    };

    if (data instanceof FollowUpModalData) {
      const obs = this._patientService.getPatientDetails(data.id, data.isLead).pipe(
        map((res) => callback(res)),
        share()
      );
      obs.subscribe();
      return obs;
    } else {
      // Note (Andrew): I don't know why it was written to call this endpoint then basically overwrite the entire thing with data passed to the function
      //  Perhaps at some point this was mean to actually fetch the details of the persons?
      const obs = this._patientService.getPatientMultiDetails(data.ids).pipe(
        map((res) => {
          res.patients = data.patients;
          res.leads = data.leads;
          res.isPatient = data.isPatient;
          res.defaultFollowUpCategoryId = data.followUpCategoryId;
          return callback(res);
        }),
        share()
      );
      obs.subscribe();
      return obs;
    }
  }
}
