import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AvaSliderAcceptedSettings, AvaSliderDto, AvaSliderLaunchViewModel } from 'src/app/services/api.service';

@Component({
  selector: 'app-case-present-dialog',
  templateUrl: './case-present-dialog.component.html',
  styleUrls: ['./case-present-dialog.component.scss'],
})
export class CasePresentDialogComponent {
  __acceptedSettings: AvaSliderAcceptedSettings;

  get __avaSlider(): AvaSliderDto {
    return this._data.casePresentation;
  }
  set __avaSlider(value: AvaSliderDto) {
    this._data.casePresentation = value;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private _data: ICasePresentDialogData) {}
}

export interface ICasePresentDialogData {
  casePresentation: AvaSliderLaunchViewModel;
}
