/* eslint-disable */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ArrayJoinPipe } from './array-join.pipe';
import { GenericFilterPipe } from './generic-filter.pipe';
import { JsonParserPipe } from './jsonParser';
import { OrderByLinkedPipe } from './order-by-category-linked.pipe';
import { PatientPrioritySortPipe } from './patient-priority-sort.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { TimePipe } from './time.pipe';
import { TreatmentTypeJsonConverter } from './treatment-type-json-converter';

@NgModule({
  declarations: [
    PhoneNumberPipe,
    TimePipe,
    PatientPrioritySortPipe,
    GenericFilterPipe,
    OrderByLinkedPipe,
    TreatmentTypeJsonConverter,
    JsonParserPipe,
    ArrayJoinPipe,
  ],
  imports: [CommonModule],
  exports: [
    PhoneNumberPipe,
    TimePipe,
    PatientPrioritySortPipe,
    GenericFilterPipe,
    OrderByLinkedPipe,
    TreatmentTypeJsonConverter,
    JsonParserPipe,
    ArrayJoinPipe,
  ],
})
export class SharedPipesModule {}
