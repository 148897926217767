import { SelectionModel } from '@angular/cdk/collections';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatButton, MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/modal/confirmation-dialog/confirmation-dialog.component';
import { CommunicationType, ModalData, PatientDetailsArea } from 'src/app/modal/modal-container/ModalData';
import { TrackGroup } from 'src/app/models/NewPatientTracker';
import { ServiceProvider } from 'src/app/service';
import { DataService } from 'src/app/services/data.service';
import { ModalContainerService } from 'src/app/services/modal-container.service';
import { SubSink } from 'subsink';
import { isNullOrUndefined } from 'util';
import { AvaFollowUpOptions } from '../../enums/avaFollowUpOptions.enum';

@Component({
  selector: 'app-new-patient-tracker-table',
  templateUrl: './new-patient-tracker-table.component.html',
  styleUrls: ['./new-patient-tracker-table.component.scss'],
})
export class NewPatientTrackerTableComponent implements OnInit, OnDestroy {
  @ViewChild('confirmWelcomeSequenceTemplate', { static: true }) private _confirmWelcomeSequenceTemplate: TemplateRef<unknown>;
  @ViewChild('openFormsDialogTemplate', { static: true }) private _openFormsDialogTemplate: TemplateRef<unknown>;
  @ViewChild('confirmInsuranceTemplate', { static: true }) private _confirmInsuranceTemplate: TemplateRef<unknown>;

  @Input('table') table: {
    name: string;
    dataTable: MatTableDataSource<TrackGroup>;
    selection: SelectionModel<number>;
    isWorking: { [newPatientTrackId: number]: boolean };
  };
  @Input('noShowStatusIds') private _noShowStatusIds: number[];
  @Input('treatmentStatuses') private _treatmentStatuses: { treatmentStatusId: number; name: string }[];
  @Input('remoteTargets') remoteTargets: string[];

  public AvaFollowUpOptions = AvaFollowUpOptions;
  public CommunicationType = CommunicationType;
  public PatientDetailsArea = PatientDetailsArea;
  public columnsToDisplay = [
    'patientName',
    'responsibleName',
    'actions',
    'treatmentStatus',
    'appointment',
    'welcome',
    'forms',
    'insurance',
    'survey',
  ];

  private _patientInfoOverlayRef: OverlayRef;
  private _patientInfoAttachmentRef: MatButton;
  private _followUpPatientTrackId: number;
  private _subsink = new SubSink();

  constructor(
    private _modalContainerService: ModalContainerService,
    private _viewContainerRef: ViewContainerRef,
    private _overlay: Overlay,
    private _dataService: DataService,
    private _dialog: MatDialog,
    private _serviceProvider: ServiceProvider,
    private _snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this._subsink.sink = this._dataService.getMessageForAvaModalClose().subscribe((result) => {
      if (result.event && this._followUpPatientTrackId) {
        const index = this.table.dataTable.data.findIndex((x) => x.track.newPatientTrackId === this._followUpPatientTrackId);
        if (index !== -1) {
          this.table.dataTable.data[index].patient.followUpLinkId = 0;
          this.table.dataTable.data = this.table.dataTable.data.slice();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._subsink.unsubscribe();
  }

  public didPatientAttend(appts: { appointmentId: number; dateTime: Date; appointmentStatusId: number }[]): boolean {
    const appt = appts.sort((a, b) => moment(a.dateTime).diff(b.dateTime))[0];
    return !this._noShowStatusIds.some((x) => x == appt.appointmentStatusId);
  }

  public getResponsibleName(rps: typeof TrackGroup.prototype.responsibleParties): string {
    if (rps.length > 0) {
      const rp = rps.find((r) => r.isPrimary);

      if (isNullOrUndefined(rp))
        //no responsible party signified as primary, return first
        return rps[0].firstName + ' ' + rps[0].lastName;
      return rp.firstName + ' ' + rp.lastName;
    }
    return null; //no responsible party found
  }

  public showPatientDetailsModal(
    PatientId: number,
    patientDetailsArea: PatientDetailsArea,
    communicationType: CommunicationType = CommunicationType.Default
  ): void {
    this._modalContainerService.open_dialog(<ModalData>{
      id: PatientId,
      isLead: false,
      patientDetailsArea,
      communicationType,
    });
  }

  public openPatientContactInfo(template: TemplateRef<unknown>, attachment: MatButton): void {
    if (this._patientInfoAttachmentRef == attachment && this._patientInfoOverlayRef.hasAttached) {
      this._patientInfoOverlayRef.detach();
      this._patientInfoAttachmentRef = null;
      return;
    }

    this._patientInfoAttachmentRef = attachment;
    const templatePortalRef = new TemplatePortal(template, this._viewContainerRef);
    const positionStrategy = this._overlay
      .position()
      .connectedTo(attachment._elementRef, { originX: 'center', originY: 'top' }, { overlayX: 'start', overlayY: 'bottom' });
    this._patientInfoOverlayRef = this._overlay.create({ minWidth: 'fit-content', positionStrategy: positionStrategy, hasBackdrop: false });
    this._patientInfoOverlayRef.attach(templatePortalRef);
  }

  public getTreatmentStatus(treatmentStatusId: number): string {
    const ind = this._treatmentStatuses.findIndex((ts) => ts.treatmentStatusId == treatmentStatusId);
    return ind != -1 ? this._treatmentStatuses[ind].name : null;
  }

  private _getTrackAppointment(group: TrackGroup): typeof TrackGroup.prototype.appointments[0] {
    if (group.appointments.length > 0) {
      const appointment = group.appointments.find((app) => app.appointmentId == group.track.appointmentId);
      return appointment;
    }
    return null;
  }

  public getTrackAppointmentDisplay(group: TrackGroup): string {
    const appointment = this._getTrackAppointment(group);
    if (appointment) return moment(appointment.dateTime).format('L');
    return '';
  }

  public showAvaFollowUpModal(option: AvaFollowUpOptions, group: TrackGroup): void {
    this._followUpPatientTrackId = group.track.newPatientTrackId;
    this._modalContainerService.openFollowupDialog(option, {
      id: group.patient.patientId,
      isLead: group.patient.isLead,
      followUpId: null,
      followUpCategoryId: null,
    });
  }

  public addPatientsToWelcomeSequence(selection: number[]): void {
    const dia = this._dialog.open(this._confirmWelcomeSequenceTemplate, { panelClass: 'ava-dialog' });

    this._subsink.sink = dia.afterClosed().subscribe((result) => {
      if (result) {
        selection.forEach((newPatientTrackId) => {
          this.table.isWorking[newPatientTrackId] = true;
          this._subsink.sink = this._serviceProvider.post<unknown>(`NewPatientTracker/EnrollPatientInWelcome/${newPatientTrackId}`).subscribe(
            () => {
              const ind = this.table.dataTable.data.findIndex((t) => t.track.newPatientTrackId == newPatientTrackId);
              this.table.dataTable.data[ind].track.welcomeSequenceStarted = true;
              this.table.isWorking[newPatientTrackId] = false;
            },
            (response: HttpErrorResponse) => {
              this._snackbar.open(`Error: ${response.error.message}`, 'OK', { duration: 5000, panelClass: 'red-snackbar' });
              this.table.isWorking[newPatientTrackId] = false;
            }
          );
        });
      }
    });
  }

  public removePatient(group: TrackGroup): void {
    const confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'confirmation-dialog-component',
      width: '358px',
      height: '218px',
      data: {
        text: `Are you sure you want to remove ${group.patient.firstName} ${group.patient.lastName}?`,
        buttonConfirmText: 'Confirm',
      },
    });

    confirmationDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._serviceProvider.put(`NewPatientTracker/DeactivatePatientTrack/${group.track.newPatientTrackId}`).subscribe(() => {
          const index = this.table.dataTable.data.findIndex((x) => x.track.newPatientTrackId === group.track.newPatientTrackId);
          if (index !== -1) {
            this.table.dataTable.data = this.table.dataTable.data.splice(index, 1);
          }
        });
      }
    });
  }

  private _sendLinkToRemote(target: string, newPatientTrackId: number) {
    this._subsink.sink = this._serviceProvider.post(`NewPatientTracker/SendFormsToGopher/${newPatientTrackId}`, { target }).subscribe(
      () => this._snackbar.open(`Forms sent to ${target}`, 'Ok', { duration: 3000 }),
      () => this._snackbar.open('There was a problem sending the forms to the remote system', 'Ok', { duration: 5000 })
    );
  }

  public openFormsDialog(track: TrackGroup): void {
    const dialog = this._dialog.open(this._openFormsDialogTemplate, { panelClass: 'ava-dialog' });

    this._subsink.sink = dialog.afterClosed().subscribe((result: { location: string; target: string }) => {
      if (result) {
        if (result.location == 'local') window.open(track.track.formsUrl, '_blank');
        else if (result.location == 'remote') this._sendLinkToRemote(result.target, track.track.newPatientTrackId);
      }
    });
  }

  public openFormsPdf(group: TrackGroup): void {
    // If forms are not done through Ava then we cannot display completed forms
    if (!group.track.isAvaForms) return;

    const formsUrl = `NewPatientTracker/GetPatientForm/${group.track.newPatientTrackId}/${group.patient.firstName} ${group.patient.lastName}.pdf`;
    this.table.isWorking[group.track.newPatientTrackId] = true;

    this._subsink.sink = this._serviceProvider.get<Blob>(formsUrl, null, 'blob').subscribe((response) => {
      const blb = new Blob([response], { type: 'application/pdf' });
      const fileUrl = URL.createObjectURL(blb);
      const win = window.open(fileUrl, '_blank');
      win.onload = () => (win.document.title = `${group.patient.firstName} ${group.patient.lastName}.pdf`);
      this.table.isWorking[group.track.newPatientTrackId] = false;
    });
  }

  public setInsuranceVerification(group: TrackGroup, IsVerified: boolean): void {
    this._subsink.sink = this._dialog
      .open(this._confirmInsuranceTemplate)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this._subsink.sink = this._updateTrack(group.track.newPatientTrackId, [
            { op: 'replace', path: '/InsuranceVerified', value: IsVerified },
          ]).subscribe((response) => {
            this._snackbar.open(response.message, 'Ok', { duration: 2500 });
            group.track.insuranceVerified = IsVerified;
          });
        }
      });
  }

  private _updateTrack(
    newPatientTrackId: number,
    patchRequest: [{ op: string; path: string; value: string | boolean | number }]
  ): Observable<{ message: string }> {
    //update using JsonPatch
    return this._serviceProvider.patch<{ message: string }>(`NewPatientTracker/Patch/${newPatientTrackId}`, patchRequest);
  }
}
