import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { BrandStoreSelectors, RootStoreState } from 'src/app/root-store';
import { AvaSliderDto, CasePresentationClient, ContractTemplateClient } from 'src/app/services/api.service';

@Component({
  selector: 'app-create-patient-contract-dialog',
  templateUrl: './create-patient-contract-dialog.component.html',
  styleUrls: ['./create-patient-contract-dialog.component.scss'],
})
export class CreatePatientContractDialogComponent {
  __form = new FormGroup({
    contractTemplateId: new FormControl(null, [Validators.required]),
    avaSliderId: new FormControl(null),
  });

  __contractTemplates$ = this._store$.select(BrandStoreSelectors.getSelectedBrandId).pipe(
    filter((id) => !!id),
    take(1),
    switchMap((brandId) => this._contractTemplateClient.contractTemplate_Templates(brandId)),
    shareReplay()
  );

  __avaSliders$ = this._casePresentationClient.casePresentation_GetAvaSliderByPatient(this._data.patientId).pipe(
    map((sliders) => sliders.filter((x) => x.acceptDate != null)),
    shareReplay()
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: ICreatePatientContractDialogData,
    private _store$: Store<RootStoreState.State>,
    private _contractTemplateClient: ContractTemplateClient,
    private _casePresentationClient: CasePresentationClient
  ) {}

  __getResults(): ICreatePatientContractDialogResult {
    return {
      patientId: this._data.patientId,
      ...this.__form.value,
    };
  }

  __sliderDescription(slider: AvaSliderDto): string {
    return slider && slider.acceptedSettings && slider.acceptedSettings.treatmentTypes[0].name + ' - ' + moment(slider.acceptDate).format('l');
  }
}

export interface ICreatePatientContractDialogData {
  patientId: number;
}

export interface ICreatePatientContractDialogResult {
  patientId: number;
  contractTemplateId: number;
  avaSliderId?: number;
}
