import { Action, createReducer, on } from '@ngrx/store';
import { LocationStoreActions } from '../location-store';
import * as AutomationFollowupStoreActions from './actions';
import { AutomationFollowupPatientArea, AutomationFollowupPatientStoreEntity, AvaPatientInfo, featureAdapter, initialState, State } from './state';

const reducer = createReducer(
  initialState,
  on(AutomationFollowupStoreActions.LoadPendingPatientsRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(AutomationFollowupStoreActions.LoadPendingPatientsSuccess, (state, action) =>
    upsertPatientsForArea(action.patients, { ...state, isLoading: false }, 'Pending')
  ),
  on(AutomationFollowupStoreActions.LoadPendingPatientsFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),

  on(AutomationFollowupStoreActions.LoadAvasPatientsRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(AutomationFollowupStoreActions.LoadAvasPatientsSuccess, (state, action) =>
    upsertPatientsForArea(action.patients, { ...state, isLoading: false }, 'Ava', action.patientCounts)
  ),
  on(AutomationFollowupStoreActions.LoadAvasPatientsFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),

  on(AutomationFollowupStoreActions.UpdatePerson, (state, action) => featureAdapter.updateOne(action.update, { ...state })),
  on(AutomationFollowupStoreActions.RemovePerson, (state, action) => featureAdapter.removeOne(action.personId, { ...state })),
  on(AutomationFollowupStoreActions.AddPerson, (state, action) => featureAdapter.upsertOne(action.person, { ...state })),
  on(AutomationFollowupStoreActions.AddPersons, (state, action) => featureAdapter.upsertMany(action.persons, { ...state })),

  // Whe Location is changed, clear out currently held persons
  on(LocationStoreActions.SelectRequest, (state) => featureAdapter.removeAll({ ...state }))
);

export function featureReducer(state: State, action: Action): unknown {
  return reducer(state, action);
}

function upsertPatientsForArea(
  patients: AutomationFollowupPatientStoreEntity[],
  state: State,
  area: AutomationFollowupPatientArea,
  info?: AvaPatientInfo
): State {
  // Remove patients who are not in new list of patients then upsert
  const incomingIds = patients.filter((p) => p.cardArea == area).map((p) => p.id);
  const currentIds = Object.values(state.entities)
    .filter((p) => p.cardArea == area)
    .map((p) => p.id);

  const result = featureAdapter.removeMany(
    currentIds.filter((id) => !incomingIds.includes(id)),
    { ...state }
  );

  if (info) {
    result.avaPatientsCount = info;
  }

  return featureAdapter.upsertMany(patients, { ...result });
}
