import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GravityTransactionRequest, TerminalClient, TransactionTypeEnum } from 'src/app/services/api.service';
import { EmergePayService } from 'src/app/services/emergepay.service';

@Component({
  selector: 'app-create-auto-pay-dialog',
  templateUrl: './create-auto-pay-dialog.component.html',
  styleUrls: ['./create-auto-pay-dialog.component.scss'],
})
export class CreateAutoPayDialogComponent {
  isWorking$ = new ReplaySubject<boolean>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { ledgerId: number; patientId: number },
    private _dialogRef: MatDialogRef<CreateAutoPayDialogComponent>,
    private _terminalClient: TerminalClient,
    private _emergePayService: EmergePayService
  ) {}

  submit(value: AddressFormValue): void {
    this.isWorking$.next(true);
    this._terminalClient
      .terminal_GetLedgerTerminal(
        this._data.patientId,
        this._data.ledgerId,
        new GravityTransactionRequest({
          ...value,
          transactionType: value.paymentMethod == 'CC' ? TransactionTypeEnum.CreditSaveCard : TransactionTypeEnum.AchSaveAccount,
        })
      )
      .pipe(switchMap((terminal) => this._emergePayService.open(terminal.transactionToken)))
      .subscribe(
        (result) => {
          result.billingName = value.billingName; // Value is not returned by gravity so setting value for ourselves
          const location = { cardHolderAddress: value.billingAddress, zip: value.billingPostalCode };
          this._dialogRef.close(Object.assign(result, location));
        },
        (result) => console.log('failure', result)
      );
  }
}

type AddressFormValue = { billingName: string; billingAddress: string; billingPostalCode: string; paymentMethod: 'CC' | 'ACH' };
