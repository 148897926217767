import { Component, Input } from '@angular/core';
import { AvaSliderAcceptedSettings, IAdjustmentItem, IPaymentOptionDto, ITreatmentTypeDto } from 'src/app/services/api.service';
import { FinancialService } from '../financial.service';

@Component({
  selector: 'app-fee-overview',
  templateUrl: './fee-overview.component.html',
  styleUrls: ['./fee-overview.component.scss'],
})
export class FeeOverviewComponent {
  @Input('acceptedSliderSettings') public acceptedSliderSettings: AvaSliderAcceptedSettings;

  constructor(private _financialService: FinancialService) {}

  __showComponent(): boolean {
    return (
      this.acceptedSliderSettings &&
      this.acceptedSliderSettings.treatmentTypes &&
      this.acceptedSliderSettings.treatmentTypes.length > 0 &&
      this.acceptedSliderSettings.paymentOptions &&
      this.acceptedSliderSettings.paymentOptions.length > 0
    );
  }

  __getTreatmentType(): ITreatmentTypeDto {
    return this._financialService.getAcceptedTreatmentType(this.acceptedSliderSettings);
  }

  __getTreatmentAddons(): IAdjustmentItem[] {
    return this._financialService.getAcceptedTreatmentAddons(this.acceptedSliderSettings);
  }

  __getPaymentPlanAddons(): IAdjustmentItem[] {
    return this._financialService.getAcceptedPaymentPlanAddons(this.acceptedSliderSettings);
  }

  __getSubtotal(): number {
    return this._financialService.calculateAcceptedSubtotal(this.acceptedSliderSettings);
  }

  __getPaymentOption(): IPaymentOptionDto {
    return this._financialService.getAcceptedPaymentOption(this.acceptedSliderSettings);
  }

  __getPaymentOptionDescription(): string {
    const option = this.__getPaymentOption();
    if (option.payInFull) {
      return option.name;
    } else {
      return `${option.name} ${option.lengthMonth.min}-${option.lengthMonth.max} months`;
    }
  }

  __getDiscounts(): IAdjustmentItem[] {
    return this._financialService.getAcceptedDiscounts(this.acceptedSliderSettings);
  }

  __getContractTotal(): number {
    return this._financialService.getAcceptedContractTotal(this.acceptedSliderSettings);
  }

  __getContractGrandTotal(): number {
    return this._financialService.getAcceptedContractGrandTotal(this.acceptedSliderSettings);
  }

  __getPaymentDescription(): string {
    return this._financialService.getAcceptedPaymentDescription(this.acceptedSliderSettings);
  }

  __getAddonAmount(addon: IAdjustmentItem): number {
    return this._financialService.getAddonAmount(this.acceptedSliderSettings, addon);
  }
}
