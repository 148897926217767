/* eslint-disable */
export class SurveyStructure {
  questions: SurveyQuestion[];
  categories: SurveyCategory[];
}

export class SurveyQuestion {
  category: number;
  order: number;
  questionText: string;
  questionType: SurveyQuestionType;
  answers: SurveyQuestionAnswer[];
  propertyName: string;
  required: boolean;
  requiredMessage: string;
}

export class SurveyCategory {
  id: number;
  name: string;
  description: string;
}

export enum SurveyQuestionType {
  None = 0,
  Bool,
  YesNo,
  Multi,
  Range,
  Text,
  Check
}

export class SurveyQuestionAnswer {
  order: number;
  text: string;
  value: string | number;
}
