import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
// import { FilterPatientPipe } from './../utility/filter/filter-patient.pipe';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule, Routes } from '@angular/router';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ClickOutsideModule } from 'ng-click-outside';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { CKEditorModule } from 'ng2-ckeditor';
import { MomentModule } from 'ngx-moment';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { FeatureFlagModule } from '../directives/featureFlag.module';
import { FeatureFlagGuardService } from '../guards/featureFlagGuard.service';
import { AlertDialogComponent } from '../modal/alert-dialog/alert-dialog.component';
import { ModalContainerModule } from '../modal/modal-container/modal-container.module';
import { PatientPrioritySortPipe } from '../pipes/patient-priority-sort.pipe';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { AvaSelectModule } from '../shared/ava-select';
import { AutomationsComponent } from './automations/automations.component';
import { AvaContributionDialogComponent } from './ava-contribution/ava-contribution-dialog/ava-contribution-dialog.component';
import { AvaContributionComponent } from './ava-contribution/ava-contribution.component';
import { AvaPatientCardComponent } from './avafollowup/ava-patient/ava-patient.component';
import { AvafollowupComponent } from './avafollowup/avafollowup.component';
import { ListFilterPipe } from './avafollowup/list-filter.pipe';
import { WinModal } from './avafollowup/modal/win-modal/win-modal.component';
import { TeamDecisionComponent } from './avafollowup/team-decision/team-decision.component';
import { CasepresentationComponent } from './casepresentation/casepresentation.component';
import { ChatDashboardComponent } from './chat-dashboard/chat-dashboard.component';
import { ContactDetails } from './chat-dashboard/modal/contact-details/contact-details';
import { ContactViewTranscript } from './chat-dashboard/modal/contact-view-transcript/contact-view-transcript';
import { CardCheckboxComponent } from './modal/card-checkbox/card-checkbox.component';
import { DialogVCWins } from './modal/dialog-vc-wins/dialog-vc-wins';
import { LaunchViewComponent } from './modal/launch-view/launch-view.component';
import { DialogBookingDetailsComponent } from './newpatientscheduler/modal/dialog-booking-details.component';
import { NewpatientschedulerComponent } from './newpatientscheduler/newpatientscheduler.component';
import { TrackerCommunicationDialogComponent } from './newpatienttracker/modal/tracker-communication-dialog.component';
import { NewPatientTrackerTableComponent } from './newpatienttracker/new-patient-tracker-table/new-patient-tracker-table.component';
import { NewpatientTrackerComponent } from './newpatienttracker/newpatienttracker.component';
import { SurveyReportComponent } from './newpatienttracker/survey-report/survey-report.component';
import { TrackerSearchComponent } from './newpatienttracker/tracker-search/tracker-search.component';
import { PatientfollowupComponent } from './patientfollowup/patientfollowup.component';
import { DialogDataExampleDialog } from './virtualconsult/modal/dialog-data-example-dialog';
import { VirtualconsultComponent } from './virtualconsult/virtualconsult.component';
import { RcmDashboardComponent } from './rcm-dashboard/rcm-dashboard.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
};
const routes: Routes = [
  {
    path: '',
    component: AutomationsComponent,
    children: [
      { path: 'ava-followup', component: AvafollowupComponent },
      { path: 'chat-dashboard', component: ChatDashboardComponent, canActivate: [FeatureFlagGuardService], data: { FeatureFlag: ['ava-chat'] } },
      { path: 'virtual-consult', component: VirtualconsultComponent },
      { path: 'new-patient-scheduler', component: NewpatientschedulerComponent },
      { path: 'new-patient-tracker', component: NewpatientTrackerComponent },
      { path: 'case-presentation', component: CasepresentationComponent },
      { path: 'patient-follow-up', component: PatientfollowupComponent },
      { path: 'rcm-dashboard', component: RcmDashboardComponent },
      { path: '', redirectTo: 'ava-followup', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  declarations: [
    VirtualconsultComponent,
    NewpatientschedulerComponent,
    DialogBookingDetailsComponent,
    AutomationsComponent,
    NewpatientTrackerComponent,
    CasepresentationComponent,
    PatientfollowupComponent,
    AvafollowupComponent,
    DialogDataExampleDialog,
    DialogVCWins,
    TrackerCommunicationDialogComponent,
    ListFilterPipe,
    AvaPatientCardComponent,
    TeamDecisionComponent,
    WinModal,
    LaunchViewComponent,
    CardCheckboxComponent,
    ChatDashboardComponent,
    ContactDetails,
    ContactViewTranscript,
    SurveyReportComponent,
    AvaContributionComponent,
    AvaContributionDialogComponent,
    AlertDialogComponent,
    TrackerSearchComponent,
    NewPatientTrackerTableComponent,
    RcmDashboardComponent
  ],
  imports:[
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatInputModule,
    MatProgressBarModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    MomentModule,
    ModalContainerModule,
    SharedPipesModule,
    MatIconModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatButtonModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatBadgeModule,
    MatListModule,
    OverlayModule,
    MatRadioModule,
    ChartsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    ModalContainerModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSliderModule,
    MatSlideToggleModule,
    CKEditorModule,
    MatTableExporterModule,
    AvaSelectModule,
    FeatureFlagModule,
    MatCardModule,
    NgbAccordionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    DatePipe,
    PatientPrioritySortPipe,
    ThemeService,
  ],
  entryComponents: [
    DialogDataExampleDialog,
    DialogVCWins,
    TrackerCommunicationDialogComponent,
    WinModal,
    DialogBookingDetailsComponent,
    ContactDetails,
    ContactViewTranscript,
    AlertDialogComponent,
    SurveyReportComponent,
    TrackerSearchComponent,
  ],
  exports: [SurveyReportComponent, AvaContributionDialogComponent],
})
export class AutomationsModule {}
