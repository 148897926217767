import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { HelpContactDetailsData } from "src/app/models/AvaMultiContext";
import { HelpService } from "src/app/services/help.service";
import { ContactViewTranscript } from "../contact-view-transcript/contact-view-transcript";

@Component({
    selector: 'contact-details',
    templateUrl: './contact-details.html',
    styleUrls: ['./contact-details.css'],
})
export class ContactDetails {
  contactDetails: string[] = ['Team member', 'Chat result', 'Requested', 'Phone Number', 'Question', 'Date and time'];
  startDate: Date;
  endDate: Date;
  botMessengerId: string;
  customStartDate: Date;
  customEndDate: Date;
  contactDetailsList: HelpContactDetailsData[];
  requestStatus: string;
  bindNumberOfDays: number;
  
  constructor(
    public dialog: MatDialog,
    private _helpService: HelpService,
    private _dialogRef: MatDialogRef<ContactDetails>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.bindNumberOfDays = data.bindNumberOfDays;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.botMessengerId = data.botMessengerId;

    this._getContactDetailsData(this.startDate, this.endDate, this.botMessengerId);
  }
  
  public confirmDone(): void {
    this._dialogRef.close();
  }
  
  public showMore(lbl: string, displaydata: string): void {
    const display = document.getElementById(displaydata);
    const labeltext = document.getElementById(lbl);

    if (display.style.overflow === 'hidden' || labeltext.innerHTML === 'more') {
      display.style.overflowY = 'visible';
      display.style.whiteSpace = 'pre-wrap';
      labeltext.innerHTML = 'Less';
    } else {
      display.style.overflow = 'hidden';
      display.style.whiteSpace = 'nowrap';
      labeltext.innerHTML = 'more';
    }
  }

  public viewTranscript(chatSessionId: number, rowId: string): void {
    const rowHighlight = document.getElementById(rowId);
    rowHighlight.style.backgroundColor = '#b5d6f4';
    const dialogRef = this.dialog.open(ContactViewTranscript, {
      backdropClass: 'g-transparent-backdrop',
      panelClass: 'no-pad-dialog',
      data: {
        chatSessionId: chatSessionId,
        rowHighlight: rowHighlight,
      },
    });

    dialogRef.afterClosed().subscribe();
  }

  private _getContactDetailsData(startDate: Date, endDate: Date, botMessengerId: string): void {
    this._helpService.getContactDetails(startDate, endDate, botMessengerId).subscribe(
      (result) => {
        this.contactDetailsList = result.contactLists;
        for (let i = 0; i < this.contactDetailsList.length; i++) {
          if (this.contactDetailsList[i].teamName.length) {
            this.contactDetailsList[i].teamName = this.contactDetailsList[i].teamName[this.contactDetailsList[i].teamName.length - 1];
          }
          this.requestStatus = result.contactLists[i]['requestedStatus'];
          if (this.requestStatus === 'Accepted' || this.requestStatus === 'Done') {
            this.contactDetailsList[i].requestedStatus = 'LiveChat';
          } else if (this.requestStatus === 'Missed') {
            this.contactDetailsList[i].requestedStatus = 'MISSED CHAT';
          } else {
            this.contactDetailsList[i].requestedStatus = ' ';
          }
        }
      }
    );
  }
}