/* eslint-disable */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'time'
})
export class TimePipe implements PipeTransform {

	transform(value: string): string {
		const regex = /([0-2]?[0-9]):([0-9]{2})(:[0-9]{2})?/;
		let hour = Number.parseInt(value.replace(regex, '$1'));
		const minute = Number.parseInt(value.replace(regex, '$2'));
		let amPm = 'am';

		if (hour < 0 || hour > 24 || minute < 0 || minute > 59) {
			return 'Invalid time.';
		}

		if (hour === 0) {
			hour = 12;
		} else if (hour >= 12) {
			amPm = 'pm';
			if (hour > 12) {
				hour -= 12;
			}
		}

		return `${hour}:${minute < 10 ? `0${minute}` : `${minute}`} ${amPm}`;
	}
}
