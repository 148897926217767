/* eslint-disable */
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatTabsModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClickOutsideModule } from 'ng-click-outside';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { AvaSelectModule } from 'src/app/shared/ava-select';
import { AlertDialogComponent } from '../communication/alert-dialog/alert-dialog.component';
import { CommunicationComponent } from '../communication/communication.component';
import { DropdownComponent } from '../communication/dropdown/dropdown.component';
import { PatientDetailsComponent } from '../patient-details/patient-details.component';
import { PatientFinancialModule } from '../patient-financial/patient-financial.module';
import { ResponsiblePartyConfirmDialogComponent } from '../responsible-party-confirm-dialog/responsible-party-confirm-dialog.component';
import { AutoModal } from './../auto-modal/auto-modal.component';
import { DateSelectorComponent } from './../date-selector/date-selector.component';
import { RemindModal } from './../remind-modal/remind-modal.component';
import { SetModal } from './../set-modal/set-modal.component';
import { PatientDetailsModal } from './modal-container.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
};

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    MatDialogModule,
    ClickOutsideModule,
    FormsModule,
    SharedPipesModule,
    MatTooltipModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ScrollingModule,
    AvaSelectModule,
    PatientFinancialModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
  ],
  declarations: [
    PatientDetailsComponent,
    CommunicationComponent,
    DropdownComponent,
    PatientDetailsModal,
    AutoModal,
    SetModal,
    RemindModal,
    DateSelectorComponent,
    AlertDialogComponent,
    ResponsiblePartyConfirmDialogComponent,
  ],
  exports: [CommunicationComponent, PatientDetailsModal],
  entryComponents: [
    PatientDetailsModal,
    AutoModal,
    SetModal,
    RemindModal,
    DateSelectorComponent,
    AlertDialogComponent,
    ResponsiblePartyConfirmDialogComponent,
  ],
})
export class ModalContainerModule {}
