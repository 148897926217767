import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, pipe, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMapTo, take } from 'rxjs/operators';
import { AvasPatient, AvasPatientsViewModel, AvasWin, AvasWinsResponse } from 'src/app/models/Dashboard';
import { IDropDownOption } from 'src/app/models/IDropDownOption';
import { PatientPrioritySortPipe } from 'src/app/pipes/patient-priority-sort.pipe';
import {
  AutomationFollowupStoreActions,
  AutomationFollowupStoreSelectors,
  LocationStoreSelectors,
  RootStoreState,
  UserStoreSelectors,
} from 'src/app/root-store';
import { SubSink } from 'subsink';
import { ServiceProvider } from '../../service';
import { DataService } from '../../services/data.service';
import { RedgrayCount } from './interfaces/IRedgrayCount';

@Component({
  selector: 'app-avafollowup',
  templateUrl: './avafollowup.component.html',
  styleUrls: ['./avafollowup.component.css'],
})
export class AvafollowupComponent implements OnInit, OnDestroy {
  public consultants = [];
  public consultants$: Observable<IDropDownOption[]> = this._store$.select(UserStoreSelectors.selectAllUsers).pipe(
    map((users) => users.filter((u) => u.isActive)),
    map((users) => [{ value: 'All', id: null }].concat(users.map((user) => ({ value: `${user.firstName} ${user.lastName}`, id: user.id }))))
  );
  public recallDisabledMsg: string;
  public actionAvaPatientMenu: Subject<void> = new Subject<void>();
  public wins: AvasPatientsViewModel<AvasWin> = {
    newlead: [],
    noshow: [],
    recall: [],
    pending: [],
    misc: [],
  };
  public redgraycount = <RedgrayCount>{};
  isLoading$ = this._store$.select(AutomationFollowupStoreSelectors.selectIsLoading);

  //#region Pending Patients
  private _pendingPatientPipe = pipe(
    startWith([]),
    filter((x) => !!x),
    map((x: AvasPatient[]) => x.sort((a, b) => this._stringCompare(a.lastname, b.lastname)))
  );
  teamDecisionsNewlead$ = this._store$.select(AutomationFollowupStoreSelectors.selectPendingPatientNewLeads).pipe(this._pendingPatientPipe);
  teamDecisionsNoshow$ = this._store$.select(AutomationFollowupStoreSelectors.selectPendingPatientNoShow).pipe(this._pendingPatientPipe);
  teamDecisionsRecall$ = this._store$.select(AutomationFollowupStoreSelectors.selectPendingPatientRecall).pipe(this._pendingPatientPipe);
  teamDecisionsPending$ = this._store$.select(AutomationFollowupStoreSelectors.selectPendingPatientPending).pipe(this._pendingPatientPipe);
  teamDecisionsMisc$ = this._store$.select(AutomationFollowupStoreSelectors.selectPendingPatientMisc).pipe(this._pendingPatientPipe);
  //#endregion

  //#region Ava's Patients
  private _avaPatientPipe = pipe(
    startWith([]),
    filter((x) => !!x),
    map((x: AvasPatient[]) => this._patientPrioritySortPipe.transform(x))
  );
  avaPatientNewlead$ = this._store$.select(AutomationFollowupStoreSelectors.selectAvasPatientNewLead).pipe(this._avaPatientPipe);
  avaPatientNoshow$ = this._store$.select(AutomationFollowupStoreSelectors.selectAvasPatientNoShow).pipe(this._avaPatientPipe);
  avaPatientRecall$ = this._store$.select(AutomationFollowupStoreSelectors.selectAvasPatientRecall).pipe(this._avaPatientPipe);
  avaPatientPending$ = this._store$.select(AutomationFollowupStoreSelectors.selectAvasPatientPending).pipe(this._avaPatientPipe);
  avaPatientMisc$ = this._store$.select(AutomationFollowupStoreSelectors.selectAvasPatientMisc).pipe(this._avaPatientPipe);
  avaPatientNewleadCount$ = this._store$.select(AutomationFollowupStoreSelectors.selectAvasPatientNewLeadCount);
  avaPatientNoShowCount$ = this._store$.select(AutomationFollowupStoreSelectors.selectAvasPatientNoShowCount);
  avaPatientRecallCount$ = this._store$.select(AutomationFollowupStoreSelectors.selectAvasPatientRecallCount);
  avaPatientPendingCount$ = this._store$.select(AutomationFollowupStoreSelectors.selectAvasPatientPendingCount);
  avaPatientMiscCount$ = this._store$.select(AutomationFollowupStoreSelectors.selectAvasPatientMiscCount);
  //#endregion

  private _subs = new SubSink();
  private _selectedLocationId$ = this._store$.select(LocationStoreSelectors.getSelectedLocationId);

  constructor(
    private _remoteService: ServiceProvider,
    private _dataService: DataService,
    private _store$: Store<RootStoreState.State>,
    private _patientPrioritySortPipe: PatientPrioritySortPipe
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._selectedLocationId$
        .pipe(
          distinctUntilChanged(),
          filter((id) => id != null)
        )
        .subscribe((locationId) => {
          this._getFollowupDataFrom_API(locationId);
        })
    );
    this._subs.add(
      this._dataService
        .getMessageForAvaModalClose()
        .pipe(switchMapTo(this._selectedLocationId$.pipe(take(1))))
        .subscribe((locationId) => {
          this._getFollowupDataFrom_API(locationId);
        })
    );
    this._subs.add(
      this._dataService
        .getMessageForCommunicationModalClose()
        .pipe(switchMapTo(this._selectedLocationId$.pipe(take(1))))
        .subscribe((locationId) => {
          this._getFollowupDataFrom_API(locationId);
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public pationCardItemSelected(event: any): void {
    this.hideItemMenusExceptCurrent(event.cardId);
  }

  public hideItemMenusExceptCurrent(currentCardId: any): void {
    this.actionAvaPatientMenu.next(currentCardId);
  }

  private _getFollowupDataFrom_API(locationId: number): void {
    this._remoteService
      .get<{ isDisabled: boolean; dbName: string }>(`AvasFollowUp/CheckRecallDisabled/Location/${locationId}`)
      .subscribe((response) => {
        if (response.isDisabled) {
          this.recallDisabledMsg = `Recall is disabled for ${response.dbName} because Ava cannot read recall dates.
          If you wish to use this feature please contact your ${response.dbName} provider and ask them to make this data available to 3rd parties.`;
        }
      });

    this._remoteService.get<AvasWinsResponse>(`avasfollowup/AvaPatientWinsDetails/Location/${locationId}`).subscribe((res) => {
      this.wins.newlead = res.avasPatientNewLead;
      this.wins.noshow = res.avasPatientNoShow;
      this.wins.recall = res.avasPatientRecall;
      this.wins.pending = res.avasPatientPending;
    });
  }

  //For sorting patients by name
  private _stringCompare(stringA: string, stringB: string): number {
    const aL = stringA.toLowerCase();
    const bL = stringB.toLowerCase();
    if (aL > bL) return 1;
    if (aL < bL) return -1;
    return 0;
  }

  refreshPendingCards() {
    this._subs.sink = this._store$
      .select(LocationStoreSelectors.getSelectedLocationId)
      .subscribe((locationId) => this._store$.dispatch(AutomationFollowupStoreActions.LoadPendingPatientsRequest({ locationId: locationId })));
  }

  refreshAvaCards() {
    this._subs.sink = this._store$
      .select(LocationStoreSelectors.getSelectedLocationId)
      .subscribe((locationId) => this._store$.dispatch(AutomationFollowupStoreActions.LoadAvasPatientsRequest({ locationId: locationId })));
  }
}
