import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ChatDetail } from '../models/Communication';
import { AvasPatient, CallReminder } from '../models/Dashboard';
import { ofSignalrEventType, SIGNALR_EVENTS } from '../root-store';
import { ServiceProvider } from '../service';
import { NotificationClient, NotificationFollowUpModelDto, NotificationsDto } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private _remoteService: ServiceProvider, private _actions$: Actions, private _notificationClient: NotificationClient) {}

  ofEvent(eventName: typeof SIGNALR_EVENTS.BlastSent): Observable<string>;
  ofEvent(eventName: typeof SIGNALR_EVENTS.CallLoggedNotification): Observable<CallReminder>;
  ofEvent(eventName: typeof SIGNALR_EVENTS.FollowUpAdded): Observable<AvasPatient>;
  ofEvent(eventName: typeof SIGNALR_EVENTS.FollowUpAddedRange): Observable<AvasPatient[]>;
  ofEvent(eventName: typeof SIGNALR_EVENTS.FollowUpRemoved): Observable<AvasPatient>;
  ofEvent(eventName: typeof SIGNALR_EVENTS.FollowUpRemovedRange): Observable<AvasPatient[]>;
  ofEvent(eventName: typeof SIGNALR_EVENTS.MessageReceivedCommunication): Observable<ChatDetail>;
  ofEvent(eventName: typeof SIGNALR_EVENTS.MessageSentRangeCommunication): Observable<ChatDetail[]>;
  ofEvent(eventName: typeof SIGNALR_EVENTS.MessageReceivedNotification): Observable<NotificationFollowUpModelDto>;
  ofEvent(eventName: typeof SIGNALR_EVENTS.MessageSentNotification): Observable<NotificationFollowUpModelDto>;
  ofEvent(eventName: typeof SIGNALR_EVENTS.MessageSentCommunication): Observable<ChatDetail>;
  ofEvent(eventName: typeof SIGNALR_EVENTS.ReceivedCallNotification): Observable<CallReminder>;
  ofEvent<T = unknown>(eventName: keyof typeof SIGNALR_EVENTS): Observable<T> {
    return this._actions$.pipe(
      ofSignalrEventType<T>(eventName),
      map(([_, data]) => {
        return data;
      })
    );
  }

  getCallNotifications(): Observable<CallReminder[]> {
    return this._remoteService.get<CallReminder[]>('Notification/GetCallReminders');
  }

  getReceivedCommsNotifications(): Observable<NotificationsDto> {
    return this._notificationClient.notification_GetNotifications().pipe(take(1));
  }

  sendPushNotification(chatId: string | number, isLiveChatRequest: boolean): Observable<unknown> {
    return this._remoteService.post('v1/DesktopNotifications/send/' + chatId, { isLiveChatRequest: isLiveChatRequest });
  }

  public checkBridgeSync(): Observable<boolean> {
    return this._remoteService.get<boolean>('Notification/IsBridgeSyncFailed');
  }
}
