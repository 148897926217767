import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import * as html2pdf from 'html2pdf.js';
import * as moment from 'moment';
import { PatientContractClient, PatientContractDto } from 'src/app/services/api.service';
import {
  ContractCheckboxes,
  ContractSignatures,
  ContractViewComponent,
  IContractViewData,
} from 'src/app/shared/financial/contract-view/contract-view.component';

@Component({
  selector: 'app-view-patient-contract',
  templateUrl: './view-patient-contract.component.html',
  styleUrls: ['./view-patient-contract.component.scss'],
})
export class ViewPatientContractDialogComponent implements OnInit {
  @ViewChildren(ContractViewComponent) contractViewEle: QueryList<ContractViewComponent>;

  private _patientContract: PatientContractDto;
  get viewData(): IContractViewData {
    if (this._patientContract) {
      return {
        headers: this._patientContract.signedValues.contractBody.headers,
        contractSections: this._patientContract.signedValues.contractBody.sections,
        financial: this._patientContract.signedValues.contractFinancial.acceptedSettings,
        finalSignature: this._patientContract.signedValues.finalSignature,
        signedDate: this._patientContract.signedDate,
        logoUrl: this._data.logoUrl || this._patientContract.logoUrl,
        patient: { firstName: this._patientContract.patientFirstName, lastName: this._patientContract.patientLastName },
      };
    }
  }

  get signatures(): ContractSignatures {
    if (this._patientContract) {
      return this._patientContract.signedValues.signatures;
    }
  }

  get checkboxes(): ContractCheckboxes {
    if (this._patientContract) {
      return this._patientContract.signedValues.checkboxes;
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) private _data: IViewPatientContractDialogData, private _patientContractClient: PatientContractClient) {}

  ngOnInit(): void {
    this._patientContractClient
      .patientContract_GetPatientContract(this._data.patientId, this._data.patientContractId)
      .subscribe((result) => (this._patientContract = result));
  }

  createPDF(): void {
    const content = this.contractViewEle.first.element.nativeElement;
    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: `${this._data.patientContractId}_${moment(this._patientContract.signedDate).format('MMDDYYYY')}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2, useCORS: true },
      pagebreak: { mode: ['avoid-all'] },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    html2pdf()
      .from(content)
      .set(opt)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        const pgHt = pdf.internal.pageSize.getHeight();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(150);

          const today = moment();
          const publishedDt = today.format('MM/DD/YYYY, hh:mm a');
          const footerText = `Page ${i} of ${totalPages}  ${publishedDt}`;
          pdf.text(footerText, 0.25, pgHt - 0.25);
        }
      })
      .save();
  }
}

export interface IViewPatientContractDialogData {
  patientId: number;
  patientContractId: number;
  logoUrl?: string;
}
