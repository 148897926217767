/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TENANT_SETTINGS_FEATURE_KEY } from './featurekey';
import { State } from './state';

export const selectState = createFeatureSelector<State>(TENANT_SETTINGS_FEATURE_KEY);
export const selectError = createSelector(selectState, (state) => state.error);
export const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

// FINANCIAL
export const IsLedgersEnabled = createSelector(selectState, (state) => state.financial && state.financial.isLedgersEnabled);
export const IsSlidersEnabled = createSelector(selectState, (state) => state.financial && state.financial.isSlidersEnabled);
export const IsContractsEnabled = createSelector(selectState, (state) => state.financial && state.financial.isContractsEnabled);
export const IsFinancialEnabled = createSelector(IsLedgersEnabled, IsSlidersEnabled, IsContractsEnabled, (a, b, c) => a || b || c);

// BLAST
export const BlastMaxSendAmount = createSelector(selectState, (state) => state.blast && state.blast.maxSendAmount);
