import { NgModule } from '@angular/core';
import { FeatureIfDirective } from './if-feature.directive';
import { RemoveIfFeatureOffDirective } from './remove-if-feature-off.directive';
import { RemoveIfFeatureOnDirective } from './remove-if-feature-on.directive';

@NgModule({
  declarations: [
      RemoveIfFeatureOnDirective,
      RemoveIfFeatureOffDirective,
      FeatureIfDirective],
  imports: [],
  providers: [],
  bootstrap: [],
  exports:[
    RemoveIfFeatureOnDirective,
    RemoveIfFeatureOffDirective,
    FeatureIfDirective]
})
export class FeatureFlagModule {}