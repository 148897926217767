import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { merge } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { BrandStoreActions, LocationStoreActions, RootStoreState } from '../root-store';
import { AuthStoreActions, AuthStoreSelectors } from '../root-store/auth-store';
import { AuthStoreEffects } from '../root-store/auth-store/effects';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  username = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required]);

  error_msg: string = '';
  redirectUrl: string;

  constructor(
    private _router: Router,
    private _store$: Store<RootStoreState.State>,
    private _activatedRoute: ActivatedRoute,
    private _authEffects: AuthStoreEffects
  ) {
    this._activatedRoute.queryParamMap.subscribe((params) => {
      if (params.has('redirect')) this.redirectUrl = params.get('redirect');
    });
  }

  ngOnInit(): void {
    this._store$
      .select(AuthStoreSelectors.selectLoggedIn)
      .pipe(
        filter((x) => x == true),
        take(1)
      )
      .subscribe(() => {
        if (this.redirectUrl) this._router.navigateByUrl(this.redirectUrl);
        else this._router.navigate(['dashboard']);
      });
  }

  getUsernameMessage(): string {
    return this.username.hasError('required') ? 'Email is required' : '';
  }

  getPasswordMessage(): string {
    return this.password.hasError('required') ? 'Password is required' : '';
  }

  login(): void {
    this.error_msg = '';
    if (this.username.hasError('required') || this.password.hasError('required')) {
      this.error_msg = 'Email and password required!';
      return;
    }
    if (this.username.value != '' && this.password.value != '') {
      merge(this._authEffects.loginResult$, this._authEffects.loginAuthResult$.pipe(filter((x) => x.type == AuthStoreActions.LoginFailure.type)))
        .pipe(take(1))
        .subscribe((result) => {
          if (result.type == AuthStoreActions.LoginSuccess.type) {
            this._store$.dispatch(
              BrandStoreActions.LoadRequest({
                teamMemberId: result.userInfo.teamMemberId,
                isAdmin: result.userInfo.roles.includes('CustomerAdmin') || result.userInfo.roles.includes('Administrator'),
              })
            );
            this._store$.dispatch(LocationStoreActions.LoadRequest({ teamMemberId: result.userInfo.teamMemberId }));
          } else if (result.type == AuthStoreActions.LoginFailure.type) {
            this.error_msg = typeof result.error === 'object' ? result.error.error_description : result.error;
          }
        });

      this._store$.dispatch(AuthStoreActions.Login({ username: this.username.value, password: this.password.value }));
    }
  }

  forgot_password(): void {
    this._router.navigate(['forgot-password']);
  }
}
