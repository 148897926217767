import { Injectable } from '@angular/core';
import { PatientSearch, PatientSearchViewModel } from '../models/PatientSearch';

@Injectable({
  providedIn: 'root',
})
export class PatientSearchService {
  patientSerach: PatientSearch;
  patients: PatientSearchViewModel[];

  constructor() {
    this.reset();
  }

  reset(): void {
    this.patientSerach = null;
    this.patients = null;
  }
}
