import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  IAdjustmentItem,
  IAvaSliderAcceptedSettings,
  IPaymentOptionDto,
  ITreatmentTypeDto,
  PatientContractClient,
  PatientContractDto,
} from 'src/app/services/api.service';
import { isNullOrUndefined } from 'util';
import { SignatureDialogComponent, SignatureDialogData, SignatureDialogResult, SignatureType } from '../digital-signature';
import {
  ContractViewDialogComponent,
  IContractViewDialogData,
  IContractViewDialogResult,
} from './modal/contract-view-dialog/contract-view-dialog.component';

@Injectable()
export class FinancialService {
  constructor(private _dialog: MatDialog, private _patientContractClient: PatientContractClient) {}

  openContractViewDialog(
    patientContractId: number,
    acceptedSliderSettings: IAvaSliderAcceptedSettings,
    patientId: number
  ): Observable<IContractViewDialogResult>;
  openContractViewDialog(
    patientContract: PatientContractDto,
    acceptedSliderSettings: IAvaSliderAcceptedSettings
  ): Observable<IContractViewDialogResult>;
  openContractViewDialog(contractViewData: IContractViewDialogData): Observable<IContractViewDialogResult>;
  openContractViewDialog(
    patientContractValue: PatientContractDto | IContractViewDialogData | number,
    acceptedSliderSettings?: IAvaSliderAcceptedSettings,
    patientId: number = null
  ): Observable<IContractViewDialogResult> {
    const patientContractId =
      typeof patientContractValue === 'number'
        ? patientContractValue
        : 'patientContractId' in patientContractValue
        ? patientContractValue.patientContractId
        : null;
    patientId = typeof patientContractValue === 'number' ? patientId : 'patientId' in patientContractValue ? patientContractValue.patientId : null;

    // When explicit view data is passed (ie when previewing contract)
    if (patientContractId == null && patientId == null) {
      if (typeof patientContractValue !== 'object') {
        throw new Error('Unable to view contract');
      }

      return this._dialog
        .open<ContractViewDialogComponent, IContractViewDialogData, IContractViewDialogResult>(ContractViewDialogComponent, {
          data: patientContractValue as IContractViewDialogData,
          height: '100vh',
        })
        .afterClosed();
    }

    return this._patientContractClient.patientContract_GetSignDetails(patientContractId, patientId).pipe(
      switchMap((signDetails) =>
        this._dialog
          .open<ContractViewDialogComponent, IContractViewDialogData, IContractViewDialogResult>(ContractViewDialogComponent, {
            data: {
              contractViewData: {
                contractSections: signDetails.contractBody.sections,
                headers: signDetails.contractBody.headers,
                financial: acceptedSliderSettings,
                logoUrl: signDetails.logoUrl,
              },
            },
            height: '100vh',
          })
          .afterClosed()
      )
    );
  }

  openSignatureDialog(signatureType: SignatureType, currentValue: string = null): Observable<SignatureDialogResult> {
    return this._dialog
      .open<SignatureDialogComponent, SignatureDialogData, SignatureDialogResult>(SignatureDialogComponent, {
        data: { type: signatureType, currentValue },
        minWidth: '50vw',
      })
      .afterClosed();
  }

  // #region Slider Accepted Settings Functions

  getAcceptedTreatmentType(acceptedSliderSettings: IAvaSliderAcceptedSettings): ITreatmentTypeDto {
    return !isNullOrUndefined(acceptedSliderSettings) && acceptedSliderSettings.treatmentTypes[0];
  }

  getAcceptedTreatmentAddons(acceptedSliderSettings: IAvaSliderAcceptedSettings): IAdjustmentItem[] {
    return !isNullOrUndefined(acceptedSliderSettings) && acceptedSliderSettings.treatmentTypes[0].addOns;
  }

  getAcceptedPaymentPlanAddons(acceptedSliderSettings: IAvaSliderAcceptedSettings): IAdjustmentItem[] {
    return !isNullOrUndefined(acceptedSliderSettings) && acceptedSliderSettings.paymentOptions[0].addOns;
  }

  calculateAcceptedSubtotal(acceptedSliderSettings: IAvaSliderAcceptedSettings): number {
    const treatment = this.getAcceptedTreatmentType(acceptedSliderSettings);
    const addons = this.getAcceptedTreatmentAddons(acceptedSliderSettings);
    if (treatment && addons) {
      const addonTotal = addons.map((x) => this.getAddonAmount(acceptedSliderSettings, x)).reduce((x, y) => x + y, 0);
      return treatment.fee + addonTotal;
    }
    return undefined;
  }

  getAcceptedPaymentOption(acceptedSliderSettings: IAvaSliderAcceptedSettings): IPaymentOptionDto {
    return acceptedSliderSettings && acceptedSliderSettings.paymentOptions.length > 0 && acceptedSliderSettings.paymentOptions[0];
  }

  getAcceptedDiscounts(acceptedSliderSettings: IAvaSliderAcceptedSettings): IAdjustmentItem[] {
    const treatmentType = this.getAcceptedTreatmentType(acceptedSliderSettings);
    const paymentOption = this.getAcceptedPaymentOption(acceptedSliderSettings);
    const treatmentDiscounts = treatmentType && treatmentType.discount;
    const paymentDiscounts = paymentOption && paymentOption.discount;
    return [...treatmentDiscounts, ...paymentDiscounts];
  }

  getAcceptedContractTotal(acceptedSliderSettings: IAvaSliderAcceptedSettings): number {
    const treatmentType = this.getAcceptedTreatmentType(acceptedSliderSettings);
    const paymentOption = this.getAcceptedPaymentOption(acceptedSliderSettings);
    if (treatmentType && paymentOption) {
      const treatmentFee = treatmentType.fee;
      const downPayment = acceptedSliderSettings.downPaymentAmountSelected;
      const addons = [...this.getAcceptedTreatmentAddons(acceptedSliderSettings), ...this.getAcceptedPaymentPlanAddons(acceptedSliderSettings)]
        .map((x) => this.getAddonAmount(acceptedSliderSettings, x))
        .reduce((acc, x) => acc + x, 0);
      const discounts = this.getAcceptedDiscounts(acceptedSliderSettings)
        .map((x) => this.getAddonAmount(acceptedSliderSettings, x))
        .reduce((acc, x) => acc + x, 0);
      const interest = treatmentFee * (paymentOption.interest / 100);
      return treatmentFee - (!paymentOption.payInFull ? downPayment : 0) + addons - discounts + interest;
    }

    return undefined;
  }

  getAcceptedContractGrandTotal(acceptedSliderSettings: IAvaSliderAcceptedSettings): number {
    return this.getAcceptedContractTotal(acceptedSliderSettings) - acceptedSliderSettings.insuranceCoverageAmount;
  }

  getAcceptedPaymentDescription(acceptedSliderSettings: IAvaSliderAcceptedSettings): string {
    const months = acceptedSliderSettings.monthsAmountSelected;
    const total = this.getAcceptedContractGrandTotal(acceptedSliderSettings);
    if (this.getAcceptedPaymentOption(acceptedSliderSettings).payInFull) {
      return `$${total} Paid In Full`;
    } else {
      return `${months} Months @ $${Math.round(this.getAcceptedContractGrandTotal(acceptedSliderSettings) / months)}/month`;
    }
  }

  getAddonAmount(acceptedSliderSettings: IAvaSliderAcceptedSettings, adjustmentItem: IAdjustmentItem): number {
    const treatmentType = this.getAcceptedTreatmentType(acceptedSliderSettings);
    const total = treatmentType.fee - (adjustmentItem.includeDownPayment ? acceptedSliderSettings.downPaymentAmountSelected : 0);
    return adjustmentItem && (adjustmentItem.isPercent ? total * (adjustmentItem.amount / 100) : adjustmentItem.amount);
  }

  // #endregion
}
