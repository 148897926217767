import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { PaymentOptionDto } from 'src/app/services/api.service';
import { SubSink } from 'subsink';
import { CaseItemListMode, ICaseItemListComponent } from '../case-item-list/case-item-list.component';
import { CasePresentationService } from '../case-presentation.service';

@Component({
  selector: 'app-case-payment-option-list',
  templateUrl: './case-payment-option-list.component.html',
  styleUrls: ['./case-payment-option-list.component.scss'],
})
export class CasePaymentOptionListComponent implements ICaseItemListComponent<PaymentOptionDto>, OnDestroy {
  private _subSink = new SubSink();
  @Input('paymentOptions') items: PaymentOptionDto[];
  @Input('mode') mode: CaseItemListMode = 'select';
  @Output('paymentOptionActivated') itemActivated = new EventEmitter<PaymentOptionDto>();
  @Output('paymentOptionDeleted') itemDeleted = new EventEmitter<PaymentOptionDto>();

  @Input('selected') selectedItems: PaymentOptionDto[] = [];
  @Output('selectedChange') selectedItemsChange = new EventEmitter<PaymentOptionDto[]>();

  constructor(private _casePresentationService: CasePresentationService) {}

  ngOnDestroy = (): void => this._subSink.unsubscribe();

  __editItem(item: PaymentOptionDto): void {
    this._subSink.sink = this._casePresentationService.openPaymentOptionEditorDialog(item).subscribe((result) => {
      if (result) {
        const ind = this.items.findIndex((x) => x.id == item.id);
        this.items[ind] = result;

        // Emit change to ensure parent has up to date items
        this.selectedItemsChange.emit(this.selectedItems);
      }
    });
  }
}
