import { NotificationFollowUpModelDto } from '../services/api.service';
import { HelpSessionRequest } from './AvaMultiContext';
import { EventConstants } from './Event';
import { FileViewModel } from './FileViewModel';

export interface Communication {
  customerId: number;
  patientId: number;
  leadId: number;
  responsiblePartyId: number;
  followUpStepId: number;
  teamMemberId: number;
  content: string;
  subjectLine: string;
  source: string;
  actionId: ActionType;
  eventId: EventConstants;
  file: File;
}

export enum CheckboxTypes {
  FridayFollowUp,
  SaturdayFollowUp,
  SundayFollowUp,
  FridayLeadUp,
  SaturdayLeadUp,
  SundayLeadUp,
}

export interface WeekendsMessagesSetting {
  avoidSendFollowUpOnFriday: boolean;
  avoidSendFollowUpOnSaturday: boolean;
  avoidSendFollowUpOnSunday: boolean;
  avoidSendLeadUpOnFriday: boolean;
  avoidSendLeadUpOnSaturday: boolean;
  avoidSendLeadUpOnSunday: boolean;
}

export enum ActionType {
  email = 1,
  notificaiton = 2,
  message = 3,
}

export interface CommunicationHistory {
  date: Date;
  chatdetail: ChatDetail[];
}

export interface ChatDetail {
  msgdate: string;
  communicationtype: string;
  sender_firstname: string;
  sender_lastname: string;
  msg_dir: string;
  msg_content: string;
  patientId: number;
  isLead: boolean;
  files: FileViewModel[];
}

export interface FutureChatDetail extends ChatDetail {
  followUpStepId: number;
  setId: number;
  remindId: number;
}

export type FollowUpViewModel = NotificationFollowUpModelDto;

export interface ContactLaterDetail {
  acceptedRequests: HelpSessionRequest[];
  teamAlerts: HelpSessionRequest[];
}
