/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FOLLOWUP_CATEGORY_FEATURE_KEY } from './featurekey';
import { FollowUpCategoryStoreEntity, State, featureAdapter } from './state';

export const selectFollowUpCategoryState = createFeatureSelector<State>(FOLLOWUP_CATEGORY_FEATURE_KEY);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectFollowUpCategoryState);
export const selectFollowUpCategoryIds = selectIds;
export const selectFollowUpCategoryTotal = selectTotal;
export const selectAllFollowUpCategory = selectAll;
export const selectAllFollowUpCategoryEntities = selectEntities;

export const selectFollowUpCategoryById = (id: number) =>
  createSelector(selectAllFollowUpCategory, (allFollowUpCategory: FollowUpCategoryStoreEntity[]) => {
    if (allFollowUpCategory) {
      return allFollowUpCategory.find((c) => c.followUpCategoryId === id);
    } else {
      return null;
    }
  });

export const selectBrandsError = createSelector(selectFollowUpCategoryState, (state) => state.error);

export const selectBrandsIsLoading = createSelector(selectFollowUpCategoryState, (state) => state.isLoading);
