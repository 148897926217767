import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserStoreEffectsService } from './effects';
import { USERS_FEATURE_KEY } from './featurekey';
import { featureReducer } from './reducer';

@NgModule({
  imports: [StoreModule.forFeature(USERS_FEATURE_KEY, featureReducer), EffectsModule.forFeature([UserStoreEffectsService])],
})
export class UserStoreModule {}
