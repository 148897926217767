import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientResponsiblePartyLink } from 'src/app/models/AvaContext';
import { Language } from 'src/app/models/AvaMultiContext';
import { PatientSearchResultResponsibleParty } from 'src/app/models/PatientSearch';
import { FollowUpCategoryStoreSelectors, RootStoreState, UserStoreEntity } from 'src/app/root-store';
import { IAppointment, IPerson, PatientAppointmentDetailDto } from 'src/app/services/api.service';
import { PatientService } from 'src/app/services/patient.service';
import { LogCallService } from '../log-call.service';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.css'],
})
export class PatientDetailsComponent implements OnInit, OnChanges {
  @Input() patient: IPerson;
  @Input() languages: Language[];
  @Input() teamMembers: UserStoreEntity[];
  @Input() nextFollowUpMessageDate: Date;
  @Input() includeLogCall: boolean;

  @ViewChild('ConfirmStopRemindersTemplate', { static: true }) ConfirmStopRemindersTemplate: TemplateRef<any>;
  public isLogCallVisible: boolean;

  moment = moment;
  appointmentDetails: PatientAppointmentDetailDto[];
  scheduledStatusNames: string[] = [];

  get treatmentStatus(): string {
    if (!this.patient.isLead) {
      return this.patient.treatmentStatus ? this.patient.treatmentStatus.name : '';
    } else {
      if (this.patient.isVirtualConsult) {
        return 'Virtual Consult';
      } else if (this.patient.isNewPatientScheduler) {
        return 'New Patient Scheduler';
      } else if (this.patient.isAvaChat) {
        return 'Ava Chat';
      } else {
        return '';
      }
    }
  }

  followUpRemovalCategories$ = (followUpCategoryIds: number[]): Observable<string[]> =>
    this._store$.select(FollowUpCategoryStoreSelectors.selectAllFollowUpCategory).pipe(
      map((cats) => cats.filter((x) => followUpCategoryIds.includes(x.followUpCategoryId))),
      map((cats) => cats.map((x) => x.name))
    );

  @Output() patientUpdated = new EventEmitter<IPerson>();

  constructor(
    private _patientService: PatientService,
    private _logCallService: LogCallService,
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    public actionDialog: MatDialog,
    private _store$: Store<RootStoreState.State>
  ) {}

  ngOnInit(): void {
    this._getDetails();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.includeLogCall && changes.includeLogCall.currentValue) {
      this.isLogCallVisible = changes.includeLogCall.currentValue;
    }
  }

  onPropertyChanged(): void {
    this._updatePatient();
  }

  private _getDetails() {
    this._patientService.getAppointmentDetails(this.patient.patientId).subscribe((response) => {
      this.appointmentDetails = response;
    });
    this.patient.appointments.sort((a, b) => {
      if (a.dateTime < b.dateTime) return -1;
      else if (a.dateTime > b.dateTime) return 1;
      else return 0;
    });
    this._patientService.getScheduledStatusNames().subscribe((response) => {
      this.scheduledStatusNames = response;
    });
  }

  getAppointmentType(appointment: IAppointment): string {
    if (this.appointmentDetails) {
      const appt = this.appointmentDetails.find((x) => x.appointmentId == appointment.appointmentId);
      return appt && appt.appointmentType;
    }
  }

  getAppointmentStatus(appointment: IAppointment): string {
    if (this.appointmentDetails) {
      const appt = this.appointmentDetails.find((x) => x.appointmentId == appointment.appointmentId);
      return appt && appt.appointmentStatus;
    }
  }

  getAvaAppointmentStatus(appointment: IAppointment): string {
    if (this.appointmentDetails) {
      const appt = this.appointmentDetails.find((x) => x.appointmentId == appointment.appointmentId);
      return appt && appt.avaAppointmentStatus;
    }
  }

  getAppointmentReminderStatus(appointment: IAppointment): boolean {
    if (this.appointmentDetails) {
      const appt = this.appointmentDetails.find((x) => x.appointmentId == appointment.appointmentId);
      if ((this.scheduledStatusNames && !this.scheduledStatusNames.includes(appt.appointmentStatus)) || new Date(appointment.dateTime) < new Date()) {
        return false;
      }
      return appt.isReminded;
    }
    return null;
  }

  stopReminders(appointmentId: number): void {
    this._dialog
      .open(this.ConfirmStopRemindersTemplate, { maxWidth: '300px' })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          const appointment = this.patient.appointments.find((a) => a.appointmentId === appointmentId);
          appointment.isRemindersCancelled = true;

          this._patientService.stopAppointmentReminders(appointmentId).subscribe();
        }
      });
  }

  onPrimaryRpClicked(selectedRpLink: PatientResponsiblePartyLink, toggle: MatSlideToggle): void {
    if (this.patient.isChild && selectedRpLink.isDefault) {
      toggle.checked = true;
      this._snackbar.open('Child patients require a primary responsible party.', 'OK', { duration: 5000 });
    } else {
      for (const link of this.patient.patientResponsiblePartyLinks) {
        link.isDefault = link.responsiblePartyId === selectedRpLink.responsiblePartyId ? !link.isDefault : false;
        link.isAvaPrimary = link.isDefault;
      }
      this._updatePatient(true);
    }
  }

  private _updatePatient(hasResponsiblePartyChanged = false): void {
    this._patientService.updateAvaSettings(this.patient).subscribe(
      () => {
        this._snackbar.open('Patient updated successfully.', 'OK', { duration: 2000 });
        this.patientUpdated.emit(this.patient);
        if (hasResponsiblePartyChanged) {
          const responsiblePartyLink = this.patient.patientResponsiblePartyLinks.find((x) => x.isAvaPrimary);
          const responsibleParty = <PatientSearchResultResponsibleParty>{
            responsiblePartyId: responsiblePartyLink.responsibleParty.responsiblePartyId,
            firstName: responsiblePartyLink.responsibleParty.firstName,
            lastName: responsiblePartyLink.responsibleParty.lastName,
          };
          this._patientService.sendUpdatedResponsibleParty(this.patient.patientId, responsibleParty);
        }
      },
      () => this._snackbar.open('Something went wrong while updating patient.', 'OK', { duration: 2000 })
    );
  }

  public openCommunication(): void {
    this._logCallService.logCallButtonClicked();
  }

  createPatientLogin(responsiblePartyId: number = null): void {
    this._patientService.createPatientLogin(this.patient.patientId, responsiblePartyId).subscribe(
      () => this._snackbar.open('Sucessfully created Patient Login', 'OK', { duration: 5000 }),
      (result: HttpErrorResponse) => this._snackbar.open(`FAILED: ${result.error.message}`, 'OK')
    );
  }

  followUpCategory(followUpCategoryId: number): Observable<string> {
    return this._store$.select(FollowUpCategoryStoreSelectors.selectFollowUpCategoryById(followUpCategoryId)).pipe(map((x) => x.name));
  }
}
