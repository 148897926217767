import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MatSelectChange, MatSnackBar, MAT_DIALOG_DATA } from "@angular/material";
import { TrackerTemplate } from "src/app/models/NewPatientTracker";
import { ServiceProvider } from "src/app/service";
import { SubSink } from "subsink";

@Component({
  selector: 'tracker-communication-dialog',
  templateUrl: './tracker-communication-dialog.component.html',
  styleUrls: ['./tracker-communication-dialog.component.css'],
})
export class TrackerCommunicationDialogComponent implements OnInit, OnDestroy {
    public templateContentEmail: string;
    public templateContentResponsibleEmail: string;
    public templateContentSms: string;
    public templateContentResponsibleSms: string;
    public templates: TrackerTemplate[] = [];
    public form = this._formBuilder.group({
        trackerTemplateId: [null, [Validators.required]],
        newPatientTrackIds: [[], [Validators.required]],
    });
    private _subs = new SubSink();
    private _columnsToDisplay: string[] = [
        'patientName',
        'responsibleName',
        'actions',
        'treatmentStatus',
        'appointment',
        'reactionActions',
        'welcome',
        'forms',
        'insurance',
        'survey',
    ];
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { newPatientTrackIds: number[] },
        public dialogRef: MatDialogRef<TrackerCommunicationDialogComponent>,
        private _remoteService: ServiceProvider,
        private _snackbar: MatSnackBar,
        private _formBuilder: FormBuilder) {}
        
    ngOnInit(): void {
        this.form.patchValue({
            newPatientTrackIds: this.data.newPatientTrackIds,
        });
        this._subs.sink = this._remoteService.get('NewPatientTracker/templates').subscribe(
            (response: TrackerTemplate[]) => {
                this.templates = response;
            },
            () => {
                this._snackbar.open('Sorry, there was an issue when trying to contact the server.', 'Ok', { duration: 5000 });
            }
        );
    }

    ngOnDestroy(): void {
        this._subs.unsubscribe();
    }
    
    public updateTemplatePreview(eve: MatSelectChange): void {
        const template = this.templates.find((a) => a.trackerTemplateId == eve.value);
        this.templateContentEmail = template.contentEmail;
        this.templateContentResponsibleEmail = template.contentResponsibleEmail;
        this.templateContentSms = template.contentSms;
        this.templateContentResponsibleSms = template.contentResponsibleSms;
    }
}
  