import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/ComponentBase';
import { BrandStoreSelectors, RootStoreState } from 'src/app/root-store';
import {
  AvaSliderDto,
  ContractTemplateClient,
  LedgerClient,
  PatientLedgerDto,
  PaymentOptionClient,
  PaymentOptionDto,
  TreatmentTypeClient,
  TreatmentTypeDto,
} from 'src/app/services/api.service';

@Component({
  selector: 'app-case-editor',
  templateUrl: './case-editor.component.html',
  styleUrls: ['./case-editor.component.scss'],
})
export class CaseEditorComponent extends ComponentBase {
  private _avaSlider: AvaSliderDto;
  private _avaSlider$ = new BehaviorSubject<AvaSliderDto>(null);
  public get avaSlider(): AvaSliderDto {
    return this._avaSlider;
  }
  @Input('avaSlider')
  public set avaSlider(value: AvaSliderDto) {
    this._avaSlider = value;
    this._avaSlider$.next(value);
    this.__ledgers$ = this._ledgerClient.ledger_GetLedgers(this.avaSlider.patientId);
  }
  @Output('avaSliderChange') avaSliderChange = new EventEmitter<AvaSliderDto>();

  private _brandId$ = this._store$.select(BrandStoreSelectors.getSelectedBrandId).pipe(
    filter((id) => !!id),
    distinctUntilChanged()
  );

  private _treatmentTypes$ = this._brandId$.pipe(
    switchMap((brandId) => this._treatmentTypeClient.treatmentType_GetAll(brandId)),
    shareReplay(1)
  );
  __treatmentTypes$: Observable<TreatmentTypeDto[]> = combineLatest([this._avaSlider$, this._treatmentTypes$]).pipe(
    map(([avaSlider, items]) => {
      // remap slider settings treament types onto values from api
      if (avaSlider && avaSlider.settings) {
        avaSlider.settings.treatmentTypes.forEach((sliderTreatment) => {
          const ind = items.findIndex((y) => y.id == sliderTreatment.id);
          if (ind != -1) items[ind] = sliderTreatment;
        });
      }

      return items;
    })
  );

  private _paymentOptions$ = this._brandId$.pipe(
    switchMap((brandId) => this._paymentOptionClient.paymentOption_GetAll(brandId)),
    shareReplay(1)
  );
  __paymentOptions$: Observable<PaymentOptionDto[]> = combineLatest([this._avaSlider$, this._paymentOptions$]).pipe(
    map(([avaSlider, items]) => {
      // remap slider settings payment options onto values from api
      if (avaSlider && avaSlider.settings) {
        avaSlider.settings.paymentOptions.forEach((paymentOption) => {
          const ind = items.findIndex((y) => y.id == paymentOption.id);
          if (ind != -1) items[ind] = paymentOption;
        });
      }

      return items;
    })
  );

  __ledgers$: Observable<PatientLedgerDto[]>;
  __contractTemplates$ = this._brandId$.pipe(
    switchMap((brandId) => this._contractTemplateClient.contractTemplate_Templates(brandId).pipe(shareReplay()))
  );

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _treatmentTypeClient: TreatmentTypeClient,
    private _paymentOptionClient: PaymentOptionClient,
    private _ledgerClient: LedgerClient,
    private _contractTemplateClient: ContractTemplateClient
  ) {
    super();
  }
}
