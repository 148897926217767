import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {
	transform(patient: any, consultant: any, options: any): any {
		const consultant_name = consultant;
		let patients_namefiltered = [];
		let patients_allfiltered = [];
		let setChecked = false;
		let autoChecked = false;
		let remindChecked = false;
		
		if(options!=undefined) {
			options.forEach((option) => {
				if(option.name == 'set' && option.checked == true) {
					setChecked = true;
				}
				
				if(option.name == 'auto' && option.checked == true) {
					autoChecked = true;
				}
				
				if(option.name == 'remind' && option.checked == true) {
					remindChecked = true;
				}
			});
		}
							
		if(consultant_name == 'All') {
			patients_namefiltered = patient;
		}
		else {			
			patient.forEach((value) => {
				if(value.consultant == consultant_name) {
					patients_namefiltered.push(value);
				}
			});
		}		
		
		if(setChecked!=false || autoChecked!=false || remindChecked!=false) {
						
			patients_namefiltered.forEach((value) => {
				
				if(setChecked==true && value.setting == 'set') {
					patients_allfiltered.push(value);			
				}
				if(autoChecked==true && value.setting == 'auto') {
					patients_allfiltered.push(value);			
				}
				if(remindChecked==true && value.setting == 'remind') {
					patients_allfiltered.push(value);			
				}
			});
			
			return patients_allfiltered;
		}
		else {
			patients_allfiltered = patients_namefiltered;
			
			return patients_allfiltered;
		}	
	}
}
