import { createAction, props } from '@ngrx/store';
import { ContractTemplateStoreEntity } from './state';

export const Init = createAction('[Contract Templates] Init');

export const LoadRequest = createAction('[Contract Templates] Load Request', props<{ brandId?: number; reload?: boolean }>());
export const LoadSuccess = createAction('[Contract Templates] Load Success', props<{ contractTemplates: ContractTemplateStoreEntity[] }>());
export const LoadFailure = createAction('[Contract Templates] Load Failure', props<{ error: string | unknown }>());

export const AddRequest = createAction('[Contract Templates] Add Request', props<{ contractTemplate: ContractTemplateStoreEntity }>());
export const AddSuccess = createAction('[Contract Templates] Add Success', props<{ contractTemplate: ContractTemplateStoreEntity }>());
export const AddFailure = createAction('[Contract Templates] Add Failure', props<{ error: string | unknown }>());

export const UpdateRequest = createAction('[Contract Templates] UpdateRequest', props<{ contractTemplate: ContractTemplateStoreEntity }>());
export const UpdateSuccess = createAction('[Contract Templates] UpdateSuccess', props<{ contractTemplate: ContractTemplateStoreEntity }>());
export const UpdateFailure = createAction('[Contract Templates] UpdateFailure', props<{ error: string | unknown }>());

export const DeleteRequest = createAction('[Contract Templates] DeleteRequest', props<{ contractTemplateId: number }>());
export const DeleteSuccess = createAction('[Contract Templates] DeleteSuccess', props<{ contractTemplateId: number }>());
export const DeleteFailure = createAction('[Contract Templates] DeleteFailure', props<{ error: string | unknown }>());
