/* eslint-disable */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { FollowUpCategoryEnum, FollowUpTypeEnum } from 'src/app/models/AvaContext';
import { ActionType } from 'src/app/models/Communication';
import { LocationStoreSelectors, RootStoreState } from 'src/app/root-store';
import { AuthService } from 'src/app/services/auth.service';
import { AvaSelectOption } from 'src/app/shared/ava-select';
import { ITemplateContextComponent } from 'src/app/templates/ITemplateContextComponent';
import { TemplateType } from 'src/app/templates/TemplateType';
import { TemplatesHelper } from 'src/app/templates/TemplatesHelper';
import { TemplatesLibraryComponent } from 'src/app/templates/templates-library/templates-library.component';
import { ServiceProvider } from '../../service';
import { FollowUpModalComponent } from '../followup-modal';

@Component({
  selector: 'app-set-modal',
  templateUrl: './set-modal.component.html',
  styleUrls: ['./set-modal.component.css'],
})
export class SetModal extends FollowUpModalComponent implements OnInit, ITemplateContextComponent {
  actionOptions: AvaSelectOption[] = [new AvaSelectOption('Text', ActionType.message), new AvaSelectOption('Email', ActionType.email)];

  dragIndexArr: any = [];
  timings: string[] = [
    '12:00 am',
    '01:00 am',
    '02:00 am',
    '03:00 am',
    '04:00 am',
    '05:00 am',
    '06:00 am',
    '07:00 am',
    '08:00 am',
    '09:00 am',
    '10:00 am',
    '11:00 am',
    '12:00 pm',
    '01:00 pm',
    '02:00 pm',
    '03:00 pm',
    '04:00 pm',
    '05:00 pm',
    '06:00 pm',
    '07:00 pm',
    '08:00 pm',
    '09:00 pm',
    '10:00 pm',
    '11:00 pm',
  ];
  get timingOptions(): AvaSelectOption[] {
    return this.timings.map((t) => new AvaSelectOption(t, t));
  }

  get canAddToFollowUp(): boolean {
    return !(
      this.ajaxIsBusy ||
      (this.AutoSetRemindObj.actionId === ActionType.email && !this.canSendEmail) ||
      (this.AutoSetRemindObj.actionId === ActionType.message && !this.canSendSms) ||
      (this.patientsIsLead() && this.AutoSetRemindObj.followUpCategoryId !== FollowUpCategoryEnum.NewLead)
    );
  }

  get canSubmit(): boolean {
    if (!this.canAddToFollowUp) {
      return false;
    } else if (this.AutoSetRemindObj.actionId === ActionType.email) {
      return !!this.AutoSetRemindObj.subject && !!this.AutoSetRemindObj.content;
    } else {
      return !!this.AutoSetRemindObj.content;
    }
  }

  constructor(
    _matDialogRef: MatDialogRef<SetModal>,
    remoteService: ServiceProvider,
    authService: AuthService,
    @Inject(MAT_DIALOG_DATA) data: any,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    store$: Store<RootStoreState.State>,
    public templatesHelper: TemplatesHelper
  ) {
    super(data, _matDialogRef, authService, remoteService, snackBar, dialog, store$, FollowUpTypeEnum.Set);
  }

  ngOnInit() {
    super.ngOnInit();
    this._store$.select(LocationStoreSelectors.getSelectedLocationId).subscribe((selectedLocationId) => {
      var getOffsetFunc = this.isMultiMode
        ? () => this.remoteService.get<string>(`avasfollowup/getlocaldatestringtomorrowforlocationid/${selectedLocationId}`, null, 'text')
        : () => this.remoteService.get<string>(`avasfollowup/getlocaldatestringtomorrow/${this.patient.location.offset}`, null, 'text');
      getOffsetFunc().subscribe(
        (date) => {
          this.AutoSetRemindObj.date = date;
          this.startDate = new Date(date + 'T00:00:00');
        },
        (err) => {
          this.startDate.setDate(this.startDate.getDate() + 1);
        }
      );
    });
    this.setTiming('12:00 pm');
    this.setAction(ActionType.message); // SMS
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(ev: DragEvent, index: number) {
    document.getElementById('textarea_' + index).focus;
    ev.dataTransfer.setData('Text', (ev.target as HTMLInputElement).id);
  }
  insertTextCharAt(mainText, tokenAdd, startpos, endpos) {
    return mainText.substr(0, startpos) + tokenAdd + mainText.substr(endpos);
  }
  drop(ev: DragEvent) {
    this.dragIndexArr = [];
    const element = ev.target as HTMLInputElement;
    const data = ev.dataTransfer.getData('Text');
    const templateData = element.value;
    const keytext = document.getElementById(data).getAttribute('data-text');

    const startpos = element.selectionStart;
    const endpos = element.selectionEnd;
    const actualText = templateData.substr(0, startpos) + keytext + templateData.substr(endpos);
    element.value = actualText;
    ev.preventDefault();
  }

  setTiming(id: string) {
    this.AutoSetRemindObj.time = id;
  }
  setAction(id: number) {
    this.AutoSetRemindObj.actionId = id;
    this.checkCanSendMessage();
  }

  submit() {
    // CONDITION: No message content or missing email or cell phone number
    if (
      !this.AutoSetRemindObj.content ||
      (this.AutoSetRemindObj.actionId === ActionType.email && !this.canSendEmail) ||
      (this.AutoSetRemindObj.actionId === ActionType.message && !this.canSendMessage)
    ) {
      return;
    }
    // CONDITION: Email with no subject line
    if (this.AutoSetRemindObj.actionId === ActionType.email && !this.AutoSetRemindObj.subject) {
      return;
    }
    super.submit();
  }

  public openTemplatesLibrary(): void {
    let type: TemplateType;
    if (this.AutoSetRemindObj.actionId === ActionType.email) {
      type = TemplateType.Email;
    } else if (this.AutoSetRemindObj.actionId === ActionType.message) {
      type = TemplateType.Message;
    } else {
      return;
    }

    this.templatesHelper.templateTableData = [];
    this.templatesHelper.templateTableData.push(this.templatesHelper.getTemplateTableDataByType(type));

    const dialogRef = this.dialog.open(TemplatesLibraryComponent, {
      width: '900px',
      panelClass: 'template-library-dialog',
      autoFocus: false,
    });

    const instance = dialogRef.componentInstance;
    instance.dataSource = this.templatesHelper.templateTableData;

    const updateFunction = this.templatesHelper.updateData.bind(this);
    instance.updateFunction = updateFunction;

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.AutoSetRemindObj.content = data.content;
        this.AutoSetRemindObj.subject = data.title;
      }
    });
  }
}
