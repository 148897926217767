
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';
import { AvaChatStatistics } from 'src/app/models/AvaChatStatistics';
import { BrandStoreSelectors, RootStoreState } from 'src/app/root-store';
import { HelpCategoryCountList, HelpService } from '../../services/help.service';
import { Answer } from '../../settings/ava-chat-bot/chat-tree/chat-tree.component';
import { ChatBotService } from '../../settings/ava-chat-bot/services/chat-bot.service';
import { QuestionnaireService } from '../../settings/ava-chat-bot/services/questionnaire.service';
import { HelpAnalytics } from '../models/HelpAnalytics.model';
import { HelpCategoryAnalytics } from '../models/HelpCategoryAnalytics.model';
import { ContactDetails } from './modal/contact-details/contact-details';

@Component({
  selector: 'app-chat-dashboard',
  templateUrl: './chat-dashboard.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./chat-dashboard.component.scss'],
})
export class ChatDashboardComponent implements OnInit {
  @ViewChild('tree', { static: true }) tree;
  public customStartDateSelected = false;
  public customEndDateSelected = false;
  public listChats: any = [];
  public questionnairesTree: any = { answerId: '' };
  public activeQuestionnaires: any;
  public allChatStatistics: AvaChatStatistics;
  public chatStatistics: AvaChatStatistics;
  public treeControl = new NestedTreeControl<Answer>(
    (node) => {
      const qustinar = node.questionnaires || [];
      const answers: Answer[] = (qustinar[0] || { answers: [] }).answers;

    return answers;
  });
  public dataSource = new MatTreeNestedDataSource<Answer>();
  private _startDate: Date;
  private _endDate: Date;
  private _customStartDate: Date;
  private _customEndDate: Date;
  private _isValidCustomDate = false;
  private _currentDays: number;
  private _chatAnalyticsList: HelpAnalytics[];
  private _getInformationHelpCategoryList: HelpCategoryAnalytics[];
  private _virtualConsultationHelpCategoryList: HelpCategoryAnalytics[];
  private _scheduleApptHelpCategoryList: HelpCategoryAnalytics[];
  private _connectHumanHelpCategoryList: HelpCategoryAnalytics[];
  private _treatmentHelpCategoryList: HelpCategoryAnalytics[];
  private _gotoShedulPageHelpCatogoryList: HelpCategoryAnalytics[];
  private _treatmentOptionclearHelpCategoryList: HelpCategoryAnalytics[];
  private _contactInformationHelpCategoryList: HelpCategoryAnalytics[];
  private _helpCategoryAnalyticsList: HelpCategoryCountList = { helpCategoryId: 0, count: 0 };
  private _customeDay: number;
  private _labels = 'abcdefghijklmnopqrstuvwxyz'.split('');

  constructor(
    public dialog: MatDialog,
    private _helpService: HelpService,
    private _chatBotService: ChatBotService,
    private _questionnaireService: QuestionnaireService,
    private _store$: Store<RootStoreState.State>
  ) {}

  ngOnInit(): void {
    this._store$
      .select(BrandStoreSelectors.getSelectedBrandId)
      .pipe(
        filter((id) => !!id),
        distinctUntilChanged()
      )
      .subscribe(
        () => {
          this._getAllChats();
        }
      );
      this._onSelectTimePeriod(7);
      this._getAnalyticsData(this._startDate, this._endDate);
      this._getAllChats();
      this._chatBotService
      .geAllStatistics({startDate: this._startDate.toJSON(), endDate: this._endDate.toJSON()})
      .subscribe(
        (res: AvaChatStatistics) => {
          this.allChatStatistics = res;
        }
      );
  }

  public loadChat(chatId: number): void {
    this._questionnaireService
    .getAllTree(chatId)
    .subscribe(
      (tree) => {
        this.questionnairesTree = tree;
        this.activeQuestionnaires = tree;
        this.dataSource.data = [];
        this.dataSource.data = this.questionnairesTree.answers;
        this.treeControl.dataNodes = this.questionnairesTree.answers;
        this.tree.treeControl.expandAll();
        this.generatePrefix(this.questionnairesTree.answers, '', true, 0);
      }
    );
    this._chatBotService
      .getChatStatistics(chatId, { startDate: this._startDate.toJSON(), endDate: this._endDate.toJSON() })
      .subscribe(
        (chatStatistics: AvaChatStatistics) => {
          this.chatStatistics = chatStatistics;
        }
      );
  }

  public openTreeBranch(answer: Answer): void {
    this.activeQuestionnaires = answer.questionnaires[0];
  }

  public generatePrefix(answers: Answer[], prefix: string, first = false, level = 0): void {
    answers.forEach(
      (a: Answer, i) => {
        const index = i + 1;
        a.prefix = prefix + (first ? index : this._labels[i]);
        a.level = level;
        if (a.questionnaires && a.questionnaires[0] && a.questionnaires[0].answers) {
          this.generatePrefix(a.questionnaires[0].answers, a.prefix, false, a.level + 1);
        }
      }
    );
  }

  hasChild = (_: number, node: Answer): boolean => !!(node.questionnaires && node.questionnaires[0]) && node.questionnaires[0].answers.length > 0;

  public openContactDetails(): void {
    const dialogRef = this.dialog.open(ContactDetails, {
      backdropClass: 'whitebackdrop',
      panelClass: 'no-pad-dialog',
      width: '100%',
      data: {
        bindNumberOfDays: this._currentDays,
        startDate: this._startDate,
        endDate: this._endDate,
        botMessengerId: this.questionnairesTree.answerId,
      },
    });
    dialogRef.afterClosed().subscribe();
  }

  public onChangeTab(event: MatTabChangeEvent): void {
    switch (event.index) {
      case 0:
        this._currentDays = 7;
        this._onSelectTimePeriod(7);
        this._getAnalyticsData(this._startDate, this._endDate);
        break;
      case 1:
        this._currentDays = 30;
        this._onSelectTimePeriod(30);
        this._getAnalyticsData(this._startDate, this._endDate);
        break;
      case 2:
        if (this._isValidCustomDate == true) {
          this._startDate = this._customStartDate;
          this._endDate = this._customEndDate;
          this._getAnalyticsData(this._customStartDate, this._customEndDate);
        }
        break;
    }
  }

  public onSelectCustomDate(value: Date, index: number): void {
    this._isValidCustomDate = false;

    if (index === 1) {
      this._customStartDate = new Date(value);
      this.customStartDateSelected = true;
    } else {
      this._customEndDate = new Date(value);
      this.customEndDateSelected = true;
    }

    if (this._customStartDate !== undefined && this._customEndDate !== undefined) {
      if (this._customStartDate.getTime() <= this._customEndDate.getTime()) {
        this._isValidCustomDate = true;
      }
    }

    if (this._isValidCustomDate) {
      this._startDate = this._customStartDate;
      this._endDate = this._customEndDate;
      this._getAnalyticsData(this._customStartDate, this._customEndDate);
      this._chatBotService
        .geAllStatistics({ startDate: this._startDate.toJSON(), endDate: this._endDate.toJSON() })
        .subscribe((res: AvaChatStatistics) => (this.allChatStatistics = res));
      this._chatBotService
        .getChatStatistics(this.questionnairesTree.answerId, { startDate: this._startDate.toJSON(), endDate: this._endDate.toJSON() })
        .subscribe((res: AvaChatStatistics) => (this.chatStatistics = res));
    }
    this._customeDay = this._customStartDate.getTime() - this._customEndDate.getTime();
    this._currentDays = Math.abs(this._customeDay / (1000 * 3600 * 24));
  }

  private _getAllChats(): void {
    const brandId = Number(sessionStorage.getItem('brandId'));

    this._store$
        .select(BrandStoreSelectors.getSelectedBrandId)
        .pipe(
          take(1),
          switchMap((brandId) => {
            return this._chatBotService.getAllChats(brandId);
          })
        )
        .subscribe(
          (listChats) => {
            this.listChats = listChats;
            if (this.listChats.length) {
              this.loadChat(this.listChats[0].id);
            }
          }
        );
  }

  private _getAnalyticsData(startDate: Date, endDate: Date): void {
    this._helpService
    .GetAnalyticsData(startDate, endDate)
    .subscribe(
      (res) => {
        this._chatAnalyticsList[0].count = res.chatsInitiated;
        this._chatAnalyticsList[1].count = res.humanChatsRequested;

        if (res.chatsInitiated !== 0) {
          this._chatAnalyticsList[1].percentage = Math.round((res.humanChatsRequested / res.chatsInitiated) * 100).toString() + '%';
        }

        this._chatAnalyticsList[2].count = res.humanChatsAccepted;

        if (res.humanChatsRequested !== 0) {
          this._chatAnalyticsList[2].percentage = Math.round((res.humanChatsAccepted / res.humanChatsRequested) * 100).toString() + '%';
        }
      }
    );
  }

  private _onSelectTimePeriod(period: number): void {
    this._endDate = new Date();
    this._startDate = new Date();

    if (period === 7) {
      this._startDate.setDate(this._endDate.getDate() - 7);
      this._currentDays = 7;
    } else {
      this._startDate.setDate(this._endDate.getDate() - 30);
    }
  }
}



