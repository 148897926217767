import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { FeatureFlagService } from "../services/feature-flag.service";

@Directive({
    selector: '[RemoveIfFeatureOn]'
})
export class RemoveIfFeatureOnDirective implements OnInit {
    @Input('RemoveIfFeatureOn') featureName: string;
    
    constructor(private _el: ElementRef, private _featureFlagService: FeatureFlagService) { }
        
    ngOnInit(): void {
        if (this._featureFlagService.featureOn(this.featureName)) {
            this._el.nativeElement.remove();
        }
    }
}