import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { AuthStoreSelectors } from 'src/app/root-store/auth-store';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-url-gopher',
  templateUrl: './url-gopher.component.html',
  styleUrls: ['./url-gopher.component.css'],
})
export class UrlGopherComponent implements OnInit, OnDestroy {
  @ViewChild('overlayTemplate', { static: true }) overlayTemplate: TemplateRef<any>;
  @ViewChild('unlockTemplate', { static: true }) unlockTemplate: TemplateRef<any>;

  overlayRef: OverlayRef; //reference to the overlay that the template is attached and detached from
  iFrameUrl: SafeUrl; //url for which the iframe to navigate to
  hubConn: signalR.HubConnection; //connection to the SignalR hub
  HubConnectionState = signalR.HubConnectionState;
  machineName: string; //name of this machines connection
  locked: boolean = false; //boolean indicating whether the overlay is locked or not
  lockPasscode: string; //passcode for locking or unlocking the overlay
  unlockDialog: MatDialogRef<any>; //reference to the dialog created when unlock dialog is opened
  subs = new SubSink(); //subsink for droping all subscriptions into (for mass unsubscribe OnDestroy)
  customerId: string; //encrypted CustomerId that is passed to the server so machine is identified for who it belongs to.
  private _oauth$ = this._store$.select(AuthStoreSelectors.selectOAuth);

  constructor(
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _domSanitizer: DomSanitizer,
    private _snackbar: MatSnackBar,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _store$: Store<RootStoreState.State>
  ) {
    this.machineName = localStorage.getItem('GopherMachineName');
    this._activatedRoute.params.subscribe((params) => {
      this.customerId = params['customerId'];
      if (!this.customerId) {
        this._snackbar.open('No Customer ID Found. Please verify url parameters.', 'OK');
      }
    });
  }

  ngOnInit(): void {
    const positionStrategy = this._overlay.position().global().centerHorizontally().centerVertically();
    this.overlayRef = this._overlay.create({
      backdropClass: 'cdk-overlay-dark-backdrop',
      hasBackdrop: true,
      height: '100%',
      width: '100%',
      positionStrategy,
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (this.hubConn) this.hubConn.stop();
  }

  //Create iframe overlay that urls will be loaded into, set locked
  build(): void {
    const portal = new TemplatePortal(this.overlayTemplate, this._viewContainerRef);
    this.overlayRef.attach(portal);
    this.locked = true;
  }

  //Remove overlay
  deconstruct(): void {
    if (this.overlayRef.hasAttached) {
      this.overlayRef.detach();
    }
  }

  //Connect to UrlGopher hub where we will listen for incoming urls.
  connect(): void {
    if (isNullOrUndefined(this.machineName)) return;
    if (isNullOrUndefined(this.hubConn))
      //make sure its been initialized
      this.initHub();
    if (this.hubConn.state == signalR.HubConnectionState.Disconnected)
      //make sure its in a disconnected state
      this.hubConn.start().then(() => {
        this.setMachineName(this.machineName);
        this.build();
      });
  }

  //Disconnect from hub
  disconnect(): void {
    if (!isNullOrUndefined(this.hubConn))
      if (this.hubConn.state == signalR.HubConnectionState.Connected)
        //make sure its been initialized
        //make sure its in a connected state
        this.hubConn.stop();
  }

  //Sets up hub connection configuration with response actions
  initHub(): void {
    this._store$
      .select(AuthStoreSelectors.selectUserInfo)
      .pipe(take(1))
      .subscribe((res) => {
        this.hubConn = new signalR.HubConnectionBuilder()
          .withUrl(`${environment.ServerPath}api/GopherHub?client_name=${res.clientName}`, {
            accessTokenFactory: () =>
              this._oauth$
                .pipe(
                  take(1),
                  map((x) => x.access_token)
                )
                .toPromise(),
          })
          .build();
        this.hubConn.on('UrlRequest', (json: string) => {
          const request: GopherUrlRequest = JSON.parse(json);
          if (request && request.url) {
            this.iFrameUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(request.url);
          }
        });
        this.hubConn.on('MachineName', (name: string) => {
          this.machineName = name;
        });
        this.hubConn.onclose((err) => {
          if (err) this._snackbar.open(`Hub Disconnected Error: ${err.message}`, 'Ok');
        });
      });
  }

  //Sends the friendly name of the machine to be set inside the hub
  setMachineName(name: string): void {
    localStorage.setItem('GopherMachineName', name);
    if (!isNullOrUndefined(this.hubConn)) this.hubConn.send('MachineName', this.customerId, name);
  }

  //Loop back test sends url to hub then gets it right back as incoming message
  sendUrl(url: string): void {
    if (!isNullOrUndefined(this.hubConn))
      this.hubConn.send('SendMessage', this.customerId, this.machineName, JSON.stringify({ url: url }), 'ReceiveUrl');
  }

  //open up the dialog for unlocking (detaching) the overlay
  openUnlockDialog(): void {
    if (this.locked) {
      this.unlockDialog = this._dialog.open(this.unlockTemplate);
      this.subs.sink = this.unlockDialog.afterClosed().subscribe((result) => {
        //bool indicating either successful unlock or cancel
        if (result) {
          this.iFrameUrl = null;
          this.locked = false;
          this.deconstruct();
        }
      });
    }
  }

  //compare passcode to stored lock passcode
  tryUnlock(passcode: string): void {
    if (passcode == this.lockPasscode) this.unlockDialog.close(true);
    else this._snackbar.open('Incorrect Passcode', 'OK', { duration: 2000 });
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    //Ctrl+Shift+~ to open unlock dialog
    if (event.ctrlKey && event.shiftKey && event.key == '~') {
      this.openUnlockDialog();
    }
  }
}

export class GopherUrlRequest {
  url: string;
}
