import { OnInit, Component } from '@angular/core';
import { ITemplate } from './ITemplate';
import { TemplateType } from './TemplateType';
import { TemplatesService } from '../services/templates.service';
import { TemplateAction } from './TemplateAction';
import { ITemplateTableData } from './templates-table/ITemplateTableData';
import { EMAILS_TABLE_NAME, MESSAGES_TABLE_NAME, NOTES_TABLE_NAME } from './TemplatesHelper';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})

export class TemplatesComponent implements OnInit {
  public noteTemplates: ITemplate[];
  public emailTemplates: ITemplate[];
  public messageTemplates: ITemplate[];
  public TemplateType = TemplateType;
  public templateTableData: ITemplateTableData[] = [];
  public updateFunction = this.updateData.bind(this);

  private _templates: ITemplate[];

  constructor(private _templatesService: TemplatesService) { }

  ngOnInit(): void {
      this._templatesService.getAll().subscribe(data => {
      this._templates = data;
      this.noteTemplates = data.filter(_ => _.templateType === TemplateType.Note);
      this.emailTemplates = data.filter(_ => _.templateType === TemplateType.Email);
      this.messageTemplates = data.filter(_ => _.templateType === TemplateType.Message);

      this.templateTableData.push(<ITemplateTableData>{
        tableName: NOTES_TABLE_NAME,
        tableType: TemplateType.Note,
        templates: this.noteTemplates
      });
      this.templateTableData.push(<ITemplateTableData>{
        tableName: EMAILS_TABLE_NAME,
        tableType: TemplateType.Email,
        templates: this.emailTemplates
      });
      this.templateTableData.push(<ITemplateTableData>{
        tableName: MESSAGES_TABLE_NAME,
        tableType: TemplateType.Message,
        templates: this.messageTemplates
      });
      this.templateTableData = this.templateTableData.slice();
    });
  }

  public updateData(data: any): void {
    switch (data.action) {
      case TemplateAction.Add:
        this._templatesService.add(data.template).subscribe(_ => this._addToTable(_));
        break;
      case TemplateAction.Edit:
        this._templatesService.edit(data.template).subscribe(_ => this._updateTable(_));
        break;
      case TemplateAction.Delete:
        this._templatesService.delete(data.template.id).subscribe(() => this._deleteFromTable(data.template));
        break;
    }
  }

  private _addToTable(data: ITemplate): void {
    if (data.templateType === TemplateType.Note) {
      this.noteTemplates.push(data);
      this.noteTemplates = this.noteTemplates.slice();
      this.templateTableData[0].templates = this.noteTemplates;
      this.templateTableData = this.templateTableData.slice();
    }
    if (data.templateType === TemplateType.Email) {
      this.emailTemplates.push(data);
      this.emailTemplates = this.emailTemplates.slice();
      this.templateTableData[1].templates = this.emailTemplates;
      this.templateTableData = this.templateTableData.slice();
    } else {
      this.messageTemplates.push(data);
      this.messageTemplates = this.messageTemplates.slice();
      this.templateTableData[2].templates = this.messageTemplates;
      this.templateTableData = this.templateTableData.slice();
    }
    this._templates.push(data);
  }

  private _updateTable(data: ITemplate): void {
    const existing = this._templates.find(x => x.id === data.id);
    const index = this._templates.indexOf(existing);
    this._templates[index] = data;
    if (data.templateType === existing.templateType) {
      this._updateTableByType(data.templateType);
    } else {
      this._updateTemplates(this._templates);
    }
  }

  private _deleteFromTable(data: ITemplate): void {
    const existing = this._templates.find(x => x.id === data.id);
    const index = this._templates.indexOf(existing);
    this._templates.splice(index, 1);
    this._updateTableByType(existing.templateType);
  }

  private _updateTemplates(data: ITemplate[]): void {
    this._updateEmailTemplates(data);
    this._updateMessageTemplates(data);
    this.templateTableData = this.templateTableData.slice();
  }

  private _updateTableByType(type: TemplateType): void {
    switch (type) {
      case TemplateType.Email:
        this._updateEmailTemplates(this._templates);
        this.templateTableData = this.templateTableData.slice();
        break;
      case TemplateType.Message:
        this._updateMessageTemplates(this._templates);
        this.templateTableData = this.templateTableData.slice();
        break;
      case TemplateType.Note:
        this._updateNotesTemplates(this._templates);
        this.templateTableData = this.templateTableData.slice();
        break;
    }
  }

  private _updateNotesTemplates(data: ITemplate[]): void {
    this.messageTemplates = data.filter(_ => _.templateType === TemplateType.Note);
    this.templateTableData[0] = <ITemplateTableData>{
      tableName: 'Notes',
      tableType: TemplateType.Message,
      templates: this.messageTemplates
    };
  }

  private _updateEmailTemplates(data: ITemplate[]): void {
    this.emailTemplates = data.filter(_ => _.templateType === TemplateType.Email);
    this.templateTableData[1] = (<ITemplateTableData>{
      tableName: 'Emails',
      tableType: TemplateType.Email,
      templates: this.emailTemplates
    });
  }

  private _updateMessageTemplates(data: ITemplate[]): void {
    this.messageTemplates = data.filter(_ => _.templateType === TemplateType.Message);
    this.templateTableData[2] = <ITemplateTableData>{
      tableName: 'Text Messages',
      tableType: TemplateType.Message,
      templates: this.messageTemplates
    };
  }
}
