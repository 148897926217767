import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TemplatesService } from '../services/templates.service';
import { SubjectTemplate } from '../templates/SubjectTemplate';
import { ITemplateContextComponent } from './ITemplateContextComponent';
import { ITemplateUpdate } from './ITemplateUpdate';
import { TemplateAction } from './TemplateAction';
import { ITemplateTableData } from './templates-table/ITemplateTableData';
import { TemplateType } from './TemplateType';

export const EMAILS_TABLE_NAME = 'Emails';
export const MESSAGES_TABLE_NAME = 'Text Messages';
export const NOTES_TABLE_NAME = 'Notes';

@Injectable({
  providedIn: 'root',
})
export class TemplatesHelper {
  public templateTableData: ITemplateTableData[];
  private _subject = new Subject<SubjectTemplate>();
  public get templatesService(): TemplatesService {
    return this._templatesService;
  }

  constructor(private _templatesService: TemplatesService) {}

  public sendTableData(tableData: ITemplateTableData[]): void {
    this._subject.next({ data: tableData[0].templates });
  }

  public getTableData(): Observable<any> {
    return this._subject.asObservable();
  }

  public crearTableData(): void {
    this._subject.next();
  }

  public getTemplateTableDataByType(templateType: TemplateType): ITemplateTableData {
    const templateTableData = <ITemplateTableData>{
      tableType: templateType,
    };

    switch (templateType) {
      case TemplateType.Email:
        templateTableData.tableName = EMAILS_TABLE_NAME;
        break;
      case TemplateType.Message:
        templateTableData.tableName = MESSAGES_TABLE_NAME;
        break;
      case TemplateType.Note:
        templateTableData.tableName = NOTES_TABLE_NAME;
        break;
    }

    this._templatesService.getAllByType(templateType).subscribe((_) => {
      templateTableData.templates = _;
    });

    return templateTableData;
  }

  public updateData(data: ITemplateUpdate): void {
    const context = (<ITemplateContextComponent>Object(this)).templatesHelper;
    switch (data.action) {
      case TemplateAction.Add:
        context.templatesService.add(data.template).subscribe((_) => {
          context.templateTableData[0].templates.push(_);
          context.sendTableData(context.templateTableData);
        });
        break;
      case TemplateAction.Edit:
        context.templatesService.edit(data.template).subscribe((_) => {
          const existing = context.templateTableData[0].templates.find((x) => x.id === _.id);
          const index = context.templateTableData[0].templates.indexOf(existing);
          context.templateTableData[0].templates[index] = _;
          context.sendTableData(context.templateTableData);
        });
        break;
      case TemplateAction.Delete:
        context.templatesService.delete(data.template.id).subscribe(() => {
          const existing = context.templateTableData[0].templates.find((x) => x.id === data.template.id);
          const index = context.templateTableData[0].templates.indexOf(existing);
          context.templateTableData[0].templates.splice(index, 1);
          context.sendTableData(context.templateTableData);
        });
        break;
    }
  }
}
