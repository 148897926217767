import { Component, Input, ViewChildren } from '@angular/core';
import { MatCheckboxChange } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, take } from 'rxjs/operators';
import { CommunicationType, ModalData, PatientDetailsArea } from 'src/app/modal/modal-container/ModalData';
import { FollowUpModalData, FollowUpMultiModalData } from 'src/app/models/AutoSetRemind';
import { AvasPatient } from 'src/app/models/Dashboard';
import { ModalContainerService, MODAL_TYPE } from 'src/app/services/modal-container.service';
import { AvaSelectOption } from 'src/app/shared/ava-select';
import { ServiceProvider } from '../../../service';

@Component({
  selector: 'app-teamdecision-card',
  templateUrl: './team-decision.component.html',
  styleUrls: ['./team-decision.component.css'],
})
export class TeamDecisionComponent {
  @ViewChildren('actionmenu') Actionmenu: any;
  @ViewChildren('stop') Stop: any;
  @ViewChildren('recordlist') recordList: any;
  @Input('cardId') cardId: any;
  private _cardList: AvasPatient[];
  @Input('cardList') get cardList(): AvasPatient[] {
    return this._cardList;
  }
  set cardList(value: AvasPatient[]) {
    this._cardList = value;
    this.displayCardList = (value || []).slice();
    this.selectedPatients = this.selectedPatients.filter((x) => this._cardList.indexOf(x) !== -1); // remove all data which not in filter
  }
  displayCardList = (this.cardList || []).slice();
  @Input('cardHeading') cardHeading: any;
  @Input('followUpId') followUpId: number;
  @Input('followUpCategoryId') followUpCategoryId: number;
  @Input('disabled') disabledMsg: string;
  @Input() stopSide: string;
  @Input('showLeadSource') showLeadSource: boolean = false;

  public openDropdownId: string = '';
  private _teamdShowCardPatients = false;
  public get teamdShowCardPatients(): boolean {
    return this._teamdShowCardPatients;
  }
  public set teamdShowCardPatients(value: boolean) {
    this._teamdShowCardPatients = value;
    this.selectedPatients = [];
    this.searchFilter = '';
  }
  public selectedConsult = 'All';
  public checkOptions = [
    { name: 'auto', value: 1, checked: false },
    { name: 'set', value: 2, checked: false },
    { name: 'remind', value: 3, checked: false },
  ];
  public isShowMultiRemoveWindow = false;
  public isShowConfirmMultiHandlingWindow = false;
  public isMultiActionOpen = false;
  public multiHandlingMode?: MODAL_TYPE | 'remove' = null;
  public searchFilter: string = '';
  public selectedPatients: AvasPatient[] = [];
  public sortDirection = 1;
  get isAllSelected(): boolean {
    return this.selectedPatients.length === this.displayCardList.length;
  }
  public multiHandlingModes: AvaSelectOption[] = [
    new AvaSelectOption('auto', 'auto'),
    new AvaSelectOption('set', 'set'),
    new AvaSelectOption('remind', 'remind'),
    new AvaSelectOption('remove', 'remove'),
  ];
  public PatientDetailsArea = PatientDetailsArea;
  public CommunicationType = CommunicationType;

  constructor(private _modalContainerService: ModalContainerService, public dialog: MatDialog, private _remoteService: ServiceProvider) {}

  public showActionmenu(event: any): void {
    const list = event.target.parentElement;

    if (list.classList.contains('clicked') == false) {
      this.recordList.forEach((child) => {
        child.nativeElement.classList.remove('clicked');
      });

      list.classList.add('clicked');
    } else {
      list.classList.remove('clicked');
    }
  }

  public cancelMultiHandling(): void {
    this.isShowConfirmMultiHandlingWindow = false;
    this.isShowMultiRemoveWindow = false;
    this.resetMultiHandlingDdValue();
  }

  public resetMultiHandlingDdValue() {
    this.multiHandlingMode = null;
  }

  public confirmMultiHandling(): void {
    this.isShowConfirmMultiHandlingWindow = false;
    this.showAvaMultiFollowupModal();
    this.resetMultiHandlingDdValue();
  }

  public confirmMultiRemove(): void {
    const isLead = this.selectedPatients.every((x) => x.isLead);
    const ids = this.selectedPatients.map((x) => x.id);
    this.resetMultiHandlingDdValue();
    this._remoteService.post<any>(`avasfollowup/removerangefromteamdecision/${this.followUpCategoryId}/${isLead}`, ids).subscribe(() => {
      if (this.cardList) {
        const cardData = this.cardList;
        this.cardList = [];
        this.cardList = cardData.filter((x) => !ids.includes(x.id));
        this.setFilter();
        this.selectedPatients = [];
      }
    });
    this.isShowMultiRemoveWindow = false;
  }

  public isPatientSelected(patient: AvasPatient) {
    return this.selectedPatients.indexOf(patient) !== -1;
  }

  public selectRow(event: MatCheckboxChange, patient: AvasPatient) {
    if (event.checked) {
      if (!this.selectedPatients.some((x) => x == patient)) {
        this.selectedPatients.push(patient);
      }
    } else {
      this.selectedPatients = this.selectedPatients.filter((x) => x !== patient);
    }
  }

  public setFilter() {
    this.displayCardList = this.cardList.filter(
      (x) => !this.searchFilter || `${x.firstname || ''} ${x.lastname || ''}`.toLowerCase().includes(this.searchFilter.toLowerCase())
    );
    this.selectedPatients = this.selectedPatients.filter((x) => this.displayCardList.indexOf(x) !== -1); // remove all data which not in filter
  }

  public selectAll(event: MatCheckboxChange) {
    this.selectedPatients = event.checked ? this.displayCardList : [];
  }

  public startHandleSelected(): void {
    if (!this.multiHandlingMode) {
      return;
    }
    if (this.multiHandlingMode == 'remove') {
      this.startRemoveSelected();
      return;
    }
    this.isShowConfirmMultiHandlingWindow = true;
  }

  public startRemoveSelected() {
    this.isShowMultiRemoveWindow = true;
  }

  public showAvaMultiFollowupModal(): void {
    if (!this.multiHandlingMode || this.multiHandlingMode == 'remove') {
      return;
    }
    const data = this.populateFollowUpMultiModalData();

    this._modalContainerService
      .openFollowupDialog(this.multiHandlingMode, data)
      .pipe(
        switchMap((ref) => ref.afterClosed()),
        take(1)
      )
      .subscribe((result) => {
        if (result) {
          const ids = data.ids;
          if (this.cardList) {
            const cardData = this.cardList;
            this.cardList = [];
            this.cardList = cardData.filter((x) => !ids.includes(x.id));
            this.setFilter();
            this.selectedPatients = [];
          }
        }
      });
  }

  public sortCardList(field: keyof AvasPatient): void {
    this.displayCardList.sort((a: AvasPatient, b: AvasPatient): number => {
      const aName = `${a.firstname} ${a.lastname}`;
      const bName = `${b.firstname} ${b.lastname}`;
      return (aName > bName ? 1 : -1) * this.sortDirection;
    });
    this.sortDirection *= -1;
  }

  private populateFollowUpMultiModalData(): FollowUpMultiModalData {
    return new FollowUpMultiModalData({
      ids: (this.selectedPatients || []).map((x) => x.id),
      isPatient: false,
      patients: this.selectedPatients.filter((x) => !x.isLead).slice(),
      leads: this.selectedPatients.filter((x) => x.isLead).slice(),
      isLead: null,
      followUpId: this.followUpId,
      followUpCategoryId: this.followUpCategoryId,
    });
  }
  public stopPatient(event: any): void {
    this.recordList.forEach((child) => {
      child.nativeElement.setAttribute('class', 'record-list');
    });

    const recordList = event.target.parentElement.parentElement;
    recordList.setAttribute('class', 'record-list stoppatient-opened');
  }

  public showPatientDetailsModal(details: any, area: PatientDetailsArea, communicationType: CommunicationType = CommunicationType.Default): void {
    this._modalContainerService.open_dialog(<ModalData>{
      id: details.id,
      isLead: details.isLead,
      patientDetailsArea: area,
      communicationType: communicationType,
    });
  }

  public closeStop(action: string, followUpCategoryId: number, patient: AvasPatient = null): void {
    if (action === 'confirmStop') {
      this._remoteService
        .post<boolean>(`avasfollowup/removefromteamdecision/${patient.id}/${followUpCategoryId}/${patient.isLead}`)
        .subscribe((result) => {
          if (this.cardList) {
            const cardData = this.cardList;
            this.cardList = [];
            this.cardList = cardData.filter((x) => x !== patient);
          }
        });
    }

    this.recordList.forEach((child) => {
      child.nativeElement.setAttribute('class', 'record-list');
    });
  }

  public getLeadSource(person: AvasPatient): string {
    return person.leadSource != null && person.leadSource.trim() != '' && person.leadSource.toLowerCase() != 'null' ? '- ' + person.leadSource : '';
  }

  public openPopupActionMenu(event: any): void {
    const actionMenuElement = event.target.parentElement.getElementsByClassName('action-menu');

    this.Stop.forEach((child) => {
      child.nativeElement.setAttribute('class', 'stop');
    });

    if (actionMenuElement[0].classList.contains('show')) {
      actionMenuElement[0].setAttribute('class', 'action-menu');
    } else {
      this.Actionmenu.forEach((child) => {
        child.nativeElement.setAttribute('class', 'action-menu');
      });

      actionMenuElement[0].setAttribute('class', 'action-menu popup show');
    }
  }

  public stopPopupPatient(event: any): void {
    const stopElement = event.target.parentElement.parentElement.getElementsByClassName('stop');
    stopElement[0].setAttribute('class', 'stop popup show');
  }

  public closePopupStop(event: any, action: string, followUpCategoryId: number, patient: AvasPatient = null): void {
    if (action == 'confirmStop') {
      this._remoteService.post<boolean>(`avasfollowup/removefromteamdecision/${patient.id}/${followUpCategoryId}/${patient.isLead}`).subscribe();
    }

    const stopElement = event.target.parentElement.parentElement;
    stopElement.setAttribute('class', 'stop');
  }

  public showMoreCardPatients(): void {
    this.teamdShowCardPatients = true;
  }

  public hideMoreCardPatients(): void {
    this.teamdShowCardPatients = false;

    this.selectedConsult = 'All';
    this.checkOptions = [
      { name: 'auto', value: 1, checked: false },
      { name: 'set', value: 2, checked: false },
      { name: 'remind', value: 3, checked: false },
    ];
  }

  public showAvaFollowupModal(type: 'auto' | 'set' | 'remind', patient: AvasPatient): void {
    this._modalContainerService.openFollowupDialog(type, this._setAvaFollowupObj(patient), true);
  }

  public clickOutsideEvents(): void {
    this.recordList.forEach((child) => {
      child.nativeElement.setAttribute('class', 'record-list');
    });
  }

  private _setAvaFollowupObj(obj: AvasPatient): FollowUpModalData {
    return new FollowUpModalData({
      id: obj.id,
      isLead: obj.isLead,
      followUpId: this.followUpId,
      followUpCategoryId: this.followUpCategoryId,
    });
  }
}
