import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, share, switchMap, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { ServiceProvider } from 'src/app/service';
import { AuthStoreSelectors } from '../auth-store';
import { LocationStoreSelectors } from '../location-store';
import { State } from '../root-state';
import * as BrandStoreActions from './actions';
import * as BrandStoreSelectors from './selectors';
import { BrandStoreEntity } from './state';

@Injectable({ providedIn: 'root' })
export class BrandStoreEffects implements OnInitEffects {
  constructor(private _store$: Store<State>, private _actions$: Actions, private _serviceProvider: ServiceProvider) {}

  ngrxOnInitEffects(): Action {
    return BrandStoreActions.Init();
  }

  loadOnLogin$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BrandStoreActions.Init),
      switchMap(() =>
        forkJoin({
          userInfo: this._store$.select(AuthStoreSelectors.selectUserInfo).pipe(
            filter((l) => !!l),
            take(1)
          ),
          isAdmin: this._store$.select(AuthStoreSelectors.selectUserIsInRoles(['Administrator', 'CustomerAdmin'])).pipe(
            filter((r) => r != null),
            take(1)
          ),
        })
      ),
      map(({ userInfo, isAdmin }) => BrandStoreActions.LoadRequest({ teamMemberId: userInfo.teamMemberId, isAdmin }))
    )
  );

  selectSuccessEffect$: Observable<Action> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BrandStoreActions.SelectSuccess),
        tap((action) => {
          sessionStorage.setItem('selectedBrandId', action.brand.brandId.toString());
        })
      ),
    { dispatch: false }
  );

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(BrandStoreActions.LoadRequest),
      switchMap((action) =>
        this._serviceProvider.get<BrandStoreEntity[]>(`Brand/GetBrands`).pipe(
          map((result) => BrandStoreActions.LoadSuccess({ brands: result })),
          catchError((res) => of(BrandStoreActions.LoadFailure({ error: res })))
        )
      )
    )
  );

  loadSuccessSelectRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(BrandStoreActions.LoadSuccess),
      withLatestFrom(this._store$.select(BrandStoreSelectors.getSelectedBrandId)),
      filter(([, id]) => id == null || id == undefined),
      switchMapTo(this._store$.select(LocationStoreSelectors.getSelectedLocation).pipe(filter((l) => !!l))),
      map((selectedLocation) => BrandStoreActions.SelectRequest({ id: selectedLocation.brandId }))
    )
  );

  selectRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(BrandStoreActions.SelectRequest),
      withLatestFrom(this._store$.select(BrandStoreSelectors.selectBrandsState)),
      switchMap(([action, state]) => {
        if (state.ids.length > 0 && state.entities[action.id]) {
          return of(BrandStoreActions.SelectSuccess({ brand: state.entities[action.id] }));
        } else if (state.ids.length > 0 && action.id == null) {
          return of(BrandStoreActions.SelectSuccess({ brand: state.entities[state.ids[0]] }));
        } else {
          return of(BrandStoreActions.SelectFailure({ error: 'No Brands loaded' }));
        }
      })
    )
  );

  addRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BrandStoreActions.AddRequest),
      switchMap((action) =>
        this._serviceProvider.post<BrandStoreEntity>(`Brand/UpdateOrCreateBrand/${encodeURIComponent(action.brand.name)}/0`).pipe(
          take(1),
          map((result) =>
            BrandStoreActions.AddSuccess({
              brand: result,
            })
          ),
          catchError((err) => of(BrandStoreActions.AddFailure({ error: err })))
        )
      ),
      share()
    )
  );

  updateRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BrandStoreActions.UpdateRequest),
      switchMap((action) =>
        this._serviceProvider
          .post<BrandStoreEntity>(`Brand/UpdateOrCreateBrand/${encodeURIComponent(action.brand.name)}/${action.brand.brandId}`)
          .pipe(
            take(1),
            map((result) => BrandStoreActions.UpdateSuccess({ brand: result })),
            catchError((err) => of(BrandStoreActions.UpdateFailure({ error: err })))
          )
      ),
      share()
    )
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(BrandStoreActions.DeleteRequest),
      switchMap((action) =>
        this._serviceProvider.delete<boolean>(`brand/delete/${action.brandId}`).pipe(
          switchMap((result) => {
            return result
              ? of(BrandStoreActions.DeleteSuccess({ brandId: action.brandId }))
              : of(BrandStoreActions.DeleteFailure({ error: 'Unknown error' }));
          }),
          catchError((err) => of(BrandStoreActions.DeleteFailure({ error: err })))
        )
      ),
      share()
    )
  );

  setTeamMemberForAppointmentRemindersEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(BrandStoreActions.SetTeamMemberForAppointmentReminderRequest),
      switchMap((action) =>
        this._serviceProvider.put<boolean>(`TeamSettings/UpdateDefaultTeamMemberId/${action.teamMemberId}/${action.brandId}`).pipe(
          switchMap((result) => {
            return result
              ? of(BrandStoreActions.SetTeamMemberForAppointmentReminderSuccess({ brandId: action.brandId, teamMemberId: action.teamMemberId }))
              : of(
                  BrandStoreActions.SetTeamMemberForAppointmentReminderFailure({
                    brandId: action.brandId,
                    teamMemberId: action.teamMemberId,
                    error: 'There was an error while trying to update the team member',
                  })
                );
          }),
          catchError((err) =>
            of(
              BrandStoreActions.SetTeamMemberForAppointmentReminderFailure({
                brandId: action.brandId,
                teamMemberId: action.teamMemberId,
                error: 'There was an error while trying to update the team member',
              })
            )
          )
        )
      ),
      share()
    )
  );

  private _getCachedBrandId(): number {
    return Number(sessionStorage.getItem('selectedBrandId'));
  }
}
