/* eslint-disable */
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bridge-sync-failed',
  templateUrl: './bridge-sync-failed.component.html',
  styleUrls: ['./bridge-sync-failed.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BridgeSyncFailedComponent {
  constructor(
    public dialogRef: MatDialogRef<BridgeSyncFailedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { userName: string }) {}
}
