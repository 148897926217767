import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmergePayTransactionResponse } from '../models/EmergePayTransactionResponse';

export type EmergePay = {
  open: (options: EmergePayOptions) => void;
  close: () => unknown;
  init: () => void;
};

export type EmergePayOptions = {
  transactionToken: string;
  onTransactionSuccess?: (successData: EmergePayTransactionResponse) => void;
  onTransactionFailure?: (failureData) => void;
  onTransactionCancel?: (cancelData: EmergePayTransactionResponse) => void;
};

@Injectable({ providedIn: 'root' })
export class EmergePayService {
  private get _emergepay(): EmergePay {
    return window['emergepay'];
  }

  open(transactionToken: string): Observable<EmergePayTransactionResponse> {
    return new Observable((subscriber) => {
      const emergepay = this._emergepay;
      emergepay.open({
        transactionToken: transactionToken,
        onTransactionSuccess: (result) => {
          subscriber.next(result);
          emergepay.close();
          subscriber.complete();
        },
        onTransactionFailure: (result) => {
          subscriber.error(result);
        },
        onTransactionCancel: (result) => {
          subscriber.next(result);
          subscriber.complete();
        },
      });
    });
  }

  openCallback(
    transactionToken: string,
    successCallback: (arg1: EmergePayTransactionResponse, arg2: EmergePay) => void,
    failureCallback: (arg1: EmergePayTransactionResponse, arg2: EmergePay) => void = null,
    cancelCallBack: (arg1: EmergePay) => void = null
  ): void {
    const emergepay = this._emergepay;
    emergepay.open({
      transactionToken: transactionToken,
      onTransactionSuccess: (result) => successCallback(result, emergepay),
      onTransactionFailure: (result) => failureCallback(result, emergepay),
      onTransactionCancel: () => cancelCallBack(emergepay),
    });
  }
}
