import { Component, Input } from '@angular/core';
import { ComponentBase } from 'src/app/core/ComponentBase';
import { AdjustmentItem, TreatmentTypeDto } from 'src/app/services/api.service';

@Component({
  selector: 'app-case-treatment-type-editor',
  templateUrl: './case-treatment-type-editor.component.html',
  styleUrls: ['./case-treatment-type-editor.component.scss'],
})
export class CaseTreatmentTypeEditorComponent extends ComponentBase {
  @Input('value') treatmentType: TreatmentTypeDto;

  __addNewDiscount(): void {
    this.treatmentType.discount.push(
      new AdjustmentItem({
        amount: 0,
        isPercent: false,
        description: '',
        includeDownPayment: false,
      })
    );
  }

  __removeDiscount(discount: AdjustmentItem, index: number): void {
    this.treatmentType.discount.splice(index, 1);
  }

  __addNewAddOn(): void {
    this.treatmentType.addOns.push(
      new AdjustmentItem({
        amount: 0,
        isPercent: false,
        description: '',
        includeDownPayment: false,
      })
    );
  }

  __removeAddOn(addon: AdjustmentItem, index: number): void {
    this.treatmentType.addOns.splice(index, 1);
  }
}
