/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getSelectedLocationId } from '../location-store/selectors';
import { USERS_FEATURE_KEY } from './featurekey';
import { UserStoreEntity } from './models';
import { featureAdapter, State } from './state';

export const selectUsersState = createFeatureSelector<State>(USERS_FEATURE_KEY);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectUsersState);
export const selectUserIds = selectIds;
export const selectUsersTotal = selectTotal;
export const selectAllUsers = selectAll;
export const selectAllUserEntities = selectEntities;

export const selectUserById = (id: number) =>
  createSelector(selectAllUsers, (allUsers: UserStoreEntity[]) => {
    if (allUsers) {
      return allUsers.find((c) => c.id === id);
    } else {
      return null;
    }
  });

export const getSelectedUserId = createSelector(selectUsersState, (state) => state.selectedUserId);

export const getSelectedUser = createSelector(selectUsersState, (state) => state.entities[state.selectedUserId]);

export const selectUsersError = createSelector(selectUsersState, (state) => state.error);

export const selectUsersIsLoading = createSelector(selectUsersState, (state) => state.isLoading);

export const selectLoggedUserId = createSelector(selectUsersState, (state) => state.loggedUserId);

export const selectLoggedUser = createSelector(selectAllUsers, selectLoggedUserId, (users, userId) => users && users.find((u) => u.id == userId));

export const selectUsersForLocation = (locationId: number) =>
  createSelector(
    selectAllUsers,
    (users) =>
      users &&
      users.filter((u) =>
        u.locationIds
          .split(',')
          .map((x) => Number(x))
          .includes(locationId)
      )
  );

export const selectUsersForActiveLocation = createSelector(selectAllUsers, getSelectedLocationId, (users, locationId) =>
  users.filter((u) =>
    u.locationIds
      .split(',')
      .map((id) => Number(id))
      .includes(locationId)
  )
);
