import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, ReplaySubject, forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, share, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { isNullOrUndefined } from 'util';
import { RootStoreState } from '../root-store';
import { AuthStoreActions, AuthStoreEffects, AuthStoreSelectors } from '../root-store/auth-store';
import { UserStoreRequestResult } from '../root-store/user-store/models';
import { LogoutService } from '../shared/logoutService';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _currentUser$ = new ReplaySubject<AvaUser>(0);
  public currentUser$ = this._currentUser$.asObservable();

  constructor(
    private _http: HttpClient,
    private _logoutService: LogoutService,
    private _snackbar: MatSnackBar,
    private _store$: Store<RootStoreState.State>,
    private _authEffects: AuthStoreEffects
  ) {
    this._currentUser$.next(this.User);
  }

  public Login(tokenRequest: { username: string; password?: string; grant_type: string }): Observable<LoginResult> {
    const obs = this._http
      .post<TokenRequestResponse>(`${environment.ServerPath}api/Authorize/token`, new HttpParams({ fromObject: tokenRequest })) //make post to endpoint and convert request to x-www-form-urlencoded (HttpParams)
      .pipe(
        share(), //pipe request into multicasting share to prevent http call being made multiple times
        map(
          (response): AvaUser => {
            //maps to user
            return {
              Auth: {
                Created: new Date(Date.now()),
                RefreshToken: response.refresh_token,
                Token: response.access_token,
                TokenExpiresIn: response.expires_in,
                TokenType: response.token_type,
              },
              ClientName: '',
              UserName: '',
              CustomerId: 0,
              TeamMemberId: 0,
              Roles: null,
              IntercomUser: null,
            };
          }
        ),
        concatMap((user) => {
          //followup by fetching ava specifics of user
          return this._http
            .get<UserInfoResponse>(`${environment.ServerPath}api/Authorize/Info`, {
              headers: new HttpHeaders({ Authorization: `${user.Auth.TokenType} ${user.Auth.Token}` }),
            })
            .pipe(
              share(), //share to prevent multiple http calls
              map(
                (infoResponse): AvaUser => {
                  //map responese onto the user and pass it further along
                  user.ClientName = infoResponse.clientName;
                  user.UserName = infoResponse.userName;
                  user.CustomerId = infoResponse.customerId;
                  user.TeamMemberId = infoResponse.teamMemberId;
                  user.Roles = infoResponse.roles;
                  user.IntercomUser = infoResponse.intercomUser;
                  return user;
                }
              )
            );
        }),
        map(
          (user): LoginResult => {
            //finalize user into storage and return success result
            this.User = user;
            this._currentUser$.next(user);
            return { Status: true, Message: 'Login successful', TeamMemberId: user.TeamMemberId };
          }
        ),
        catchError(
          (err): Observable<LoginResult> => {
            if (err.error instanceof Error) {
              //client side error
              this._snackbar.open('Error when trying to contact the server. Please try again or contact support for help.', 'Error');
            } else if (err instanceof HttpErrorResponse) {
              if (err.error != null && 'error_description' in err.error)
                //authentication failed
                return of({ Status: false, Message: err.error.error_description, TeamMemberId: null });
              else return of({ Status: false, Message: 'Login failed', TeamMemberId: null }); //Perhaps the call for user info failed?
            }
          }
        )
      );
    return obs;
  }

  public ValidateTokenObs(): Observable<boolean> {
    return forkJoin({
      isTokenValid: this._store$.select(AuthStoreSelectors.selectTokenIsValid(Date.now())).pipe(take(1)),
      oAuth: this._store$.select(AuthStoreSelectors.selectOAuth).pipe(take(1)),
    }).pipe(
      switchMap(({ isTokenValid, oAuth }) => {
        if (!isTokenValid && oAuth != null) {
          setTimeout(() => this._store$.dispatch(AuthStoreActions.RefreshToken()));
          return this._authEffects.refreshResult$.pipe(
            map((action) => (action.type == AuthStoreActions.RefreshTokenSuccess.type ? true : false)),
            catchError((_) => of(false))
          );
        }
        return of(true);
      }),
      switchMap((result) => {
        if (result === false) {
          this._logoutService.logout();
          return EMPTY;
        }
        return of(true);
      })
    );
  }

  public ValidateToken(): Promise<boolean> {
    return this.ValidateTokenObs().toPromise();
  }

  private _SetClientName(client: { customerId: number; clientName: string }, teamMemberId: number = null): void {
    const user = this.User;
    user.CustomerId = client.customerId;
    user.ClientName = client.clientName;
    if (teamMemberId && teamMemberId > 0) user.TeamMemberId = teamMemberId;
    this.User = user;
  }

  public SwitchClient(customerId: number, clientName: string): void {
    this._store$
      .select(AuthStoreSelectors.selectOAuth)
      .pipe(
        take(1),
        map((oAuth) => ({
          Authorization: oAuth.token_type + ' ' + oAuth.access_token,
          'x-client-name': clientName,
        })),
        switchMap((headers) =>
          // Fetch team members from upcoming client so that we can use an admin's team member Id
          this._http.get<UserStoreRequestResult>(`${environment.ServerPath}api/TeamSettings/UserList`, { headers: headers })
        ),
        take(1)
      )
      .subscribe((result) => {
        if (result.data) {
          // Find first team member with admin access
          const adminTeamMember = result.data.find(
            (t) => (t.accessLevel == 'Administrator' || t.accessLevel == 'CustomerAdmin') && t.isActive && !t.isLocked
          );

          this._authEffects.changeClient$.pipe(take(1)).subscribe((action) => {
            if (action.type == AuthStoreActions.ChangeClientSucess.type) setTimeout(() => window.location.reload());
          });

          // Clear items from session so that they will be set to new brand and location
          sessionStorage.removeItem('selectedBrandId');
          sessionStorage.removeItem('selectedLocationId');

          // Set session storage to have updated customerId, clientName, and admin team member Id
          this._store$.dispatch(AuthStoreActions.ChangeClient({ customerId, clientName, teamMemberId: adminTeamMember && adminTeamMember.id }));
        }
      });
  }

  //Checks if AuthUser exists in the store and then checks if their token is expired if they do, otherwise returns false
  private get _TokenIsExpired(): boolean {
    const user = this.User;
    if (!isNullOrUndefined(this.User)) {
      //make sure they are logged in
      const tokenExpiration = new Date(user.Auth.Created).setSeconds(user.Auth.Created.getSeconds() + user.Auth.TokenExpiresIn);
      return tokenExpiration < Date.now(); //is token expiration time before now?
    }
    return false; //either they are not logged in or AuthUser isn't set
  }

  //fetch and parse user from storage
  public get User(): AvaUser {
    const jsonUser = sessionStorage.getItem('AuthUser');
    if (!isNullOrUndefined(jsonUser)) {
      const user = JSON.parse(jsonUser, this._Reviver) as AvaUser;
      return user;
    }
    return null;
  }

  //stringify and store user into storage
  public set User(user: AvaUser) {
    sessionStorage.setItem('AuthUser', JSON.stringify(user));
  }

  //Simple custom reviver that will convert JSON formatted dates automatically to date objects
  private _Reviver(key: unknown, value: unknown): unknown | Date {
    if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d{0,3}Z$/.test(value)) {
      return new Date(value);
    }

    return value;
  }
}

export class AvaUser {
  Auth: AvaOAuthToken;
  TeamMemberId: number;
  CustomerId: number;
  ClientName: string;
  UserName: string;
  Roles: string[];
  IntercomUser: IntercomUser;
}

export class AvaOAuthToken {
  Token: string;
  TokenType: string;
  TokenExpiresIn: number;
  RefreshToken: string;
  Created: Date;
}

export class LoginResult {
  Status: boolean;
  Message: string;
  TeamMemberId: number;
}

export class TokenRequestResponse {
  scope: string;
  token_type: string;
  access_token: string;
  expires_in: number;
  refresh_token: string;
}

class UserInfoResponse {
  userId: string;
  teamMemberId: number;
  customerId: number;
  clientName: string;
  userName: string;
  roles: string[];
  intercomUser: IntercomUser;
}

export class IntercomUser {
  [key: string]: unknown;
  customer_name: string;
  name: string;
}
