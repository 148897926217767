import { createAction, props } from '@ngrx/store';
import { LocationStoreEntity } from './state';

export const Init = createAction('[Locations] Init');

//LOAD PAGE
export const LoadRequest = createAction('[Locations] Load Request', props<{ teamMemberId: number }>());
export const LoadSuccess = createAction('[Locations] Load Success', props<{ locations: LocationStoreEntity[] }>());
export const LoadFailure = createAction('[Locations] Load Failure', props<{ error: string | unknown }>());

//SELECT
export const SelectRequest = createAction('[Locations] Select Request', props<{ id: number }>());
export const SelectSuccess = createAction('[Locations] Select Success', props<{ location: LocationStoreEntity }>());
export const SelectFailure = createAction('[Locations] Select Failure', props<{ error: string | unknown }>());

export const UpdateRequest = createAction('[Locations] UpdateRequest', props<{ location: LocationStoreEntity }>());
export const UpdateSuccess = createAction('[Locations] UpdateSuccess', props<{ location: LocationStoreEntity }>());
export const UpdateFailure = createAction('[Locations] UpdateFailure', props<{ error: string | unknown }>());

export const UpdateNPSDisplayName = createAction(
  '[Locations] UpdateNPSDisplayName',
  props<{ values: { locationId: number; npsDisplayName: string }[] }>()
);
