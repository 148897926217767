import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootStoreState } from '../root-store';
import { AuthStoreActions } from '../root-store/auth-store';

@Injectable()
export class LogoutService {
  constructor(private _router: Router, private _store$: Store<RootStoreState.State>) {}

  logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    this._store$.dispatch(AuthStoreActions.Logout());
    this._router.navigate(['login']);
  }
}
