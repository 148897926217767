import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-case-item-list',
  templateUrl: './case-item-list.component.html',
  styleUrls: ['./case-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CaseItemListComponent<T extends { id: number; name?: string }> implements ICaseItemListComponent<T> {
  @Input('items') items: T[];
  @Input('mode') mode: CaseItemListMode = 'select';
  @Output('itemActivated') itemActivated = new EventEmitter<T>();
  @Output('itemDeleted') itemDeleted = new EventEmitter<T>();
  @Output('itemEdit') itemEdit = new EventEmitter<T>();

  @Input('selected') selectedItems: T[] = [];
  @Output('selectedChange') selectedItemsChange = new EventEmitter<T[]>();

  __compareWithFn(a: T, b: T): boolean {
    return a.id == b.id;
  }
}

export type CaseItemListMode = 'edit' | 'select' | 'inplaceedit';
export interface ICaseItemListComponent<T extends { name?: string }> {
  items: T[];
  mode: CaseItemListMode;
  itemActivated: EventEmitter<T>;
  itemDeleted: EventEmitter<T>;
  selectedItems: T[];
  selectedItemsChange: EventEmitter<T[]>;
}
