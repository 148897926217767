import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { SurveyCategory, SurveyQuestion, SurveyQuestionAnswer, SurveyQuestionType, SurveyStructure } from 'src/app/models/NewPatientSurvey';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-new-patient-survey',
  templateUrl: './new-patient-survey.component.html',
  styleUrls: ['./new-patient-survey.component.css'],
})
export class NewPatientSurveyComponent {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  subs = new SubSink();
  customerId: string;
  newPatientTrackId: string;
  surveyStructure: SurveyStructure;
  activedStep = 0;
  SurveyQuestionType = SurveyQuestionType;
  surveyForm: FormGroup;
  submitted: boolean = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private _http: HttpClient,
    private _snackbar: MatSnackBar,
    private _spinner: SpinnerVisibilityService
  ) {
    this._spinner.show();
    this.subs.sink = this._activatedRoute.paramMap.subscribe((params) => {
      this.customerId = params.get('customerId');
      this.newPatientTrackId = params.get('newPatientTrackId');
      this.loadSurveyStructure();
    });
    this.surveyForm = this._fb.group({
      version: ['1.0'],
      answers: this._fb.group({}),
    });
  }

  loadSurveyStructure(): void {
    this.subs.sink = this._http
      .get<SurveyStructure>(`${environment.ServerPath}api/public/NPT/${this.customerId}/${this.newPatientTrackId}/survey`)
      .subscribe(
        (response) => {
          this.surveyStructure = response;
          this.buildSurveyForm();
          this._spinner.hide();
        },
        (err: HttpErrorResponse) => {
          this._spinner.hide();
          if (err.status == 409)
            //patient already has a submitted survey
            this.submitted = true;
          else this._snackbar.open('Error when loading survey.', 'OK', { duration: 5000 });
        }
      );
  }

  /// Add form controls to answers group for html elements to bind to
  buildSurveyForm(): void {
    this.surveyStructure.questions.forEach((q) => {
      (<FormGroup>this.surveyForm.get('answers')).addControl(q.propertyName, this._fb.control(null, q.required ? [Validators.required] : []));
    });
  }

  // Dynamically create a form control for the step to validate on
  getStepControl(category: SurveyCategory): AbstractControl[] {
    const questions = this.surveyStructure.questions.filter((q) => q.category == category.id);
    const ar = questions.map((q) => this.surveyForm.get('answers').get(q.propertyName));
    return ar;
  }

  /// Get form categories sorted by id
  getCategories(): SurveyCategory[] {
    return this.surveyStructure.categories.sort((a, b) => a.id - b.id);
  }

  /// Get form questions sorted by order property
  getQuestions(category: SurveyCategory): SurveyQuestion[] {
    return this.surveyStructure.questions.filter((q) => q.category == category.id).sort((a, b) => a.order - b.order);
  }

  /// Get answers from question sorted by order property
  getAnswers(question: SurveyQuestion): SurveyQuestionAnswer[] {
    return question.answers.sort((a, b) => a.order - b.order);
  }

  isStepValid(category: SurveyCategory): boolean {
    const questions = this.surveyStructure.questions.filter((q) => q.category == category.id);
    const ar = questions.map((q) => this.surveyForm.get('answers').get(q.propertyName));
    return ar.filter((x) => x.status !== 'VALID').length > 0;
  }

  saveSurvey(): void {
    this._spinner.show();
    this.stepper.steps.forEach((s) => (s.editable = false));
    this.subs.sink = this._http
      .post<unknown>(`${environment.ServerPath}api/public/NPT/${this.customerId}/${this.newPatientTrackId}/survey`, this.surveyForm.value)
      .subscribe(
        () => {
          this._snackbar.open('Survey saved!', 'OK', { duration: 2500 });
          this.submitted = true;
          this._spinner.hide();
        },
        () => {
          this._snackbar.open('Error when attempting to save survey', 'OK', { duration: 2500 });
          this._spinner.hide();
        }
      );
  }
}
