import { coerceArray, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay, scan } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { isNullOrUndefined } from 'util';

/**
 * This base class is to be used to extend components with often used functionality. Functions
 * should be as lightweight as possible and have value across a large portion of the app.
 */
export abstract class ComponentBase implements OnDestroy {
  /**
   * Utility for ensuring all subscriptions are unsubscribed on component destruction
   */
  protected __subSink: SubSink = new SubSink();
  ngOnDestroy(): void {
    this.__subSink.unsubscribe();
  }

  /**
   * Emits the value of how much workingCount$ is adjusted by
   */
  private _isWorking$ = new BehaviorSubject<number>(0);
  /**
   * Utility observable for keeping track of the number of working processes. Most useful for
   * when running HTTP request and a value greater than zero indicates if a loading indicator
   * should be displayed.
   */
  public __workingCount$ = this._isWorking$.asObservable().pipe(
    delay(0), // adding `delay(0)` helps prevent 'Expression has been changed...` error https://blog.angular-university.io/angular-debugging/
    scan((counter: number, change: number) => (counter + change >= 0 ? counter + change : 0))
  );
  /**
   * Adjust the workingCount$ by a value
   * @param count Number by which to adjust workingCount$'s value
   * @returns {void}
   */
  protected __working = (count: number = 1): void => this._isWorking$.next(Math.abs(count));
  /**
   * Utility function for `working(-1)` that provides some semantic readability value to code
   * @returns {void}
   */
  protected __doneWorking = (): void => this._isWorking$.next(-1);

  /**
   * Coerce a value, typically a string, to a boolean property
   * @param {unknown} value Value to be coerced
   * @returns {boolean} boolean
   */
  protected __coerceBooleanProperty(value: unknown): boolean {
    return coerceBooleanProperty(value);
  }

  /**
   * Coerce a value, typically a string, to a number property
   * @param {unknown} value Value to be coerced
   */
  protected __coerceNumberProperty(value: unknown): number;
  /**
   * Coerce a value, typically a string, to a number property
   * @param {unknown} value Value to be coerced
   * @param {T} fallback Fallback when value cannot be coerced to valid number
   */
  protected __coerceNumberProperty<T>(value: unknown, fallback: T): T;
  protected __coerceNumberProperty<T>(value: unknown, fallback?: T): number | T {
    return coerceNumberProperty(value, fallback);
  }

  /**
   * Coerce a value to an array
   * @param value value to be coerced
   * @returns {T[]} Array of value type
   */
  protected __coerceArray<T>(value: T | T[]): T[] {
    return coerceArray(value);
  }

  /**
   * Create a trackByFn which tracks entities based upon the supplied field
   * @param trackField Field which to track items by
   * @returns {(index: number, item: T) => number | unknown} Function
   */
  protected __trackByFieldFn<T>(trackField: keyof T): (index: number, item: T) => number | unknown {
    return (index, item): number | unknown => this.__trackByFn(index, item, trackField);
  }

  /**
   * Standard trackByFn that when used will track entities by their index. Ideal for index-less items.
   * @param {number} index
   * @param {T} item
   * @param {keyof T} trackField
   * @returns {number | unknown}
   */
  protected __trackByFn<T>(index: number, item: T, trackField?: keyof T): number | unknown {
    if (trackField) {
      return item[trackField];
    } else {
      return index;
    }
  }

  /**
   * Calculate if a value is equal to null or undefined
   * @param {unknown} value Any nullable value
   * @returns {boolean} value indicating if value is equal to null or undefined
   */
  protected __isNullOrUndefined(value: unknown): value is null | undefined {
    return isNullOrUndefined(value);
  }

  protected __defineElementAs<T>(element: unknown): T {
    return element as T;
  }
}
