import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SignalREffects, signalrReducer } from 'ngrx-signalr-core';
import { AuthStoreModule } from './auth-store/auth-store.module';
import { DashboardHubStoreModule } from './automation-followup-store';
import { BrandStoreModule } from './brands-store';
import { ContractTemplateStoreModule } from './contract-templates-store';
import { FollowUpCategoryStoreModule } from './followup-category-store';
import { LocationStoreModule } from './location-store';
import { SignalrHubStoreModule } from './signalr-store/signalr-store.module';
import { TenentSettingsStoreModule } from './tenant-settings-store';
import { UserStoreModule } from './user-store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LocationStoreModule,
    BrandStoreModule,
    DashboardHubStoreModule,
    UserStoreModule,
    FollowUpCategoryStoreModule,
    SignalrHubStoreModule,
    ContractTemplateStoreModule,
    TenentSettingsStoreModule,
    StoreModule.forRoot({ signalR: signalrReducer }),
    EffectsModule.forRoot([SignalREffects]),
    AuthStoreModule,
  ],
})
export class RootStoreModule {}
