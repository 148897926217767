import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { BrandStoreSelectors, RootStoreState } from 'src/app/root-store';
import { AvasPatient } from '../models/Dashboard';
import { MultiPatientDetailsResponse } from '../models/MultiPatientDetailsResponse';
import { PatientSearchResultResponsibleParty } from '../models/PatientSearch';
import { ServiceProvider } from '../service';
import {
  AvasFollowUpClient,
  AvasPatientResponseDto,
  AvasPatientsRequestDto,
  GetPatientByIdDto,
  IPerson,
  PatientAppointmentDetailDto,
  PatientClient,
} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  private _subject = new Subject<{ patientId: number; responsibleParty: PatientSearchResultResponsibleParty }>();

  constructor(
    private _httpService: ServiceProvider,
    private _store$: Store<RootStoreState.State>,
    private _patientClient: PatientClient,
    private _followupClient: AvasFollowUpClient
  ) {}

  getPatientDetails(id: number, isLead: boolean): Observable<GetPatientByIdDto> {
    return this._store$.select(BrandStoreSelectors.getSelectedBrandId).pipe(
      filter((id) => !!id),
      take(1),
      switchMap((brandId) => this._patientClient.patient_Details(id, brandId, isLead)),
      take(1)
    );
  }

  getAvasPatients(request: AvasPatientsRequestDto[]): Observable<AvasPatientResponseDto> {
    return this._followupClient.avasFollowUp_GetAvasPatientsById(request);
  }

  getPatientMultiDetails(ids: number[]): Observable<MultiPatientDetailsResponse> {
    return this._patientClient.patient_MultiDetails(ids).pipe(
      map((res) => {
        if (res) {
          return {
            isPatient: false,
            patients: new Array<AvasPatient>(),
            leads: new Array<AvasPatient>(),
            languages: res.languages,
            followUpSequences: res.followUpSequences,
          };
        }
      })
    );
  }

  updateAvaSettings(patient: IPerson): Observable<void> {
    const patRpLink = patient.patientResponsiblePartyLinks ? patient.patientResponsiblePartyLinks.find((l) => l.isDefault) : null;
    const data = {
      isLead: patient.isLead,
      patientId: patient.isLead ? patient.leadId : patient.patientId,
      languageId: patient.languageId,
      teamMemberId: patient.teamMemberId,
      primaryResponsiblePartyId: patRpLink ? patRpLink.responsiblePartyId : null,
    };
    return this._httpService.post(`Patient/UpdateAvaSettings`, data);
  }

  getAppointmentDetails(id: number): Observable<PatientAppointmentDetailDto[]> {
    return this._patientClient.patient_AppointmentDetails(id);
  }

  getScheduledStatusNames(): Observable<string[]> {
    return this._httpService.get<string[]>(`Patient/GetScheduledStatusNames`);
  }

  stopAppointmentReminders(id: number): Observable<void> {
    return this._httpService.post(`Patient/StopAppointmentReminders`, id);
  }

  public sendUpdatedResponsibleParty(patientId: number, rp: PatientSearchResultResponsibleParty): void {
    this._subject.next({ patientId: patientId, responsibleParty: rp });
  }

  public getUpdatedResponsibleParty(): Observable<{ patientId: number; responsibleParty: PatientSearchResultResponsibleParty }> {
    return this._subject.asObservable();
  }

  createPatientLogin(patientId: number, responsiblePartyId: number = null): Observable<unknown> {
    return this._httpService.post(`Patient/CreatePatientLogin`, { patientId, responsiblePartyId });
  }
}
