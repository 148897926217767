/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTOMATION_FOLLOWUP_FEATURE_KEY } from './featurekey';
import { featureAdapter, State } from './state';

export const selectState = createFeatureSelector<State>(AUTOMATION_FOLLOWUP_FEATURE_KEY);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectState);
export const selectPatientIds = selectIds;
export const selectPatientsTotal = selectTotal;
export const selectAllPatients = selectAll;
export const selectAllPatientEntities = selectEntities;

export const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

export const selectPendingPatients = createSelector(selectAllPatients, (patients) => patients && patients.filter((p) => p.cardArea == 'Pending'));
export const selectAvasPatients = createSelector(selectAllPatients, (patients) => patients && patients.filter((p) => p.cardArea == 'Ava'));

// Pending
export const selectPendingPatientMisc = createSelector(selectPendingPatients, (pending) => pending && pending.filter((p) => p.card == 'Misc'));
export const selectPendingPatientNewLeads = createSelector(selectPendingPatients, (pending) => pending && pending.filter((p) => p.card == 'NewLead'));
export const selectPendingPatientNoShow = createSelector(selectPendingPatients, (pending) => pending && pending.filter((p) => p.card == 'NoShow'));
export const selectPendingPatientPending = createSelector(selectPendingPatients, (pending) => pending && pending.filter((p) => p.card == 'Pending'));
export const selectPendingPatientRecall = createSelector(selectPendingPatients, (pending) => pending && pending.filter((p) => p.card == 'Recall'));

export const selectPendingPatientById = (id: number, isLead: boolean) =>
  createSelector(selectPendingPatients, (pending) => pending && pending.find((p) => p.id == id && p.isLead == isLead));

// Avas Patients
export const selectAvasPatientMisc = createSelector(selectAvasPatients, (patients) => patients && patients.filter((p) => p.card == 'Misc'));
export const selectAvasPatientMiscCount = createSelector(selectState, (avas) => avas && avas.avaPatientsCount && avas.avaPatientsCount.Misc);

export const selectAvasPatientNewLead = createSelector(selectAvasPatients, (patients) => patients && patients.filter((p) => p.card == 'NewLead'));
export const selectAvasPatientNewLeadCount = createSelector(selectState, (avas) => avas && avas.avaPatientsCount && avas.avaPatientsCount.NewLead);

export const selectAvasPatientNoShow = createSelector(selectAvasPatients, (patients) => patients && patients.filter((p) => p.card == 'NoShow'));
export const selectAvasPatientNoShowCount = createSelector(selectState, (avas) => avas && avas.avaPatientsCount && avas.avaPatientsCount.NoShow);

export const selectAvasPatientPending = createSelector(selectAvasPatients, (patients) => patients && patients.filter((p) => p.card == 'Pending'));
export const selectAvasPatientPendingCount = createSelector(selectState, (avas) => avas && avas.avaPatientsCount && avas.avaPatientsCount.Pending);

export const selectAvasPatientRecall = createSelector(selectAvasPatients, (patients) => patients && patients.filter((p) => p.card == 'Recall'));
export const selectAvasPatientRecallCount = createSelector(selectState, (avas) => avas && avas.avaPatientsCount && avas.avaPatientsCount.Recall);

export const selectAvasPatientById = (id: number, isLead: boolean) =>
  createSelector(selectAvasPatients, (patients) => patients && patients.find((p) => p.id == id && p.isLead == isLead));
